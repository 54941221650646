import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useData } from "../DataContext/DataProvider";
import {
  checkInstagram,
  checkNativeShare,
  checkWhatsapp,
} from "./UIContextUtils";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { DateTime } from "luxon";
import { useUser } from "../UserContext/UserProvider";

const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [likedFilter, setLikedFilter] = useState(false);
  const [posterFilter, setPosterFilter] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [doubleCollapsed, setDoubleCollapsed] = useState(false);
  const [bottomSheetRef, setBottomSheetRef] = useState(null); //this is the ref to the bottom sheet component in the outlet context
  const [mainMapRef, setMainMapRef] = useState(null); //this is the ref to the main map component in the outlet context
  const { globalConfig } = useData();
  const categories = globalConfig?.categories || [];
  const [likedEvents, setLikedEvents] = useState([]);
  const [activeCategories, setActiveCategories] = useState(
    categories.map((c) => c.id)
  );
  const [availableApps, setAvailableApps] = useState({});
  const [filterDateActive, setFilterDateActive] = useState(false);
  const { user } = useUser();
  const mainContentRef = useRef(null);

  const checkAvailableApps = async () => {
    const apps = {
      instagram: await checkInstagram(),
      whatsapp: await checkWhatsapp(),
      native: await checkNativeShare(),
    };
    setAvailableApps((prev) => ({ ...prev, ...apps }));
  };

  useEffect(() => {
    checkAvailableApps();
    //set dark mode from local storage
    const storageDarkMode = localStorage.getItem("darkMode") || false;
    if (storageDarkMode) {
      setDarkMode(JSON.parse(storageDarkMode));
    }
  }, []);

  const likeEvent = async (event) => {
    // find the event in the likedEvents array
    let likedEvent = likedEvents.find((e) => e._id === event._id);
    let existsInLikedEvents = true;
    // if the event is not in the likedEvents array, add it
    if (!likedEvent) {
      existsInLikedEvents = false;
      likedEvent = {
        ...event,
      };
    }

    //toggle the likes array of the event which is an array of usr ids
    likedEvent.likes = likedEvent.likes?.includes(user?._id)
      ? likedEvent.likes.filter((id) => id !== user?._id)
      : [...likedEvent.likes, user?._id];

    if (existsInLikedEvents) {
      setLikedEvents(
        likedEvents.map((e) => (e._id === likedEvent._id ? likedEvent : e))
      );
    } else {
      setLikedEvents([...likedEvents, likedEvent]);
    }
  };

  useEffect(() => {
    document.body.classList.toggle("dark", darkMode);
    document.body.classList.toggle("default", !darkMode);

    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  const value = {
    availableApps,
    searchOpen,
    setSearchOpen,
    settingsOpen,
    setSettingsOpen,
    collapsed,
    setCollapsed,
    doubleCollapsed,
    setDoubleCollapsed,
    bottomSheetRef,
    setBottomSheetRef,
    mainMapRef,
    setMainMapRef,
    mainContentRef,
    filterDateActive,
    setFilterDateActive,
    likedFilter,
    setLikedFilter,
    posterFilter,
    setPosterFilter,
    activeCategories,
    setActiveCategories,
    likeEvent,
    likedEvents,
    darkMode,
    setDarkMode,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUIContext = () => {
  return useContext(UIContext);
};

export default UIContext;
