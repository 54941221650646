import React from "react";
import { useNavigate } from "react-router-dom";
import { App } from "@capacitor/app";
import { useEffect } from "react";

const AppUrlListener = () => {
  let navigate = useNavigate();

  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
      let url;
      if (event.url.includes("/app")) {
        const path = event.url.split("/app").pop();
        const slug = path?.split("#")[1] || "/";
        // navigate(slug)
        url = slug;
      } else if (event.url.startsWith("inyc://")) {
        // You might need to adjust this logic based on your URL structure
        const path = event.url.replace("inyc://", "");
        // navigate(path.startsWith('/') ? path : `/${path}`)
        url = path.startsWith("/") ? path : `/${path}`;
      }
      // If no match, do nothing - let regular routing
      // logic take over  
      if (!url) {
        return;
      }

      navigate(url);
    });
  }, [navigate]);

  return null;
};

export default AppUrlListener;
