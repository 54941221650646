import React from "react";
import { IoMdClose } from "react-icons/io";

const Tag = (props) => {
  const { onClick, active, children, activeColor, className, ...otherProps } =
    props;

  return (
    <div
      onClick={onClick}
      className={`${className} ${
        active
          ? props.id === "all"
            ? "text-[var(--inyc-secondary-text-color)]"
            : `text-white`
          : "border-[1px] border-[var(--inyc-input-border-color)]"
      }`}
      {...otherProps}
      style={{
        backgroundColor: active && activeColor ? activeColor : null,
        borderColor: active && activeColor ? activeColor : null,
      }}
    >
      {children}
      {/* <div
        className={`overflow-hidden duration-100 text-[var(--inyc-secondary-text-color)] pointer-events-none ${
          active
            ? "translate-x-[0] opacity-[1] w-[15px]"
            : "translate-x-[-15px] opacity-0 w-0"
        }`}
      >
        <IoMdClose
          className={`pointer-events-none `}
          color={
            props.id === "all"
              ? "var(--inyc-secondary-text-color)"
              : "var(--inyc-primary-text-color)"
          }
        />
      </div> */}
    </div>
  );
};

export default Tag;
