import React, { useEffect, useState } from "react";
import CollapsiblePanel from "../CollapsiblePanel/CollapsiblePanel";
import axiosInstance from "../../api/axiosInstance";
import ContentList from "../ContentList/ContentList";
import { backendUrl } from "../../api/constants";
import Button from "../Button/Button";
import textIcon from "../../assets/icons/list-text.png";
import { modal } from "../../util/useModal";

const PendingPosts = ({ refreshTickets, tickets }) => {
  //   const [tickets, setTickets] = useState();

  const cancelTicket = async (tk) => {
    try {
      const prompt = await modal(({ proceed }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>canceling a ticket is an irreversible action</div>
          <div style={{ fontFamily: "Primary-Bold" }}>are you sure you want to continue ?</div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => proceed(true)}>Yes</Button>
            <Button onClick={() => proceed(false)}>No</Button>
          </div>
        </div>
      ))
      if (prompt) {
        const { data } = await axiosInstance.post(`${backendUrl}/business/tickets/cancel/${tk._id}`);
        if (data.error) {
          console.log(data.error);
        } else {
          //refresh component
          refreshTickets();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    // <CollapsiblePanel title="pending posts" style={{ paddingTop: "50px", paddingBottom: 40 }}>
    <div
      style={{
        background: "var(--inyc-primary)",
        padding: "1rem",
        paddingTop: "2rem",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
      }}
    >
      <ContentList
        emptyText="no pending posts"
        optionsPosition={0}
        items={tickets?.map((tk) => {
          return {
            ...tk,
            name: tk.resource?.name,
            venueType: tk.resource?.venueType,
            address: tk.resource?.address,
            createdAt: new Date(tk.createdAt).toLocaleString(),
            status: tk.status ? tk.status.replaceAll("_", " ") : null,
          };
        })}
        options={[
          (item) => {
            if (item.status === "awaiting review") {
              return {
                label: "cancel",
                onClick: cancelTicket,
                style: {
                  backgroundColor: "darkorange",
                  color: "var(--inyc-secondary-text-color)",
                  border: "none",
                },
                hoverStyle: {
                  backgroundColor: "orange",
                },
              };
            }
          },
          (item) => {
            if (item.rejection_message) {
              return {
                hoverText: item.rejection_message,
                style: {
                  border: "none",
                  padding: 10,
                },
                hoverStyle: {
                  backgroundColor: "rgba(0,0,0,0.1)",
                  color: "black",
                },
                icon: textIcon,
                onClick: async (event) => {
                  console.log(event.rejection_message);
                },
              };
            }
          },
        ]}
        useColumns={{
          type: "post type",
          status: "status",
          name: "venue name",
          venueType: "venue type",
          address: "address",
          createdAt: "created",
        }}
      />
    </div>
    // </CollapsiblePanel>
  );
};

export default PendingPosts;
