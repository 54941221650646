import React, { useEffect, useState } from "react";
import "./Checkbox.css";

const CheckBoxYesNo = ({ Ref, defaultChecked = false, onChange, label, value }) => {
  const isControlled = value !== undefined;
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (isControlled) {
      setChecked(value);
    }
  }, [value]);

  const handleChange = (val)=> () => {
    value === undefined && setChecked(val);
    onChange && onChange(val);
  };

  return (
    <div className={`[&>.checked]:bg-[var(--inyc-secondary)] flex justify-center items-center gap-[1rem]`}>
      <div className={`flex items-center bg-transparent  min-w-[30px] h-[30px] cursor-pointer `}>
        <input className="checkbox" type="checkbox" ref={Ref} checked={checked} />
        <div className="flex gap-[5px] items-center justify-start text-[1.2rem]">
          <div onClick={handleChange(true)} className={`duration-200 font-bold rounded-[2rem] px-[1rem] py-[5px] ${checked ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]" : ""} `}>
            yes
          </div>
          <div onClick={handleChange(false)} className={`duration-200 font-bold rounded-[2rem] px-[1rem] py-[5px] ${!checked ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]" : ""} `}>
            no
          </div>
        </div>
      </div>
      {label && <div className="flex items-center h-full whitespace-nowrap text-[1.4rem]">{label}</div>}
    </div>
  );
};

export default CheckBoxYesNo;
