import React, { useImperativeHandle, useRef, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ImageSlider.css";
import addImagesIcon from "../../assets/icons/add-images-icon.png";
import removeImageIcon from "../../assets/icons/remove-image-icon.png";
import Icon from "../Icon/Icon";
import Hover from "../Hover/Hover";
import { useSnackbar } from "notistack";
import { backendUrl } from "../../api/constants";
import { useEffect } from "react";
import { cropImageModal } from "../../util/imageCroper";
import ImageSliderComponent from "./ImageSliderComponent";
import { HiOutlineCamera } from "react-icons/hi2";

const urlsToGalleryItems = async (images) => {
  const imgs = [...images];

  // Asynchronously check each image URL
  const checkedImages = await Promise.all(
    imgs.map(async (img) => {
      // Directly return non-string images or local images
      if (typeof img !== "string") {
        return img;
      }

      // Prepend the backend URL if needed
      let imageUrl = img;
      if (!img.includes("http")) {
        imageUrl = `${backendUrl}/assets/${img}`;
      }

      try {
        // Perform a HEAD request to check if the image URL is valid
        const response = await fetch(imageUrl, { method: "HEAD" });
        if (response.status === 200) {
          return { original: imageUrl }; // Valid image URL
        } else {
          // Invalid image URL, return null
          return null;
        }
      } catch (error) {
        // Error during fetch, return null
        return null;
      }
    })
  );

  return checkedImages.filter(Boolean);
};

const ImageSlider = React.forwardRef(
  ({ images = [], onChange, style, className, edit = false }, ref) => {
    const [hovered, setHovered] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      if (sliderRef.current) {
        sliderRef.current.slideToIndex(currentIndex);
      }
    }, [currentIndex]);

    useEffect(() => {
      if (onChange) {
        onChange(selectedImages);
      }

      if (edit && sliderRef.current) {
        sliderRef.current.slideToIndex(selectedImages.length - 1);
      }
    }, [selectedImages]);

    const previousImagesRef = useRef();
    useEffect(() => {
      if (
        JSON.stringify(images) !== JSON.stringify(previousImagesRef.current)
      ) {
        const handleImages = async () => {
          setSelectedImages(await urlsToGalleryItems(images));
        };
        handleImages();
        previousImagesRef.current = images; // Update the ref with the new images
      }
    }, [images]);

    useImperativeHandle(ref, () => ({
      // Expose functions or values to the parent component through the ref
      getValue: () => {
        return selectedImages?.filter((img) => img.file).map((img) => img.file);
      },
      getURLs: () => {
        return selectedImages
          ?.filter((img) => img.original && !img.file)
          .map((img) => {
            //take only /assets/... part of the url
            return img.original.split("/assets/")[1];
          });
      },
      setValue: async (newValue) => {
        setSelectedImages(await urlsToGalleryItems(newValue));
      },
    }));

    const pickImages = async (add = false, maxImages = 10) => {
      try {
        const fileInput = document.createElement("input");
        fileInput.style.display = "none";
        fileInput.type = "file";
        fileInput.multiple = true;
        fileInput.accept = "image/*";

        document.body.appendChild(fileInput);

        // Listen for the file input change event
        fileInput.addEventListener("change", async (event) => {
          const files = Array.from(event.target.files);
          document.body.removeChild(fileInput); // Remove the input element immediately after use

          if (files.length === 0) {
            return;
          }

          if (files.length > maxImages) {
            enqueueSnackbar(`Maximum ${maxImages} images allowed.`, {
              variant: "error",
            });
            return;
          }

          const processFiles = async (files) => {
            const processedImages = [];
            for (const file of files) {
              const reader = new FileReader();
              const base64 = await new Promise((resolve) => {
                reader.onload = () => resolve(reader.result);
                reader.readAsDataURL(file);
              });

              // Call the cropImageModal to let the user crop the image
              const croppedBase64 = await cropImageModal({
                image: { file, base64 },
                borderRadius: 0,
              });
              if (croppedBase64) {
                // Check if the cropping was not cancelled
                const blob = await fetch(croppedBase64.base64).then((res) =>
                  res.blob()
                );
                const croppedFile = new File([blob], file.name, {
                  type: blob.type,
                });

                processedImages.push({
                  file: croppedFile,
                  original: URL.createObjectURL(croppedFile),
                });
              }
            }
            return processedImages;
          };

          const selectedImageURLs = await processFiles(files);

          // Update the state based on whether images are added or replaced
          if (add) {
            setSelectedImages((prev) => [...prev, ...selectedImageURLs]);
          } else {
            setSelectedImages(selectedImageURLs);
          }
        });

        // Trigger the file input dialog
        fileInput.click();
      } catch (error) {
        enqueueSnackbar("error picking images", { variant: "error" });
      }
    };

    const removeImage = () => {
      setSelectedImages((prev) =>
        prev.filter((im, i) => i !== sliderRef.current.getCurrentIndex())
      );
    };

    // Add this function to determine the appropriate className based on whether images exist
    const getContainerClassName = () => {
      const hasImages = selectedImages && selectedImages.length > 0;
      if (typeof className === 'function') {
        return className(hasImages);
      }
      return className || '';
    };

    return (
      <>
        {((selectedImages && selectedImages.length > 0) || edit) && (
          <div
            className={`relative h-fit max-w-[100%] ${getContainerClassName()}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
          >
            {edit && selectedImages && selectedImages.length > 0 && (
              <>
                <Hover
                  hstyle={styles.hoveredIcon}
                  style={{
                    ...styles.controlIcon,
                    ...styles.addIcon,
                    ...(hovered ? styles.hoveredIconStyle : {}),
                  }}
                  onClick={() => {
                    pickImages(true);
                  }}
                >
                  <Icon src={addImagesIcon} />
                </Hover>
                <Hover
                  hstyle={styles.hoveredIcon}
                  style={{
                    ...styles.controlIcon,
                    ...styles.removeIcon,
                    ...(hovered ? styles.hoveredIconStyle : {}),
                  }}
                  onClick={removeImage}
                >
                  <Icon src={removeImageIcon} />
                </Hover>
              </>
            )}
            {selectedImages && selectedImages.length > 0 ? (
              <>
                <div 
                  style={{ userSelect: 'none' }}
                  onDragStart={(e) => e.preventDefault()}
                >
                  <ImageSliderComponent ref={sliderRef} items={selectedImages} />
                </div>
              </>
            ) : (
              <>
                {edit ? (
                  <div
                    onClick={pickImages}
                    style={{
                      ...styles.noImages,
                      ...(hovered ? styles.noImagesHover : {}),
                    }}
                  >
                    <div
                      className="flex items-center justify-center"
                      style={{
                        ...styles.noImagesImage,
                        ...(hovered ? styles.noImagesImageHover : {}),
                      }}
                    >
                      {/* <Icon src={addImagesIcon} /> */}
                      <div className="flex flex-col items-center text-center">
                        <HiOutlineCamera
                          size={40}
                          className="mb-[0.5rem]"
                          color="var(--inyc-no-image-color)"
                        />
                        <span className="text-[var(--inyc-no-image-color)] font-['Primary-Regular'] text-[1.5rem]">
                          click to upload
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ ...styles.noImages, cursor: "default" }}></div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
);

const styles = {
  imageSlider: {
    // boxShadow: "0 10px 10px rgba(0,0,0,0.05)",
    maxWidth: "100%",
    height: "100%",
    // margin: "20px",
    position: "relative",
  },
  controlIcon: {
    transition: "0.3s",
    opacity: 0,
    backgroundColor: "black",
    padding: "0.5rem",
    position: "absolute",
    zIndex: 1,
    height: "40px",
    width: "40px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
  },
  noImagesImage: {
    width: 140,
    height: 100,
    transition: "0.3s",
  },
  noImages: {
    backgroundColor: "var(--inyc-image-slider-bg)",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "var(--inyc-input-border-radius)",
    border: "2px solid var(--inyc-input-border-color)",
    height: "100%",
    // aspectRatio: "4/2.4",
    transition: "0.3s",
  },
  noImagesHover: {
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  noImagesImageHover: {
    transform: "scale(1.1)",
  },
  addIcon: {
    top: 0,
    right: 0,
    transform: "translate(50%,-50%)",
  },
  removeIcon: {
    top: 0,
    left: 0,
    transform: "translate(-50%,-50%)",
  },
  hoveredIconStyle: {
    opacity: 1,
  },
  hoveredIcon: {
    backgroundColor: "rgb(102, 102, 102)",
  },
};

export default ImageSlider;
