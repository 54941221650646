import React, { useEffect, useState, useRef } from "react";
import InfiniteScroller from "../InfiniteScroller/InfiniteScroller";
import EventCard from "../EventCard/EventCard";
import Loader from "../icons/Loader";
import PullToActionWrapper from "../PullToActionWrapper/PullToActionWrapper";
import { useWatchList } from "../../context/WatchListContext/WatchListProvider";
import { useEventDetails } from "../../context/EventDetailsProvider/EventDetailsProvider";
import { useUser } from "../../context/UserContext/UserProvider";
import ProfilePicture from "../icons/ProfilePicture";
import { BottomSheetDateFilter } from "../Map/Map";
import Input from "../Input/Input";
import { FiSearch } from "react-icons/fi";
import { FaRegEye, FaCheck } from "react-icons/fa6";
import { useAccountDetails } from "../../context/AccountDetailsContext/AccountDetailsProvider";
import axiosInstance from "../../api/axiosInstance.js";
import { backendUrl } from "../../api/constants";
import { useMediaQuery } from "../../util/useMediaQuery";
import EventDetails from "../EventDetails/EventDetails";
import AccountDetails from "../AccountDetails/AccountDetails";
import "./Watchlist.css";
import { useUIContext } from "../../context/UIContext/UIContext.jsx";

const AccountBubble = ({
  account,
  size = "4rem",
  className = "",
  isActive,
  onFilter,
  filterEnabled = false,
  showCheckIcon = false,
}) => {
  const { selectAccount } = useAccountDetails();
  const [accountDetails, setAccountDetails] = useState(null);
  const [pressStartTime, setPressStartTime] = useState(null);
  const [pressTimer, setPressTimer] = useState(null);
  const [actionFired, setActionFired] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [dragged, setDragged] = useState(false);
  const initialPos = useRef({ x: 0, y: 0 });
  const DRAG_THRESHOLD = 5; // pixels

  const fetchAccountDetails = async (accountId) => {
    try {
      const { data } = await axiosInstance.get(
        `${backendUrl}/user/${accountId}`
      );
      console.log("fetched account details :", data);
      setAccountDetails(data.account);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (account && account?._id) {
      fetchAccountDetails(account._id);
    } else {
      setAccountDetails({ username: "not available" });
    }
  }, [account]);

  const handlePress = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPressStartTime(Date.now());
    setActionFired(false);
    setIsPressed(true);
    setDragged(false);

    // Record initial pointer/touch position.
    const clientX =
      e.touches && e.touches[0] ? e.touches[0].clientX : e.clientX;
    const clientY =
      e.touches && e.touches[0] ? e.touches[0].clientY : e.clientY;
    initialPos.current = { x: clientX, y: clientY };

    if (filterEnabled) {
      const timer = setTimeout(() => {
        // If not dragged, trigger selectAccount after long press
        if (!dragged) {
          setActionFired(true);
          selectAccount(account);
        }
      }, 600);
      setPressTimer(timer);
    }
    // For non-filter mode, we wait until release to check if it was a drag.
  };

  const handleMove = (e) => {
    let clientX, clientY;
    if (e.touches && e.touches.length > 0) {
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      clientX = e.clientX;
      clientY = e.clientY;
    }
    if (
      !dragged &&
      (Math.abs(clientX - initialPos.current.x) > DRAG_THRESHOLD ||
        Math.abs(clientY - initialPos.current.y) > DRAG_THRESHOLD)
    ) {
      setDragged(true);
    }
  };

  const handleRelease = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Avoid processing if mouse/touch isn't pressed.
    if (!isPressed) {
      return;
    }

    if (filterEnabled) {
      if (pressTimer) {
        clearTimeout(pressTimer);
        setPressTimer(null);
      }
      if (!actionFired && !dragged) {
        onFilter && onFilter(account._id);
      }
    } else {
      if (!dragged) {
        selectAccount(account);
      }
    }
    setPressStartTime(null);
    setIsPressed(false);
  };

  // If account details is not valid, return a placeholder bubble.
  if (!accountDetails || !accountDetails?._id || accountDetails?.error) {
    return (
      <div className="w-[4rem] h-[4rem] text-[0.7rem] text-center rounded-full bg-[--inyc-primary-lighter] flex items-center justify-center cursor-pointer">
        not available
      </div>
    );
  }

  return (
    <div
      onMouseDown={handlePress}
      onMouseMove={handleMove}
      onMouseUp={handleRelease}
      onMouseLeave={handleRelease}
      onTouchStart={handlePress}
      onTouchMove={handleMove}
      onTouchEnd={handleRelease}
      onTouchCancel={handleRelease}
      className={`flex flex-col w-fit items-center justify-center cursor-pointer ${
        isActive ? "opacity-100" : "opacity-60"
      } ${className}`}
    >
      <div
        style={{ width: size, height: size }}
        className={`
          relative mb-[0.5rem] transform transition-transform duration-200 
          ${showCheckIcon && filterEnabled ? "rounded-full border-[2px] border-[--inyc-primary-text-color-light] shadow-md" : ""}
          ${isPressed ? "scale-[0.9]" : "scale-100"}
        `}
      >
        <ProfilePicture
          profilePicture={accountDetails?.profilePicture}
          username={
            accountDetails?.firstName?.charAt(0) +
            accountDetails?.lastName?.charAt(0)
          }
        />
        {showCheckIcon && filterEnabled && (
          <div className="absolute bottom-0 right-0 bg-[var(--inyc-secondary)] rounded-full p-1 shadow-md">
            <FaCheck size={10} className="text-[--inyc-secondary-text-color]" />
          </div>
        )}
      </div>
      <div
        className="text-[1.2rem] font-['Primary-Regular'] text-center text-[--inyc-primary-text-color] truncate"
        style={{ width: `calc(${size} + 2rem)` }}
      >
        {accountDetails?.businessName || accountDetails?.username}
      </div>
    </div>
  );
};

const WatchList = () => {
  const medium = useMediaQuery("md");
  const eventRef = useRef(null);
  const subEventRef = useRef(null);

  const {
    watchListEvents,
    watchListLoading,
    totalResults,
    setWatchListPage,
    pageLoading,
    refreshWatchList,
    seenArtists,
    queryFilter,
    setQueryFilter: setWatchListQueryFilter,
    dateFilter,
    setDateFilter: setWatchListDateFilter,
    tagFilter,
    setTagFilter: setWatchListTagFilter,
    accountFilter,
    setAccountFilter,
    toggleAccountFilter,
  } = useWatchList();
  const { mainContentRef } = useUIContext();
  const {
    selectEvent,
    selectSubEvent,
    setQueryFilter,
    setDateFilter,
    setTagFilter,
    selectedEvent,
    setSelectedEvent,
    selectedSubEvent,
    setSelectedSubEvent,
    setEventDetailsDateFilter,
  } = useEventDetails();
  const { user } = useUser();

  const { selectedAccount } = useAccountDetails();

  // Filter out artists that the user is already following
  const seenArtistsFiltered = (seenArtists || []).filter(
    (artist) => !user?.following?.includes(artist._id)
  );

  console.log("mainContentRef ", mainContentRef?.current);

  return (
    <div className="overflow-x-hidden">
      <div className="max-w-screen-xl mr-auto p-[1rem]">
        <div className={`${
            medium && (selectedEvent || selectedSubEvent || selectedAccount)
              ? "translate-x-[max(500px,35vw)]"
              : ""
          } duration-300`}>
          <div className="text-[2rem] flex items-center justify-between font-['Primary-Bold'] text-start">
            watchlist
          </div>
          {seenArtistsFiltered.length > 0 && (
            <>
              <div className="text-[1.2rem] font-['Primary-Regular'] text-[--inyc-primary-text-color-light]">
                artists you've recently viewed
              </div>
              <div className="mb-[1.5rem] text-[1.2rem] p-[1rem] flex gap-4 overflow-x-auto overflow-y-hidden">
                {seenArtistsFiltered.map((artist) => (
                  <AccountBubble
                    key={artist._id}
                    account={artist}
                    filterEnabled={false}
                  />
                ))}
              </div>
            </>
          )}
          {user?.following?.length > 0 && (
            <>
              <div className="text-[1.3rem] font-['Primary-Medium'] text-[--inyc-primary-text-color] flex items-center justify-between">
                <span>events from artists you watch</span>
                {accountFilter.length > 0 && (
                  <span
                    onClick={() => setAccountFilter([])}
                    className="text-[1rem] text-[--inyc-secondary] cursor-pointer bg-[--inyc-primary-light] px-[0.7rem] py-[0.2rem] rounded-[1rem]"
                  >
                    clear filters
                  </span>
                )}
              </div>
              <div className="w-full mb-[1.5rem] text-[1.2rem] p-[1rem] flex gap-4 overflow-x-auto overflow-y-hidden">
                {user?.following?.map((artistId) => (
                  <AccountBubble
                    key={artistId}
                    account={{
                      _id: artistId,
                      username: "not available",
                      profilePicture: "not available",
                      businessName: "not available",
                    }}
                    isActive={
                      accountFilter.length === 0 ||
                      accountFilter.includes(artistId)
                    }
                    onFilter={toggleAccountFilter}
                    filterEnabled={true}
                    showCheckIcon={
                      accountFilter.length > 0 &&
                      accountFilter.includes(artistId)
                    }
                  />
                ))}
              </div>
            </>
          )}
          <div className="md:max-w-[800px]">
            {user?.following?.length > 0 && (
              <>
                <Input
                  placeHolder="search events from artists you watch"
                  onChange={(e) => setWatchListQueryFilter(e.target.value)}
                  value={queryFilter}
                  icon={<FiSearch size={25} />}
                />
                <BottomSheetDateFilter
                  className={"md:!translate-y-[0] mt-[1rem]"}
                  filterDate={dateFilter}
                  setFilterDate={setWatchListDateFilter}
                  tagFilter={tagFilter}
                  setTagFilter={setWatchListTagFilter}
                />
              </>
            )}
            {watchListLoading ? (
              <div className="flex justify-center items-center w-full h-[100px]">
                <Loader />
              </div>
            ) : !user?.following?.length ? (
              <div className="map-events-none !text-[1.2rem] mt-[2rem]">
                you're not watching any artist yet. <br /> <br />
                watch artists by tapping the
                <FaRegEye className="inline-block ml-[1rem] mr-[0.5rem]" /> icon
                on an artist posted event, or their profile.
              </div>
            ) : !watchListEvents?.length ? (
              <div className="map-events-none mt-[2rem]">
                no upcoming events from artists you follow
              </div>
            ) : (
              <>
                <PullToActionWrapper
                  className={"pb-[5rem]"}
                  containerRef={mainContentRef}
                  onPull={refreshWatchList}
                >
                  <InfiniteScroller
                    hasMore={watchListEvents.length < totalResults}
                    getData={() => {
                      setWatchListPage((prev) => ({
                        page: prev.page + 1,
                        limit: prev.limit,
                      }));
                    }}
                    loading={pageLoading}
                    items={watchListEvents}
                    renderer={(event, i) => (
                      <EventCard
                        key={i}
                        className="map-event-container"
                        latestEvent={event._matchingEvent}
                        data={event}
                        onClick={() => {
                          // Reset the filters in EventDetails context
                          setQueryFilter("");
                          setDateFilter(null);
                          setTagFilter([]);
                          if (event.venueDetails?._id) {
                            selectEvent(
                              {
                                ...event.venueDetails,
                                type: "venue",
                              },
                              { ...event, _searchPageResult: true }
                            );
                          } else {
                            selectEvent(event);
                          }
                        }}
                        onVenueClick={() => {
                          // Also clear filters when the venue itself is clicked
                          setQueryFilter("");
                          setDateFilter(null);
                          setTagFilter([]);
                          selectEvent(event);
                        }}
                        onEventClick={(ev) => {
                          selectSubEvent(ev);
                        }}
                      />
                    )}
                  />
                </PullToActionWrapper>
              </>
            )}
          </div>
        </div>
        {medium && (
          <>
            {/* Event Details Panel */}
            <div
              ref={eventRef}
              className={`max-w-[35vw] min-w-[500px] ml-[--inyc-side-bar-width] watchlist-map-event-details-container duration-300 h-screen overflow-y-auto ${
                !selectedEvent ? "translate-x-[-100%]" : "translate-x-[0]"
              }`}
            >
              {selectedEvent && (
                <>
                  <div className="absolute text-white left-[5rem] top-[1.5rem] text-[1.3rem] font-['Primary-Medium']">
                    events
                  </div>
                  <EventDetails
                    className="!pt-[2rem]"
                    parentRef={eventRef}
                    Close={() => {
                      setSelectedEvent(null);
                    }}
                    data={selectedEvent}
                    onSelectEvent={(event) => {
                      selectSubEvent(event);
                    }}
                  />
                </>
              )}
            </div>

            {/* Sub Event Details Panel */}
            <div
              ref={subEventRef}
              className={`max-w-[35vw] min-w-[500px] ml-[--inyc-side-bar-width] watchlist-map-sub-event-details-container duration-300 h-screen overflow-y-auto ${
                !selectedSubEvent ? "translate-x-[-100%]" : "translate-x-[0]"
              }`}
            >
              {selectedSubEvent && (
                <>
                  <div className="absolute text-white left-[5rem] top-[1.5rem] text-[1.3rem] font-['Primary-Medium']">
                    event list
                  </div>
                  <EventDetails
                    className="!pt-[2rem]"
                    parentRef={subEventRef}
                    Close={() => {
                      setSelectedSubEvent(null);
                      if (selectedEvent?.events?.length <= 1) {
                        setSelectedEvent(null);
                      }
                    }}
                    data={selectedSubEvent}
                  />
                </>
              )}
            </div>

            {/* Account Details Panel */}
            <div
              className={`max-w-[35vw] min-w-[500px] ml-[--inyc-side-bar-width] watchlist-map-account-details-container duration-300 h-screen overflow-y-auto ${
                !selectedAccount ? "translate-x-[-100%]" : "translate-x-[0]"
              }`}
            >
              {selectedAccount && <AccountDetails />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WatchList;
