import axios from "axios";
import { backendUrl } from "./constants";
import { enqueueSnackbar } from "notistack";

axios.defaults.headers.common["ngrok-skip-browser-warning"] = "6941";

let authError = false;


const instance = axios.create({
  baseURL: backendUrl,
});

instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("jwt_token");
    
    if(!token){
      token = sessionStorage.getItem("jwt_token");
    }
    
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    authError = false;
    return response;
  },
  (error) => {
    if (error.response?.status === 403) {
      window.location.href = "/";
      enqueueSnackbar(`${error.response.data?.error}`, { variant: "error" });
    }
    if (error.response?.status === 401) {
      if(!authError){
        // enqueueSnackbar(`${error.response.data?.error}`, { variant: "error" });
      }
      sessionStorage.removeItem("jwt_token");
      localStorage.removeItem("jwt_token");

      //show authorization error
      console.log(error.response);
      authError = true;

      // window.location.href = "/auth/";
    }
    return Promise.reject(error);
  }
);

export default instance;
