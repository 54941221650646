import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

const CustomImageSlider = forwardRef(
  ({ items, onSlideChange, autoSlide = false, slideInterval = 2000 }, ref) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [previousTranslate, setPreviousTranslate] = useState(0);
    const [animationId, setAnimationId] = useState(null);

    useImperativeHandle(ref, () => ({
      slideToIndex: (index) => {
        if (index >= 0 && index < items.length) {
          setCurrentIndex(index);
        }
      },
      getCurrentIndex: () => currentIndex,
    }));

    useEffect(() => {
      if (onSlideChange) {
        onSlideChange(currentIndex);
      }
    }, [currentIndex, onSlideChange]);

    useEffect(() => {
      const timer = autoSlide
        ? setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
          }, slideInterval)
        : null;

      return () => {
        if (timer) clearInterval(timer);
      };
    }, [autoSlide, slideInterval, items.length]);

    useEffect(() => {
      const containerWidth = sliderRef.current.clientWidth;
      setCurrentTranslate(-currentIndex * containerWidth);
      setPreviousTranslate(-currentIndex * containerWidth);
    }, [currentIndex]);

    const start = (event) => {
      setIsDragging(true);
      setStartX(getPositionX(event));
      setAnimationId(requestAnimationFrame(animation));
    };

    const end = () => {
      setIsDragging(false);
      cancelAnimationFrame(animationId);

      const movedBy = currentTranslate - previousTranslate;
      if (movedBy < -100) {
        setCurrentIndex((prevIndex) =>
          Math.min(prevIndex + 1, items.length - 1)
        );
      }
      if (movedBy > 100) {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      }

      const containerWidth = sliderRef.current.clientWidth;
      setCurrentTranslate(-currentIndex * containerWidth);
      setPreviousTranslate(-currentIndex * containerWidth);
    };

    const move = (event) => {
      if (isDragging) {
        const currentPosition = getPositionX(event);
        const diff = currentPosition - startX;
        setCurrentTranslate(previousTranslate + diff);
      }
    };

    const getPositionX = (event) => {
      return event.type.includes("mouse")
        ? event.pageX
        : event.touches[0].clientX;
    };

    const animation = () => {
      setSliderPosition();
      if (isDragging) setAnimationId(requestAnimationFrame(animation));
    };

    const setSliderPosition = () => {
      sliderRef.current.style.transform = `translateX(${currentTranslate}px)`;
    };

    return (
      // <div>
        <div
          className="relative w-full h-full overflow-hidden rounded-[var(--inyc-input-border-radius)]"
          onMouseDown={start}
          onMouseUp={end}
          onMouseLeave={end}
          onMouseMove={move}
          onTouchStart={start}
          onTouchEnd={end}
          onTouchMove={move}
        >
          <div
            ref={sliderRef}
            className={`flex h-full max-h-full ${isDragging ? "" : "duration-300"}`}
            style={{ transform: `translateX(${currentTranslate}px)` }}
          >
            {items.map((item, index) => {
              const distanceFromCurrent = Math.abs(currentIndex - index);
              const scale = 1 - 0.1 * distanceFromCurrent;
              const opacity = 1 - 0.2 * distanceFromCurrent;
              const zIndex = 1000 - distanceFromCurrent; // Ensure current image is always on top

              return (
                <div
                  style={{
                    transform: `scale(${scale})`,
                    opacity: opacity,
                    zIndex: zIndex,
                  }}
                  key={index}
                  className="min-w-full flex justify-center items-center max-h-full h-full duration-300"
                >
                  <img
                    src={item.original}
                    alt={`Slide ${index}`}
                    className="rounded-[var(--inyc-input-border-radius)] max-h-full h-full object-contain"
                  />
                </div>
              );
            })}
          </div>
          {items?.length > 1 && (
            <>
              <div
                className="cursor-pointer absolute left-[0.5rem] top-[50%] translate-y-[-50%] z-[1001] bg-white/70 flex items-center justify-center text-[0.8rem] w-[2.5rem] h-[2.5rem] rounded-full duration-300 hover:bg-white"
                onClick={() => {
                  if (currentIndex === 0) {
                    setCurrentIndex(items.length - 1);
                  } else {
                    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                  }
                }}
              >
                &#10094;
              </div>
              <div
                className="cursor-pointer absolute right-[0.5rem] top-[50%] translate-y-[-50%] z-[1001] bg-white/70 flex items-center justify-center text-[0.8rem] w-[2.5rem] h-[2.5rem] rounded-full duration-300 hover:bg-white"
                onClick={() => {
                  if (currentIndex === items.length - 1) {
                    setCurrentIndex(0);
                  } else {
                    setCurrentIndex((prevIndex) =>
                      Math.min(prevIndex + 1, items.length - 1)
                    );
                  }
                }}
              >
                &#10095;
              </div>
              <div className="absolute bottom-[1.5rem] left-0 w-full flex justify-center gap-[0.5rem]">
                {items.map((img, i) => (
                  <div
                    key={i}
                    className={`h-[0.6rem] duration-300 rounded-full cursor-pointer ${
                      i === currentIndex
                        ? "bg-white w-[1.5rem]"
                        : "w-[0.6rem] bg-white/70"
                    }`}
                    onClick={() => {
                      setCurrentIndex(i);
                    }}
                  ></div>
                ))}
              </div>
            </>
          )}
        </div>
      // </div>
    );
  }
);

export default CustomImageSlider;
