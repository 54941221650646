import React from "react";

const Icon = ({ src, style,className ,imgClassName}) => {
  return (
    <div className={className} style={{ ...styles.default, ...(style || {}) }}>
      <img 
      className={imgClassName}
      src={src} style={styles.imageStyle} />
    </div>
  );
};

const styles = {
  default: {
    width: "100%",
    height: "100%",
    // backgroundColor: "red",
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
};

export default Icon;
