import React, { useEffect, useState } from "react";
import ContentList, {
  HoverItem,
  Option,
} from "../../../components/ContentList/ContentList";
import { backendUrl } from "../../../api/constants";
import axiosInstance from "../../../api/axiosInstance";
import Button from "../../../components/Button/Button";
import textIcon from "../../../assets/icons/list-text.png";
import { useMediaQuery } from "../../../util/useMediaQuery";
import { DateTime } from "luxon";
import { usePortalContext } from "../../../context/PortalContext/portalcontext";
import { BiSolidEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { modal } from "../../../util/useModal";
import { PortalTitle } from "../../Portal";

const PostedEvents = () => {
  const medium = useMediaQuery("md");
  const [events, setEvents] = useState();
  const { setEditEvent, setCurrentTab } = usePortalContext();

  const fetchEvents = async () => {
    const { data } = await axiosInstance.get(`${backendUrl}/influencer/events`);
    if (data.error) {
      console.log(data.error);
    } else {
      setEvents(data.data);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const editEvent = async (event) => {
    setEditEvent(event);
    setCurrentTab("new-event");
  };

  const cancelEvent = async (event) => {
    try {
      const result = await modal(({ proceed }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>canceling an event request is an irreversible action</div>
          <div
            className="whitespace-nowarp"
            style={{ fontFamily: "Primary-Medium" }}
          >
            are you sure you want to continue ?
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => proceed(true)}>Yes</Button>
            <Button onClick={() => proceed(false)}>No</Button>
          </div>
        </div>
      ));

      if (result) {
        try {
          const { data } = await axiosInstance.post(
            `${backendUrl}/influencer/cancelEvent/${event._id}`
          );
          if (data.error) {
            console.log(data.error);
          } else {
            fetchEvents();
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        background: "var(--inyc-primary)",
        padding: "2rem",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
      }}
    >
      <PortalTitle className="pb-[1rem]">posted events</PortalTitle>
      <div
        style={{
          ...(medium
            ? panelStyles.listContainer
            : panelStyles.listContainerMobile),
        }}
      >
        <ContentList
          formatCell={(item, key) => {
            if (key === "name") {
              return (
                <div className="flex flex-col items-center text-[0.9rem]">
                  <div>{item[key]}</div>
                  <div className="text-[var(--inyc-primary-text-color-light)] text-[0.9rem]">
                    {new Date(item.createdAt).toLocaleString()}
                  </div>
                </div>
              );
            }
            if (key === "status") {
              return (
                <div className="flex gap-[5px] justify-center items-center flex-wrap">
                  {item[key]?.replaceAll("_", " ")}
                  {item[key] === "rejected" &&
                    item.rejection_message &&
                    item.rejection_message !== "" && (
                      <HoverItem text={item.rejection_message}>
                        <img src={textIcon} className="w-[20px] h-[20px]" />
                      </HoverItem>
                    )}
                </div>
              );
            }
            if (key === "options") {
              return (
                <div className="flex gap-[0.5rem] items-center justify-center">
                  {item.options?.map((opt, i) => {
                    const option = opt(item);
                    if (option?.label === "edit") {
                      return (
                        <div
                          className="flex cursor-pointer"
                          onClick={option?.onClick}
                        >
                          <BiSolidEditAlt className="mr-[2px] text-2xl cursor-pointer inline-block" />
                          edit
                        </div>
                      );
                    }
                    if (option?.label === "cancel") {
                      return (
                        <div
                          className="flex cursor-pointer"
                          onClick={option?.onClick}
                        >
                          <MdDelete className=" text-2xl cursor-pointer inline-block" />
                          delete
                        </div>
                      );
                    }
                    return (
                      <Option
                        hoverText={opt.hoverText}
                        opt={opt}
                        index={i}
                        item={item}
                      />
                    );
                  })}
                </div>
              );
            }

            return item[key];
          }}
          optionsPosition={1}
          items={events?.map((ev) => {
            return {
              ...ev,
              createdAt: new Date(ev.createdAt).toLocaleString(),
              status: ev.status ? ev.status.replaceAll("_", " ") : null,
              options: [
                (item) => {
                  //edit event
                  return {
                    label: "edit",
                    onClick: () => {
                      editEvent(item);
                    },
                    style: {
                      backgroundColor: "black",
                      color: "white",
                      border: "none",
                    },
                    hoverStyle: {
                      backgroundColor: "rgba(0,0,0,0.8)",
                    },
                  };
                },
                (item) => {
                  return {
                    label: "cancel",
                    onClick: () => cancelEvent(item),
                    style: {
                      backgroundColor: "black",
                      color: "white",
                      border: "none",
                    },
                    hoverStyle: {
                      backgroundColor: "rgba(0,0,0,0.8)",
                    },
                  };
                },
              ],
            };
          })}
          useColumns={{
            name: "event name",
            status: "status",
            options: "options",
          }}
        />
      </div>
    </div>
  );
};

const panelStyles = {
  listContainer: {
    padding: 40,
  },
  listContainerMobile: {
    padding: 0,
    overflowX: "auto",
    overflowY: "hidden",
  },
};

export default PostedEvents;
