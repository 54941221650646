import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import axiosInstance from "../../../api/axiosInstance";
import { backendUrl } from "../../../api/constants";
import InputMessage from "../../../components/Input/InputMessage";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../../context/UserContext/UserProvider";
import Notice from "../../../components/ui/notice";
import logoLight from "../../../assets/imgs/logo-transparent-light.gif";

const useMessageFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const messageKey = searchParams.get("message");

  const messageMap = {
    invalid_token: "invalid, please send another verification email",
    token_expired:
      "the link has expired, please send another verification email",
  };

  const message = messageMap[messageKey] || messageKey;

  return { status, message };
};

const VerifyEmail = () => {
  const { user, fetchUser, isLoading } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState(user?.email);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { status, message: paramMessage } = useMessageFromSearchParams();

  //if no user is logged in, redirect to login page
  // useEffect(() => {
  //   if (!user && !paramMessage && !isLoading) {
  //     sessionStorage.setItem("redirect_url", `/auth/verify-email?status=${status}`);
  //     navigate("/auth/signin");
  //   }
  // }, [user,paramMessage,isLoading]);

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        `${backendUrl}/user/me/verify-email-request`,
        { email }
      );
      if (data.error) {
        setMessage({ error: data.validation?.email?.error || data.error });
      } else {
        setMessage({
          success: `an email has been sent to ${data.email} with a link to verify your email`,
        });
        // start a polling function to check if the user has verified their email by calling fetchUser() every 2 seconds
        const interval = setInterval(async () => {
          const dt = await fetchUser();
          if (dt?.emailVerified) {
            clearInterval(interval);
            searchParams.set("status", "success");
          }
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: "something went wrong, please try again later" });
    } finally {
      setLoading(false);
    }
  };

  const verified =
    status === "success" || user?.emailVerified || status === "verified";

  return (
    <div className="w-[90%] min-h-[100vh] max-w-[500px] px-[2rem] flex flex-col justify-center items-center gap-[1rem]">
      <div className="auth-logo !mb-[2rem]">
        <img src={logoLight} className="invert-[var(--inyc-invert-black)]" alt="logo" />
      </div>
      <div>
        {status === "success" ? (
          <>
            <div className="text-center text-[2.5rem] mb-[1rem] font-['Primary-Medium']">
              done!
            </div>
            <div className="text-center text-[1.1rem] mb-[0.5rem] !mt-0 font-['Primary-Regular']">
              {" "}
              your email has been successfully verified
            </div>
            <div className="text-center text-[1.1rem] mb-[1rem] !mt-0 font-['Primary-Regular']">
              you can now close this page or
              <br />
              go to the{" "}
              <Link className="text-[--inyc-primary-text-color-light]" to="/">
                homepage
              </Link>
            </div>
          </>
        ) : verified ? (
          <>
            <div className="text-center text-[1.5rem] mb-[1rem] !mt-0 font-['Primary-Regular']">
              {" "}
              your email is already verified
            </div>
            <div>
              go to the{" "}
              <Link className="text-[--inyc-primary-text-color-light]" to="/">
                homepage
              </Link>
            </div>
          </>
        ) : status === "error" ? (
          <>
            <div className="text-center text-[2.5rem] mb-[2rem] !mt-0 font-['Primary-Medium']">
              verify email
            </div>
            <div className="mb-[2rem] text-[rgb(223,44,44)] font-bold text-[1.3rem]">
              {paramMessage || "something went wrong, please try again"}
            </div>
            <div>
              <Button
                onClick={() => {
                  setSearchParams({});
                  setMessage(null);
                  if (!user) {
                    sessionStorage.setItem(
                      "redirect_url",
                      `/auth/verify-email?status=${status}`
                    );
                    navigate("/auth/signin");
                  }
                }}
              >
                try again
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="text-center text-[2.5rem] mb-[2rem] !mt-0 font-['Primary-Medium']">
              verify email
            </div>
            <div className="mb-[2rem]">
              <>
                {user?.email ? (
                  <div className="mb-[1rem] text-[1.1rem] !mt-0 font-['Primary-Regular']">
                    click below to verify the current email address associated
                    with your account{" "}
                    <span className="font-['Primary-Medium']">{email}</span>
                  </div>
                ) : (
                  <div className="mb-[1rem] text-[1.1rem] !mt-0 font-['Primary-Regular']">
                    you have not set an email address for your account
                  </div>
                )}
                <Notice noicon className="mb-[1rem] !text-center">
                  your email can always be updated in the{" "}
                  <Link
                    className="text-[--inyc-primary-text-color-light] font-bold"
                    to="/settings"
                  >
                    settings
                  </Link>{" "}
                  page
                </Notice>
              </>
            </div>
            {user?.email && <div className="mb-[1rem]">
              <Button
                loading={loading}
                className={"mb-[1rem]"}
                onClick={verifyEmail}
              >
                send verification email
              </Button>
              {message && (
                <InputMessage className="!mx-auto w-fit" messageObj={message} />
              )}
            </div>}
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
