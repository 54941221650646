import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../api/axiosInstance";
import { backendUrl } from "../../../api/constants";
import EditEvent from "../../../components/ui/EditEvent";
import { usePortalContext } from "../../../context/PortalContext/portalcontext";
import { IoIosAddCircle } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import Input from "../../../components/Input/Input";
import { MdDelete } from "react-icons/md";
import Button from "../../../components/Button/Button";
import { createModal } from "../../../util/Modal";
import { BiSolidEditAlt } from "react-icons/bi";
import { IoDuplicate } from "react-icons/io5";
import { styles } from "../../../components/PortalStyles/portalStyles.style";
import { FaPlus } from "react-icons/fa6";
import { enqueueSnackbar } from "notistack";
import { PortalTitle } from "../../Portal";

const Templates = () => {
  const [templates, setTemplates] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [postError, setPostError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userLocation, setEditEvent, setCurrentTab } = usePortalContext();
  const templateNameRef = useRef();
  const eventRef = useRef();

  const fetchTemplates = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${backendUrl}/influencer/templates`
      );
      if (data?.error) {
        //TODO handle error
        enqueueSnackbar(data.error, { variant: "error" });
      } else {
        setTemplates(data);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("something went wrong, please try again later", {
        variant: "error",
      });
      //TODO handle error
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const saveTemplate = async () => {
    if (
      !templateNameRef.current.value ||
      templateNameRef.current.value.trim() === ""
    )
      return setPostError("please enter a template name");

    const { form, error } = await eventRef.current?.getFormData();
    if (error) {
      setPostError(form.error);
      return;
    }

    //log the form and all its inner nested content keeping in mind its a formData so simple console log wont work
    // Log form data contents
    console.log("Form data contents:");
    for (let [key, value] of form.entries()) {
      if (key === 'event') {
        // Parse and log the event object
        console.log('event:', JSON.parse(value));
      } else {
        console.log(`${key}:`, value);
      }
    }


    form.append("templateName", templateNameRef.current.value);

    try {
      const { data: res } = await axiosInstance.post(
        `${backendUrl}/influencer/templates/save`,
        form
      );
      if (res?.error) {
        if (typeof res.error === "string") {
          setPostError(res.error);
          return;
        } else {
          setPostError(res.error[Object.keys(res.error)[0]]);
          return;
        }
      } else {
        setPostError(null);
        setSelectedTemplate(null);
        enqueueSnackbar("changes saved successfully", { variant: "success" });
        fetchTemplates();
      }
    } catch (error) {
      console.log(error);
      //TODO handle error
      setPostError("something went wrong, please try again later");
      return;
    }
  };

  const deleteTemplate = async (id) => {
    const result = await createModal(({ onModalResult }) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div style={{ fontFamily: "Primary-Bold" }}>
          are you sure you want to delete this template ?
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <Button onClick={() => onModalResult(true)}>Yes</Button>
          <Button onClick={() => onModalResult(false)}>No</Button>
        </div>
      </div>
    ));

    if (!result) {
      return;
    }
    try {
      const { data } = await axiosInstance.get(
        `${backendUrl}/influencer/templates/delete/${id}`
      );
      if (data?.error) {
        //TODO handle error
      } else {
        fetchTemplates();
      }
    } catch (error) {
      console.log(error);
      //TODO handle error
    }
  };

  return (
    <div className="bg-[var(--inyc-primary)] p-[1rem] min-h-[100vh]">
      <PortalTitle className="px-[1rem] py-[1rem]">templates</PortalTitle>
      <div className="mb-[2rem] rounded-[1rem] font-bold flex justify-between items-center w-full max-w-[1200px] mx-auto shadow-md p-[1rem]">
        {selectedTemplate ? (
          <>
            <div
              className="cursor-pointer"
              onClick={() => {
                setSelectedTemplate(null);
              }}
            >
              <IoIosArrowRoundBack className="inline-block mr-[5px] text-3xl cursor-pointer" />
              cancel
            </div>
            <div
              className="flex items-center justify-center gap-[1rem]"
            >
              <div>
                template name
              </div>
            <Input
              defaultValue={selectedTemplate?.templateName}
              className="border-[1px] mt-0 border-[rgba(0,0,0,0.04)] rounded-[1rem] !px-[0.5rem] !py-[0rem]"
              placeHolder="template name"
              Ref={templateNameRef}
            />
            </div>
          </>
        ) : (
          <div
            className="cursor-pointer"
            onClick={() => {
              setSelectedTemplate({});
            }}
          >
            <IoIosAddCircle className="inline-block mr-[5px] text-3xl cursor-pointer" />
            new template
          </div>
        )}
      </div>

      <div className="">
        {selectedTemplate ? (
          <>
            <EditEvent
              Ref={eventRef}
              userLocation={userLocation}
              edit={selectedTemplate}
            />
            <Button
              error={postError}
              prompt
              message="Confirm"
              style={styles.PostButton}
              loading={loading}
              onClick={saveTemplate}
            >
              {selectedTemplate?._id ? "save changes" : "save template"}
            </Button>
          </>
        ) : (
          <div>
            {templates?.map((temp, i) => {
              return (
                <div className="mb-[1rem] p-[1rem] shadow-md rounded-[1rem] max-w-[500px] border-[1px] border-[rgba(0,0,0,0.1)]">
                  <div className="p-[1rem]">
                    <div className="text-[1.5rem] font-['Primary-Medium']">
                      {temp.templateName}
                    </div>
                  </div>
                  <div className="pt-[0.5rem] px-[1rem] border-t-[1px] border-[rgba(0,0,0,0.1)] flex justify-start items-center gap-[1rem] ">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const { _id, templateName, type, ...rest } = temp;
                        setEditEvent(rest);
                        setCurrentTab("new-event");
                      }}
                    >
                      <FaPlus className="mr-[2px] p-[1px] text-2xl cursor-pointer inline-block" />
                      post
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedTemplate(temp);
                      }}
                    >
                      <BiSolidEditAlt className="mr-[2px] text-2xl cursor-pointer inline-block" />
                      edit
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const { _id, ...rest } = temp;
                        setSelectedTemplate(rest);
                      }}
                    >
                      <IoDuplicate className="mr-[2px] text-2xl cursor-pointer inline-block" />
                      duplicate
                    </div>
                    <div
                      className="text-[var(--inyc-primary-text-color)] cursor-pointer"
                      onClick={() => {
                        deleteTemplate(temp._id);
                      }}
                    >
                      <MdDelete className=" text-2xl cursor-pointer inline-block" />
                      delete
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Templates;
