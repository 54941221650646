import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import SocialsInput from "./SocialsInput";
import Button from "../Button/Button";
import { socialOptions } from "../../util/socials";
import { v4 as uuid } from "uuid";

const SocialsHandler = forwardRef(({ value, onChange, defaultValue }, ref) => {
  const defValue = defaultValue || value || [
    {
      platform: "",
      link: "",
    },
  ]
  const [socials, setSocials] = useState(defValue);
  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(socials)) {
      setSocials(value?.length > 0 ? value.map(s => ({ ...s, id: s.id || uuid() })) : []);
    }
  }, [value]);

  // useEffect(() => {

  //   alert(`value ${JSON.stringify(socials,null,2)}`);
  // },[socials])

  const inputRefs = useRef([]);

  // Ref mechanism for parent to interact with SocialsHandler
  useImperativeHandle(ref, () => ({
    getValue: () => {
      // console.log("returning socials ", socials);
      return socials || [];
    }, // Provide current value to parent
    setValue: (newValue) => {
      setSocials(newValue);
      onChange && onChange(newValue);
    },
    reset: () => onChange && onChange([]), // Reset all socials
    addSocial: () => {
      const newValue = [...(value || []), ""];
      setSocials(newValue);
      onChange && onChange(newValue);
    },
  }));

  // const handleDelete = (indexToDelete) => {
  //   const newSocials = socials.filter((_, index) => index !== indexToDelete);
  //   setSocials([...newSocials]); // Ensure a new array reference is used
  //   onChange && onChange([...newSocials]);
  // };

  const handleDelete = (indexToDelete) => {
    const newSocials = socials.filter((_, index) => index !== indexToDelete);
    setSocials(newSocials);
    // onChange && onChange(newSocials.filter(social => social.platform || social.link));
    onChange && onChange(newSocials);
  };

  const handleAddSocialOption = (option) => () => {
    // the link should start with the first item of the option's patterns
    const newSocials = [
      {
        platform: "",
        link: option.patterns[0] || 'www.example.com' + "/",
      },
      ...socials,
    ];

    //TODO focus the input

    setSocials(newSocials);

    setTimeout(() => {
      inputRefs.current[0].linkInput().focus();
      //select all the text
      inputRefs.current[0].linkInput().select();
    }, 100);
  };

  const handleChange = (index) => (v) => {
    // console.log("v", v);
    // alert(`v ${JSON.stringify(v, null, 2)}`);
    const newValue = [...socials];
    newValue[index] = v;
    setSocials(newValue);
    onChange && onChange(newValue);
  }
  return (
    <div>
      <div className="text-[1.4rem] font-['Primary-Medium']  mb-[1rem]">
        socials
      </div>
      <div
        className="mb-[2rem] px-[1rem] text-[0.9rem]"
      >
        add the social(s) for you and/or all the artists to give the audience a taste of what to expect
      </div>
      {/* Social Options as Buttons */}
      <div className="flex flex-wrap gap-[0.5rem] mb-[1rem] md:justify-start justify-center">
        {socialOptions.map((option, index) => (
          <Button
            key={index}
            btnClassName={"flex hover-icon-btn  justify-center items-center"}
            className=""
            onClick={handleAddSocialOption(option)}
          >
            {
              <option.rawIcon
                size={24}
                className="h-[1.5rem]"
                color="var(--icon-color)"
              />
            }
          </Button>
        ))}
      </div>

      <div className="mb-[1.5rem]">
        {socials?.map((v, i) => {
          return (
            <>
              <SocialsInput
                key={i}
                ref={(el) => (inputRefs.current[i] = el)}
                onAddSocial={() => {
                  //add a social directly after this one
                  const newValue = [...socials];
                  newValue.splice(i + 1, 0, {
                    platform: "",
                    link: "",
                  });
                  setSocials(newValue);
                  onChange && onChange(newValue);
                }}
                z={204 + socials.length - 1 - i}
                value={v}
                onChange={handleChange(i)}
                onDelete={() => handleDelete(i)}
                linkError={v.linkerror}
                platformError={v.platformerror}
              />
            </>
          );
        })}
      </div>
    </div>
  );
});

export default SocialsHandler;
