import React, { useEffect, useRef, useState } from "react";
import Loader from "../icons/Loader";

const InfiniteScroller = ({
  className,
  items,
  renderer,
  getData,
  loading,
  hasMore,
}) => {
  const loaderRef = useRef(null);
  const [recentlyLoaded, setRecentlyLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && hasMore) {
          if (!recentlyLoaded) {
            setRecentlyLoaded(true);
            setTimeout(() => {
              setRecentlyLoaded(false);
            }, 2000);
            getData(); // Trigger fetching more data
          }
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1, // Adjust threshold as needed
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.disconnect();
      }
    };
  }, [loading, hasMore,recentlyLoaded]);

  // console.log("hasMore :", hasMore);

  return (
    <div className={className}>
      {items.map((item, index) => renderer(item, index))}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      )}
      {hasMore && (
        <div
          style={{
            height: "20px", // Slightly larger for better visibility
            marginTop: "50px",
            marginBottom: "50px",
          }}
          ref={loaderRef}
        ></div>
      )}
    </div>
  );
};

export default InfiniteScroller;
