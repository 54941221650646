import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import "./DatePickerFilter.css";
import { MdCancel } from "react-icons/md";
import { DayPicker } from "react-day-picker";
import { BsCheckLg } from "react-icons/bs";
import "react-day-picker/dist/style.css";
import Icon from "../Icon/Icon";
import calendarIcon from "../../assets/icons/calendar-icon.svg";
import { DateTime } from "luxon";

const DatePickerFilter = forwardRef(
  ({ currentDate, setCurrentDate, onCalendarIconClick }, ref) => {
    const [calendarOpen, setCalendarOpen] = useState(false);
    // const [isDefaultDate, setIsDefaultDate] = useState(false);

    // useEffect(() => {
    //   if (currentDate) {
    //     if (currentDate.start && currentDate.end) {
    //       if (
    //         currentDate?.start?.toLocaleDateString() ===
    //           DateTime.now().startOf("day").toJSDate().toLocaleDateString() &&
    //         currentDate?.end?.toLocaleDateString() ===
    //           DateTime.now()
    //             .startOf("day")
    //             .plus({ hours: 48 })
    //             .toJSDate()
    //             .toLocaleDateString()
    //       ) {
    //         setIsDefaultDate(true);
    //       } else {
    //         setIsDefaultDate(false);
    //       }
    //     }
    //   }
    // }, [currentDate]);

    useImperativeHandle(ref, () => ({
      calendarOpen: calendarOpen,
    }));

    const handleSelect = (date) => {
      if (!date) {
        if (currentDate.start) {
          setCurrentDate({
            start: new Date(currentDate.start.setHours(0, 0, 0)),
            end: new Date(currentDate.start.setHours(23, 59, 59)),
          });
        }
      } else {
        if (!date.to) {
          setCurrentDate({
            start: new Date(date.from.setHours(0, 0, 0)),
            end: new Date(date.from.setHours(23, 59, 59)),
          });
        } else {
          setCurrentDate({
            start: new Date(date.from.setHours(0, 0, 0)),
            end: new Date(date.to.setHours(23, 59, 59)),
          });
        }
      }
    };

    const cancelFilter = () => {
      setCurrentDate(null);
    };

    const toggleCalendar = () => {
      setCalendarOpen(!calendarOpen);
    };

    const isDefaultDate =
      currentDate?.start?.toLocaleDateString() ===
        DateTime.now().startOf("day").toJSDate().toLocaleDateString() &&
      currentDate?.end?.toLocaleDateString() ===
        DateTime.now()
          .startOf("day")
          .plus({ hours: 48 })
          .toJSDate()
          .toLocaleDateString();

    return (
      <>
        <div
          onClick={() => {
            toggleCalendar();
            onCalendarIconClick && onCalendarIconClick();
          }}
          className="cursor-pointer min-w-[1.5rem] w-[1.5rem] h-[1.5rem] flex items-center"
        >
          <Icon
            src={calendarIcon}
            className={`translate-y-[-20%]`}
            alt="calendar icon"
            imgClassName={
              currentDate
                ? "invert-[--inyc-invert-white]"
                : "invert-[--inyc-lighten-white]"
            }
            style={{
              cursor: "pointer",
              // marginLeft: "5px",
            }}
          />
        </div>
        <div className="date-picker-filter-container">
          <div
            className={`date-picker-calendar-container border-[var(--inyc-modal-border-color)]  ${
              calendarOpen
                ? "border-[1px] rounded-[var(--inyc-input-border-radius)] open"
                : ""
            }`}
            style={{
              backgroundColor: "var(--inyc-primary)",
              width: "90%",
              margin: "auto",
              boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.1)",
              // padding: "10px",
            }}
          >
            <div id="date-picker">
              <div
                className={`date-picker-filter ${currentDate ? "active" : ""}`}
              >
                <div
                  className={`date-picker-filter-text ${
                    currentDate && !isDefaultDate ? "active" : ""
                  }`}
                >
                  {isDefaultDate
                    ? "select a date or date range"
                    : !currentDate || !currentDate.start
                    ? "select a date or date range"
                    : `${currentDate.start.toLocaleDateString()}${
                        currentDate.end &&
                        currentDate.start.toLocaleDateString() !==
                          currentDate.end.toLocaleDateString()
                          ? `- ${currentDate.end.toLocaleDateString()}`
                          : ""
                      }`}
                </div>
                {!isDefaultDate && currentDate?.start && (
                  <MdCancel
                    style={{
                      cursor: "pointer",
                    }}
                    color="var(--inyc-primary-text-color)"
                    size={25}
                    onClick={cancelFilter}
                  />
                )}
              </div>
              <DayPicker
                id="date-picker-calendar"
                showOutsideDays
                mode="range"
                selected={
                  isDefaultDate
                    ? null
                    : { from: currentDate?.start, to: currentDate?.end }
                }
                onSelect={handleSelect}
                footer={false}
                formatters={{
                  formatCaption: (month, { locale }) => {
                    return (
                      month
                        .toLocaleString(locale, { month: "long" })
                        .toLowerCase() +
                      " " +
                      month.toLocaleString(locale, { year: "numeric" })
                    );
                  },
                }}
              />
              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid rgba(0,0,0,0.1)",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <BsCheckLg
                  size={30}
                  onClick={toggleCalendar}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    margin: "10px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default DatePickerFilter;
