import { DateTime } from "luxon";
import { Options, RRule, Weekday } from "rrule";

export const weekdayMap: { [key: number]: Weekday } = {
  0: RRule.MO,
  1: RRule.TU,
  2: RRule.WE,
  3: RRule.TH,
  4: RRule.FR,
  5: RRule.SA,
  6: RRule.SU,
} as const;

export const convertByWeekday = (byweekday: any) => {
  try {
    const convertedByWeekDay = byweekday?.map((day: any) => {
      const daynumber = typeof day === "number" ? day : day?.weekday || 0;
      // Convert from { weekday, n } format to RRule.{Day} format
      const formattedDay = day?.n
        ? weekdayMap[daynumber].nth(day.n)
        : weekdayMap[daynumber];

      return formattedDay;
    });

    return convertedByWeekDay;
  } catch (error) {
    return byweekday;
  }
};

export const convertRRule = (rrule: any, zone?: any) => {
  try {
    const rruleoptions = { ...(rrule.origOptions || {}) };
    const conversionFunction =
      typeof rruleoptions.dtstart === "string"
        ? DateTime.fromISO
        : DateTime.fromJSDate;
    const processedDates = {
      dtstart: rruleoptions.dtstart
        ? conversionFunction(rruleoptions.dtstart, {
            zone: zone || "utc",
          })
            .setZone("utc", {
              keepLocalTime: true,
            })
            .toJSDate()
        : undefined,
      until: rruleoptions.until
        ? conversionFunction(rruleoptions.until, {
            zone: zone || "utc",
          })
            .setZone("utc", {
              keepLocalTime: true,
            })
            .toJSDate()
        : undefined,
      tzid: "utc",
    };
    const convertedRRule = {
      ...rruleoptions,
      ...processedDates,
      byweekday: convertByWeekday(rruleoptions.byweekday),
    };
    return convertedRRule;
  } catch (error) {
    console.log("Error converting RRule: ", error);
    return rrule;
  }
};

export const calculateNextOccurrence = (
  event: {
    name: any;
    rrule: any;
    recurring: boolean;
    frequency: string;
    recurringEnd: string;
    date: {
      start: Date;
      end: Date;
    };
    timezone: string[];
  },
  referenceDate: any = DateTime.now(),
  opts: { verbose?: boolean } = {}
) => {
  const debug = (message: string, ...args: any[]) => {
    if (opts.verbose) {
      console.log(`[NextOccurrence] ${message}`, ...args);
    }
  };

  try {
    const { rrule, recurring, frequency, recurringEnd, date } = event;
    const startDate = DateTime.fromJSDate(date.start);

    debug("Input parameters:", {
      eventName: event.name,
      recurring,
      frequency,
      recurringEnd,
      date,
      rrule,
      startDate: startDate.toISO(),
      referenceDate:
        referenceDate instanceof DateTime
          ? referenceDate.toISO()
          : referenceDate,
    });

    if (!rrule && !recurring) {
      debug("Event is not recurring, returning original date");
      return date;
    }

    // Reference date normalization
    if (referenceDate instanceof Date && !isNaN(referenceDate.getTime())) {
      referenceDate = DateTime.fromJSDate(referenceDate);
      debug("Normalized reference date from JS Date:", referenceDate.toISO());
    } else if (!(referenceDate instanceof DateTime)) {
      referenceDate = DateTime.now();
      debug("Using current date as reference:", referenceDate.toISO());
    }

    // Check if event is currently ongoing or is in the future
    const isOngoing =
      startDate <= referenceDate &&
      DateTime.fromJSDate(date.end) >= referenceDate;
    debug("isOngoing", isOngoing, "startDate", startDate, " is less than ", referenceDate, " and ", date.end, " is greater than ", referenceDate);
    const isFuture = startDate >= referenceDate;
    debug("isFuture", isFuture, "startDate", startDate, " is greater than ", referenceDate);
    if (isOngoing || isFuture) {
      debug("Event is ongoing or in future, returning original date", {
        isOngoing,
        isFuture,
      });
      return date;
    }

    const zone = event.timezone && event.timezone.length
      ? event.timezone[0]
      : "utc";
    debug("Using timezone:", zone);

    let rule;
    if (rrule && rrule.origOptions) {
      const convertedRule = convertRRule(rrule, zone);
      debug("Using existing RRule with converted options:", convertedRule);
      rule = new RRule(convertedRule);
    } else {
      debug("Creating new RRule with frequency:", frequency);
      const ruleOptions = {
        freq:
          frequency === "daily"
            ? RRule.DAILY
            : frequency === "weekly"
            ? RRule.WEEKLY
            : frequency === "monthly"
            ? RRule.MONTHLY
            : null,
        dtstart: startDate.toJSDate(),
        until: recurringEnd ? new Date(recurringEnd) : undefined,
      };
      debug("RRule options:", ruleOptions);

      if (!ruleOptions.freq) {
        debug("Invalid frequency, returning original date");
        return date;
      }
      rule = new RRule(ruleOptions as Partial<Options>);
    }

    const after =
      typeof referenceDate.toJSDate === "function"
        ? referenceDate.toJSDate()
        : referenceDate;
    debug("Calculating next occurrence after:", after);

    const nextOccurrence = rule.after(after);
    if (!nextOccurrence) {
      debug("No next occurrence found, returning original date");
      return date;
    }

    const nextStartDate = DateTime.fromJSDate(nextOccurrence)
      .toUTC()
      .setZone(zone, { keepLocalTime: true });

    const duration = DateTime.fromJSDate(date.end).diff(startDate);
    const nextEndDate = nextStartDate.plus(duration);

    debug("Calculated next occurrence:", {
      nextStartDate: nextStartDate.toISO(),
      nextEndDate: nextEndDate.toISO(),
      duration: duration.toObject(),
    });

    return {
      start: nextStartDate.toJSDate(),
      end: nextEndDate.toJSDate(),
    };
  } catch (error) {
    console.error("Error calculating next occurrence:", {
      error,
      eventName: event.name,
      referenceDate:
        referenceDate instanceof DateTime
          ? referenceDate.toISO()
          : referenceDate,
    });
    return event.date;
  }
};
