import ConfigService from "../../../admin/src/utils/ConfigService";

export const backendUrl = import.meta.env.VITE_BACKEND_URL;
// export const DEBUG = import.meta.env.VITE_DEBUG === 'true';
export const DEBUG = false;
export const ENABLE_LOGGING = import.meta.env.VITE_ENABLE_LOGGING === "true";
export const FACEBOOK_APP_ID = "937522511109972";
export const ANDROID_DOWNLOAD_LINK = "https://play.google.com/store/apps/details?id=us.inyc.app"
export const IOS_DOWNLOAD_LINK = "https://apps.apple.com/us/app/in-yc/id6473464078"

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "unknown";
}

export const version = "2.2.20";


// Returns the current value whenever accessed
export const getDynamicConstants = () => ({
  BASE_URL: ConfigService.getBackendUrl(),
  SOCKET_URL: ConfigService.getBackendUrl(),
  ENVIRONMENT: ConfigService.getCurrentEnvironment(),
  ENV_COLOR: ConfigService.getEnvironmentColor(),
  LOGGING_ENABLED: ConfigService.isLoggingEnabled(),
  version: '2.2.20',
  tinymceApiKey: import.meta.env.VITE_TINYMCE_API_KEY
});

// For components that need to re-render on config changes
export const useEnvironmentChange = (callback) => {
  React.useEffect(() => {
    const handleConfigChanged = () => {
      if (callback) callback();
    };
    
    window.addEventListener('admin_config_changed', handleConfigChanged);
    return () => {
      window.removeEventListener('admin_config_changed', handleConfigChanged);
    };
  }, [callback]);
  
  return getDynamicConstants();
};

