import React, { useEffect } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MapMarker from "../Map/MapMarker";
import musicMarker from "../../assets/map/music-marker.png";
import barMarker from "../../assets/map/bar-marker.png";
import locationMarker from "../../assets/map/location-marker.png";
import markerShadow from "../../assets/map/marker-shadow.png";
import L from "leaflet";
import MapTiles from "../MapTiles/MapTiles";
import { createCategoryIcon, createUserIcon } from "../../util/mapStyles";



const MiniMap = ({ event, userLocation, style }) => {
  const mapRef = React.useRef();

  useEffect(() => {
    if (mapRef.current && event?.location) {
      mapRef.current.setView( [event.location.coordinates[1], event.location.coordinates[0]]);
    }
  }, [event]);

  return (
    <MapContainer
      key={event?._id}
      attributionControl={false}
      style={style}
      whenReady={(map) => {
        mapRef.current = map.target;
      }}
      center={event?.location ? [event.location.coordinates[1], event.location.coordinates[0]] : userLocation ? [userLocation.lat, userLocation.lng] : [0, 0]}
      zoom={13}
      minZoom={10}
      className="mini-map-container"
      zoomControl={false}
      maxBounds={[
        [-90, -180], // southwest corner of the world
        [90, 180], // northeast corner of the world
      ]}
    >
      <MapTiles 
        key={event?._id}
      />
      <MapMarker
        icon={createCategoryIcon(event?.category)}
        position={event?.location ? [event.location.coordinates[1], event.location.coordinates[0]] : [0, 0]}
        title={event.name}
      />
      {userLocation && (
        <Marker
          icon={createUserIcon()}
          position={[userLocation.lat, userLocation.lng]}
        />
      )}
    </MapContainer>
  );
};

export default MiniMap;
