import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Loader from "../icons/Loader";
import chevronIcon from "../../assets/icons/chevron-up.png";
import "./DropDownInput.css";
import { useOutsideAlerter } from "../../util/useOutsideAlerter";

const DropDownInput = forwardRef((props, ref) => {
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(
    props.value || props.defaultValue ? true : false
  );
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setFocused(false));
  let compRef = ref;
  const handleChange = (event) => {
    setHasValue(event.target.value ? true : false);
    props.onChange && props.onChange(event);
  };

  useEffect(() => {
    props.onFocusChange &&
      props.onFocusChange(
        (props.open !== undefined && !props.open ? false : focused) &&
          hasValue &&
          (props.dropItems?.length > 0 || props.dropLoading),
        focused
      );

    // console.log(
    //   props.open !== undefined ? props.open : focused,
    //   hasValue,
    //   props.dropItems?.length > 0,
    //   props.dropLoading
    // );
  }, [focused, hasValue, props.dropLoading, props.dropItems, props.open]);

  // Expose the focused state via the ref
  useImperativeHandle(compRef, () => ({
    isOpen: () => focused,
    focused: focused,
    close: () => setFocused(false),
    open: () => setFocused(true),
    selectAllInputValue: () => {
      wrapperRef.current.querySelector("input").select();
    },
    getWrapperRef: () => wrapperRef,
  }));

  return (
    <div
      ref={wrapperRef}
      className="mt-[1.5rem] relative z-[100] flex gap-[1rem] justify-center items-center px-[1.5rem] py-[0.5rem] border-[1px] border-[var(--inyc-input-border-color)] rounded-[var(--inyc-input-border-radius)]"
      style={{ ...(props.style || {}) }}
      onClick={() => {
        // console.log("click");
        props.disabled && setFocused(!focused);
      }}
    >
      {props.icon && <div className="dropdown-input-icon">{props.icon}</div>}
      {props.label && (
        <label
          className={`absolute font-['Primary-Medium'] top-[2rem] translate-y-[-50%] text-[var(--inyc-primary-text-color)] bg-[var(--inyc-primary)] text-[1.4rem] duration-200 pointer-events-none  
        ${
          focused || hasValue || props.placeHolder
            ? "py-0 !top-[-18px] !left-0 text-[var(--inyc-primary-color)] "
            : ""
        }`}
        >
          {props.label}
        </label>
      )}
      <input
        className={`dropdown-input cursor-pointer ${
          props.disabled ? "pointer-events-none" : ""
        }`}
        placeholder={props.placeHolder}
        ref={props.Ref}
        onChange={handleChange}
        value={props.value}
        onFocus={() => {
          setFocused(true);
        }}

        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setFocused(false);
          }
        }}
        disabled={props.disabled}
        style={props.inputStyle || {}}
      />
      {props.showDropDownArrow && (
        <img
          onClick={() => {
            setFocused(!focused);
          }}
          className="cursor-pointer invert-[var(--inyc-invert-black)]"
          src={chevronIcon}
          style={{
            ...{
              height: 15,
              width: 15,
            },
            ...(focused ? {} : { transform: "rotate(180deg)" }),
          }}
        />
      )}
      <div
        className={`absolute z-[10] ${
          props.dropDownDirection === "top"
            ? "rounded-t-[1rem] border-b-[0]"
            : "rounded-b-[1rem] border-t-[0]"
        }  w-[calc(100%-2rem)] border-[1px] border-[var(--inyc-input-border-color)] box-content bg-[var(--inyc-primary)]  overflow-auto max-h-[500px] 
        ${
          props.dropDownDirection === "top"
            ? "shadow-[0px_-10px_10px_rgba(0,0,0,0.1)]"
            : "shadow-[0px_15px_10px_rgba(0,0,0,0.1)]"
        } grid duration-200 ${
          (props.open !== undefined && !props.open ? false : focused) &&
          hasValue &&
          (props.dropItems?.length > 0 || props.dropLoading)
            ? `grid-rows-[1fr] pointer-events-auto opacity-1 ${
                props.dropDownDirection === "top"
                  ? "bottom-[100%]"
                  : "top-[100%]"
              }`
            : `grid-rows-[0fr] opacity-0 ${
                props.dropDownDirection === "top" ? "bottom-[90%]" : "top-[90%]"
              } pointer-events-none`
        } ${props.dropDownClassName}`}
      >
        <div className="min-h-0">
          {props.dropLoading ? (
            <div className="dropdown-loader">
              <Loader />
            </div>
          ) : (
            <div>
              {props.dropItems &&
                props.dropItems.map((item, i) => {
                  if (props.ItemComponent) {
                    return (
                      <props.ItemComponent
                        key={i}
                        item={item}
                        onClick={() => {
                          setFocused(false);
                        }}
                      />
                    );
                  } else {
                    return (
                      <div
                        key={i}
                        className="dropdown-item"
                        onClick={() => {
                          setFocused(false);
                          item.onClick && item.onClick();
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const styles = {
  smallLabel: {
    transform: "scale(0.9)",
    fontSize: "1.5rem",
    padding: "0 0.5rem",
    top: "-18px",
    left: "5px",
    // color: "#b6c8db",
    color: "var(--inyc-primary-color)",
  },
  dropDownContainer: {
    position: "absolute",
    zIndex: 10,
    top: "100%",
    left: "-1px",
    width: "100%",
    boxSizing: "content-box",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,0.05)",
    borderTop: "none",
    boxShadow: "0px 7px 10px rgba(0,0,0,0.1)",
    maxHeight: "500px",
    overflowY: "scroll",
  },
};

export default DropDownInput;
