import { createContext, useContext } from "react";
import { createpopup } from "../../util/Modal";
import axios from "axios";
import { ENABLE_LOGGING, backendUrl } from "../../api/constants";

const enabled = ENABLE_LOGGING

class Log {
  constructor(meta) {
    this.logs = [];
    this.meta = meta;
    this.styles = {
      Error: "text-[rgb(223,44,44)] font-['Primary-Regular']",
      Success: "text-[rgb(32,197,87)] font-['Primary-Regular']",
      Info: "text-[blue]",
      Default: "text-black/70",
    };
  }

  formatMessage(message) {
    if (typeof message === "string") {
      try {
        // Attempt to parse the string as JSON
        const parsed = JSON.parse(message);
        // If parsing succeeds, format the JSON object
        return JSON.stringify(parsed, null, 2);
      } catch (e) {
        // If parsing fails, return the original string
        return message;
      }
    } else if (typeof message === "object") {
      // Pretty-print JSON objects
      return JSON.stringify(message, null, 2);
    } else {
      return message;
    }
  }

  parseLogLevel(log) {
    let level = "Default";
    let message = log;
    Object.keys(this.styles).forEach((key) => {
      if (log.includes(`[${key}]`)) {
        level = key;
        message = log.replace(`[${key}]`, "");
      }
    });
    return { level, message };
  }

  log(...messages) {
    if (!enabled) {
      return;
    }

    const combinedMessage = messages
      .map((message) => {
        if (message instanceof FormData) {
          // Convert FormData to a readable object
          let formDataObj = {};
          message.forEach((value, key) => (formDataObj[key] = this.formatMessage(value)));
          return `FormData: ${JSON.stringify(formDataObj, null, 2)}`;
        } else {
          return this.formatMessage(message);
        }
      })
      .join(" ");

    // console.log(combinedMessage);
    this.logs.push(
      `[${new Date().toLocaleString()}]${this.meta?.location ? `[${this.meta.location}]` : ""}${this.meta?.function ? `[${this.meta.function}]` : ""} ${combinedMessage}`
    );
  }

  async display() {
    if(!enabled) {
      return;
    }
    await createpopup(() => {
      return (
        <div className="max-h-[80vh]">
          <h1
            className="mb-[1rem]"
          >Logs</h1>
          <div className="border-[1px] text-start border-black/10 px-[1rem] max-h-[80vh] max-w-[80vw] text-[0.9rem] text-black/70 overflow-scroll">
            {this.logs?.map((log, index) => {
              const { level, message } = this.parseLogLevel(log);
              return (
                <div
                  key={index}
                  className={`py-[0.5rem] border-b-[1px] border-b-black/10 ${this.styles[level]}`}
                >
                  {message}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  }

  async send() {
    if (!enabled) {
      return;
    }

    try {
      const { data } = await axios.post(`${backendUrl}/user/logs`, {
        meta: this.meta,
        logs: this.logs,
      });
      if (data.error) {
        this.log("[Error] error in sending logs", data.error);
      } else {
        this.log("[Success] logs sent successfully", data);
      }
    } catch (error) {
      this.log("[Error] error in sending logs", error);
    }

    this.display();
  }

  clear() {
    this.logs = []; // Clear the logs
  }
}

export const LogContext = createContext();

export const LogProvider = ({ children }) => {
  const startLog = (meta) => {
    return new Log(meta);
  };

  const value = {
    startLog,
  };

  return <LogContext.Provider value={value}>{children}</LogContext.Provider>;
};

export const useLogger = () => {
  return useContext(LogContext);
};
