import React from "react";
import "./Loader.css";
import { useData } from "../../context/DataContext/DataProvider";
const Loader = ({
  white = false,
  width = 18,
  height = 10,
  color = "black",
}) => {
  const data = useData();

  const categories = data?.globalConfig?.categories || [
    {
      activeColor: "#874840",
    },
    {
      activeColor: "#E65A41",
    },
    {
      activeColor: "#F47F6B",
    },
    {
      activeColor: "#FBB04B",
    },
    {
      activeColor: "#8A8D56",
    },
    {
      activeColor: "#3F684A",
    },
  ];

  return (
    <div
      className="dot-loader-1"
      style={{
        "--color": white ? "white" : color,
        "--size": `${height}px`,
      }}
    >
      {categories?.slice(0, 6)?.map((category, index) => (
        <span
          key={index}
          style={{
            "--color": category.activeColor,
          }}
        ></span>
      ))}
    </div>
  );
};

export default Loader;
