import React from "react";
import { useUser } from "../../context/UserContext/UserProvider";
import Logo from "../../assets/imgs/inyc.svg";

const VenueCard = ({
  size = 1,
  venue,
  className,
  onUnselect,
  hideOwnershipStatus = false,
  scale = 1,
}) => {
  const { user } = useUser();

  return (
    <div
      className={`relative rounded-[var(--inyc-input-border-radius)] border-[var(--inyc-input-border-color)] p-[1rem] mb-[1.5rem] shadow-sm ${className}`}
      style={{
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        marginBottom: `${1.5 * scale}rem`,
        padding: `${0.5 * scale}rem ${1.2 * scale}rem ${0.2 * scale}rem ${
          1.2 * scale
        }rem`,
        borderRadius: `0.5rem`,
        boxShadow: "0 0.1rem 0.2rem rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        className="font-['Primary-Medium'] text-[1.5rem]"
        style={{
          fontSize: `${1.5 * scale}rem`,
        }}
      >
        {venue?.name?.toLowerCase()}
      </div>
      <div
        className="mb-[1rem]"
        style={{
          marginBottom: `${0.5 * scale}rem`,
        }}
      >
        <div
          className="font-['Primary-Medium'] text-[1.2rem]"
          style={{
            fontSize: `${1.2 * scale}rem`,
          }}
        >
          {venue?.venueType}
        </div>
        <div
          className="font-['Primary-Regular'] text-[1rem]"
          style={{
            fontSize: `${1 * scale}rem`,
          }}
        >
          {venue?.address?.toLowerCase()}
        </div>
      </div>
      <div
        className="flex items-center mb-[0.5rem] justify-between"
        style={{
          fontSize: `${1 * scale}rem`,
        }}
      >
        <div
          className="bg-[var(--inyc-primary-button)] border-[1px] border-[var(--inyc-input-border-color)] ml-[-0.5rem] shadow-md hover:bg-[var(--inyc-secondary)] cursor-pointer hover:text-[var(--inyc-secondary-text-color)] duration-300 rounded-[2rem] text-[var(--inyc-primary-text-color)] py-[0.7rem] px-[1.4rem] w-fit text-[1.3rem] font-['Primary-Medium'] relative"
          style={{
            transition: "0.3s",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            fontSize: `${1 * scale}rem`,
          }}
          onClick={onUnselect}
        >
          unselect
        </div>
        <div
          className="flex flex-col justify-end"
        >
          <div
            className="text-[var(--inyc-primary-text-color)] border-b-[1px] pb-[0.2rem]"
            style={{
              fontSize: `${1 * scale}rem`,
            }}
          >
            {!hideOwnershipStatus
              ? venue?.me || venue?.account_id === user?._id
                ? "owned by you"
                : venue?.account_id
                ? "claimed by another user"
                : "unclaimed"
              : null}
          </div>
          <div
            className="flex justify-end items-center gap-[0.15rem] rounded-lg"
            style={{
              // padding: `${0.5 * scale}rem ${0.1 * scale}rem`,
              gap: `${0.15 * scale}rem`,
              // borderRadius: `${0.5 * scale}rem`,
            }}
          >
            <img
              src={Logo}
              alt="inyc verified"
              className="w-[2rem] mr-[0.1rem] h-[2rem] invert-[var(--inyc-invert-black)] object-contain"
              style={{
                width: `${2 * scale}rem`,
                height: `${2 * scale}rem`,
              }}
            />
            <div
              className="text-[var(--inyc-primary-text-color)] font-['Primary-Regular'] text-[0.9rem]"
              style={{
                fontSize: `${0.9 * scale}rem`,
              }}
            >
              verified
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueCard;
