import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../components/icons/Loader";
import axiosInstance from "../api/axiosInstance";

const TermsAndConditions = () => {
  const [terms, setTerms] = useState(null);
  const [loading, setLoading] = useState(true);

  const getTerms = async () => {
    setLoading(true);
    const res = await axiosInstance.get("/user/terms");
    setTerms(res.data?.terms?.content);
    setLoading(false);
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <div>
      {loading ? (
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          className="p-[2rem] max-w-[1500px] mx-auto"
          dangerouslySetInnerHTML={{
            __html: terms,
          }}
        ></div>
      )}
    </div>
  );
};

export default TermsAndConditions;
