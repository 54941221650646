import React, { useRef, useState, useEffect } from 'react';

const PullToActionWrapper = ({
  children,
  onPull = () => {
    console.log('Pulled');
  },
  onRelease,
  onPullStart,
  onPullCancel,
  className,
  containerRef = null, // External container reference
  pullThreshold = 100, // Minimum distance to activate pull
}) => {
  const internalContainerRef = useRef(null); // Internal fallback container
  const startY = useRef(0); // Tracks the initial Y position of the touch
  const [pullDistance, setPullDistance] = useState(0); // Current pull distance
  const [isPulling, setIsPulling] = useState(false); // Tracks if the user is pulling
  const componentBounds = useRef(null);

  // Get the correct container
  const getContainer = () => containerRef?.current || internalContainerRef.current;

  const handleTouchStart = (e) => {
    const container = getContainer();
    if (!container) return;

    // Get component boundaries
    componentBounds.current = internalContainerRef.current.getBoundingClientRect();
    const touch = e.touches[0];
    
    // Check if touch is within component bounds
    const isWithinBounds = 
      touch.clientY >= componentBounds.current.top &&
      touch.clientY <= componentBounds.current.bottom &&
      touch.clientX >= componentBounds.current.left &&
      touch.clientX <= componentBounds.current.right;

    if (container.scrollTop === 0 && isWithinBounds) {
      startY.current = touch.clientY;
      setIsPulling(true);
      setPullDistance(0);
      onPullStart && onPullStart();
    } else {
      setIsPulling(false);
    }
  };

  const handleTouchMove = (e) => {
    if (!isPulling) return;
    
    const touch = e.touches[0];
    
    // Check if touch is still within component bounds
    const isWithinBounds = 
      touch.clientX >= componentBounds.current.left &&
      touch.clientX <= componentBounds.current.right;

    if (!isWithinBounds) {
      setIsPulling(false);
      setPullDistance(0);
      return;
    }

    const currentY = touch.clientY;
    const distance = currentY - startY.current;

    if (distance > 0) {
      setPullDistance(distance);
    } else {
      setIsPulling(false);
    }
  };

  const handleTouchEnd = () => {
    if (isPulling) {
      if (pullDistance >= pullThreshold) {
        // Activate the pull action
        onPull && onPull();
        onRelease && onRelease(); // Notify parent of success
      } else {
        // Cancel the pull action
        onPullCancel && onPullCancel();
      }
    }
    // Reset states
    setPullDistance(0);
    setIsPulling(false);
  };

  // Calculate progress percentage
  const progress = Math.min((pullDistance / pullThreshold) * 100, 100);

  // Attach event listeners to the container
  useEffect(() => {
    const container = getContainer();
    if (!container) return;

    container.addEventListener('touchstart', handleTouchStart, { passive: true });
    container.addEventListener('touchmove', handleTouchMove, { passive: true });
    container.addEventListener('touchend', handleTouchEnd, { passive: true });

    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchmove', handleTouchMove);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, [containerRef, isPulling, pullDistance]);

  return (
    <div
      ref={internalContainerRef}
      className={`relative overflow-auto w-full ${className}`}
    >
      {/* Circular Pull Indicator */}
      <div
        className="relative top-0 left-0 w-full flex justify-center items-center"
        style={{
          height: `${Math.min(pullDistance, pullThreshold)}px`,
          opacity: pullDistance > 0 ? 1 : 0,
          transition: isPulling ? 'none' : '0.3s ease',
        }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 50 50"
          className="transform"
          style={{
            transition: isPulling ? 'none' : 'transform 0.3s ease',
            position: 'absolute',
            transform: isPulling ? 'translateY(50%)' : 'translateY(-100%)',
          }}
        >
          <circle
            cx="25"
            cy="25"
            r="20"
            strokeWidth="4"
            stroke="var(--inyc-refresh-light)"
            fill="none"
          />
          <circle
            cx="25"
            cy="25"
            r="20"
            strokeWidth="4"
            stroke="var(--inyc-refresh-dark)"
            fill="none"
            strokeDasharray="126" // Circumference of the circle (2πr)
            strokeDashoffset={`${126 - (progress / 100) * 126}`}
            strokeLinecap="round"
          />
        </svg>
      </div>

      {/* Child Content */}
      <div>{children}</div>
    </div>
  );
};

export default PullToActionWrapper;
