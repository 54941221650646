import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

export const TabContent = (props) => {
  const { children, name, className } = props;
  return (
    <div name={name} className={`w-full overflow-x-hidden ${className}`}>
      {children}
    </div>
  );
};

const Tabs = (props) => {
  const { className, staticHeight, stickyContent,contentWrapperClass, children, hideTabs, currentTab, duration = 200, setCurrentTab, tabs } = props;
  const isControlled = currentTab !== undefined;
  const [activeTab, setActiveTab] = useState(currentTab);
  const [parentWidth, setParentWidth] = useState(0);
  const [activeTabHeight, setActiveTabHeight] = useState(0);

  const parentRef = useRef();

  const getActiveTabIndex = () => {
    const activeTabElement = parentRef.current?.querySelector(`div[name="${activeTab}"]`);
    if (!activeTabElement) return 0;
    const activeTabIndex = Array.from(activeTabElement.parentNode.children).indexOf(activeTabElement);
    return activeTabIndex;
  };

  const updateActiveTabHeight = () => {
    const activeTabElement = parentRef.current?.querySelector(`div[name="${activeTab}"]`);
    if (activeTabElement) {
      const startHeight = parentRef.current.clientHeight; // current height
      const endHeight = activeTabElement.offsetHeight; // new height

      const startTime = performance.now(); // start time

      const animateHeight = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // progress between 0 and 1

        // Calculate the current height
        const currentHeight = startHeight + (endHeight - startHeight) * progress;
        if (parentRef.current) parentRef.current.style.height = `${currentHeight}px`;

        if (progress < 1) {
          requestAnimationFrame(animateHeight); // Continue animation
        } else {
          if (parentRef.current) parentRef.current.style.height = ""; // Remove fixed height after animation
        }
      };

      requestAnimationFrame(animateHeight); // Start the animation
    }
  };

  useEffect(() => {
    const activeTabElement = parentRef.current?.querySelector(`div[name="${activeTab}"]`);

    if (!activeTabElement) return;

    const observer = new ResizeObserver(() => {
      setActiveTabHeight(activeTabElement.offsetHeight);
    });

    observer.observe(activeTabElement, {
      box: "border-box",
    });

    updateActiveTabHeight();

    // Clean up the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, [activeTab]);

  useEffect(() => {
    const parent = parentRef.current;
    if (!parent) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        setParentWidth(width);
      }
    });

    resizeObserver.observe(parent);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (isControlled) {
      setActiveTab(currentTab);
    }
  }, [currentTab, isControlled]);

  const handleTabChange = (tab) => {
    setCurrentTab && setCurrentTab(tab);
    setActiveTab(tab);
  };

  return (
    <div className={`overflow-hidden ${className}`}>
      {!hideTabs && (
        <div className="mb-[0.5rem] flex items-center justify-center gap-[1rem] max-w-full overflow-auto">
          {tabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={`font-bold text-center flex-1 cursor-pointer whitespace-nowrap duration-${duration} text-[1.2rem] px-[1rem] py-[0.5rem] rounded-[2rem] shadow-lg  ${
                  activeTab === tab ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]" : "text-[var(--inyc-primary-text-color)]/70 border-[1px] border-[var(--inyc-input-border-color)]"
                } `}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </div>
            );
          })}
        </div>
      )}
      {stickyContent}
      <div ref={parentRef} className={`max-w-full overflow-hidden`}>
        <div
          style={{
            width: parentWidth * React.Children.toArray(children).filter(Boolean)?.length,
            transform: `translateX(-${parentWidth * getActiveTabIndex()}px)`,
            transition: `transform ${duration / 1000}s ease-in-out`,
            height: staticHeight ? "100%" : activeTabHeight,
          }}
          className={`${contentWrapperClass} flex items-start justify-center relative z-[100]`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
