import React from "react";
import { forwardRef, useEffect, useRef, useState } from "react";

const Radio = forwardRef(({ value, onChange, options, style = {} }, ref) => {
  return (
    <div>
      <div className="flex flex-col gap-[1rem]">
        {options.map((option) => (
          <div key={option.value} className="flex items-center justify-start">
            <input
              ref={ref}
              style={{
                marginRight: "10px",
                width: "20px",
                minWidth: "20px",
              }}
              className="cursor-pointer"
              type="radio"
              name={option.value}
              value={option.value}
              checked={value === option.value}
              onChange={(e) => onChange(
                option.value,
              )}
            />
            <label
              className="max-w-full overflow-hidden"
            >
              {
                //if option.label is a function call it
                typeof option.label === "function"
                  ? option.label()
                  : option.label
              }
            </label>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Radio;
