import React, { useEffect, useRef, useState } from "react";
import logoLight from "../../../assets/imgs/logo-transparent-light.gif";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { backendUrl } from "../../../api/constants";
import InputMessage from "../../../components/Input/InputMessage";
import ProfilePicture from "../../../components/icons/ProfilePicture";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [tokenMessage, setTokenMessage] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  //check if url query contains token
  const processToken = async (token) => {
    //redirect to reset password page
    try {
      const { data } = await axios.get(
        `${backendUrl}/auth/reset-password-token/${token}`
      );
      if (data.error) {
        setTokenMessage({ error: data.error });
      } else {
        setTokenMessage({ success: "token is valid", user: data.user });
      }
    } catch (error) {
      setTokenMessage({
        error: "something went wrong, please try again later",
      });
    }
  };

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      setMessage();
      processToken(token);
    }
  }, [searchParams]);

  const resetPassword = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${backendUrl}/auth/reset-password-request`,
        { email }
      );
      if (data.error) {
        setMessage({ error: data.error });
      } else {
        setMessage({
          success: `an email has been sent to ${email} with a link to reset your password`,
        });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: "something went wrong, please try again later" });
    } finally {
      setLoading(false);
    }
  };

  const reset = async () => {
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setMessage({ error: "passwords do not match" });
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(`${backendUrl}/auth/reset-password`, {
        token: searchParams.get("token"),
        password: passwordRef.current.value,
      });
      if (data.error) {
        setMessage({ error: data.validation?.password?.error || data.error });
      } else {
        setMessage({ success: "password has been reset", reset: true });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: "something went wrong, please try again later" });
    } finally {
      setLoading(false);
    }
    (" className=");
  };

  return (
    <div className="w-[90%] max-w-[500px] px-[2rem] min-h-full flex flex-col justify-center items-center gap-[1rem]">
      <div className="auth-logo !mb-[2rem]">
        <img
          src={logoLight}
          className="invert-[var(--inyc-invert-black)]"
          alt="logo"
        />
      </div>
      <div>
        <div className="text-center text-[2.5rem] mb-[2rem] !mt-0 font-['Primary-Medium']">
          reset password
        </div>
        {!tokenMessage ? (
          <>
            <div className="mb-[2rem]">
              <>
                <div className="mb-[1rem] text-[1.1rem] !mt-0 font-['Primary-Regular']">
                  {!email || email === "" ? (
                    "please enter your email address below to reset your password."
                  ) : (
                    <>
                      an email will be sent to{" "}
                      <span className="font-['Primary-Medium']">'{email}'</span>{" "}
                      with a link to reset your password.
                    </>
                  )}
                </div>
                <Input
                  underline
                  placeHolder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </>
            </div>
            <div className="mb-[1rem]">
              <Button
                type="secondary"
                loading={loading}
                className={"mb-[0.5rem] text-[1.2rem]"}
                onClick={resetPassword}
              >
                reset password
              </Button>
              {message && (
                <InputMessage className="!mx-auto w-fit" messageObj={message} />
              )}
            </div>

            <Link to="/auth/signin">
              <div className="text-[1.2rem] font-['Primary-Regular'] mt-[2rem] text-[--inyc-primary-text-color-light]">
                go back to signin
              </div>
            </Link>
          </>
        ) : tokenMessage.error ? (
          <div>
            <div className="mb-[2rem]">
              <div className="p-[0.5rem] rounded-[1rem] flex justify-center items-stretch">
                <div>
                  <div className="mb-[2rem] text-[rgb(223,44,44)] font-bold text-[1.3rem]">
                    {tokenMessage.error ||
                      "something went wrong, please try again"}
                  </div>
                  <Button
                    onClick={() => {
                      setTokenMessage();
                      searchParams.delete("token");
                    }}
                  >
                    try again
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-[2rem]">
            <div className="p-[1rem] mb-[1rem] mx-auto w-fit rounded-[1rem] shadow-lg flex justify-start items-stretch gap-[1rem]">
              <ProfilePicture
                style={{
                  width: "3rem",
                  height: "3rem",
                }}
                profilePicture={tokenMessage.user.profilePicture}
                username={
                  tokenMessage.user.firstName.charAt(0) +
                  tokenMessage.user.lastName.charAt(0)
                }
              />
              <div className="text-start">
                <div className="font-['Primary-Regular']">
                  {tokenMessage.user.firstName +
                    " " +
                    tokenMessage.user.lastName}
                </div>
                <div>{tokenMessage.user.email}</div>
              </div>
            </div>
            <div className="mb-[1rem] xflex justify-start flex-col items-center">
              <Input Ref={passwordRef} placeHolder="new password" password />
              <Input
                Ref={confirmPasswordRef}
                placeHolder="confirm password"
                password
              />
            </div>
            {message?.reset ? (
              <div>
                {message && (
                  <InputMessage
                    className="!mx-auto w-fit mb-[0.5rem]"
                    messageObj={message}
                  />
                )}
                <div>
                  go to the{" "}
                  <Link
                    className="text-[--inyc-primary-text-color-light]"
                    to="/auth/signin"
                  >
                    signin page
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <Button
                  type="secondary"
                  loading={loading}
                  className={"mb-[0.5rem] text-[1.2rem]"}
                  onClick={reset}
                >
                  reset password
                </Button>
                {message && (
                  <InputMessage
                    className="!mx-auto w-fit"
                    messageObj={message}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
