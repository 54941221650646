import { DateTime } from "luxon";
import { DEBUG } from "../api/constants";
import { RRule } from "rrule";
import { convertRRule, convertByWeekday } from "lib/src";
const defaultDateFormat = "MM-dd-yyyy";

export function convertDatesToLocalTimezones(locations) {
  locations.forEach((location) => {
    // Determine if the location is an event or a venue
    if (location.type === "event") {
      convertEventDate(location);
    } else if (location.type === "venue" && location.events) {
      location.events.forEach((event) => {
        convertEventDate(event);
      });
    }
  });

  return locations;
}

export function convertEventDate(event) {
  //~ Convert Date to Local
  if (event.type === "event") {
    // console.log('before convert', event)
    if (event.date && event.date.start) {
      event.date.start = convertUTCDateToLocal(event.date.start);
    }
    if (event.date && event.date.end) {
      event.date.end = convertUTCDateToLocal(event.date.end);
    }
    if (event.nextOccurence && event.nextOccurence.start) {
      event.nextOccurence.start = convertUTCDateToLocal(
        event.nextOccurence.start
      );
    }
    if (event.nextOccurence && event.nextOccurence.end) {
      event.nextOccurence.end = convertUTCDateToLocal(event.nextOccurence.end);
    }
  } else if (event.type === "venue" && event.events) {
    event.events.forEach((event) => {
      convertEventDate(event);
    });
    if (event._matchingEvent) {
      convertEventDate(event._matchingEvent);
    }
  }

  return event;
}

function convertUTCDateToLocal(utcDateString) {
  // Parse the UTC date string into a Luxon DateTime object
  const utcDate = DateTime.fromISO(utcDateString, { zone: "utc" });

  // Convert the UTC DateTime object to local timezone
  const localDate = utcDate.toLocal().toJSDate();

  return localDate;
}

export const formatedDate = (odate, timezone = []) => {
  if (!odate) {
    return;
  }
  let dateString;
  let date = { ...odate }; // Clone the original date object

  let zone = "local"; // Default to local timezone
  if (timezone.length > 0) {
    zone = timezone[0]; // Use the first timezone
  }

  let now = DateTime.local().setZone(zone); // Current date in the specified timezone
  let tomorrow = now.plus({ days: 1 }); // Tomorrow's date in the specified timezone

  if (date.start) {
    date.start = DateTime.fromJSDate(date.start).setZone(zone).toISO();
  }
  if (date.end) {
    date.end = DateTime.fromJSDate(date.end).setZone(zone).toISO();
  }

  // Check if event date is 'today'
  if (
    date.start &&
    DateTime.fromISO(date.start).setZone(zone).toFormat(defaultDateFormat) ===
      now.toFormat(defaultDateFormat)
  ) {
    dateString = "today";
  }
  // Check if event date is 'tomorrow'
  else if (
    date.start &&
    DateTime.fromISO(date.start).setZone(zone).toFormat(defaultDateFormat) ===
      tomorrow.toFormat(defaultDateFormat)
  ) {
    dateString = "tomorrow";
  }
  // If event has a start and end date
  else if (date.start && date.end) {
    //if the formatting is the same, only show the start date if not show both
    if (
      DateTime.fromISO(date.start).setZone(zone).toFormat(defaultDateFormat) ===
      DateTime.fromISO(date.end).setZone(zone).toFormat(defaultDateFormat)
    ) {
      dateString = DateTime.fromISO(date.start)
        .setZone(zone)
        .toFormat(defaultDateFormat);
    } else {
      dateString =
        DateTime.fromISO(date.start).setZone(zone).toFormat(defaultDateFormat) +
        " - " +
        DateTime.fromISO(date.end).setZone(zone).toFormat(defaultDateFormat);
    }
  }
  // If event has only a start date or start and end date are the same
  else if (date.start) {
    dateString = DateTime.fromISO(date.start).setZone(zone).toLocaleString();
  }

  return dateString;
};

export const formatedTime = (odate, timezone = []) => {
  if (!odate) {
    return;
  }
  let date = { ...odate }; // Clone the original date object

  let zone = "local"; // Default to local timezone
  if (timezone.length > 0) {
    zone = timezone[0]; // Use the first timezone
  }

  // Convert and format start and end times in 12-hour format
  const startTime = date.start
    ? DateTime.fromJSDate(date.start).setZone(zone).toFormat("hh:mm a")
    : null;
  const endTime = date.end
    ? DateTime.fromJSDate(date.end).setZone(zone).toFormat("hh:mm a")
    : null;

  let utcOffset =
    DateTime.fromJSDate(date.start).setZone(zone).toFormat("ZZ") || null; // UTC Offset
  // ... rest of your UTC offset logic ...

  const eventTimeStr =
    startTime && endTime
      ? `${startTime} - ${endTime}${DEBUG ? ` (UTC${utcOffset})` : ""}`
      : `${startTime}${DEBUG ? ` (UTC${utcOffset})` : ""}`;

  return eventTimeStr;
};

export const parseDateFromInputs = (
  startDate,
  endDate,
  timeHourStartRef,
  timeMinuteStartRef,
  timePeriodStart,
  timeHourEndRef,
  timeMinuteEndRef,
  timePeriodEnd
) => {
  const errors = [];

  // Function to check if the ref is valid and non-empty
  const validateRef = (ref, name) => {
    if (!ref.current || ref.current.value.trim() === "") {
      errors.push({
        message: `${name} is empty or invalid`,
        input: ref.current ? ref.current.name : "general",
      });
    }
  };

  // Validate all refs
  validateRef(timeHourStartRef, "Start Hour");
  validateRef(timeMinuteStartRef, "Start Minute");
  validateRef(timeHourEndRef, "End Hour");
  validateRef(timeMinuteEndRef, "End Minute");

  if (errors.length > 0) {
    return { errors };
  }

  // Convert 12-hour time to 24-hour time
  const convertTo24Hour = (hour, minute, period) => {
    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 < 12) {
      hour24 += 12;
    } else if (period === "AM" && hour24 === 12) {
      hour24 = 0;
    }
    return { hour: hour24, minute: parseInt(minute, 10) };
  };

  // Convert start time and end time
  const startTime = convertTo24Hour(
    timeHourStartRef.current.value,
    timeMinuteStartRef.current.value,
    timePeriodStart
  );
  const endTime = convertTo24Hour(
    timeHourEndRef.current.value,
    timeMinuteEndRef.current.value,
    timePeriodEnd
  );

  // Combine date and time for start and end
  const startDateTime = DateTime.fromJSDate(startDate)
    .setZone("utc", {
      keepLocalTime: true,
    })
    .set({ hour: startTime.hour, minute: startTime.minute });
  const endDateTime = DateTime.fromJSDate(endDate)
    .setZone("utc", {
      keepLocalTime: true,
    })
    .set({ hour: endTime.hour, minute: endTime.minute });

  // Check if end date is before start date
  if (endDateTime < startDateTime) {
    errors.push({
      message: "End date and time cannot be before start date and time",
      input: "general",
    });
    return { errors };
  }

  // Return the result if no errors
  return {
    date: {
      start: startDateTime.toJSDate(),
      end: endDateTime.toJSDate(),
    },
  };
};

const defaultFilterDateFormat = "MM/dd/yyyy"; // Define your default date format here

export const removeTimeFromRRuleText = (text) => {
  // Create a mapping of full day names to their short forms
  const dayShortForm = {
    monday: "mon",
    tuesday: "tue",
    wednesday: "wed",
    thursday: "thu",
    friday: "fri",
    saturday: "sat",
    sunday: "sun",
  };

  if (!text) {
    return text;
  }

  // Change weekdays to short form
  text = text.replace(
    /(monday|tuesday|wednesday|thursday|friday|saturday|sunday)/gi,
    (match) => dayShortForm[match.toLowerCase()]
  );

  // Remove the "at HH:mm" part from the string
  return text.replace(/ at \d{1,2}(:\d{2})?/, "")?.toLowerCase();
};

export const handleSpecialFilter = (events, dateFilter) => {
  let filterFunction;
  
  switch (dateFilter) {
    case "now":
      filterFunction = (ev, { nowZoned }) => {
        const date = ev.nextOccurence || ev.date;
        return date.start <= nowZoned && date.end >= nowZoned;
      };
      break;

    case "soon":
      filterFunction = (ev, { threeHoursFromNow, now }) => {
        const date = ev.nextOccurence || ev.date;
        return (
          (date.start < threeHoursFromNow && date.end > now) ||
          (date.end < threeHoursFromNow && date.end > now)
        );
      };
      break;

    case "later":
      filterFunction = (ev, { threeHoursFromNow, twelveHoursFromNow }) => {
        const date = ev.nextOccurence || ev.date;
        return (
          date.start >= threeHoursFromNow && date.start < twelveHoursFromNow
        );
      };
      break;

    case "late night":
      filterFunction = (ev, { twelveHoursFromNow }) => {
        const date = ev.nextOccurence || ev.date;
        return date.start >= twelveHoursFromNow;
      };
      break;

    default:
      filterFunction = (e) => true;
      break;
  }

  return events?.filter((e) => {
    const relativeDates = {
      now: DateTime.local(),
      nowZoned: DateTime.local().setZone(e.timezone?.[0] || 'local'),
      threeHoursFromNow: DateTime.local().plus({ hours: 3 }),
      twelveHoursFromNow: DateTime.local().plus({ hours: 12 })
    };

    if (e.type === "event") {
      return filterFunction(e, relativeDates);
    } else if (e.type === "venue" && e.events) {
      e.events = e.events?.filter((o) => filterFunction(o, relativeDates));
      if (e._matchingEvent) {
        e._matchingEvent = filterFunction(e._matchingEvent, relativeDates) 
          ? e._matchingEvent 
          : null;
      }
      return e.events?.length > 0;
    }
    return false;
  });
};

export const defaultDate = {
  start: DateTime.now().startOf("day").toJSDate(),
  end: DateTime.now().startOf("day").plus({ hours: 48 }).toJSDate(),
};

// Determine if the current dateFilter is set to the default dates
export const isDefaultDateFilter = (dateFilter) => 
  dateFilter && typeof dateFilter !== "string" &&
  dateFilter.start.toLocaleDateString() ===
    defaultDate.start.toLocaleDateString() &&
  dateFilter.end.toLocaleDateString() ===
    defaultDate.end.toLocaleDateString();


export const formatDateTime = (odate, timezone = [], recurrance = {}) => {
  if (!odate || (!odate.start && !odate.end)) {
    return { formattedDate: null, formattedTime: null };
  }

  // console.log('odate ', odate, ' timezone ', timezone, ' recurrance ', recurrance)


  try {
    // Set the default timezone to 'local' or the first provided timezone
    const zone = timezone.length > 0 ? timezone[0] : "local";

    // console.log('odate start', odate.start, odate.end, recurrance, timezone)
    // Initialize 'now' and 'tomorrow' once to avoid redundant computations
    const now = DateTime.local().setZone(zone);
    const tomorrow = now.plus({ days: 1 });

    // Processing start and end dates/times
    const startDate = odate.start
      ? DateTime.fromJSDate(odate.start).setZone(zone)
      : null;
    const endDate = odate.end
      ? DateTime.fromJSDate(odate.end).setZone(zone)
      : null;

    // console.log('start date', startDate, endDate, recurrance, odate, timezone)

    // Formatted date string
    let dateString;
    if (startDate) {
      if (
        startDate.toFormat(defaultFilterDateFormat) ===
        now.toFormat(defaultFilterDateFormat)
      ) {
        dateString = "today";
      } else if (
        startDate.toFormat(defaultFilterDateFormat) ===
        tomorrow.toFormat(defaultFilterDateFormat)
      ) {
        dateString = "tomorrow";
      } else if (
        endDate &&
        startDate.toFormat(defaultFilterDateFormat) !==
          endDate.toFormat(defaultFilterDateFormat)
      ) {
        dateString = `${startDate.toFormat(
          defaultFilterDateFormat
        )} - ${endDate.toFormat(defaultFilterDateFormat)}`;
      } else {
        dateString = startDate.toLocaleString();
      }
    }

    // Formatted time string
    let timeString;
    const startTime = startDate ? startDate.toFormat("hh:mm a") : null;
    const endTime = endDate ? endDate.toFormat("hh:mm a") : null;
    const utcOffset = startDate ? startDate.toFormat("ZZ") : null; // UTC Offset
    const DEBUG = false; // Set to 'true' to include UTC offset in the time string

    if (startTime) {
      timeString = endTime
        ? `${startTime} - ${endTime}${DEBUG ? ` (UTC${utcOffset})` : ""}`
        : `${startTime}${DEBUG ? ` (UTC${utcOffset})` : ""}`;
    }

    let rruleString;
    //recurring events
    if (recurrance?.rrule?.origOptions) {
      const rule = new RRule(convertRRule(recurrance.rrule, zone));
      rruleString = ` (${removeTimeFromRRuleText(
        rule.toText()?.toLowerCase()
      )})`;
    } else if (recurrance?.recurring) {
      if (recurrance?.frequency === "daily") {
        rruleString += " (daily";
      } else if (recurrance?.frequency === "weekly") {
        rruleString += " (weekly";
      } else if (recurrance?.frequency === "monthly") {
        rruleString += " (monthly";
      }

      if (recurrance?.recurringEnd) {
        rruleString += ` until ${DateTime.fromISO(recurrance?.recurringEnd)
          .setZone(zone)
          .toFormat(defaultFilterDateFormat)}`;
      }

      rruleString += ")";
    }

    return {
      formattedDate: `${dateString || ""} ${
        rruleString && rruleString !== "undefined" ? ` ${rruleString}` : ""
      }`,
      formattedTime: timeString,
      dateString,
      rruleString,
    };
  } catch (error) {
    console.log(
      "Error for event with recurrance  :",
      error,
      console.log(
        convertByWeekday(
          (recurrance.rrule.origOptions || recurrance.rrule.options).byweekday
        )
      ),
      recurrance,
      odate,
      timezone
    );
  }
};

export const distance = (lat1, lon1, lat2, lon2) => {
  // console.log(lat1, lon1, lat2, lon2);
  const R = 3958.8; // Radius of the earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180; // deg2rad below
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};
