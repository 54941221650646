import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import closeIcon from "../../assets/icons/closeIcon.png";
import { useData } from "../../context/DataContext/DataProvider";
import { loadIcon } from "../../util/iconRegistry";
// import "./TagInput.css";

const TagInput = forwardRef(
  (
    { tags, copy, onTagAdd, onTagDelete, label, defaultValue, placeHolder },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue || "");
    const [tagsValue, setTagsValue] = useState(tags || []);
    const [focused, setFocused] = useState(false);
    const { globalConfig } = useData();
    const knownServices = globalConfig?.knownServices || {};
    // console.log("knownServices ", knownServices);
    // forward values and a way to change them and get them
    useImperativeHandle(ref, () => ({
      value,
      setValue: (val) => setTagsValue(val),
      getValue: () => tagsValue,
    }));

    useEffect(() => {
      if (tags) {
        setTagsValue(tags);
      }
    }, [tags]);

    // useEffect(() => {
    //   console.log(tagsValue);
    // }, [tagsValue]);

    const handleChange = (e) => {
      setValue(e.target.value);
    };

    const handleTagAdd = (val) => {
      // console.log(val);
      let newTags = [...(tagsValue || [])];

      if (!tagsValue?.includes(val)) {
        setValue("");
        newTags.push(val);
        setTagsValue(newTags);
        onTagAdd && onTagAdd(val);
      }
    };

    const handleTagDelete = (tag) => {
      setTagsValue(tagsValue.filter((t) => t !== tag));
      onTagDelete && onTagDelete(tag);
    };

    const handleKeyDown = (e) => {
      if (
        e.key === "Enter" ||
        e.key === "Space" ||
        e.key === " " ||
        e.key === "Tab"
      ) {
        e.stopPropagation();
        // console.log(value, tagsValue);

        if (!tagsValue?.includes(value)) {
          handleTagAdd(
            value
              .replace(/[^a-zA-Z0-9]/g, "")
              .replace(/\s+/g, " ")
              .trim()
          );
          setValue("");
        }
      } else if (e.key === "Backspace" && value === "") {
        tagsValue && handleTagDelete(tagsValue[tagsValue.length - 1]);
      }
    };

    return (
      <div className="flex gap-[10px] relative justify-start flex-wrap items-center px-[20px] py-[10px] border-[1px] rounded-[1rem] border-[var(--inyc-input-border-color)]">
        {label && (
          <label
            className="input-label"
            style={{
              ...(focused || (value && value !== "") || placeHolder
                ? styles.smallLabel
                : {}),
              top: copy ? "-1px" : "-20px",
              transform: copy ? "translateY(-100%)" : "",
            }}
          >
            {label}
            {copy && <div className="mt-[0.5rem] mb-[1rem]">{copy}</div>}
          </label>
        )}
        {tagsValue &&
          tagsValue.map((tag, i) => {
            return (
              <div
                className="px-[20px] py-[10px] font-['Primary-Medium'] rounded-[1rem] bg-[var(--inyc-primary-lighter)] flex justify-center items-center gap-[5px]"
                key={i}
              >
                {
                  //if tag is a known service, render the icon
                  knownServices[tag?.toLowerCase()]?.icon ? (
                    <>
                      <div
                        className="
                      w-[1.4rem] 
                      h-[1.5rem]
                    "
                      >
                        {loadIcon(knownServices[tag?.toLowerCase()]?.icon, {
                          style: {
                            marginRight: "0.5rem",
                          },
                          size: 18,
                        })}
                      </div>
                      <div className="font-['Primary-Medium'] leading-[1rem]">
                        {knownServices[tag?.toLowerCase()]?.name}
                      </div>
                    </>
                  ) : (
                    tag
                  )
                }
                <img
                  className="invert-[var(--inyc-invert-black)] translate-y-[-5%]"
                  onClick={() => {
                    handleTagDelete(tag);
                  }}
                  src={closeIcon}
                  style={styles.closeIcon}
                />
              </div>
            );
          })}
        <input
          placeholder={placeHolder}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          style={styles.tagInnerInput}
        />
      </div>
    );
  }
);

const styles = {
  //   =====================
  tagInput: {
    display: "flex",
    gap: "10px",
    position: "relative",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "10px 20px",
    // border: "1px solid rgba(0,0,0,0.05)",
  },
  tag: {
    padding: "10px 20px",
    borderRadius: "20px",
    backgroundColor: "rgba(0,0,0,0.04)",
    // border: "1px solid rgba(0,0,0,0.05)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  closeIcon: {
    color: "var(--inyc-primary-color)",
    transition: "0.3s",
    // backgroundColor: "rgba(0,0,0,0.1)",
    // border: "1px solid rgba(0,0,0,0.1)",
    // boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
    cursor: "pointer",
    borderRadius: "50%",
    height: 20,
    width: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
  },
  tagInnerInput: {
    minWidth: "100px",
    width: "100px",
    flex: 1,
    minHeight: "45px",
    backgroundColor: "var(--inyc-primary)",
    outline: "none",
    fontSize: 16,
    border: "none",
    // backgroundColor: "red",
  },
  smallLabel: {
    transform: "scale(0.9)",
    fontSize: "1.4rem",
    padding: "0 0.5rem",
    transform: "translateY(-50%)",
    top: "-18px",
    left: "0px",
    color: "var(--inyc-primary-text-color)",
  },
};

export default TagInput;
