import React, { useState } from "react";

const Hover = ({ hstyle, style, ...props }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      style={{ ...style, ...(hovered ? hstyle : {}) }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      //pass props
      {...props}
    >
      {props.children}
    </div>
  );
};

export default Hover;
