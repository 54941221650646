import React from "react";
import logoLight from "../assets/imgs/logo-transparent-light.gif";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../context/UserContext/UserProvider";
import Loader from "../components/icons/Loader";
import Switch from "../components/Switch/Switch";
import axiosInstance from "../api/axiosInstance";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { backendUrl } from "../api/constants";

const UnsubscribeEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, fetchUser } = useUser();
  const navigate = useNavigate();

  const login = async () => {
    sessionStorage.setItem("redirect_url", "/unsubscribe-email");
    navigate("/auth/signin");
  };

  const handleSettingChange = (setting) => async (value) => {
    try {
      let response;
      if (user) {
        const url = `/user/me/unsubscribe-${setting}?value=${value}`;
        const { data } = await axiosInstance.get(url);
        response = data;
      } else {
        const url = `${backendUrl}/user/me/unsubscribe-${setting}/${searchParams.get("token")}?value=${value}`;
        const { data } = await axios.get(url);
        response = data;
      }
      // Update individual parameters
      if (response.notifications !== undefined) {
        searchParams.set("notifications", response.notifications+"");
      }
      if (response.newsletter !== undefined) {
        searchParams.set("newsletter", response.newsletter+"");
      }

      // Apply the updated search parameters to the URL
      setSearchParams(searchParams);
      if (user) {
        await fetchUser();
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("something went wrong", { variant: "error" });
    }
  };

  return (
    <div className="w-full min-h-[100vh] flex justify-center items-center">
      <div className="w-[90%] max-w-[500px] px-[2rem] min-h-full flex flex-col justify-center items-center gap-[1rem]">
      <div className="px-[2rem] py-[1rem] top-0 left-0 w-full ">
        <img className="h-[200px] invert-[var(--inyc-invert-black)]" src={logoLight} alt="logo" />
      </div>
        <div>
          <div className="text-center text-[2.5rem] mb-[2rem] !mt-0 font-['Primary-Medium']">email settings</div>
          {searchParams.get("status") === "success" && (
            <>
              {searchParams.get("message") === "newsletter_unsubscribed" && (
                <div className="text-center text-[1.5rem] mb-[2rem] font-['Primary-Regular']">You have successfully unsubscribed from our newsletter.</div>
              )}
              {searchParams.get("message") === "notifications_unsubscribed" && (
                <div className="text-center text-[1.5rem] mb-[2rem] font-['Primary-Regular']">You have successfully unsubscribed from our notifications.</div>
              )}
            </>
          )}

          {user || searchParams.get("token") ? (
            <div className="mb-[2rem]">
              <div className="mb-[1rem] flex justify-start items-stretch gap-[1rem]">
                <div className="pt-[0.5rem]">
                  <Switch value={user?.settings?.notifications || searchParams.get("notifications") === "true"} onChange={handleSettingChange("notifications")} />
                </div>
                <div>
                  <div className="text-[1.5rem] font-['Primary-Medium']">notifications</div>
                  <div className="text-[--inyc-primary-text-color-light]">receive notifications about your account, posts and more</div>
                </div>
              </div>
              <div className="mb-[1rem] flex justify-start items-stretch gap-[1rem]">
                <div className="pt-[0.5rem]">
                  <Switch value={user?.settings?.newsletter || searchParams.get("newsletter") === "true"} onChange={handleSettingChange("newsletter")} />
                </div>
                <div>
                  <div className="text-[1.5rem] font-['Primary-Medium']">newsletter</div>
                  <div className="text-[--inyc-primary-text-color-light]">receive regular updates about events, news and more</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center text-[1.5rem] mb-[2rem] font-['Primary-Regular']">
              you are not logged in.
              <br />
              please{" "}
              <div onClick={login} className="cursor-pointer inline-block text-[--inyc-primary-text-color]">
                login
              </div>{" "}
              to manage your email settings.
            </div>
          )}
          <div className="w-full flex justify-center">
            <Link to="/" className=" text-center text-[1.2rem] font-['Primary-Regular'] text-[--inyc-primary-text-color]">
              go back to home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeEmail;
