import React from "react";
import ContentList from "../ContentList/ContentList";
import Notice from "../ui/notice";

const ClaimedVenues = ({ userVenues, selectedVenue, handleVenuPick }) => {
  const handleListClick = (item, ri, ci) => {
    if (ci !== 0) {
      return;
    }
    handleVenuPick(item);
  };
  return (
    <div
      style={{
        background: "var(--inyc-primary)",
        padding: "1rem",
        paddingTop: "2rem",
        // boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
      }}
    >
      <ContentList
        emptyText="no claimed venues"
        emptyJSX={
          <Notice>
            if you’re an owner or representative of a business or space you can{" "}
            <strong>claim</strong> a business. this allows you to edit how the
            business appears to <strong>in—yc</strong> users.
            <br />
            <br />
            to claim, search or upload a new venue under the{" "}
            <strong>add new</strong> tab to the left and select{" "}
            <strong>claim</strong>. we’ll reach out via email to verify.
          </Notice>
        }
        onClick={handleListClick}
        selected={(item) => item._id === selectedVenue.venue?._id}
        // optionsPosition={0}
        items={userVenues?.map((vn) => {
          return {
            ...vn,
            createdAt: new Date(vn.createdAt).toLocaleString(),
            status: vn.status ? vn.status.replaceAll("_", " ") : null,
          };
        })}
        useColumns={{
          name: "name",
          // status: "status",
          venueType: "type",
          address: "address",
          createdAt: "created",
        }}
      />
    </div>
  );
};

export default ClaimedVenues;
