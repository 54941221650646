import { SocialLinkOption } from "../components/SocialsInput/SocialsInput";
import { FaInstagram } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io5";
import { IoLogoTiktok } from "react-icons/io5";
import { FaSpotify } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { FaSoundcloud } from "react-icons/fa6";
import { FaBandcamp } from "react-icons/fa";
import { FaVimeo } from "react-icons/fa";
import websiteIcon from "../assets/icons/website.svg";
import Icon from "../components/Icon/Icon";
import { PiGlobeSimpleLight } from "react-icons/pi";

export const socialOptions = [
  {
    rawIcon: FaInstagram,
    icon: (
      <FaInstagram size={25} color="var(--inyc-primary-text-color-light)" />
    ),
    id: "instagram",
    patterns: ["instagram.com"],
    placeholder: "@username", // Optional placeholder
    extract: (link) => {
      try {
        const formattedLink =
          link.startsWith("http://") || link.startsWith("https://")
            ? link
            : `https://${link}`;
        const url = new URL(formattedLink);
        if (url.hostname.includes("instagram.com")) {
          const parts = url.pathname.split("/").filter(Boolean);
          if (parts.length > 0) {
            return `@${parts[0]}`;
          }
        }
        return null;
      } catch {
        return null;
      }
    }, // Optional extract logic
    validateAndCompleteLink: (link, username) => {
      //if instagram link doesnt have a valid username at the end add it
      if(username?.startsWith("@")){
        return `instagram.com/${username?.replace("@","")}`;
      }

      return link;
    },
  },
  {
    rawIcon: IoLogoTiktok,
    icon: (
      <IoLogoTiktok size={25} color="var(--inyc-primary-text-color-light)" />
    ),
    id: "tiktok",
    patterns: ["tiktok.com"],
  },
  {
    rawIcon: IoLogoFacebook,
    icon: (
      <IoLogoFacebook size={25} color="var(--inyc-primary-text-color-light)" />
    ),
    id: "facebook",
    patterns: ["facebook.com", "fb.com"],
  },
  {
    rawIcon: FaSpotify,
    icon: <FaSpotify size={25} color="var(--inyc-primary-text-color-light)" />,
    id: "spotify",
    patterns: ["spotify.com"],
  },
  {
    rawIcon: FaXTwitter,
    icon: <FaXTwitter size={25} color="var(--inyc-primary-text-color-light)" />,
    id: "twitter",
    patterns: ["twitter.com", "x.com"],
    placeholder: "@handle",
    extract: (link) => {
      try {
        const formattedLink =
          link.startsWith("http://") || link.startsWith("https://")
            ? link
            : `https://${link}`;
        const url = new URL(formattedLink);
        if (url.hostname.includes("twitter.com") || url.hostname.includes("x.com")) {
          const parts = url.pathname.split("/").filter(Boolean);
          if (parts.length > 0) {
            return `@${parts[0]}`;
          }
        }
        return null;
      } catch {
        return null;
      }
    },
  },
  {
    rawIcon: FaApple,
    icon: <FaApple size={25} color="var(--inyc-primary-text-color-light)" />,
    id: "apple",
    patterns: ["music.apple.com", "itunes.apple.com"],
  },
  {
    rawIcon: FaSoundcloud,
    icon: (
      <FaSoundcloud size={25} color="var(--inyc-primary-text-color-light)" />
    ),
    id: "soundcloud",
    patterns: ["soundcloud.com"],
  },
  {
    rawIcon: FaYoutube,
    icon: <FaYoutube size={25} color="var(--inyc-primary-text-color-light)" />,
    id: "youtube",
    patterns: ["youtube.com", "youtu.be"],
  },
  {
    rawIcon: FaBandcamp,
    icon: <FaBandcamp size={25} color="var(--inyc-primary-text-color-light)" />,
    id: "bandcamp",
    patterns: ["bandcamp.com"],
  },
  {
    rawIcon: FaVimeo,
    icon: <FaVimeo size={25} color="var(--inyc-primary-text-color-light)" />,
    id: "vimeo",
    patterns: ["vimeo.com"],
  },
  {
    rawIcon: PiGlobeSimpleLight,
    icon: (
      <PiGlobeSimpleLight size={25} color="var(--inyc-primary-text-color-light)" />
    ),
    id: "website",
    patterns: [], // No specific patterns; fallback for unmatched links
  },
];


export const getSocialIcon = (social) => {
  return (
    socialOptions.find((opt) => opt.id === social)?.icon || (
      <Icon
        className="!w-[25px] invert-[var(--inyc-lighten-white)]"
        src={websiteIcon}
      />
    )
  );
};
