import React, { useEffect, useState } from "react";
import MyVenuesPanel from "./MyVenuesPanel";
import PendingPosts from "./PendingPosts";
import { backendUrl } from "../../api/constants";
import axiosInstance from "../../api/axiosInstance";
import { getVenues } from "../../api/events";
import Loader from "../icons/Loader";
import { useMediaQuery } from "../../util/useMediaQuery";
import { useUser } from "../../context/UserContext/UserProvider";
import { Link } from "react-router-dom";
import ClaimedVenues from "./claimedVenues";
import barMarkerSelected from "../../assets/map/bar-marker-selected.png";
import { PortalTitle } from "../../pages/Portal";
import Button from "../Button/Button";
import { modal } from "../../util/useModal";

const VenuesDashboard = ({ userLocation }) => {
  const {user} = useUser();
  const medium = useMediaQuery("md");
  const [userVenues, setUserVenues] = useState();
  const [venues, setVenues] = useState();
  const [tickets, setTickets] = useState();
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("new_venue");
  const [selectedVenue, setSelectedVenue] = useState({});

  const fetchData = async (marker) => {
    setLoading(true);
    try {
      let newData;
      const tickets = await axiosInstance.get(`${backendUrl}/business/tickets`);
      const userVenues = await axiosInstance.get(`${backendUrl}/business/venues`);
      newData = { tickets: tickets?.data?.data, userVenues: userVenues?.data?.data?.map((ven) => ({ ...ven, me: true })) };
      if (userLocation) {
        const veunesData = await getVenues(userLocation);
        newData = { ...newData, venues: veunesData?.data?.data };
      }

      setUserVenues(newData.userVenues);
      setVenues(newData.venues);
      setTickets(newData.tickets);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const refreshAll = (arg) => {
    fetchData(arg);
  };

  const refreshTickets = async () => {
    try {
      const { data } = await axiosInstance.get(`${backendUrl}/business/tickets`);

      setTickets(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshUserVenues = async () => {
    try {
      const { data } = await axiosInstance.get(`${backendUrl}/business/venues`);
      setUserVenues(data?.data.map((ven) => ({ ...ven, me: true })));
    } catch (error) {
      console.log(error);
    }
  };

  const refreshVenues = async (marker) => {
    try {
      const veunesData = await getVenues(marker);
      setVenues(veunesData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refreshVenues(userLocation);
  }, [userLocation]);
  useEffect(() => {
    fetchData(userLocation);
  }, [userLocation]);

  if (!user?.emailVerified) {
    return (
      <div className="p-[2rem]  justify-center px-[3rem] text-[1.5rem] text-center flex items-center pt-[4rem] bg-[var(--inyc-primary)] min-h-[calc(100vh-25rem)] font-bold h-[60px] w-full duration-300">
        <div>
          before you can post we need to verify your email.
          <br />
          <br />
          verify by clicking{" "}
          <Link className="inline-block font-['Primary-Medium'] ml-[5px]" to="/auth/verify-email">
            here
          </Link>
        </div>
      </div>
    );
  }

  const handleVenuPick = async (vn,showPrompt = false) => {
    //if venue is different from selected venue, then notify user that they're switching venues
    console.log('selectedVenue venuesDashboard',selectedVenue)
    console.log('vn',vn)
    if (selectedVenue.venue?._id !== vn._id && showPrompt) {
      const proceed = await modal(({ proceed }) => (
        <div className="text-center p-[2rem]">
          <div className="text-[1.3rem] mb-[2rem] text-[var(--inyc-primary-text-color)]">
            you're about to switch venues. do you want to select the {vn.name} venue, or would you like to just use it's address
          </div>
          <div className="flex gap-[1rem] justify-center">
            <Button onClick={() => proceed(false)}>only use address</Button>
            <Button onClick={() => proceed(true)}>switch venues</Button>
          </div>
        </div>
      ));

      if (!proceed) return;
    }

    setSelectedVenue({
      venue: { ...vn, me: userVenues.some((v) => v._id === vn._id) },
      marker: {
        icon: barMarkerSelected,
        lat: vn.location.coordinates[1],
        lng: vn.location.coordinates[0],
      },
    });
    setCurrentTab("new_venue");

    // add tags to input
    // setInputTags(vn.tags);

    // mapRef.current.flyTo([
    //   vn.location.coordinates[1],
    //   vn.location.coordinates[0],
    // ]);
  };

  return (
    <div style={{ ...styles.Container, ...(!medium ? styles.ContainerMobile : {}) }}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          <PortalTitle
            className='px-[1rem] py-[1rem]'
          >
            venues dashboard
          </PortalTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "2rem",
              overflowX: "auto",
              padding: "1rem",
              fontSize: "1.3rem",
              color: "rgba(0,0,0,0.7)",
            }}
          >
            <div
              onClick={() => {
                setCurrentTab("new_venue");
              }}
              className="whitespace-nowrap"
              style={
                currentTab === "new_venue"
                  ? {
                      background: "var(--inyc-secondary)",
                      padding: "0.5rem 1.5rem",
                      borderRadius: "2rem",
                      color: "var(--inyc-secondary-text-color)",
                      fontSize: "1rem",
                    }
                  : {
                      cursor: "pointer",
                      fontSize: "1rem",
                      border: '1px solid var(--inyc-input-border-color)',
                      padding: '0.5rem 1.5rem',
                      borderRadius: '2rem'
                    
                    }
              }
            >
              add new
            </div>
            <div
              onClick={() => {
                setCurrentTab("pending_venues");
              }}
              className="whitespace-nowrap"
              
              style={
                currentTab === "pending_venues"
                  ? {
                      background: "var(--inyc-secondary)",
                      padding: "0.5rem 1.5rem",
                      borderRadius: "2rem",
                      color: "var(--inyc-secondary-text-color)",
                      fontSize: "1rem",
                    }
                  : {
                      cursor: "pointer",
                      fontSize: "1rem",
                      border: '1px solid var(--inyc-input-border-color)',
                      padding: '0.5rem 1.5rem',
                      borderRadius: '2rem'
                    
                    }
              }
            >
              pending reviews
            </div>
            <div 
              onClick={() => {
                setCurrentTab("claimed_venues");
              }}
              className="whitespace-nowrap"
              
              style={
                currentTab === "claimed_venues"
                  ? {
                      background: "var(--inyc-secondary)",
                      padding: "0.5rem 1.5rem",
                      borderRadius: "2rem",
                      color: "var(--inyc-secondary-text-color)",
                      fontSize: "1rem",
                    }
                  : {
                      cursor: "pointer",
                      fontSize: "1rem",
                      border: '1px solid var(--inyc-input-border-color)',
                      padding: '0.5rem 1.5rem',
                      borderRadius: '2rem'
                    }
              }


            >
              claimed venues
              </div>
          </div>
          {currentTab === "new_venue" && (
            <MyVenuesPanel
              userLocation={userLocation}
              userVenues={userVenues}
              venues={venues}
              refreshAll={refreshAll}
              refreshUserVenues={refreshUserVenues}
              refreshVenues={refreshVenues}
              selectedVenue={selectedVenue}
              handleVenuPick={handleVenuPick}
              setSelectedVenue={setSelectedVenue}
            />
          )}
          {currentTab === "pending_venues" && <PendingPosts tickets={tickets} refreshTickets={refreshTickets} />}
          {currentTab === "claimed_venues" &&  <ClaimedVenues 
            userVenues={userVenues}
            selectedVenue={selectedVenue}
            handleVenuPick={(v) => {
              // console.log('handleVenu pick ',v)
              handleVenuPick(v)
            }}
          />}
          </div>
      )}
    </div>
  );
};

const styles = {
  Container: {
    padding: 20,
    width: "100%",
    height: "100%",
    backgroundColor: 'var(--inyc-primary)',
  },
  ContainerMobile: {
    padding: 0,
  },
};

export default VenuesDashboard;
