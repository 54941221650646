import React from "react";
import "./logo.css";
import logoLight from "../../assets/imgs/logo-transparent-light.gif";

const Logo = ({ location = "yc" }) => {
  return (
    <div className="logo">
      <img src={logoLight} 
      className="invert-[--inyc-invert-black]"
      alt="logo" />
    </div>
  );
};

export default Logo;
