import axios from "axios";
import { backendUrl } from "../../api/constants";
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';

export const localStorageSessionKey = "analyticsSession";
export const analyticsSendInterval = 30000 // 30 seconds 
export const sessionTimeoutThreshold = 1800000 // 30 minutes 
export const maxSessionLength = 86400000; // 24 hours

let previousSession = null;

const getLocation = () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          resolve(null); // resolve with null in case of error or denial of permission
        }
      );
    } else {
      resolve(null); // resolve with null if geolocation is not supported
    }
  });

export const startNewSession = async (user) => {
  const location = await getLocation();
  const session = {
    id: uuidv4(),
    sessionStart: Date.now(),
    lastActivity: Date.now(),
    userAgent: navigator.userAgent,
    user: user?._id,
    referrer: document.referrer,
    events: [],
    location,
  };

  localStorage.setItem(localStorageSessionKey, JSON.stringify(session));

  return session;
};

export const sendSessionData = async () => {
  const session = JSON.parse(localStorage.getItem(localStorageSessionKey));

  if (!session) return;

  if(import.meta.env.VITE_DISABLE_ANALYTICS === 'true') return;

  // if session hasn't changed, don't send it
  const areSessionsEqual = _.isEqualWith(previousSession, session, (value, other, key) => {
    if (key === 'lastActivity') return true;
  });

  if (areSessionsEqual) return;

  await axios.post(`${backendUrl}/analytics/session`, session);

  //empty events array
  session.events = [];
  previousSession = session;
  localStorage.setItem(localStorageSessionKey, JSON.stringify(session));
};

export const updateSession = async (newSession) => {
  const session = JSON.parse(localStorage.getItem(localStorageSessionKey));

  if (!session) return;

  const updatedSession = {
    ...session,
    ...newSession,
    lastActivity: Date.now(),
  };

  localStorage.setItem(localStorageSessionKey, JSON.stringify(updatedSession));

  return updatedSession;
};

export const sendHeartbeat = (currentUser) => {
  // if lastActivity is more than 5 seconds ago, send a heartbeat
  const session = JSON.parse(localStorage.getItem(localStorageSessionKey));

  if (!session) return;

  const sessionAge = Date.now() - session.lastActivity;

  if (sessionAge >= analyticsSendInterval) {
    // console log sessionAge converted to seconds
    heartBeat(currentUser);
  }
};

export const heartBeat = async (currentUser) => {
  const session = await updateSession({
    user: currentUser?._id,
  });

  if (!session) return;

  const sessionAge = Date.now() - session.lastActivity;

  if (sessionAge > sessionTimeoutThreshold || Date.now() - session.sessionStart > maxSessionLength) {
    // session is too old, start a new one
    await sendSessionData();
    await startNewSession();
  } else {
    await sendSessionData();
  }
};


// ************************************************************************************************
// * EVENT TRACKING
// ************************************************************************************************

export const trackSessionEvent = async (event) => {
    const session = JSON.parse(localStorage.getItem(localStorageSessionKey));
    
    if (!session) return;
    
    const newSession = {
        ...session,
        events: [...session.events, event]
    }


    localStorage.setItem(localStorageSessionKey, JSON.stringify(newSession));
}

// ************************************************************************************************
