export const styles = {
  container: {
    width: "100%",
  },
  Section: {
    transition: "0.3s",
    overflow: "hidden",
    height: "100%",
    borderRadius: 10,
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,0.05)",
    boxShadow: "0 0 3px rgba(0,0,0,0.05)",
  },

  expandIcon: {
    height: "40px",
    width: "40px",
  },
  SplitContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: "wrap",
  },
  SplitSection: {
    width: "50%",
    minWidth: 600,
    height: "100%",
    padding: 20,
  },
  SplitSectionMobile: {
    minWidth: "100%",
  },
  SectionTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 40,
    gap: "20px",
    cursor: "pointer",
    fontSize: "3rem",
    color: "rgba(0,0,0,0.7)",
    margin: "40px 30px",
  },
  EventAddOptions: {
    padding: "0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  mediumTitle: {
    margin: "-40px 20px 50px 50px",
    fontSize: 35,
    backgroundColor: "white",
    padding: "0 20px",
    width: "fit-content",
  },
  EventLocationOptions: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "20px",
  },
  inputLabel: {
    fontSize: 20,
  },
  FlexInputContainer: {
    width: "fit-content",
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "20px",
  },
  flexInput: {
    maxWidth: "500px",
    padding: 0,
  },
  mapContainer: {
    padding: 0,
    minHeight: "500px",
    marginTop: "0px",
    height: "100%",
    display: "flex",
    position: "relative",
    justifyContent: "center",
  },
  mapStyle: {
    maxWidth: "500px",
    // boxShadow: "0 10px 15px rgba(0,0,0,0.1)",
    border: "1px solid rgba(0,0,0,0.05)",
  },
  PostButton: {
    margin: "20px auto 20px auto",
    width: "100%",
  },
  borderSecion: {
    padding: 20,
    margin: "50px 0",
    border: "1px solid rgba(0,0,0,0.1)",
  },
  datePickerContainer: {
    width: "100%",
    // backgroundColor: "black",
    border: "1px solid rgba(0,0,0,0.05)",
    // color: "white",
    color: "rgba(0,0,0,0.7)",
    fontFamily: "Primary",
    minHeight: "50px",
    backgroundColor: "white",
    fontSize: 17,
    padding: "20px 40px",
    display: "flex",
    justifyContent: "flex-start",
  },
  LatLngInput: {
    border: "none",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  CheckBoxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  CheckBoxInputLabel: {
    fontSize: "1.5rem",
  },
};
