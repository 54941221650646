import React from "react";
import "./profilePicture.css";

const ProfilePicture = ({ username, profilePicture, onClick, style,className }) => {
  return (
    <div className={`profile-picture shadow-sm ${profilePicture ? "" : "no-image"} ${className}`} onClick={onClick} style={style}>
      {profilePicture ? <img className="profile-picture-image" referrerPolicy="no-referrer" src={profilePicture} /> : <div>{(username || "")?.toLowerCase()}</div>}
    </div>
  );
};

export default ProfilePicture;
