import React from "react";
import Loader from "../icons/Loader";

const SuspenseLoader = ({black = false}) => {

  return (
    <div
      style={{
        backgroundColor: "--inyc-primary",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader white={black}/>
    </div>
  );
};

export default SuspenseLoader;
