import React, { useRef, useState } from "react";
import selectedIcon from "../../assets/icons/selected-arrow.png";



const HoverText = ({ text, children, onMouseEnter, onMouseLeave, style, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const elRef = useRef();

  const handleMouseEnter = (e) => {
    const { top, left, width, height } = elRef.current.getBoundingClientRect();
    const { scrollY, scrollX } = window;
    const positionFromTop = top + height + 5 + scrollY;
    const positionFromLeft = left + width / 2 + scrollX;
    setIsHovered({ x: positionFromLeft, y: positionFromTop });
    onMouseEnter && onMouseEnter(e);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
    onMouseLeave && onMouseLeave(e);
  };

  return (
    <div
      className="cursor-pointer"
      ref={elRef}
      style={{ ...styles.hoverContainer, ...(style || {}) }}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text && isHovered && <div style={{ ...styles.PopupContainer, ...{ top: isHovered.y, left: isHovered.x } }}>{text}</div>}
      {children}
    </div>
  );
};

export const HoverItem = ({children,text})=>{
  const [hovered,setHovered] = useState(false)
  return <HoverText
  text={text}
  onMouseEnter={() => {
    setHovered(true);
  }}
  onMouseLeave={() => {
    setHovered(false);
  }}
>
  {children}
</HoverText>
}

export const Option = ({ opt, index, item }) => {
  const [hovered, setHovered] = useState(false);
  const defaultStyle = {
    hoverStyle: {
      backgroundColor: "black",
      color: "white",
    },
    style: {
      border: "1px solid black",
      borderRadius: "20px",
      transition: "0.3s",
      whiteSpace: "nowrap",
      padding: "4px 15px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  if ((opt instanceof Function && !opt(item)) || !opt) {
    return null;
  }
  opt = opt instanceof Function ? opt(item) : opt;
  return (
    <HoverText
      text={opt.hoverText}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      style={{ ...defaultStyle.style, ...(opt.style || {}), ...(hovered && (opt.hoverStyle ? opt.hoverStyle : defaultStyle.hoverStyle)) }}
      key={index}
      onClick={() => {
        opt.onClick(item);
      }}
    >
      {opt.icon ? <img src={opt.icon} style={{ height: 20, width: 20 }} /> : opt.label}
    </HoverText>
  );
};

const ContentList = ({ emptyJSX,items, options, useColumns,formatCell = (item,key)=> item?.[key], optionsPosition, onClick, selected,
emptyText = "no items"

 }) => {
  if (!items || items.length === 0) {
    return (
      <div
        style={{
          textAlign: "center",
          color: "var(--inyc-primary-text-color)",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        {emptyJSX || emptyText}
      </div>
    );
  }

  const handleCellClick = (item, ri, ci) => {
    onClick && onClick(item, ri, ci);
  };

  const itemFields = useColumns ? Object.keys(useColumns) : Object.keys(items[0]);
  const optionsPositionIndex = optionsPosition || 1;
  return (
    <>
      <div style={styles.container}>
        <div style={styles.row}>
          {itemFields.map((itemField, i) => (
            <>
              {options && options.length > 0 && i === optionsPositionIndex && (
                <div style={styles.column}>
                  <div style={{ ...styles.cell, ...styles.columnTitle }}>options</div>
                </div>
              )}
              <div style={{ ...styles.cell, ...styles.columnTitle }}>{useColumns ? useColumns[itemField] : itemField}</div>
            </>
          ))}
        </div>
        {items.map((item, ri) => {
          item = item instanceof Function ? item(item) : item;
          return (
            <div key={ri} style={styles.row}>
              {itemFields.map((itemField, ci) => (
                <>
                  {/* {options && options.length > 0 && ci === optionsPositionIndex && (
                    <div
                      style={{
                        ...styles.cell,
                        ...styles.columnCell,
                        ...(ri % 2 ? styles.oddCell : {}),
                        ...(ci === 0 && onClick ? { cursor: "pointer" } : {}),
                        ...{ display: "flex", flexWrap: "wrap", gap: "0.5rem", justifyContent: "center", alignItems: "center" },
                      }}
                    >
                      {options.map((opt, i) => (
                        
                      ))}
                    </div>
                  )} */}
                  <div
                    key={ci}
                    onClick={() => {
                      handleCellClick(item, ri, ci);
                    }}
                    style={{
                      ...styles.cell,
                      ...styles.columnCell,
                      ...(ri % 2 ? styles.oddCell : {}),
                      ...(ci === 0 && onClick ? { cursor: "pointer" } : {}),
                    }}
                  >
                    {selected && selected(item) && ci === 0 ? <img src={selectedIcon} style={styles.selectedIcon} /> : null}
                    {formatCell(item,itemField) || <div style={styles.nullLine}></div>}
                  </div>
                </>
              ))}
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  container: {
    borderRadius: 10,
    border: "1px solid rgba(0,0,0,0.05)",
    boxShadow: "0 5px 10px rgba(0,0,0,0.1)",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  row: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    minWidth: "100%",
    // height: "auto",
    // maxHeight: "100px",
  },
  column: {
    flex: 1,
  },
  columnTitle: {
    fontFamily: "Primary-Bold",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  columnCell: {
    borderBottom: "1px solid rgba(0,0,0,0.05)",
  },
  cell: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    textAlign: "center",
    minHeight: "70px",
    minWidth: "120px",
    maxWidth: "100%",
    overflowWrap: "word-break",
    // maxWidth: "100px",
    // height: "100%",
    // maxHeight: "100px",
    // overflow: "auto",
  },
  oddCell: {
    backgroundColor: "rgba(0,0,0,0.01)",
  },
  PopupContainer: {
    position: "absolute",
    top: "50%",
    left: "100%",
    borderRadius: 10,
    transform: "translateX(-50%)",
    minWidth: 150,
    maxWidth: 400,
    zIndex: 100,
    whiteSpace: "normal",
    padding: 10,
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
  },
  nullLine: {
    height: 3,
    maxWidth: "90%",
    width: 60,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  selectedIcon: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
};

export default ContentList;
