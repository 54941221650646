import React, { useEffect, useRef, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import axiosInstance from "../../../api/axiosInstance";
import { enqueueSnackbar } from "notistack";
import Loader from "../../../components/icons/Loader";
import { MdDeleteOutline } from "react-icons/md";
import Tabs, { TabContent } from "../../../components/ui/tabs";
import { IoIosArrowBack, IoMdSettings } from "react-icons/io";
import ProfilePicture from "../../../components/icons/ProfilePicture";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import { useUser } from "../../../context/UserContext/UserProvider";
import { Browser } from "@capacitor/browser";
import { PortalTitle } from "../../Portal";

const BusinessTools = () => {
  const [loading, setLoading] = useState(true);
  // const [accounts, setAccounts] = useState([]);
  const {
    calendarAccounts: accounts,
    setCalendarAccounts: setAccounts,
    fetchCalendarAccounts,
  } = useUser();
  const [currentTab, setCurrentTab] = useState("main");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const settingRef = useRef(null);

  useEffect(() => {
    if (selectedAccount) {
      setCurrentTab("settings");
    } else {
      setCurrentTab("main");
    }
  }, [selectedAccount]);

  const fetchAccounts = async () => {
    setLoading(true);
    await fetchCalendarAccounts();
    setLoading(false);
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const authGoogle = async () => {
    try {
      const { data } = await axiosInstance.get(`/user/calendar/google/auth`);
      if (data.error) {
        return enqueueSnackbar(data.error, { variant: "error" });
      }
      const url = data.url;
      // show popup to url
      await Browser.open({ url });
    } catch (error) {
      console.log(error.response.data);
      enqueueSnackbar("something went wrong, please try again", {
        variant: "error",
      });
    }
  };

  const unsyncGoogle = (id) => async () => {
    try {
      const { data } = await axiosInstance.get(
        `/user/calendar/google/unsync/${id}`
      );
      if (data.error) {
        return enqueueSnackbar(data.message, { variant: "error" });
      }
      fetchAccounts();
      enqueueSnackbar("account unsynced successfully", { variant: "success" });
    } catch (error) {
      console.log(error.response.data);
      enqueueSnackbar("something went wrong, please try again", {
        variant: "error",
      });
    }
  };

  const selectAccount = (account) => () => {
    setSelectedAccount(account);
  };

  const saveSettings = (id) => async () => {
    try {
      const name = settingRef.current.value;
      if (!name) {
        return enqueueSnackbar("Please enter a name", { variant: "error" });
      }
      const { data } = await axiosInstance.post(
        `/user/calendar/google/settings/${id}`,
        {
          name,
        }
      );
      if (data.error) {
        return enqueueSnackbar(data.message, { variant: "error" });
      }
      fetchAccounts();
      enqueueSnackbar("Settings saved successfully", { variant: "success" });
    } catch (error) {
      console.log(error.response.data);
      enqueueSnackbar("something went wrong, please try again", {
        variant: "error",
      });
    }
  };

  return (
    <div className="bg-[var(--inyc-primary)] p-[1rem] min-h-[100vh]">
      <PortalTitle className="px-[1rem] py-[1rem]">calendar</PortalTitle>
      <Tabs
        hideTabs
        tabs={["main", "settings"]}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      >
        <TabContent name="main">
          <div
            onClick={authGoogle}
            className="cursor-pointer duration-300 hover:bg-black/5 p-[0.8rem] w-fit border-black/5 border-[1px] shadow-md rounded-[5px] flex items-center mx-auto mb-[1rem]"
          >
            <FcGoogle size={30} />
            <h1 className="ml-[1rem] font-['Primary-Regular']">
              sync my google calendar
            </h1>
          </div>
          <div className="pt-[1rem] text-[--inyc-primary-text-color-light] text-[0.9rem] text-center mb-[1rem]">
            connect your google calendar to easily save in—yc events to it
            <br />
            sync up to 3 calendars
            <br />
            (outlook and iCal coming soon)
          </div>

          <div className="p-[1rem]">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : accounts?.length > 0 ? (
              <>
                <h1 className="font-['Primary-Bold'] text-lg">
                  connected accounts
                </h1>
                <div className="mt-[1rem]">
                  {accounts?.map((account) => (
                    <div
                      key={account._id}
                      className="p-[0.5rem] gap-[1rem] border-black/5 border-[1px] shadow-md rounded-[5px] flex items-center justify-between"
                    >
                      <FcGoogle size={30} />
                      <div className="flex-1">
                        <h1 className=" font-['Primary-Medium']">
                          {account.meta.name}
                        </h1>
                        <h1 className=" font-['Primary-Regular'] text-[0.9rem] text-black/60">
                          {account.meta.email}
                        </h1>
                      </div>
                      <div className="flex items-center">
                        <div
                          onClick={selectAccount(account)}
                          className="rounded-[50%] cursor-pointer duration-300 hover:bg-black/5 p-[0.5rem]"
                        >
                          <IoMdSettings size={20} />
                        </div>
                        <div
                          onClick={unsyncGoogle(account._id)}
                          className="rounded-[50%] cursor-pointer duration-300 hover:bg-black/5 p-[0.5rem]"
                        >
                          <MdDeleteOutline className="text-red-500" size={20} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : <div>
                <h1 className="text-center pt-[2rem] font-['Primary-Regular'] text-[--inyc-primary-text-color-light] text-lg">
                  no connected accounts
                </h1>
              </div>
              }
          </div>
        </TabContent>
        <TabContent className="p-[1rem]" name="settings">
          <div>
            <h1
              onClick={() => setSelectedAccount(null)}
              className="flex items-center cursor-pointer font-['Primary-Bold'] text-[2rem]"
            >
              <IoIosArrowBack size={38} className="mr-[5px]" />
              settings
            </h1>
          </div>
          <div className="p-[1rem]">
            {selectedAccount && (
              <div>
                <div className="mb-[4rem] flex items-center justify-start gap-[1rem]">
                  <ProfilePicture
                    style={{ width: "100px", height: "100px" }}
                    profilePicture={selectedAccount.meta.picture}
                  />
                  <div>
                    <h1 className="font-['Primary-Medium']">
                      {selectedAccount.meta.name}
                    </h1>
                    <h1 className="font-['Primary-Regular'] text-black/60">
                      {selectedAccount.meta.email}
                    </h1>
                  </div>
                </div>
                <div className="!text-[0.9rem] mb-[1rem]">
                  <Input
                    defaultValue={selectedAccount.settings?.name}
                    Ref={settingRef}
                    label="setting"
                    placeholder="setting"
                  />
                </div>
                <div>
                  <Button onClick={saveSettings(selectedAccount._id)}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </TabContent>
      </Tabs>
    </div>
  );
};

export default BusinessTools;
