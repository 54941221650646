import React, { useState } from "react";
import ShowPasswordSVG from "../icons/ShowPasswordSVG";
import HidePasswordSVG from "../icons/HidePasswordSVG";
import "./Input.css";

const Input = ({
  placeHolder,
  color,
  Ref,
  onChange,
  onClick,
  readOnly,
  value,
  defaultValue,
  formatValue,
  icon,
  name,
  label,
  style,
  inputStyle,
  hint,
  InlineComp = () => null,
  className,
  password = false,
  multiLine = false,
  underline = false,
  disabled = false,
  onKeyDown,
  autoComplete,
  copy,
  onHintClick,
}) => {
  const [hidePassword, setHidePassword] = useState(password);
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(
    value || defaultValue ? true : false
  );
  const previousValue = React.useRef(value || defaultValue || "");
  const [hintOffset, setHintOffset] = useState(0);
  const inputRef = React.useRef(null);

  // Style constants - adjust these based on your actual font metrics
  const FONT_SIZE = 1.2; // in rem (from your text-[1.4rem] class)
  const INPUT_PADDING = 0; // in rem (from px-[1.5rem] class)

  const togglePasswordShow = () => {
    setHidePassword((prev) => !prev);
  };

  const handleChange = (event) => {
    if (formatValue) {
      event.target.value = formatValue(
        event.target.value,
        previousValue.current
      );
    }

    setHasValue(event.target.value ? true : false);
    onChange && onChange(event);

    previousValue.current = event.target.value;
  };

  React.useEffect(() => {
    if (!hint || !value) return;

    const calculateHintOffset = () => {
      const hintSpan = document.createElement('span');
      hintSpan.style.position = 'absolute';
      hintSpan.style.visibility = 'hidden';
      hintSpan.style.whiteSpace = 'pre';
      hintSpan.style.fontSize = `${FONT_SIZE}rem`;
      hintSpan.textContent = value;
      document.body.appendChild(hintSpan);
      const hintWidth = hintSpan.offsetWidth;
      document.body.removeChild(hintSpan);
      setHintOffset(hintWidth);
    };

    // Calculate initial offset
    calculateHintOffset();

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(calculateHintOffset);
    if (inputRef.current) {
      resizeObserver.observe(inputRef.current);
    }

    // Clean up
    return () => {
      resizeObserver.disconnect();
    };
  }, [value, hint, FONT_SIZE]);

  return (
    <div
      className={
        `mt-[1.5rem] flex gap-[1rem] relative justify-center ${multiLine ? "items-start" : "items-center"} px-[1.5rem] py-[0.5rem] border-[1px] rounded-[var(--inyc-input-border-radius)] border-[var(--inyc-input-border-color)]${
          underline
            ? `border-b-[2px] duration-300 ${
                hasValue || isFocused
                  ? "border-b-black"
                  : "border-b-[rgba(0,0,0,0.1)]"
              }`
            : ""
        } ` + className
      }
      style={style}
      onClick={onClick}
    >
      {icon && <div className="input-icon">{icon}</div>}
      {label && 
      <>
        <label
          className={`absolute whitespace-nowrap font-['Primary-Medium'] left-[1.5rem] top-[2rem] translate-y-[-50%] text-[#adacac] bg-[var(--inyc-primary)] text-[1.4rem] duration-200 pointer-events-none  
      ${
        isFocused || hasValue || placeHolder
          ? "py-0 !left-0 text-[var(--inyc-primary-text-color)] "
          : ""
      }`}
        style={{
          top: copy ? '-1px' : "-20px",
          transform : copy ? 'translateY(-100%)' : '',
        }}
        >
          {label}
          {copy && <div
            className="mt-[1.5rem]"
          >
          {copy}
          </div>}
        </label>
      </>
      }
      {disabled && (
        <div className="absolute w-full h-full bg-black opacity-[0.01] z-10 rounded-[1rem] pointer-events-all"></div>
      )}
      <div className="relative flex-1 overflow-hidden" ref={inputRef}>
        {hint && value && (
          <span
            className={`absolute text-[#adacac] whitespace-pre overflow-hidden text-ellipsis ${
              onHintClick ? "cursor-pointer" : "pointer-events-none"
            }`}
            style={{
              left: `calc(${hintOffset}px + 0.75rem)`,
              top: "0.5rem",
              fontSize: `${FONT_SIZE}rem`,
              maxWidth: `calc(100% - ${hintOffset}px - 1.5rem)`,
            }}
            onClick={onHintClick}
          >
            {value.endsWith(" ") ? hint?.trimStart() : hint}
          </span>
        )}

        {multiLine ? (
          <textarea
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            style={{
              ...(color ? { color: color } : {}),
              ...(inputStyle || {}),
              minHeight: inputStyle?.minHeight || "160px",
              resize: inputStyle?.resize || "vertical",
            }}
            className="input"
            type={hidePassword ? "password" : "text"}
            placeholder={placeHolder}
            ref={Ref}
            defaultValue={defaultValue}
            onChange={handleChange}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            value={value}
            name={name}
          />
        ) : (
          <input
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            style={{ ...(color ? { color: color } : {}), ...(inputStyle || {}) }}
            className="input"
            type={hidePassword ? "password" : "text"}
            placeholder={placeHolder}
            ref={Ref}
            defaultValue={defaultValue}
            onChange={handleChange}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            value={value}
            name={name}
            autoComplete={autoComplete}
          />
        )}
      </div>
      {/* {hint && <div className="text-[1.2rem] rounded-[3px] px-[0.5rem] text-[--inyc-primary-text-color-light] whitespace-nowrap py-[0.2rem]">{hint}</div>} */}
      {<InlineComp />}
      {password && (
        <div
          className="input-icon"
          onClick={togglePasswordShow}
          style={{ cursor: "pointer" }}
        >
          {hidePassword ? <ShowPasswordSVG /> : <HidePasswordSVG />}
        </div>
      )}
    </div>
  );
};

export default Input;
