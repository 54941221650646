import React from "react";
import AppUrlListener from "./components/AppUrlListener/AppUrlListener";
import { AnalyticsProvider } from "./context/AnalyticsContext/AnalyticsProvider";
import { DataProvider } from "./context/DataContext/DataProvider";
import { UIProvider } from "./context/UIContext/UIContext";
import { SearchProvider } from "./context/SearchContext/SearchProvider";
import { EventDetailsProvider } from "./context/EventDetailsProvider/EventDetailsProvider";
import { MapProvider } from "./context/MapContext/MapContext";
import NetworkStatusListener from "./components/NetworkStatusListener/NetworkStatusListener";
import { AccountDetailsProvider } from "./context/AccountDetailsContext/AccountDetailsProvider";

const Providers = ({ children }) => {
  return (
    <>
      <NetworkStatusListener />
      <AppUrlListener />
      <AnalyticsProvider>
        <DataProvider>
          <UIProvider>
            <SearchProvider>
              <MapProvider>
                <EventDetailsProvider>
                  <AccountDetailsProvider>{children}</AccountDetailsProvider>
                </EventDetailsProvider>
              </MapProvider>
            </SearchProvider>
          </UIProvider>
        </DataProvider>
      </AnalyticsProvider>
    </>
  );
};

export default Providers;
