import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { useUser } from "../UserContext/UserProvider";
import {
  analyticsSendInterval,
  localStorageSessionKey,
  maxSessionLength,
  sendHeartbeat,
  sendSessionData,
  sessionTimeoutThreshold,
  startNewSession,
  updateSession,
  trackSessionEvent,
} from "./AnalyticsUtils";

const AnalyticsContext = createContext(null);

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children }) => {
  const { user } = useUser();

  //& on component mount
  useEffect(() => {
    const existingSession = JSON.parse(localStorage.getItem(localStorageSessionKey));

    if (existingSession) {
      const sessionAge = Date.now() - existingSession.sessionStart;

      if (
        sessionAge > maxSessionLength ||
        Date.now() - existingSession.lastActivity > sessionTimeoutThreshold ||
        (existingSession.user && user?._id && existingSession.user !== user?._id)
      ) {
        // session is too old, start a new one
        sendSessionData().then(() => startNewSession(user));
      } else {
        updateSession();
      }
    } else {
      startNewSession(user);
    }

    const interval = setInterval(() => {
      sendHeartbeat(user);
    }, analyticsSendInterval);

    return () => {
      clearInterval(interval);
    };
  }, [user]);

  const analytics = {
    trackSessionEvent,
  };

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};
