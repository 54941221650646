import React, { createContext, useContext, useEffect, useState } from "react";
import axiosInstance from "../../api/axiosInstance";
import { backendUrl } from "../../api/constants";
import { convertEventDate } from "../../util/eventUtils";
import { useUser } from "../UserContext/UserProvider";
import { useEventDetails } from "../EventDetailsProvider/EventDetailsProvider";
import { useData } from "../DataContext/DataProvider";
import { DateTime } from "luxon";
import { defaultDateFilter } from "../../util/dataUtils";
import { handleSpecialFilter } from "../../util/eventUtils";

const WatchListContext = createContext();

export const useWatchList = () => {
  const context = useContext(WatchListContext);
  if (!context) {
    throw new Error("useWatchList must be used within a WatchListProvider");
  }
  return context;
};

export const WatchListProvider = ({ children }) => {
  const [watchListEvents, setWatchListEvents] = useState([]);
  const [watchListLoading, setWatchListLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [watchListPage, setWatchListPage] = useState({ page: 1, limit: 10 });
  const { user } = useUser();
  const {userLocation, setUserLocation} = useData();
  const [seenArtists, setSeenArtists] = useState([]);
  const {selectedEvent, selectedSubEvent} = useEventDetails();
  const [queryFilter, setQueryFilter] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [tagFilter, setTagFilter] = useState([]);
  const [accountFilter, setAccountFilter] = useState([]);

  //if no userLocation, set it to default new york
  if(!userLocation){
    setUserLocation({lat: 40.7128, lng: -74.006});
  }

  useEffect(() => {
    const storedArtists = JSON.parse(localStorage.getItem("seenArtists") || "[]");
    setSeenArtists(storedArtists);
  }, [selectedEvent, selectedSubEvent]);
  
  useEffect(() => {
    console.log("seenArtists: ", seenArtists);
  }, [seenArtists]);

  const fetchWatchList = async (page = watchListPage) => {
    try {
      const url = `${backendUrl}/events/search?skipVenueGrouping=true&type=event&excludePastEvents=true`;
      
      // Handle special date filters and default dates
      const specialDateFilter = ["now", "soon", "later", "late night"].includes(dateFilter);
      let fromDate, toDate;
      
      if (specialDateFilter) {
        const ddate = defaultDateFilter(userLocation?.timezone);
        fromDate = ddate.start;
        toDate = ddate.end;
      } else if (dateFilter?.start && dateFilter?.end) {
        fromDate = dateFilter.start;
        toDate = dateFilter.end;
      } else {
        // Default date range if none specified
        const ddate = defaultDateFilter(userLocation?.timezone, {
          endPlus: { months: 6 },
        });
        fromDate = ddate.start;
        toDate = ddate.end;
      }

      // Convert dates to UTC ISO strings
      if (fromDate) {
        fromDate = DateTime.fromJSDate(fromDate)
          .setZone(userLocation?.timezone || 'UTC', { keepLocalTime: true })
          .toUTC()
          .toISO();
      }
      if (toDate) {
        toDate = DateTime.fromJSDate(toDate)
          .setZone(userLocation?.timezone || 'UTC', { keepLocalTime: true })
          .toUTC()
          .toISO();
      }
      
      // Build query params
      const params = {
        artists: accountFilter.length > 0 
          ? accountFilter.join(',') 
          : user?.following?.join(','),
        page: page.page,
        limit: page.limit,
        from: fromDate,
        to: toDate
      };

      // Add other filters if present
      if (queryFilter) params.search = queryFilter;
      if (tagFilter?.length) params.tags = tagFilter.join(',');

      const response = await axiosInstance.get(url, { params });

      // Process response
      let processedData = response.data.data.map(item => {
        convertEventDate(item);
        return item;
      });

      // Replace direct filter with imported utility
      if (specialDateFilter) {
        processedData = handleSpecialFilter(processedData, dateFilter);
      }

      if (page.page === 1) {
        setWatchListEvents(processedData);
      } else {
        setWatchListEvents(prev => [...prev, ...processedData]);
      }
      
      setTotalResults(response.data.totalResults);
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    }
  };

  const refreshWatchList = async () => {
    setWatchListLoading(true);
    await fetchWatchList({ page: 1, limit: watchListPage.limit });
    setWatchListLoading(false);
  };

  // Add effect to refetch when filters change
  useEffect(() => {
    if (user?.following?.length) {
      setWatchListPage({ page: 1, limit: 10 }); // Reset pagination
      fetchWatchList({ page: 1, limit: 10 });
    }
  }, [queryFilter, dateFilter, tagFilter, accountFilter, user?.following]);

  useEffect(() => {
    console.log("accountFilter: ", accountFilter);
  }, [accountFilter]);

  const toggleAccountFilter = (accountId) => {
    setAccountFilter(prev => {
      if (prev.includes(accountId)) {
        return prev.filter(id => id !== accountId);
      }
      return [...prev, accountId];
    });
  };

  useEffect(() => {
    // When the user's following changes, filter out any accountFilter values
    // that are no longer part of the user's following list.
    if (user?.following) {
      setAccountFilter(prev =>
        prev.filter(accountId => user.following.includes(accountId))
      );
    }
  }, [user?.following]);

  const value = {
    watchListEvents,
    watchListLoading,
    pageLoading,
    totalResults,
    setWatchListPage,
    refreshWatchList,
    seenArtists,
    queryFilter,
    setQueryFilter,
    dateFilter,
    setDateFilter,
    tagFilter,
    setTagFilter,
    accountFilter,
    toggleAccountFilter,
    setAccountFilter,
    accountFilter,
  };

  return (
    <WatchListContext.Provider value={value}>
      {children}
    </WatchListContext.Provider>
  );
}; 