import React from "react";
import { socialOptions } from "../../util/socials";
import { FaGlobe } from "react-icons/fa"; // Generic website icon fallback
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import websiteIcon from "../../assets/icons/website.svg";
import { Link } from "react-router-dom";
import { getSocialOption } from "../../util/styleUtils";

const SocialButton = ({ social, btnClassName,className, ...rest }) => {
  const { platform, link } = social;

  if(!link) return null;

  // Get the icon and label based on the rules
  const { icon,rawIcon, id :label } = getSocialOption(link);
  const normalizeUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  // console.log("icon", icon);
  // console.log("label", label);

  return (
    <a href={normalizeUrl(link)}
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <Button
        className={`justify-center flex items-center gap-[0.5rem] cursor-pointer ${className}`}
        {...rest}
        btnClassName={`!py-[0.7rem] flex items-center justify-center [&:hover>.icon]:!invert-[var(--inyc-lighten-white)] [&:hover>.label]:!text-[--inyc-secondary-text-color] justify-center
          ${btnClassName}
          `}
      >
        <div className="icon translate-y-[-10%] ">{rawIcon({
          size: 20,
          color: "var(--inyc-details-light-color)",
        })}</div>
        {/* Only show label if it's not a perfect match */}
        {((label !== platform) && platform) && (
          <div className="label ml-[0.7rem] duration-300 text-[1.3rem] text-[var(--inyc-details-light-color)] font-['Primary-Medium']">
            {platform}
          </div>
        )}
      </Button>
    </a>
  );
};

export default SocialButton;
