import {  useEffect, useRef, useState } from "react";
import { createConfirmation, confirmable } from "react-confirm";
import { IoMdClose } from "react-icons/io";

const animationDuration = 300;

const ModalWrapper = (props) => {
  const { children, show, Component, opts, ...rest } = props;
  const [visible, setVisible] = useState(false);

  const modalRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setVisible(show);
    }, 10);
  }, [show]);

  const handleProceed = (val) => {
    setVisible(false);
    setTimeout(() => {
      props.proceed(val);
    }, animationDuration);
  };

  //! this is the child component
  const Child = (p) => {
    if (opts.raw) {
      return <Component {...props} proceed={handleProceed} />;
    }

    return (
      <div
        style={{
          transition: `${animationDuration / 1000}s`,
        }}
        className={`bg-[var(--inyc-primary-light)] border-[1px] border-[var(--inyc-modal-border-color)]  rounded-[var(--inyc-input-border-radius)] text-[var(--inyc-primary-color)] text-[1.5rem] font-bold text-center p-[2rem]`}
      >
        <Component {...props} proceed={handleProceed} />
        {!opts.hideCloseButton && (
          <div className="flex mt-[2rem] justify-center">
            <IoMdClose
              color="var(--inyc-primary-text-color)"
              style={{
                cursor: "pointer",
              }}
              size={30}
              onClick={() => {
                handleProceed(false);
                opts?.onClose && opts.onClose();
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        transition: `${animationDuration / 1000}s`,
        zIndex: 100000,
      }}
      onClick={(e) => {
        // console.log(e.target);
        // console.log(modalRef.current);
        if (opts.enableCloseOnBackdropClick) {
          // if the click isnt within modalRef, close the modal
          if (modalRef.current && !modalRef.current.contains(e.target)) {
            handleProceed(false);
            opts?.onClose && opts.onClose();
          }
        }
      }}
      className={`${show ? "pointer-events-auto" : "pointer-events-none"} ${
        opts.fullScreen ? "" : "p-[2rem]"
      }  fixed top-0 left-0 w-full h-full flex justify-center items-center ${
        visible
          ? "bg-[var(--inyc-blur-bg-color)] backdrop-blur-[var(--inyc-blur-bg-blur)]"
          : "bg-transparent"
      }  ${opts.containerClassName}`}
    >
      <div
        ref={modalRef}
        style={{
          transition: `${animationDuration / 1000}s`,
        }}
        className={`${visible ? "" : "opacity-0 translate-y-[10px] "} ${
          opts.fullScreen ? "flex-1 min-h-[100vh] min-w-[100vw]" : ""
        }`}
      >
        {<Child />}
      </div>
    </div>
  );
};

/**
 * call this function to show a modal
 * @param {React.Component} component - the component to be shown in the modal
 * @param {Object} opts - options for the modal
 * @param {Boolean} opts.fullScreen - whether the modal should take the full screen
 * @param {Boolean} opts.hideCloseButton - whether the close button should be hidden
 * @param {Boolean} opts.raw - whether the component should be rendered raw
 * @returns {Promise} - a promise that resolves to the value passed to the proceed function
 */

export const modal = async (component, opts = {}) => {
  const modalComponent = (props) => (
    <ModalWrapper
      Component={component}
      opts={opts}
      {...{ ...props, ...(opts.props || {}) }}
    />
  );
  return await createConfirmation(confirmable(modalComponent))();
};
