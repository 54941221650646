import React, { useState } from "react";
import Loader from "../icons/Loader";
import { ErrorMessage } from "../Input/InputError";

const HoverBtn = ({ style, hoverStyle, children,className, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onClick={onClick}
      style={{ ...style, ...(hovered ? hoverStyle : {}) }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      className={className}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      {children}
    </div>
  );
};

const Button = ({ error,submit=false, style, onClick, children, prompt, loading, messageStyle, className,btnClassName, fontSize, message, disabled, type = "primary" }) => {
  const [prompted, setPrompted] = useState(false);

  const [internalLoading, setInternalLoading] = useState(false);

  const handleClick = (e) => {
    if (prompt && !prompted) {
      setPrompted(true);
    } else {
      onClick && onClick();
      setPrompted(false);
    }
  };

  const cancel = async (e) => {
    setInternalLoading(true);
    setPrompted(false);
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
    setInternalLoading(false);
  };

  return (
    <div style={style} className={className}>
      {disabled ? (
        <div style={{ ...styles.button, ...styles.disabled }}>{children}</div>
      ) : (
        <>
          {loading || internalLoading ? (
            <div
              className="flex justify-center items-center"
            >
              <Loader />
            </div>
          ) : (
            <>
              {error && <ErrorMessage className='mb-[5px] !text-[1.2rem]'>{error}</ErrorMessage>}
              {prompted ? (
                <div style={styles.buttonContainers}>
                  <div style={{ ...styles.messageContainer, messageStyle }}>confirm</div>
                  <HoverBtn
                    style={{ ...styles.button, ...styles[type]?.static, ...{ fontSize: fontSize } }}
                    hoverStyle={styles[type]?.hovered}
                    onClick={handleClick}
                  >
                    yes
                  </HoverBtn>
                  <HoverBtn
                    style={{ ...styles.button, ...styles[type]?.static, ...{ fontSize: fontSize } }}
                    hoverStyle={styles[type]?.hovered}
                    onClick={cancel}
                  >
                    no
                  </HoverBtn>
                </div>
              ) : (
                <HoverBtn
                className={btnClassName}
                  style={{ ...styles.button, ...styles[type]?.static, ...{ fontSize: fontSize } }}
                  hoverStyle={styles[type]?.hovered}
                  onClick={handleClick}
                >
                  {children}
                </HoverBtn>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  buttonContainers: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flexWrap: "wrap",
  },
  messageContainer: {
    width: "100%",
    fontSize: 20,
    textAlign: "center",
    marginTop: "-10px",
    // marginBottom: "10px",
  },
  error: {
    color: "red",
    fontSize: 20,
    marginBottom: "20px",
    textAlign: "center",
  },
  button: {
    margin: "auto",
    padding: "1rem 1.5rem",
    textAlign : 'center',
    borderRadius: "0.7rem",
    fontSize: "1.2rem",
    fontFamily: "Primary-Medium",
    border: "1px solid var(--inyc-button-border-color)",
    transition: "0.3s",
    cursor: "pointer",
    width: "100%",
  },

  disabled: {
    backgroundColor: "var(--inyc-primary)",
    cursor: "default",
    color: "var(--inyc-primary-text-color-light)",
    boxShadow: "2px 2px 8px rgba(0,0,0,0.2)",
  },

  primary: {
    static: {
      backgroundColor: "var(--inyc-primary-button)",
      boxShadow: "var(--inyc-button-shadow)",
      color: "var(--inyc-primary-text)",
    },
    hovered: {
      backgroundColor: "var(--inyc-secondary-button)",
      color: "var(--inyc-secondary-text-color)",
      boxShadow: "var(--inyc-button-shadow)",
    },
  },

  secondary : {
    static: {
      backgroundColor: "var(--inyc-secondary-button)",
      boxShadow: "var(--inyc-button-shadow)",
      color: "var(--inyc-secondary-text-color)",
    },
    hovered: {
      backgroundColor: "var(--inyc-primary-button)",
      color: "var(--inyc-primary-text-color)",
      boxShadow: "var(--inyc-button-shadow)",
    },
  }
};

export default Button;
