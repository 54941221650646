import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  Navigate,
  RouterProvider as Router,
  createHashRouter,
  useRouteError,
} from "react-router-dom";
import "./index.css";
import { SnackbarProvider, closeSnackbar } from "notistack";
import closeIcon from "./assets/icons/close-icon-white.png";
import SuspenseLoader from "./components/SuspenseLoader/SuspenseLoader";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { UserProvider, useUser } from "./context/UserContext/UserProvider";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import VerifyEmail from "./pages/Auth/VerifyEmail/VerifyEmail";
import { PortalProvider } from "./context/PortalContext/portalcontext";
import { LogProvider } from "./context/LogContext/LogProvider";
import { Capacitor } from "@capacitor/core";
import Button from "./components/Button/Button";
import {
  ANDROID_DOWNLOAD_LINK,
  backendUrl,
  getMobileOperatingSystem,
  IOS_DOWNLOAD_LINK,
  version,
} from "./api/constants";
import UnsubscribeEmail from "./pages/UnsubscribeEmail";
import GoogleOAuthCallback from "./pages/Auth/GoogleOAuthCallback/GoogleOAuthCallback";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import logoLight from "./assets/imgs/logo-transparent-light.gif";
import UILoader from "./components/UILoader/UILoader";
import Providers from "./Providers";
import "./styles/themes.css";
import { AiOutlineUndo } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import L from "leaflet";
import { SafeArea } from "capacitor-plugin-safe-area";
import WatchList from "./components/WatchList/WatchList";
import { WatchListProvider } from "./context/WatchListContext/WatchListProvider";
import OAuthCallback from "./components/OAuthCallback/OAuthCallback";
axios.defaults.headers.common["ngrok-skip-browser-warning"] = "6941"
const Portal = lazy(() => import("./pages/Portal"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Protected = lazy(() => import("./pages/Protected"));
const UserSettings = lazy(() => import("./pages/UserSettings"));
const Auth = lazy(() => import("./pages/Auth"));
const Signin = lazy(() => import("./pages/Auth/Signin/Signin"));
const Signup = lazy(() => import("./pages/Auth/Signup/Signup"));

const ErrorBoundary = () => {
  const error = useRouteError();
  console.log(error);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "var(--inyc-primary)",
        fontSize: "1.7rem",
        textAlign: "center",
        padding: "1rem 2rem",
      }}
    >
      <div className="mb-[0.5rem] font-['Primary-Regular']">
        oops! something went wrong.
        <br />
        please refresh the page.
      </div>
      <br />
      <AiOutlineUndo
        color="var(--inyc-primary-text-color)"
        style={{
          cursor: "pointer",
        }}
        size={30}
        onClick={() => {
          window.location.reload();
        }}
      />
    </div>
  );
};

const router = createHashRouter([
  {
    path: "/privacy-policy",
    Component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    Component: TermsAndConditions,
  },
  {
    path: "/unsubscribe-email",
    Component: UnsubscribeEmail,
  },
  {
    path: "/google-auth",
    Component: GoogleOAuthCallback,
  },
  {
    path: "/",
    Component: () => {
      return (
        <Suspense fallback={<SuspenseLoader black />}>
          <Providers>
            <UILoader />
            <Protected />
          </Providers>
        </Suspense>
      );
    },
    children: [
      {
        path: "",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <Dashboard />
          </Suspense>
        ),
        index: true,
      },
      {
        path: "watchlist",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <WatchListProvider>
              <WatchList />
            </WatchListProvider>
          </Suspense>
        ),
      },
      {
        path: "settings",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <UserSettings noCloseButton />
          </Suspense>
        ),
      },
      {
        path: "dashboard",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <PortalProvider>
              <Portal />
            </PortalProvider>
          </Suspense>
        ),
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/auth",
    Component: () => (
      <Suspense fallback={<SuspenseLoader />}>
        <Auth />
      </Suspense>
    ),
    children: [
      {
        path: "signin",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <Signin />
          </Suspense>
        ),
      },
      {
        path: "signup",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <Signup />
          </Suspense>
        ),
      },
      {
        path: "reset-password",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <ResetPassword />
          </Suspense>
        ),
      },
      {
        path: "verify-email",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <VerifyEmail />
          </Suspense>
        ),
      },
      {
        path: "oauth-callback",
        Component: () => (
          <Suspense fallback={<SuspenseLoader />}>
            <OAuthCallback />
          </Suspense>
        ),
      },
      {
        path: "",
        Component: () => <Navigate to="signin" replace />,
      },
    ],
  },
  {
    path: "*",
    Component: () => <Navigate to="/" replace />,
  },
]);

const OpenApp = () => {
  const [transform, setTransform] = useState("translate(0, -50vh)");
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [lockDirection, setLockDirection] = useState(null);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updateDismissed, setUpdateDismissed] = useState(false);
  useEffect(() => {
    //get latest version of the app and check if its the same as the current version
    const checkForUpdate = async () => {
      const { data } = await axios.get(
        `${backendUrl}/user/version?platform=${
          Capacitor.getPlatform() === "ios" ? "ios" : "android"
        }`
      );
      console.log(data);
      // version is a text string like "2.2.1" , we need to parse it to a number somehow and compare to the current one, if current is lower show update available
      if (
        parseInt(data.version.split(".").join("")) >
        parseInt(version.split(".").join(""))
      ) {
        setUpdateAvailable(data.version);
      }
    };
    checkForUpdate();
  }, []);

  useEffect(() => {
    // console.log("capacitor platform ", Capacitor.getPlatform());
    // console.log(getMobileOperatingSystem());
    // console.log("is native ", Capacitor.isNativePlatform());
    if (
      !Capacitor.isNativePlatform() &&
      (getMobileOperatingSystem() === "ios" ||
        getMobileOperatingSystem() === "android") &&
      Capacitor.getPlatform() === "web"
    ) {
      setTimeout(() => {
        setTransform("translate(0, 0)");
      }, 3500);
    }
  }, [Capacitor.isNativePlatform()]);

  const dismiss = (style, e) => {
    e && e.stopPropagation();
    setTransform(style || "translate(0, -150%)");
  };

  const isIOS = () => {
    return Capacitor.getPlatform() === "ios";
  };

  const handleVisibilityChange = (timer) => (e) => {
    if (e.target.visibilityState === "hidden") {
      clearTimeout(timer);
    }
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };
  const handleBlur = (timer) => (e) => {
    clearTimeout(timer);
    window.removeEventListener("blur", handleBlur);
  };

  const tryOpenApp = async () => {
    const iosLink = IOS_DOWNLOAD_LINK;
    const androidLink = ANDROID_DOWNLOAD_LINK;
    const path = window.location.hash.split("#")[1] || "";
    window.location = `inyc://${path}`;
    let timer = setTimeout(() => {
      window.location = isIOS() ? iosLink : androidLink;
    }, 1000);
    window.addEventListener("blur", handleBlur(timer));
    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange(timer)
    );
  };

  const handleDragStart = (e) => {
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    setDragging(true);
    const clientY = e.clientY || e.touches[0].clientY;
    setStartPos({ y: clientY });
  };

  const handleDragEnd = (e) => {
    setDragging(false);
    // setTimeout(() => {
    document.documentElement.style.overflow = "";
    document.body.style.overflow = "";
    // }, 200);
    if (position.y < -10) {
      dismiss("translate(0, -50vh)");
      return;
    } else {
      setPosition({ y: 0 });
    }
    setLockDirection(null);
  };

  const handleDragging = (e) => {
    if (dragging) {
      // if it has moved more than 10px in a direction then lock the direction
      const clientY = e.clientY || e.touches[0].clientY;
      const deltaY = Math.min(0, clientY - startPos.y);

      setPosition({ y: deltaY });
    }
  };

  const openAppDownload = async () => {
    const iosLink = IOS_DOWNLOAD_LINK;
    const androidLink = ANDROID_DOWNLOAD_LINK;
    window.location = isIOS() ? iosLink : androidLink;
  };

  async function setViewportHeight() {
    // Check if running in a native app environment
    const isNative = Capacitor.isNativePlatform();

    // alert(`isNative : ${isNative}`);

    if (isNative) {
      // Native app handling
      const { insets } = await SafeArea.getSafeAreaInsets();

      for (const [key, value] of Object.entries(insets)) {
        document.body.style.setProperty(
          `--safe-area-inset-${key}`,
          `${value}px`
        );
      }

      document.body.style.setProperty(
        "--inyc-bottom-space",
        `var(--safe-area-inset-bottom)`
      );
      // alert(`insets ${JSON.stringify(insets, null, 2)}`);

      //if its not native ios
      if (!isIOS()) {
        const androidVersion = parseFloat(
          navigator.userAgent.match(/Android\s([0-9.]*)/)?.[1] || 0
        );

        // alert(`androidVersion : ${androidVersion}`);
        if (androidVersion < 13) {
          // Android versions below 13 don't support safe areas
          document.body.style.setProperty("--safe-area-inset-bottom", `0px`);
        }
      }
    } else {
      // Browser handling
      const visibleHeight = window.innerHeight; // Height of the visible viewport
      const totalHeight = document.documentElement.clientHeight; // Full height

      // Calculate the bottom safe area inset
      const bottomInset = totalHeight - visibleHeight;

      // Use calc(100vh - ...px) for setting the bottom inset
      document.body.style.setProperty(
        "--safe-area-inset-bottom",
        `calc(100vh - ${visibleHeight}px)`
      );

      // Set other insets to 0 or adjust as needed
      document.body.style.setProperty("--safe-area-inset-top", `0px`);
      document.body.style.setProperty("--safe-area-inset-left", `0px`);
      document.body.style.setProperty("--safe-area-inset-right", `0px`);

      document.body.style.setProperty(
        "--inyc-bottom-space",
        `var(--safe-area-inset-bottom)`
      );
      // alert(`bottomInset : ${bottomInset}`);
    }
  }

  // Update on resize and page load
  window.addEventListener("resize", setViewportHeight);
  setViewportHeight();

  return (
    <>
      {!updateDismissed && updateAvailable && (
        <div className="absolute z-[10001] w-full h-full top-0 left-0 bg-[var(--inyc-primary)] flex items-center justify-center pt-[8rem]">
          <div className="flex flex-col items-center justify-evenly h-full gap-[5rem] p-[2rem] bg-[var(--inyc-primary)]">
            <div className="">
              <div className="absolute px-[2rem] py-[1rem] top-0 left-0 w-full ">
                <img
                  className="h-[200px] invert-[var(--inyc-invert-black)]"
                  src={logoLight}
                  alt="logo"
                />
              </div>
              <div className="mb-[2rem] text-[--inyc-primary-text-color] font-bold text-[1.5rem] text-center mt-[3rem]">
                new update available {updateAvailable && `(${updateAvailable})`}
              </div>
              <div className="text-[--inyc-primary-text-color-light] text-center text-[1.1rem]">
                a better experience with new features and bug fixes.
              </div>
              <br />
              <div className="text-[--inyc-primary-text-color-light] text-center text-[1.1rem]">
                some features may not work without update.
              </div>
            </div>
            <div className="flex items-center gap-[1rem]">
              <Button
                className="whitespace-nowrap"
                onClick={() => {
                  setUpdateDismissed(true);
                }}
              >
                update later
              </Button>
              <Button
                type="secondary"
                className="whitespace-nowrap"
                onClick={openAppDownload}
              >
                update now
              </Button>
            </div>
          </div>
        </div>
      )}
      {!updateAvailable && (
        <div
          onMouseDown={handleDragStart}
          onMouseMove={handleDragging}
          onMouseUp={handleDragEnd}
          onTouchStart={handleDragStart}
          onTouchMove={handleDragging}
          onTouchEnd={handleDragEnd}
          className={`fixed z-[200000] pt-[env(safe-area-inset-top)] w-full mx-auto shadow-md bg-[var(--inyc-notif-bg-color)] backdrop-blur-[16px] backdrop-saturate-[180%] pb-[0.5rem] px-[2rem] border-[1px] border-black/10`}
          style={{
            transform: transform || "translate(0, -50vh)",
            top: `${lockDirection?.y ? 0 : position.y}px`,
            transition: dragging ? "none" : "0.6s",
          }}
        >
          <div
            className="absolute top-[env(safe-area-inset-top)] right-0 p-[0.5rem] cursor-pointer"
            onClick={() => dismiss()}
          >
            <IoCloseOutline
              size={20}
              color="var(--inyc-secondary-text-color)"
            />
          </div>
          <div
            className={`mb-[1rem] flex items-center justify-center mt-[1rem] gap-[2rem]`}
          >
            <div className="flex-1 flex justify-between pr-[1.5rem] items-center gap-[1.5rem]">
              <div className="text-[var(--inyc-notif-text-color)] font-bold text-lg">
                check out our app!
              </div>
              <Button
                className="whitespace-nowrap use-our-app-btn"
                btnClassName={
                  "!rounded-[3rem] !border-[0px] !py-[0.7rem] z-[1]"
                }
                onClick={tryOpenApp}
              >
                open app
              </Button>
            </div>
          </div>
          <div className="mx-auto w-[50px] h-[3px] bg-black/40 rounded-[5px]"></div>
        </div>
      )}
    </>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <SnackbarProvider
    classes={{
      containerRoot: "z-alert",
      base: "notistack-snackbar-base",
    }}
    autoHideDuration={5000}
    action={(snackbarId) => (
      <button
        style={styles.snackbarCloseButton}
        onClick={() => closeSnackbar(snackbarId)}
      >
        <img src={closeIcon} style={styles.snackBarCloseIcon} />
      </button>
    )}
  >
    <LogProvider>
      <UserProvider>
        <OpenApp />
        <Router router={router} />
      </UserProvider>
    </LogProvider>
  </SnackbarProvider>
);

const styles = {
  snackbarCloseButton: {
    backgroundColor: "transparent",
    color: "var(--inyc-primary)",
    border: "none",
    paddingRight: "10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100000,
  },
  snackBarCloseIcon: {
    height: "25px",
    width: "25px",
    objectFit: "contain",
    objectPosition: "center",
  },
};
