import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useData } from "../DataContext/DataProvider";
import { enqueueSnackbar } from "notistack";
import { backendUrl } from "../../api/constants";
import axiosInstance from "../../api/axiosInstance";
import { debounce } from "lodash";
import { useUIContext } from "../UIContext/UIContext";
import { convertEventDate } from "../../util/eventUtils";
import isEqual from "lodash/isEqual";
const searchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchLocations, setSearchLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPage, setSearchPage] = useState({
    page: 1,
    limit: 10,
  });
  const [totalResults, setTotalResults] = useState();
  const { userLocation } = useData();
  const [autoSuggestion,setAutoSuggestion] = useState()
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchPageLoading, setSearchPageLoading] = useState(false);
  const { likedFilter, posterFilter, activeCategories } = useUIContext();
  const [eventsOnly, setEventsOnly] = useState(false);
  const [processedSearchLocations, setProcessedSearchLocations] = useState([]);
  const previousFilter = useRef();

  // Add request tracking
  const currentRequestId = useRef(0);
  const abortController = useRef(null);

  const performSearch = useRef(
    debounce(async (options) => {
      // Declare requestId at the very beginning of the function
      let requestId;
      
      try {
        // Cancel any ongoing request
        if (abortController.current) {
          abortController.current.abort();
        }
        
        // Create new abort controller
        abortController.current = new AbortController();
        requestId = ++currentRequestId.current; // Assign to the already declared variable

        // Don't proceed with empty query
        const { query, userLocation, searchPage, activeCategories, likedFilter, posterFilter } = options;
        if (!query || query.trim() === '') {
          setSearchLoading(false);
          setSearchPageLoading(false);
          return;
        }
        
        // Set these to true for every new request - this helps maintain loading state
        // for rapid successive searches
        setSearchPageLoading(true);
        if (searchPage?.page === 1) {
          setSearchLoading(true);
        }

        const { data } = await axiosInstance.get(
          `${backendUrl}/events/search?search=${query}&lat=${
            userLocation.lat
          }&lng=${userLocation.lng}&categories=${activeCategories.join(
            ","
          )}&liked=${likedFilter}&posterliked=${posterFilter}&page=${
            searchPage?.page
          }&limit=${searchPage?.limit}`,
          {
            signal: abortController.current.signal
          }
        );

        // Only process response if it's from the latest request
        if (requestId === currentRequestId.current) {
          setSearchPageLoading(false);
          setSearchLoading(false);

          if (data.error) {
            enqueueSnackbar(data.error, { variant: "error" });
            return;
          }

          const processedData = data?.data?.map((item) => {
            if (item.type === "event") {
              convertEventDate(item);
            } else if (item.type === "venue" && item.events) {
              item.events.forEach((event) => {
                convertEventDate(event);
              });
              if (item._matchingEvent) {
                convertEventDate(item._matchingEvent);
              }
            }
            return item;
          });

          if (searchPage?.page === 1) {
            setSearchLocations(processedData);
          } else {
            setSearchLocations((prev) => [...prev, ...processedData]);
          }
          setTotalResults(data.totalResults);
          setAutoSuggestion(data.autoSuggestion);
        }
        // If it's not the latest request, don't update the loading states
        // This ensures loading indicator stays on until the latest request completes
      } catch (error) {
        // Only handle error if it's not from cancellation and it's the latest request
        if (error.name !== 'AbortError' && requestId && currentRequestId.current === requestId) {
          console.log(error);
          setSearchPageLoading(false);
          setSearchLoading(false);
        }
      }
    }, 500)
  ).current;

  // Add suggestion interaction lock
  const [isInteractingWithSuggestions, setIsInteractingWithSuggestions] = useState(false);

  const handleSuggestionInteraction = (interacting) => {
    setIsInteractingWithSuggestions(interacting);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      performSearch.cancel();
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  //refresh results by performing the search again
  const refreshResults = () => {
    performSearch({
      query:searchQuery,
      userLocation,
      searchPage,
      activeCategories,
      likedFilter,
      posterFilter,
    });
  }
  
  useEffect(() => {
    setSearchPage({
      page: 1,
      limit: 10,
    });
    // Set loading state immediately when search query changes
    if (searchQuery) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
      setTotalResults(0);
      setSearchLocations([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery && userLocation?.lat && userLocation?.lng) {
      const filterChanged = !isEqual(previousFilter.current, {
        searchQuery,
        userLocation,
        activeCategories,
        likedFilter,
        posterFilter,
        searchPage,
      });

      if (filterChanged) {
        // Always set loading true when performing a new search
        if (searchPage.page === 1) {
          setSearchLoading(true);
        }
        setSearchPageLoading(true);
        
        performSearch({
          query: searchQuery,
          userLocation,
          searchPage,
          activeCategories,
          likedFilter,
          posterFilter,
        });
        
        previousFilter.current = {
          searchQuery,
          userLocation,
          activeCategories,
          likedFilter,
          posterFilter,
          searchPage,
        };
      }
    } else {
      setTotalResults(0);
      setSearchLocations([]);
      setSearchLoading(false);
    }
  }, [
    searchQuery,
    userLocation?.lat,
    userLocation?.lng,
    activeCategories,
    likedFilter,
    posterFilter,
    searchPage,
  ]);

  useEffect(() => {
    // if searchLocations changes, process it if eventsOnly is true
    if (eventsOnly) {
      setProcessedSearchLocations(
        searchLocations.filter(
          (item) => item.type === "event" || !item._venueMatches
        )
      );
    } else {
      setProcessedSearchLocations(searchLocations);
    }
  }, [searchLocations, eventsOnly]);

  const value = {
    searchLocations,
    setSearchLocations,
    processedSearchLocations,
    setProcessedSearchLocations,
    searchQuery,
    setSearchQuery,
    searchLoading,
    setSearchLoading,
    searchPageLoading,
    setSearchPageLoading,
    setEventsOnly,
    eventsOnly,
    searchPage,
    setSearchPage,
    refreshResults,
    totalResults,
    autoSuggestion,
    setAutoSuggestion,
    handleSuggestionInteraction,
    isInteractingWithSuggestions,
  };

  return (
    <searchContext.Provider value={value}>{children}</searchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(searchContext);
  if (!context) {
    throw new Error("useSearchContext must be used within a SearchProvider");
  }
  return context;
};
