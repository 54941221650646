import { enqueueSnackbar } from "notistack";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getEventById } from "../../api/events";
import { useData } from "../DataContext/DataProvider";
import { useUIContext } from "../UIContext/UIContext";
import axiosInstance from "../../api/axiosInstance";
import { backendUrl } from "../../api/constants";
import { convertEventDate } from "../../util/eventUtils";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import { useLocation } from "react-router-dom";
import { defaultDateFilter } from "../../util/dataUtils";
import { useUser } from "../UserContext/UserProvider";
import { handleSpecialFilter } from "../../util/eventUtils";

const EventDetailsContext = createContext();

export const EventDetailsProvider = ({ children }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSubEvent, setSelectedSubEvent] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [queryFilter, setQueryFilter] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [tagFilter, setTagFilter] = useState([]);
  const [detailsSearchLoading, setDetailsSearchLoading] = useState(false);
  const { userLocation } = useData();
  const { activeCategories, likedFilter, posterFilter } = useUIContext();
  const prevDateFilter = useRef();
  const prevQueryFilter = useRef();
  const  {user} = useUser();
  const location = useLocation();

  const fetchEventDetails = async (eventId, opts = {}) => {
    try {
      // console.log("fetching info for ", eventId);
      const data = await getEventById(eventId, {
        referenceDate: opts.referenceDate,
        userLocation: userLocation,
        includeEvents: opts.includeEvents,
        excludePastEvents: true,
      });
      // console.log("fetched data for ", eventId, data);
      opts.onSuccess && opts.onSuccess(data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("failed to fetch event details", { variant: "error" });
    }
  };

  const fetchFilteredEvents = async (options) => {
    let opts = options;
    opts.setLoading && opts.setLoading(true);
    const specialDateFilter = ["now", "soon", "later", "late night"].includes(
      opts.dateFilter
    );
    // console.log("specialDateFilter ", specialDateFilter);
    if (specialDateFilter) {
      const ddate = defaultDateFilter(opts.timezone);
      opts.from = ddate.start;
      opts.to = ddate.end;
    } else if (!opts.from && !opts.to) {
      const ddate = defaultDateFilter(opts.timezone, {
        endPlus: { months: 6 },
      });
      opts.from = ddate.start;
      opts.to = ddate.end;
    }

    opts.onSuccess = (results) => {
      console.log("fetchFilteredEvents results ", results);
      if (results) {
        // Add venue details to filtered events
        const processedResults = results.map(event => {
          if (event.venue === selectedEvent?._id) {
            return {
              ...event,
              venueDetails: {
                _id: selectedEvent._id,
                name: selectedEvent.name
              }
            };
          }
          return event;
        });

        if (specialDateFilter) {
          const presults = handleSpecialFilter(processedResults, opts.dateFilter);
          console.log("presults ", presults);
          setFilteredEvents(presults);
        } else {
          setFilteredEvents(processedResults);
        }
      }
    };

    performSearch(opts);
  };

  const performSearch = useCallback(
    debounce(async (options) => {
      const query = options.search;
      const venue = options.venue;
      const from = options.from;
      const to = options.to;
      // console.log("search query", query);
      let fromDate, toDate;
      const timezone = options.timezone || "UTC";
      const tags = options.tagFilter;
      // console.log("timezone ", timezone);

      // Date filter has to be considered in the selected event time zone and then convert it to utc
      if (from) {
        fromDate = DateTime.fromJSDate(from)
          .setZone(timezone, {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO();
      }
      if (to) {
        toDate = DateTime.fromJSDate(to)
          .setZone(timezone, {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO();
      }

      options.setLoading && options.setLoading(true);
      let url = `${backendUrl}/events/search?`;
      url += `limit=all&`;
      url += `categories=${activeCategories.join(",")}&`;
      //add excludePastEvents query
      url += `excludePastEvents=true&`;
      if (tags) {
        url += `tags=${tags?.join(",")}&`;
      }
      if (query) {
        url += `search=${query}&`;
      }
      if (likedFilter) {
        url += `liked=${likedFilter}&`;
      }
      if (posterFilter) {
        url += `posterliked=${posterFilter}&`;
      }
      if (venue) {
        url += `venue=${venue}&`;
      }
      if (fromDate) {
        url += `from=${fromDate}&`;
      }
      if (toDate) {
        url += `to=${toDate}`;
      }
      const { data } = await axiosInstance.get(url);
      options.setLoading && options.setLoading(false);
      if (data.error) {
        enqueueSnackbar(data.error, { variant: "error" });
        return;
      }
      console.log("preformat search data :", data.data?.[0]);
      const searchData = data.data?.[0] || {};

      //TODO centralize this
      const formateddata =
        searchData.events?.map((item) => {
          //~ Convert Date to Local
          if (item.type === "event") {
            convertEventDate(item);
          } else if (item.type === "venue" && item.events) {
            item.events.forEach((event) => {
              convertEventDate(event);
            });
            if (item._matchingEvent) {
              convertEventDate(item._matchingEvent);
            }
          }

          return item;
        }) || [];

      console.log("search data ", formateddata);

      options.onSuccess && options.onSuccess(formateddata);
    }, 500),
    [
      userLocation?.lat,
      userLocation?.lng,
      activeCategories,
      likedFilter,
      posterFilter,
      activeCategories,
      tagFilter,
    ]
  );

  const fetchQueryEvent = async (eventId) => {
    try {
      const data = await getEventById(eventId, {
        userLocation: userLocation,
        includeEvents: true,
      });
      console.log(">>>>>>>>>>> fetched query data :", data);
      setSelectedEvent((prev) => {
        const updated = { ...prev, ...data };
        // If the new response doesn't include events, remove it from the state.
        if (!("events" in data)) {
          delete updated.events;
        }
        return updated;
      });
      if (data._matchingEvent) {
        setSelectedSubEvent(data._matchingEvent);
      } else {
        // Clear any previously selected subevent if the new event doesn't have a matching event
        setSelectedSubEvent(null);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("failed to fetch query event details", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!selectedEvent?._id) {
      return;
    }

    if (selectedEvent?.timezone?.[0]) {
      fetchFilteredEvents({
        search: queryFilter,
        categories: activeCategories,
        venue: selectedEvent?._id,
        from: dateFilter?.start,
        to: dateFilter?.end,
        dateFilter,
        tagFilter,
        setLoading: setDetailsSearchLoading,
        timezone: selectedEvent?.timezone[0],
      });
    }
    // }

    prevDateFilter.current = dateFilter;
    prevQueryFilter.current = queryFilter;
  }, [
    dateFilter,
    tagFilter,
    queryFilter,
    selectedEvent?._id,
    activeCategories,
  ]);

  //#region fetch event details
  const sanitizeQuery = (query) => {
    const decoded = decodeURIComponent(query);
    //if contains #/?(...) extract the query
    if (decoded.includes("#/?")) {
      return decoded.split("#/?")[1];
    }

    return decoded;
  };

  useEffect(() => {
    if(!user){
      return;
    }

    const handleLocationSearch = () => {
      const queryParams = new URLSearchParams(location.search);
      const locationId = queryParams.get("location");
      if (locationId) {
        fetchQueryEvent(locationId);
      } else {
        //extract location from decoded url params
        const sanitized = sanitizeQuery(window.location.search);
        console.log("sanitized", sanitized);
        const urlParam = new URLSearchParams(sanitized); // Parse the query string
        console.log("location", urlParam.get("location"));
        const loc = urlParam.get("location");
        if (loc) {
          fetchQueryEvent(loc);
        }
      }
    };

    window.addEventListener("popstate", handleLocationSearch);
    handleLocationSearch();

    return () => window.removeEventListener("popstate", handleLocationSearch);
    // }
  }, [location,user]);

  useEffect(() => {
    // console.log("selectedEvebt ", selectedEvent);
    // console.log("selectedEvebt Timezone ", selectedEvent?.timezone?.[0]);
    if (selectedEvent?._id && userLocation) {
      if (!dateFilter && (!queryFilter || queryFilter === "")) {
        setDateFilter(defaultDateFilter(selectedEvent.timezone?.[0]));
      }

      if (queryFilter && dateFilter) {
        setDateFilter(null);
      }
      const refDate = defaultDateFilter(selectedEvent.timezone?.[0])?.start;
      fetchEventDetails(selectedEvent._id, {
        includeEvents: true,
        referenceDate: DateTime.fromJSDate(refDate)
          .setZone(selectedEvent.timezone?.[0], {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO(),
        onSuccess: (data) => {
          if (data?._id === selectedEvent._id) {
            // console.log(">>>>>>>>>>> fetched data :", data);
            setSelectedEvent({
              ...selectedEvent,
              ...data,
            });
          }
        },
      });
    }
  }, [selectedEvent?._id, userLocation]);

  useEffect(() => {
    // console.log("selectedSubEvent", selectedSubEvent);
    if (selectedSubEvent?._id && userLocation && selectedEvent?.timezone) {
      const refDate = defaultDateFilter(selectedEvent.timezone?.[0])?.start;
      fetchEventDetails(selectedSubEvent?._id, {
        referenceDate: DateTime.fromJSDate(refDate)
          .setZone((selectedSubEvent?.timezone || selectedEvent.timezone)?.[0], {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO(),
        onSuccess: (data) => {
          console.log(">>>>>>>>>>> fetched sub event data :", data);
          if (selectedSubEvent?._id === data?._matchingEvent?._id) {
            setSelectedSubEvent({
              ...selectedSubEvent,
              ...data?._matchingEvent,
            });
          }
        },
      });
    }
  }, [selectedEvent, selectedSubEvent?._id, userLocation]);

  const storeSeenArtist = (event) => {
    if (event.type === "event" && event?.account_id) {
      const storedArtists = JSON.parse(localStorage.getItem("seenArtists") || "[]");
      console.log("storedArtists ", storedArtists, ' event ', event);
      const artistExists = storedArtists.some(artist =>
          artist._id === event.account_id?._id || artist._id === event.account_id
      );
      console.log("artistExists ", artistExists);
      if (!artistExists) {
          console.log('artist seen for the first time ', event.account_id?._id || event.account_id)
          const artist = event.account_id?._id ? event.account_id : {_id: event.account_id}
  
          const updatedArtists = [artist, ...storedArtists].slice(0, 10);
          localStorage.setItem("seenArtists", JSON.stringify(updatedArtists));
      }
    }
  };

  useEffect(() => {
    if(selectedEvent) {
      storeSeenArtist(selectedEvent);
    }
    if(selectedSubEvent) {
      storeSeenArtist(selectedSubEvent);
    }
  }, [selectedEvent, selectedSubEvent]);


  const selectEvent = (event, subEv) => {
    setSelectedEvent(event);
    setSelectedSubEvent(subEv);
    console.log("selectedEvent ", event);
    console.log("selectedSubEvent ", subEv);
  };

  const selectSubEvent = (subEv) => {
    setSelectedSubEvent(subEv);
  };

  const value = {
    selectedEvent,
    setSelectedEvent,
    selectedSubEvent,
    setSelectedSubEvent,
    selectEvent,
    selectSubEvent,
    dateFilter,
    setDateFilter,
    tagFilter,
    setTagFilter,
    queryFilter,
    setQueryFilter,
    filteredEvents,
    setFilteredEvents,
    detailsSearchLoading,
    setDetailsSearchLoading,
  };
  return (
    <EventDetailsContext.Provider value={value}>
      {children}
    </EventDetailsContext.Provider>
  );
};

EventDetailsProvider.whyDidYouRender = true;

export const useEventDetails = () => {
  const context = useContext(EventDetailsContext);
  if (!context) {
    throw new Error(
      "useEventDetails must be used within a EventDetailsProvider"
    );
  }
  return context;
};
