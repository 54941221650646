import React, { createContext, useState, useContext, useEffect } from "react";
import { useUser } from "../UserContext/UserProvider";
import { backendUrl } from "../../api/constants";
import { createCategoryIcons } from "../../util/mapStyles";
import SuspenseLoader from "../../components/SuspenseLoader/SuspenseLoader";
import axios from "axios";

const newYorkCoords = {
  lat: 40.717426429835896,
  lng: -74.00518256903389,
};

const preDefinedLocations = [
  {
    id: "new-york",
    name: "New York",
    lat: newYorkCoords.lat,
    lng: newYorkCoords.lng,
    timezone: "America/New_York",
  },
];

const DataContext = createContext(null);
export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [globalConfig, setGlobalConfig] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setUser } = useUser();
  const [userLocationLoading, setUserLocationLoading] = useState(false);

  const fetchConfig = async () => {
    try {
      const { data: response } = await axios.get(`${backendUrl}/config`);
      console.log(response);
      if (!response.data?.categories) {
        // refetch after 2 seconds
        setTimeout(fetchConfig, 2000);
        return;
      }
      setGlobalConfig(response.data);
      createCategoryIcons(response.data.categories);
    } catch (error) {
      console.log(error);
      setTimeout(fetchConfig, 2000);
    }
  };

  const toggleFollowUser = (userId) => {
    setUser((prev) => ({
      ...prev,
      following: prev.following.includes(userId)
        ? prev.following.filter((id) => id !== userId)
        : [...prev.following, userId],
    }));
  };

  const getEventTimezone = (ev) => {
    // If the event has a venue, use the venue's timezone
    if (ev.venue) {
      const location = getLocationById(ev.venue);
      return location?.timezone;
    }

    // Otherwise, use the event's timezone
    return ev.timezone;
  };

  const fetchTimzone = async (loc) => {
    if (!loc?.lat || !loc?.lng) return;

    try {
      const res = await fetch(
        `https://www.timeapi.io/api/time/current/coordinate?latitude=${loc.lat}&longitude=${loc.lng}`
      );
      const data = await res.json();
      console.log("current fetched timezone", data?.timeZone);
      if (data.timeZone) {
        setSelectedTimezone(data.timeZone?.trim());
      }
    } catch (error) {
      console.log(error);
    }
  };

  //& ==================== EFFECTS ====================
  useEffect(() => {
    fetchConfig();
  }, []);

  //debug userLocation
  useEffect(() => {
    fetchTimzone(userLocation);
    console.log("User Location", userLocation);
  }, [userLocation]);

  const contextValue = {
    //* global config
    globalConfig,

    //* location handlers
    userLocation,
    setUserLocation,
    selectedTimezone,
    setSelectedTimezone,
    userLocationLoading,
    setUserLocationLoading,

    //* location handlers
    getEventTimezone,

    //* event handlers
    loading,
    setLoading,

    //* user handlers
    toggleFollowUser,


    preDefinedLocations,
  };

  if (!globalConfig) return <SuspenseLoader />;

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};
