// UserContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import userAxios from "../../api/axiosInstance";
import axios from "axios";
import { backendUrl } from "../../api/constants";
import { enqueueSnackbar } from "notistack";

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [calendarAccounts,setCalendarAccounts] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);

  const authenticateUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("jwt_token") || sessionStorage.getItem("jwt_token");

    if (!token) {
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await axios.get(`${backendUrl}/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(data.user);
    } catch (error) {
      console.error("Authentication error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUser = async (opts) => {
    if(!opts?.supressLoading) setIsLoading(true);
    try {
      const { data } = await userAxios.get("/user/me");
      if (data.error) {
        console.log(data.error);
        return null;
      } else {
        setUser(data.user);
        return data.user;
      }
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('isLoading',isLoading);
    console.log('user',user);
  },[isLoading,user]);

  const fetchCalendarAccounts = async () => {
    // setLoading(true);
    try {
      if(!user || user?.accountType === 'user'){
        return setCalendarAccounts([]);
      }
      const { data } = await userAxios.get(`/user/calendar/google/accounts`);
      if (data.error) {
        return enqueueSnackbar(data.message, { variant: "error" });
      }
      setCalendarAccounts(data.accounts);
      return data.accounts;
      // setLoading(false);
    } catch (error) {
      console.log(error.response.data);
      // enqueueSnackbar("something went wrong, please try again", { variant: "error" });
    }
  };

  useEffect(() => {
    authenticateUser();
  }, []);

  useEffect(() => {
    // console.log("User changed: ", user);
    if (user) {
      fetchCalendarAccounts();
    }
  }, [user]);

  const logout = async () => {
    sessionStorage.removeItem("jwt_token");
    localStorage.removeItem("jwt_token");
    setUser(null);
  };

  const contextValue = {
    user,
    setUser,
    calendarAccounts,
    setCalendarAccounts,
    fetchCalendarAccounts,
    logout,
    authenticateUser,
    isLoading,
    fetchUser,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
