import React, { useEffect, useState, useRef } from "react";
import { backendUrl } from "../../api/constants";
import Logo from "../../assets/imgs/inyc_white.png";
import {enqueueSnackbar, closeSnackbar } from 'notistack'
import { MdErrorOutline } from "react-icons/md";
import { RiSignalWifiOffLine } from "react-icons/ri";
import axios from 'axios';

const NetworkStatusListener = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [backendOnline, setBackendOnline] = useState(true);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(null);
  const intervalRef = useRef(null); // Ref for tracking the interval ID
  const notificationRef = useRef(null);

  const checkBackendStatus = async () => {
    try {
      const response = await axios.get(`${backendUrl}/ping`);
      if (response.status === 200) {
        setBackendOnline(true);
      } else {
        setBackendOnline(false);
      }
    } catch (error) {
      setBackendOnline(false);
    }
  };

  const checkMaintenanceStatus = async () => {
    try {
      const response = await axios.get(`${backendUrl}/health`);
      // axios automatically parses JSON response
      const data = response.data;
      if (data.status === "maintenance") {
        setIsUnderMaintenance(data);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const setBackendCheckInterval = (intervalTime) => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (isOnline && isUnderMaintenance?.status !== "maintenance") {
        checkBackendStatus();
      }
    }, intervalTime);
  };

  useEffect(() => {
    const handleNetworkChange = () => {
      const onlineStatus = navigator.onLine;
      setIsOnline(onlineStatus);

      checkBackendStatus();
      // Set frequent checks if the backend was previously down
      if (!backendOnline) {
        setBackendCheckInterval(2000); // Check every 5 seconds
      } else {
        setBackendCheckInterval(15000); // Normal check interval every 15 seconds
      }
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    checkMaintenanceStatus();

    // Initial backend check when the component mounts
    if (navigator.onLine) {
      checkBackendStatus();
    }

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (backendOnline) {
      setBackendCheckInterval(15000);
    } else {
      setBackendCheckInterval(2000);
    }
  }, [backendOnline]);

  useEffect(() => {
    if (!isOnline) {
      // show a notistack notification
      closeSnackbar(notificationRef.current);
      notificationRef.current = enqueueSnackbar("You are offline", {
        variant: "error",
        persist: true,
        style: {
          backgroundColor: "var(--inyc-primary)",
          color: "white",
        },
        content: (key) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--inyc-primary)",
              color: "white",
              fontFamily: 'Primary-Regular',
              padding: "10px",
              fontSize: "1.1rem",
              borderRadius: "5px",
            }}
          >
            <RiSignalWifiOffLine style={{ marginRight: "10px",marginLeft: '0.6rem' , fontSize: "20px" }} />
            you are offline
          </div>
        )
      });


    } else {
      closeSnackbar(notificationRef.current);
    }
  }, [isOnline]);

  useEffect(() => {
    if (!backendOnline && isOnline) {
      // show a notistack notification
      closeSnackbar(notificationRef.current);
      notificationRef.current = enqueueSnackbar("Unable to reach the server", {
        variant: "error",
        persist: true,
        style: {
          backgroundColor: "#FBB04B",
          color: "white",
        },
        content: (key) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FBB04B",
              color: "white",
              fontFamily: 'Primary-Regular',
              padding: "10px",
              fontSize: "1.1rem",
              borderRadius: "5px",
            }}
          >
            <MdErrorOutline style={{ marginRight: "10px",marginLeft: '0.6rem' , fontSize: "20px" }} />
            unable to reach the server
          </div>
        )
      });
    } else if (backendOnline) {
      closeSnackbar(notificationRef.current);
    }
  } , [backendOnline, isOnline]);

  return (
    <>
      {isUnderMaintenance && (
        <div className="absolute p-[5rem] text-center text-[1.4rem] z-[20005] top-0 left-0 w-full h-full bg-black text-white font-bold flex items-center justify-center">
          <div>
            <div className="mb-[2rem]">{isUnderMaintenance.message}</div>
            <img
              src={Logo}
              alt="logo"
              className="inverted-[1]"
              style={{
                // width: "90%",
                maxWidth: "150px",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NetworkStatusListener;
