import { confirmable, createConfirmation } from "react-confirm";
import { createPortal, createRoot } from "react-dom/client";
import { IoMdClose } from "react-icons/io";

let modalResult;
const styles = {
  modalContainer: {
    width: 400,
    borderRadius: "4px",
    padding: "30px",
    fontSize: "1.5rem",
    textAlign: "center",
    backgroundColor: "var(--inyc-primary)",
  },
};

//#region modal setup
const modalStyle = "position:fixed;top:0;left:0;width:100%;min-height:100%;background-color:rgba(0,0,0,0.2);display:none;justify-content:center;align-items:center;z-index:10000";
const modal = document.createElement("div");
modal.setAttribute("id", "modal");
modal.setAttribute("style", modalStyle);
document.body.appendChild(modal);
//#endregion

const waitForResult = async () => {
  if (modalResult !== undefined) {
    return modalResult;
  } else {
    return await new Promise((resolve, reject) => {
      setTimeout(async () => {
        resolve(await waitForResult());
      }, 30);
    });
  }
};

const onModalResult = (result) => {
  modalResult = result;
};

export const createModal = async (element, options = {}) => {
  modalResult = undefined;
  const modalRoot = createRoot(modal);
  modal.style.display = "flex";
  modalRoot.render(<Modal style={options.style}>{element({ ...options, onModalResult })}</Modal>);
  const result = await waitForResult();

  modalRoot.unmount();
  modal.style.display = "none";
  return result;
};

const Modal = ({ children, style }) => {
  return <div style={{ ...styles.modalContainer, ...(style || {}) }}>{children}</div>;
};

export const createmodal = (Component) => {
  return createConfirmation(confirmable(Component))();
};

export const createpopup = (Component, options = {}) => {
  return createConfirmation(
    confirmable((props) => (
      <>
        {props.show && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              minHeight: "100%",
              // backgroundColor: "rgba(0,0,0,0.95)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "2rem",
              alignItems: "center",
              zIndex: 10000,
            }}
          >
            <div
              style={{
                backgroundColor: "var(--inyc-primary)",
                padding: "2rem",
                borderRadius: "10px",
                fontWeight: "bold",
                fontSize: "1.5rem",
                maxWidth: "90%",
                textAlign: "center",
                color: "var(--inyc-primary-text-color)",
              }}
            >
              {Component(props)}
              {options.hideCloseButton ? null : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <IoMdClose
                    color="var(--inyc-secondary-text-color)"
                    style={{
                      cursor: "pointer",
                    }}
                    size={30}
                    onClick={() => {
                      props.proceed(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    ))
  )();
};
