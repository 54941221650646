import React from "react";
import {BiSolidErrorCircle} from "react-icons/bi";

export const ErrorMessage = (props) => {
  const { children,className,...rest } = props;
  return <div {...rest} className={`${className} pt-[0.5rem] text-red-500 text-[1.2rem]`} >
    <BiSolidErrorCircle className="inline-block translate-y-[5%] mr-[0.5rem] mb-1" />
  {children}</div>;
};

const InputError = ({ errors, names }) => {

  // Filter out all errors that match any of the names
  const relevantErrors = errors?.filter((error) => names.includes(error.input)) || [];

  // If there are relevant errors, map through them and display each one
  if (relevantErrors.length > 0) {
    return (
      <div className="my-[5px] ">
        {relevantErrors.map((err, i) => (
          <ErrorMessage key={i}>{err.message}</ErrorMessage>
        ))}
      </div>
    );
  }

  // If there are no relevant errors, return null
  return null;
};

export default InputError;