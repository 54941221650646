import React, { forwardRef, useEffect, useState } from "react";
import Loader from "../components/icons/Loader.jsx";
import { usePortalContext } from "../context/PortalContext/portalcontext.jsx";
import { useSearchParams } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";

//#region COMPONENTS
const Tabs = ({ children }) => {
  return <div className="w-full min-h-[calc(100vh-300px)]">{children}</div>;
};

//#endregion
export const PortalTitle = (props) => {
  const {
    moreOptionsOpen,
    setMoreOptionsOpen,
    handleMoreOptionsClick,
    tabsList,
    handlePortalClick,
  } = usePortalContext();

  return (
    <div
      className={`mb-[1rem] ml-[0.5rem] pb-[1rem] border-b-[1px] border-[var(--inyc-input-border-color)] w-full  ${props.className} `}
    >
      <div
        {...props}
        className={`text-[2rem] flex items-center justify-between font-['Primary-Bold'] text-start `}
      >
        {props.children}
        <div className={`cursor-pointer rounded-[3rem] px-[1rem] py-[0.7rem]`}>
          <HiOutlineDotsVertical
            onClick={() => {
              setMoreOptionsOpen(!moreOptionsOpen);
              handleMoreOptionsClick && handleMoreOptionsClick();
            }}
            size={20}
            color="var(--inyc-primary-text-color)"
          />
        </div>
      </div>
      <div
        className={`hidden md:grid
        overflow-hidden duration-300
        ${moreOptionsOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
      >
        <div className="min-h-0">
          {tabsList.map((tb) => (
           <div
           onClick={() => {
             handlePortalClick(tb.index);
             setMoreOptionsOpen(false);
             proceed();
           }}
           className="hover:bg-[--inyc-primary-light]  w-full py-[1rem] px-[1.5rem] cursor-pointer duration-200 flex justify-between items-center gap-[1rem]"
         >
           <div className="font-['Primary-Medium'] text-[1.1rem]">
             {tb.label}
           </div>
           <FaChevronDown
             size={10}
             color="var(--inyc-primary-text-color-light)"
             className="rotate-[-90deg]"
           />
         </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Portal = () => {
  // const { user } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    hasPermission,
    locationLoading,
    currentTab,
    setCurrentTab,
    tabsList,
    userLocation,
  } = usePortalContext();

  useEffect(() => {
    if (searchParams.get("tab")) {
      setCurrentTab(searchParams.get("tab"));
    } else if (hasPermission) {
      const firstTab = tabsList.find(
        (tb) => hasPermission[tb.permission] === true
      );
      if (firstTab) {
        setCurrentTab(firstTab.index);
      }
    }
  }, [searchParams, hasPermission]);

  if (locationLoading)
    return (
      <div className="min-h-[100vh] flex justify-center items-center w-full bg-[var(--inyc-primary)]">
        <Loader />
      </div>
    );

  const hasTabPermission = (perm, tab) => {
    if (hasPermission) {
      return hasPermission[perm] && tab.element({ key: tab.index });
    }
    return false;
  };

  return (
    <div style={styles.portalContainer}>
      {hasPermission === null ? (
        <div style={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {hasTabPermission(
            tabsList.find((tb) => tb.index === currentTab).permission,
            tabsList.find((tb) => tb.index === currentTab)
          ) ? (
            <div className="w-full relative min-h-[calc(100vh-110px)] bg-[var(--inyc-primary)] pb-[var(--inyc-bottom-navbar-height)]">
              <Tabs selected={currentTab}>
                {tabsList.map((tb) =>
                  currentTab === tb.index ? (
                    <>
                      {hasPermission[tb.permission] && (
                        <div index={tb.index}>
                          {userLocation && tb.element({ key: tb.index })}
                        </div>
                      )}
                    </>
                  ) : null
                )}
              </Tabs>
            </div>
          ) : (
            <div className="min-w-full flex flex-col justify-start items-center">
              <PortalTitle className="mt-[2rem] px-[2rem]">
                {tabsList.find((tb) => tb.index === currentTab).label}
              </PortalTitle>
              <div className="mt-[5rem] px-[2rem] text-[1.2rem] max-w-[500px] text-center flex-1 flex justify-center items-start">
                You don't have permission to see this page. please contact us if
                you're having issues.
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  portalContainer: {
    // paddingTop: 50,
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    maxWidth: "800px",
    display: "flex",

    margin: "auto",
    justifyContent: "center",
    // alignItems: "center",
  },
  portalContent: {
    width: "100%",
    minHeight: "calc(100vh - 110px)",
    // background: "radial-gradient(rgba(0,0,0,0.01),rgba(0,0,0,0.02))",
    paddingTop: 0,
    // paddingBottom: "50px",
  },
  portalTabs: {
    width: "100%",
    // minHeight: "60px",
    // paddingTop: "60px",
    paddingBottom: "20px",
    backgroundColor: "var(--inyc-primary)",
    // boxShadow: "0 0 15px rgba(0,0,0,0.05)",
    // boxShadow: "0 0 3px rgba(0,0,0,0.05)",
    borderBottom: "2px solid rgba(0,0,0,0.05)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  TabPagesContainer: {
    width: "100%",
    minHeight: "calc(100vh - 300px)",
  },
};

export default Portal;
