import React from "react";
import { getSocialOption } from "../../util/styleUtils";
import SocialButton from "../SocialButton/SocialButton";

const SocialsView = ({ socials }) => {
  const processedSocials = [...(socials || [])]?.map((s) => {
    // Get the icon and label based on the rules
    const { icon, rawIcon, id: label } = getSocialOption(s?.link);

    s.meta = {
      icon,
      rawIcon,
      label,
    };

    return s;
  });
  return (
    <div className={`relative max-w-full overflow-x-auto`}>
      {processedSocials && processedSocials?.length > 0 && (
        <>
          <div className="flex justify-start items-center gap-[1rem] mt-[1rem] ">
            {processedSocials
              ?.filter((s) => {
                //filter the socials that dont exist on socialOptions
                return (
                  !s.platform ||
                  s.platform === "" ||
                  s.platform === s?.meta?.label
                );
              })
              ?.map((social, i) => {
                return (
                  <SocialButton
                    btnClassName={
                      "!border-[--inyc-details-border-color] hover:!bg-[--inyc-secondary] !bg-[--inyc-details-btn-bg]"
                    }
                    className={"flex-1"}
                    key={i}
                    social={social}
                  />
                );
              })}
          </div>

          <div className="flex flex-col justify-center items-center gap-[1rem] mt-[1rem]">
            {processedSocials
              ?.filter((s) => {
                //filter the socials that do exist on socialOptions
                return (
                  s.platform &&
                  s.platform !== "" &&
                  s.platform !== s?.meta?.label
                );
              })
              ?.map((social, i) => {
                return (
                  <SocialButton
                    btnClassName={
                      "!border-[--inyc-details-border-color] hover:!bg-[--inyc-secondary] !bg-[--inyc-details-btn-bg]"
                    }
                    className={"w-full"}
                    key={i}
                    social={social}
                  />
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default SocialsView;
