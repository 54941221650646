import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosLink, IoMdClose } from "react-icons/io";
import ProfilePicture from "../icons/ProfilePicture";
import Loader from "../icons/Loader";
import { TbPhotoShare, TbShare3 } from "react-icons/tb";
import { useUser } from "../../context/UserContext/UserProvider";
import { useData } from "../../context/DataContext/DataProvider";
import { enqueueSnackbar } from "notistack";
import axios from "../../api/axiosInstance";
import { FiSearch } from "react-icons/fi";
import { BottomSheetDateFilter } from "../Map/Map";
import Input from "../Input/Input";
import InfiniteScroller from "../InfiniteScroller/InfiniteScroller";
import EventCard from "../EventCard/EventCard";
import { useAccountDetails } from "../../context/AccountDetailsContext/AccountDetailsProvider";
import { useEventDetails } from "../../context/EventDetailsProvider/EventDetailsProvider";
import { backendUrl } from "../../api/constants";
import { Share } from "@capacitor/share";
import { Instagram } from "capacitor-instagram-share";
import { modal } from "../../util/useModal";
import Tabs, { TabContent } from "../ui/tabs";
import { QRCode } from "react-qrcode-logo";
import { useUIContext } from "../../context/UIContext/UIContext";
import { IoCopyOutline } from "react-icons/io5";
import { MdQrCode2 } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import SocialsView from "../SocialsInput/SocialsView";
import { FaEye, FaRegEye } from "react-icons/fa6";

const AccountDetails = ({ parentRef, Close }) => {
  const [scrolled, setScrolled] = useState(false);
  const { user } = useUser();

  const { availableApps } = useUIContext();
  const { toggleFollowUser } = useData();
  const {
    selectedAccount,
    queryFilter,
    setQueryFilter,
    dateFilter,
    setDateFilter,
    tagFilter,
    setTagFilter,
    timezone,
    accountDetailsSearchLoading,
    filteredEvents,
    filtersActive,
    setSelectedAccount,
  } = useAccountDetails();
  const { selectEvent } = useEventDetails();
  const account = selectedAccount;

  const shareAccount = async () => {
    const result = await modal(
      ({ proceed }) => {
        // const [load, setLoad] = useState(false);
        const [tab, setTab] = useState("image");
        const fullimageref = useRef(null);
        const imgref = useRef(null);
        const stickerRef = useRef(null);
        const qrcoderef = useRef(null);

        //#region handlers
        const getAssetFile = async (element, url) => {
          let data;
          if (url) {
            data = url;
          } else {
            const canvas = await html2canvas(element, {
              useCORS: true,
              backgroundColor: null,
            });
            const imageData = canvas.toDataURL("image/png");
            canvas.remove();
            data = imageData;
          }
          const filename = `${Date.now()}.png`;
          await Filesystem.writeFile({
            path: filename,
            data: data,
            directory: Directory.Cache,
          });
          const uriResult = await Filesystem.getUri({
            directory: Directory.Cache,
            path: filename,
          });
          return { url: uriResult.uri, data: data };
        };

        const shareImage = async () => {
          const log = startLog({
            user: user,
            event: "share image",
          });
          try {
            setTab("image");
            log.log("tab set to image");
            const { url: uri, data: base64img } = await getAssetFile(
              fullimageref.current
            );
            log.log("using capacitor share");
            await Share.share({
              files: [uri],
              url: `https://app.inyc.us/#/?account=${selectedAccount?._id}`,
              title: `Check out this ${
                selectedAccount?.accountType || "user"
              } on in—yc`,
            });

            log.log("[Success] shared");
          } catch (error) {
            log.log("[Error] ", error);
            console.error(error);
          } finally {
            // updateShare();
            log.display();
          }
        };

        const copyLink = async () => {
          setTab("image");
          const shareContent = `https://app.inyc.us/#/?account=${selectedAccount?._id}`;

          navigator.clipboard
            .writeText(shareContent)
            .then(() => {
              enqueueSnackbar("link copied to clipboard.");
            })
            .catch((error) => {
              enqueueSnackbar("error copying content to clipboard.", {
                variant: "error",
              });
            });
          // updateShare();
        };

        const shareLink = async () => {
          const shareContent = `https://app.inyc.us/#/?account=${selectedAccount?._id}`;
          if (navigator.share) {
            navigator.share({
              url: shareContent,
            });
          } else {
            await Share.share({
              url: shareContent,
            });
          }
          // updateShare();
        };

        const shareInstagramStory = async () => {
          const log = startLog({
            user: user,
            event: "open instagram",
          });
          const result = await modal(({ proceed }) => {
            return (
              <div>
                <div className="text-center text-[var(--inyc-primary-text-color)] mb-[2rem]">
                  account link has automatically been copied to your clipboard.
                  paste the copied link to the <strong>"link"</strong> sticker
                  in instagram to bring your followers directly to the account
                  on <span className="font-['Primary-Bold']">in-yc</span>
                </div>
                <div className="flex items-center justify-center gap-[1.5rem]">
                  <Button onClick={() => proceed(true)}>share</Button>
                </div>
              </div>
            );
          });
          if (result) {
            try {
              try {
                const { url: stickerUri } = await getAssetFile(
                  stickerRef.current
                );
                const shareUrl = `https://app.inyc.us/#/?account=${selectedAccount?._id}`;
                await Clipboard.write({
                  string: shareUrl,
                });
                await Instagram.shareToInstagramStory({
                  appID: FACEBOOK_APP_ID,
                  stickerImage: stickerUri,
                  shareUrl: shareUrl,
                });
                log.log("opened instagram");
              } catch (err) {
                log.log("[Error] error opening instagram", JSON.stringify(err));
                log.log("[Error] ", err.message);
              }
            } catch (error) {
              log.log("[Error] ", error.message);
            } finally {
              log.display();
              // updateShare();
            }
          }
        };

        const shareQrCode = async () => {
          setTab("qr");
          const { url: qrUri } = await getAssetFile(qrcoderef.current);
          await Share.share({
            files: [qrUri],
            title: `Check out this ${
              selectedAccount?.accountType || "user"
            } on in—yc`,
          });
          // updateShare();
        };
        //#endregion

        return (
          <div className="min-h-[100vh] flex flex-col justify-between items-center">
            <div className="flex w-full justify-center items-center flex-1">
              <Tabs
                staticHeight
                contentWrapperClass="items-center"
                className="w-full"
                tabs={["image", "qr"]}
                hideTabs
                currentTab={tab}
                setCurrentTab={setTab}
              >
                <TabContent name="image">
                  <div
                    ref={fullimageref}
                    className="relative overflow-hidden h-[590px] flex items-center justify-center w-[350px] mx-auto mb-[1rem] rounded-[1rem]"
                  >
                    <div
                      ref={imgref}
                      className={`absolute top-0 left-0 w-full h-full `}
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `linear-gradient(to bottom, #323232, #1A1A1A)`,
                      }}
                    ></div>
                    <div
                      ref={stickerRef}
                      className={`relative z-[1] text-start duration-300 p-[1rem] rounded-[0.5rem] min-w-[200px] max-w-[70%] bg-[var(--inyc-secondary)]/50`}
                    >
                      <div className="mb-[20px]">
                        <ProfilePicture
                          profilePicture={selectedAccount?.profilePicture}
                          username={
                            selectedAccount?.firstName?.charAt(0) +
                            selectedAccount?.lastName?.charAt(0)
                          }
                          style={{
                            margin: "0 auto",
                            marginBottom: "1rem",
                            width: "50px",
                            height: "50px",
                            border: "1px solid var(--inyc-input-border-color)",
                          }}
                        />
                        <div
                          className="text-center mb-[-1rem]"
                        >
                          <div className="text-white text-center break-words text-[1.2rem] font-['Primary-Bold'] mb-[5px]">
                            {selectedAccount?.businessName ||
                              selectedAccount?.firstName +
                                " " +
                                selectedAccount?.lastName}
                          </div>
                          {selectedAccount?.businessName && (
                            <div className="text-white/80 text-[1rem] mb-[5px] font-['Primary-Medium']">
                              {selectedAccount?.firstName +
                                " " +
                                selectedAccount?.lastName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="gap-[5px] flex items-center justify-center text-white">
                        <div className="text-[0.9rem] relative ">
                          check them out on
                        </div>
                        <img
                          className="h-[15px]"
                          src={`${backendUrl}/icon_white_nobg.png`}
                        />
                      </div>
                    </div>
                  </div>
                </TabContent>
                <TabContent
                  name="qr"
                  className="flex items-center justify-center"
                >
                  <div ref={qrcoderef}
                    className="rounded-[var(--inyc-input-border-radius)] overflow-hidden"
                  >
                    <QRCode
                      qrStyle="fluid"
                      eyeRadius={10}
                      value={`https://app.inyc.us/#/?account=${selectedAccount?._id}`}
                      size={200}
                    />
                  </div>
                </TabContent>
              </Tabs>
            </div>
            <div
              className={`relative duration-300 w-full md:w-fit md:min-w-[300px] pt-[2rem] pb-[4rem] px-[3rem] rounded-t-[2rem] border-[1px] border-[--inyc-input-border-color] bg-[var(--inyc-primary)] shadow-md`}
            >
              <div className="flex flex-wrap justify-center items-stretch  gap-[0.5rem] mb-[1rem]">
                {(availableApps?.native || navigator.share) && (
                  <div
                    onClick={shareLink}
                    className="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                  >
                    <IoIosLink size={30} />
                    <div className="leading-[1rem] btn-text">share link</div>
                  </div>
                )}
                {availableApps?.instagram && (
                  <div
                    onClick={shareInstagramStory}
                    className="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                  >
                    <FaInstagram size={30} />
                    <div className="leading-[1rem] btn-text">post story</div>
                  </div>
                )}
                {availableApps?.native && (
                  <div
                    onClick={shareImage}
                    className="relative duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                  >
                    <TbPhotoShare size={30} />
                    <div className="leading-[1rem] btn-text">share image</div>
                  </div>
                )}
                <div
                  onClick={copyLink}
                  className="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                >
                  <IoCopyOutline size={30} />
                  <div className="leading-[1rem] btn-text">copy link</div>
                </div>
                <div
                  onClick={() => {
                    setTab("qr");
                  }}
                  className="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                >
                  <MdQrCode2 size={30} />
                  <div className="leading-[1rem] btn-text">QR code</div>
                </div>
                {availableApps?.native && (
                  <div
                    onClick={shareQrCode}
                    className="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                  >
                    <TbShare3 size={30} />
                    <div className="leading-[1rem] btn-text">share QR code</div>
                  </div>
                )}
              </div>
              <div
                onClick={() => proceed(false)}
                className="absolute top-[0.5rem] right-[0.5rem] duration-300 hover:bg-black/5 flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
              >
                <IoMdClose size={30} />
              </div>
            </div>
          </div>
        );
      },
      {
        raw: true,
        fullScreen: true,
      }
    );
  };

  const toggleFollow = async () => {
    toggleFollowUser(account?._id);

    axios
      .get(`/user/follow/${account?._id}`)
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });

          toggleFollowUser(account?._id);
        }
      })
      .catch((err) => {
        console.log(err);

        toggleFollowUser(account?._id);
      });
  };

  useEffect(() => {
    if (parentRef?.current) {
      const handleScroll = () => {
        if (parentRef.current.scrollTop > 10) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
      parentRef.current?.addEventListener("scroll", handleScroll);
      return () => {
        parentRef.current?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [parentRef]);

  return (
    <div>
      <div className="sticky top-[0.5rem] z-[1000] left-[0rem] translate-x-[-1rem]">
        <div
          style={{
            transition: "0.3s",
            margin: "1rem 1.5rem 2rem 1.5rem",
            width: "50px",
            height: "50px",
            backgroundColor: scrolled
              ? "var(--inyc-secondary)"
              : "var(--inyc-primary)",
            borderRadius: "50%",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
          onClick={()=>{
            setSelectedAccount(null)
            Close && Close()
          }}
        >
          <IoIosArrowBack
            size={25}
            className={
              scrolled
                ? "text-[var(--inyc-secondary-text-color)]"
                : "text-[var(--inyc-primary-text-color)]"
            }
          />
        </div>
      </div>
      {account ? (
        <div className="px-[1.5rem]"
        style={{
          "--inyc-details-border-color": 'var(--inyc-input-border-color)',
        }}
        >
          <ProfilePicture
            className={"text-[2rem]"}
            profilePicture={account?.profilePicture}
            username={
              account?.firstName?.charAt(0) + account?.lastName?.charAt(0)
            }
            style={{
              width: "150px",
              height: "150px",
              border: "1px solid var(--inyc-input-border-color)",
              margin: "0 auto 2rem auto",
            }}
          />
          <div className="text-[3rem] font-['Primary-Bold']">
            {account?.businessName ||
              account?.firstName + " " + account?.lastName}
          </div>
          <div className="text-[1.3rem] font-['Primary-Regular'] text-[var(--inyc-primary-text-color-light)] mb-[1rem]">
            {account?.businessName
              ? account?.firstName + " " + account?.lastName
              : ""}
          </div>
          <div className="mb-[1rem] text-[1.3rem] font-bold">
            {account?.bio || "no bio"}
          </div>
          <div className="flex mb-[1.5rem] gap-[1rem] justify-between items-center">
            <div 
            onClick={toggleFollow}
            className="event-details-button flex-1">
              <div
                className="icon engage-icon"
                style={{ cursor: "pointer", zIndex: 1001 }}
              >
                <span className="tooltip whitespace-nowrap">
                  {user?.following?.includes(account?._id) ? "watching this artist!" : "watch"}
                </span>
                <div>
                  <div className="flex-col">
                    {user?.following?.includes(account?._id) ? (
                      <FaEye className="follow-icon" size={20} />
                    ) : (
                      <FaRegEye className="follow-icon" size={20} />
                    )}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="event-details-button flex-1">
              <div
                className="icon engage-icon"
                onClick={shareAccount}
                style={{ cursor: "pointer", zIndex: 1001 }}
              >
                {/* // share event */}
                <span className="tooltip">share</span>
                <div className="flex-col">
                  <TbShare3 size={20} color="var(--inyc-secondary)" />
                </div>
              </div>
            </div>
          </div>
          {account.socials && (
            <div className="mb-[1rem]">
              <SocialsView socials={account.socials} />
            </div>
          )}
          {/* //TODO make it disabled for non posting accounts */}
          {true && (
            <div className="mb-[5rem]">
              <>
                {/* <div className="map-events-title !mt-[0] !mb-[1rem]">events</div> */}
                <Input
                  placeHolder="search for this artist's events"
                  onChange={(e) => setQueryFilter(e.target.value)}
                  value={queryFilter}
                  icon={<FiSearch size={25} />}
                  className={"!mt-[0.5rem] md:mb-[2rem] h-[3.5rem] max-w-[98%] mx-auto"}
                />
                <BottomSheetDateFilter
                tagFilter={tagFilter}
                setTagFilter={setTagFilter}
                  filterDate={dateFilter}
                  setFilterDate={setDateFilter}
                  className={"!relative mb-[-1rem] md:!min-w-full md:!w-full"}
                />
                {accountDetailsSearchLoading && (
                  <div className="fixed left-[50%] translate-x-[-50%] p-[5rem] ">
                    <Loader />
                  </div>
                )}
                {filteredEvents?.length > 0 ? (
                  <>
                    <InfiniteScroller
                      className={
                        accountDetailsSearchLoading ? "opacity-20" : ""
                      }
                      items={[
                        ...(filtersActive
                          ? filteredEvents?.map((e) => {
                              e._filterEvent = true;
                              return e;
                            }) || []
                          : []),
                        ...(filtersActive
                          ? filteredEvents?.filter(
                              (l) =>
                                !filteredEvents?.some((fl) => fl._id === l._id)
                            )
                          : filteredEvents || []),
                      ]}
                      renderer={(item, index) => {
                        return (
                          <EventCard
                            key={index}
                            disabled={filtersActive && !item._filterEvent}
                            data={{ ...item, timezone: item.timezone || timezone }}
                            className={`map-event-container p-0!`}
                            onClick={() => {
                              selectEvent(item, item._matchingEvent);
                              setSelectedAccount(null);
                            }}
                          />
                        );
                      }}
                    />
                  </>
                ) : (
                  <div className="map-events-none">no upcoming events</div>
                )}
              </>
            </div>
          )}
          {/* {JSON.stringify(account, null, 2)} */}
        </div>
      ) : (
        <div className="py-[2rem]">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default AccountDetails;
