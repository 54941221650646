import { Geolocation } from "@capacitor/geolocation";
import { DateTime } from "luxon";

export const sortEventsByDate = (events) => {
  const list = [...events] || [];
  return list.sort((a, b) => {
    // if eventDate is an object get the start date
    const aStart =
      typeof a.eventDate === "object" ? a.eventDate.start : a.eventDate;
    const bStart =
      typeof b.eventDate === "object" ? b.eventDate.start : b.eventDate;

    return new Date(aStart).valueOf() - new Date(bStart).valueOf();
  });
};

export const getCurrentLocation = async (opts = {}) => {
  opts.setLoading && opts.setLoading(true);
  let location = await Geolocation.getCurrentPosition({
    enableHighAccuracy: true,
    ...(opts?.options || {}),
  })
    .then((position) => {
      // make it update position every 1 second using setlocation
      if (opts.setLoading) {
        opts.setLoading(false);
      }
      return position.coords;
    })
    .catch((error) => {
      if (opts.setLoading) {
        opts.setLoading(false);
      }
      if (opts.default) {
        return opts.default;
      }
      console.error(error);
    });

  if (!location) {
    return;
  }

  if (opts.setLocation) {
    opts.setLocation({
      lat: location.latitude,
      lng: location.longitude,
    });
  }

  return location;
};

export const formatPosterAccount = (account, color = "var(--inyc-primary-text-color)",onClick) => {
  let formatedAccountName = "";
  let formattedAccountUsername = ``;
  if (account.accountType === "business") {
    formattedAccountUsername +=
      (account.businessName || account.firstName + " " + account.lastName);
    formatedAccountName = "business"
  } else if (account.accountType === "artist") {
    formattedAccountUsername +=
      account.firstName + " " + account.lastName;
    formatedAccountName = "artist: ";
  } else {
    formattedAccountUsername +=
      account.firstName + " " + account.lastName;
    formatedAccountName += ""
  }
  if(onClick) {
    return <>
    by {formatedAccountName} <span 
    className="cursor-pointer duration-300 hover:!text-[var(--inyc-primary-text-color)]"
    onClick={onClick} style={{color:color,

      fontFamily: 'Primary-Regular'
    }}>{formattedAccountUsername}</span>
    </>
  }
  return "by" + formatedAccountName;
};

export const defaultDateFilter = (timezone, opts = {}) => {
  // console.log("timezone ", timezone);
  const date = {
    start: DateTime.now()
      .setZone(timezone)
      .startOf("day")
      .setZone("local", {
        keepLocalTime: true,
      })
      .toJSDate(),
    end: DateTime.now()
      .plus(
        opts.endPlus || {
          days: 2,
        }
      )
      .setZone(timezone)
      .startOf("day")
      .setZone("local", {
        keepLocalTime: true,
      })
      .toJSDate(),
  };

  // console.log("date ", date);

  return date;
};
