import React, { useEffect, useRef } from "react";
import { MapContainer, useMap, useMapEvents } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import MapMarker from "../Map/MapMarker";
import eventMarker from "../../assets/map/bar-marker-selected.png";
import MapTiles from "../MapTiles/MapTiles";
import { useMediaQuery } from "../../util/useMediaQuery";
import { createIconFromImage } from "../../util/mapStyles";

// make new leaflet element
const Search = (props) => {
  const map = useMap(); // access to leaflet map
  const { provider } = props;

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider,
      showMarker: false,
    });

    const onResults = (data) => {
      props.onSearchResultSelected && props.onSearchResultSelected(data);
    };

    map.on("geosearch/showlocation", onResults);

    map.addControl(searchControl); // this is how you add a control in vanilla leaflet
    return () => {
      map.removeControl(searchControl);
      map.off("geosearch/showlocation", onResults);
    };
  }, [props]);

  return null; // don't want anything to show up from this comp
};

const MapClick = ({ onClick }) => {
  const map = useMapEvents({
    click: onClick,
  });
  return null;
};

const DashboardMap = ({
  searchBar = false,
  mobileWidth,
  userLocation,
  markers,
  style = {},
  className,
  onMapClick,
  Ref,
  onSearchResultSelected,
}) => {
  const notMobile = useMediaQuery("sm");
  const mapRef = useRef();
  const ignoreNextMapClickRef = useRef(false);

  const handleClick = (arg) => {
    console.log(">>>>>>>> ignoreNextMapClickRef", ignoreNextMapClickRef.current);
    if (ignoreNextMapClickRef.current) {
      ignoreNextMapClickRef.current = false;
      return;
    }
    onMapClick && onMapClick(arg.latlng);
  };

  useEffect(() => {
    if (mapRef.current) {
      if (markers && markers[0] && markers[0].lat && markers[0].lng) {
        mapRef.current.setView([markers[0].lat, markers[0].lng], 13);
      } else if (userLocation) {
        mapRef.current.setView([userLocation.lat, userLocation.lng], 13);
      }
    }
  }, [markers]);

  return (
    <MapContainer
      className={className}
      whenReady={(map) => {
        mapRef.current = map.target;
        if (Ref) {
          Ref.current = map.target;
        }
      }}
      attributionControl={false}
      center={
        //center on first marker if it exists
        markers && markers[0]
          ? [markers[0].lat, markers[0].lng]
          : //center on user location if it exists
          userLocation
          ? [userLocation.lat, userLocation.lng]
          : //center on default location
            [30, 30]
      }
      style={{
        ...styles.mapContainer,
        ...style,
        ...(mobileWidth && !notMobile ? { width: mobileWidth } : {}),
      }}
      zoom={13}
      tap={handleClick}
      zoomControl={false}
      minZoom={10}
      maxBounds={[
        [-90, -180], // southwest corner of the world
        [90, 180], // northeast corner of the world
      ]}
    >
      <MapTiles />
      <MapClick onClick={handleClick} />
      {markers &&
        markers.map((marker, i) => {
          if (
            !marker?.lat ||
            isNaN(marker.lat) ||
            !marker.lng ||
            isNaN(marker.lng)
          )
            return null;

          return (
            <MapMarker
              {...marker?.props || {}}
              key={i}
              zIndexOffset={marker.zIndexOffset}
              icon={createIconFromImage(
                marker.icon ? marker.icon : eventMarker,
                [20, 20],
                [10, 19]
              )}
              onClick={() => {
                marker.onClick && marker.onClick(marker.venue);
              }}
              position={[marker.lat, marker.lng]}
              data={marker}
              noHover={!marker.onClick}
              ignoreNextMapClickRef={ignoreNextMapClickRef}
            />
          );
        })}
      {searchBar && (
        <Search
          onSearchResultSelected={onSearchResultSelected}
          provider={new OpenStreetMapProvider()}
        />
      )}
    </MapContainer>
  );
};

const styles = {
  mapContainer: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    minHeight: "100%",
    position: "relative",
  },
};

export default DashboardMap;
