import {
  convertDatesToLocalTimezones,
  convertEventDate,
} from "../util/eventUtils.js";
import axiosInstance from "./axiosInstance.js";
import { backendUrl } from "./constants";
import { distance } from "../util/eventUtils.js";

export const getVenues = async (location) => {
  try {
    if (!location) return null;
    const { data } = await axiosInstance.get(
      `${backendUrl}/venues/get?lng=${location.lng}&lat=${location.lat}`
    );
    if (data.error) {
      console.log(data.error);
      return null;
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getEventById = async (eventId, opts = {}) => {
  try {
    if (!eventId) return null;
    const url = `${backendUrl}/events/${eventId}${
      opts?.includeEvents ? "?includeEvents=true" : ""
    }${
      opts.referenceDate
        ? `${opts?.includeEvents ? "&" : "?"}referenceDate=${
            opts.referenceDate
          }`
        : ""
    }${
      // add handling for excludePastEvents query
      opts.excludePastEvents
        ? `${
            opts?.includeEvents || opts.referenceDate ? "&" : "?"
          }excludePastEvents=true`
        : ""
    }
    `;
    console.log("url : ", url);
    const { data } = await axiosInstance.get(
      `${backendUrl}/events/location/${eventId}${
        opts?.includeEvents ? "?includeEvents=true" : ""
      }${
        opts.referenceDate
          ? `${opts?.includeEvents ? "&" : "?"}referenceDate=${
              opts.referenceDate
            }`
          : ""
      }${
        // add handling for excludePastEvents query
        opts.excludePastEvents
          ? `${
              opts?.includeEvents || opts.referenceDate ? "&" : "?"
            }excludePastEvents=true`
          : ""
      }
      `
    );
    if (data.error) {
      console.log(data.error);
      return null;
    } else {
      const event = data.data;

      console.log("eventbyId : ", event);
      if (opts.userLocation?.lat) {
        event.distance = `${distance(
          event.location.coordinates[1],
          event.location.coordinates[0],
          opts.userLocation.lat,
          opts.userLocation.lng
        ).toFixed(2)} miles`;
      }
      if (event.type === "event") {
        convertEventDate(event);
      } else {
        if (event.events) {
          event.events = convertDatesToLocalTimezones(event.events);
        }
        if (event._matchingEvent) {
          convertEventDate(event._matchingEvent);
        }
      }
      if (event.name === "test event split to 8am-9am and 5pm-6pm everyday") {
        console.log(event);
      }

      return event;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getLocation = async (location, userLocation) => {
  try {
    if (!location) return null;

    const { data } = await axiosInstance.get(
      `${backendUrl}/user/location/${location}`
    );
    if (data.error) {
      console.log(data.error);
      return null;
    } else {
      data.location.distance = `${distance(
        data.location.location.coordinates[1],
        data.location.location.coordinates[0],
        userLocation.lat,
        userLocation.lng
      ).toFixed(2)} miles`;

      return {
        location: convertDatesToLocalTimezones([data.location])[0],
        expanded: data.expanded
          ? convertDatesToLocalTimezones([data.expanded])[0]
          : null,
      };
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMyLocation = async (location) => {
  try {
    if (!location) return null;
    const { data } = await axiosInstance.get(
      `${backendUrl}/influencer/events/${location}`
    );
    if (data.error) {
      console.log(data.error);
      return null;
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAccountById = async (accountId) => {
  try {
    if (!accountId) return null;
    const { data } = await axiosInstance.get(`${backendUrl}/user/${accountId}`);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    console.log("fetched account details ", data.account);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

    if (data.error) {
      console.log(data.error);
      return null;
    } else {
      return data.account;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
