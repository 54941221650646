import { AppLauncher } from "@capacitor/app-launcher";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { useUIContext } from "./UIContext";
import "./UIContextCss.css";

export const checkInstagram = async () => {
  let instagramScheme = "com.instagram.android";
  if (Capacitor.getPlatform() === "ios") {
    instagramScheme = "instagram://";
  }
  const canOpen = await AppLauncher.canOpenUrl({ url: instagramScheme });
  return canOpen.value && Capacitor.isNativePlatform();
};

export const checkWhatsapp = async () => {
  const whatsappScheme = "com.whatsapp";
  const canOpen = await AppLauncher.canOpenUrl({ url: whatsappScheme });
  return canOpen.value && Capacitor.isNativePlatform();
};

export const checkNativeShare = async () => {
  return (await Share.canShare()).value;
};

export const DarkModeButton = () => {
  const { darkMode, setDarkMode } = useUIContext();
  return (
    <div className="toggle-switch cursor-pointer w-[30px] h-[30px]">
      <label
        for="themeToggle"
        class="themeToggle st-sunMoonThemeToggleBtn"
        type="checkbox"
      >
        <input 
          checked={darkMode}
          onChange={() => setDarkMode(!darkMode)}
        type="checkbox" id="themeToggle" class="themeToggleInput" />
        <svg
          width="18"
          height="18"
          viewBox="0 0 20 20"
          fill="currentColor"
          stroke="none"
        >
          <mask id="moon-mask">
            <rect x="0" y="0" width="20" height="20" fill="white"></rect>
            <circle cx="11" cy="3" r="8" fill="black"></circle>
          </mask>
          <circle
            class="sunMoon"
            cx="10"
            cy="10"
            r="8"
            mask="url(#moon-mask)"
          ></circle>
          <g>
            <circle class="sunRay sunRay1" cx="18" cy="10" r="1.5"></circle>
            <circle class="sunRay sunRay2" cx="14" cy="16.928" r="1.5"></circle>
            <circle class="sunRay sunRay3" cx="6" cy="16.928" r="1.5"></circle>
            <circle class="sunRay sunRay4" cx="2" cy="10" r="1.5"></circle>
            <circle class="sunRay sunRay5" cx="6" cy="3.1718" r="1.5"></circle>
            <circle class="sunRay sunRay6" cx="14" cy="3.1718" r="1.5"></circle>
          </g>
        </svg>
      </label>
    </div>
  );
};
