import React, { useRef, useState, useEffect } from "react";
import { MapContainer, Marker, useMap } from "react-leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import "./Map.css";
import MapMarker from "./MapMarker";
import EventDetails from "../EventDetails/EventDetails";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import BottomSheet from "../BottomSheet/BottomSheet";
import MapTiles from "../MapTiles/MapTiles";
import InfiniteScroller from "../InfiniteScroller/InfiniteScroller";
import DatePickerFilter from "../DatePickerFilter/DatePickerFilter";
import EventCard from "../EventCard/EventCard";
import Button from "../Button/Button";
import { useMediaQuery } from "../../util/useMediaQuery";
import Tag from "../ui/Tag";
import { useData } from "../../context/DataContext/DataProvider";
import { useAnalytics } from "../../context/AnalyticsContext/AnalyticsProvider";
import Loader from "../icons/Loader";
import DateTag from "../ui/DateTag";
import {
  createCategoryIcon,
  createImgIcon,
  createUserIcon,
} from "../../util/mapStyles";
import { AiOutlineUndo } from "react-icons/ai";
import { useUIContext } from "../../context/UIContext/UIContext";
import { distance } from "../../util/eventUtils";
import { useOutsideAlerter } from "../../util/useOutsideAlerter";
import { getCurrentLocation } from "../../util/dataUtils";
import SearchPage from "./SearchPage";
import { useEventDetails } from "../../context/EventDetailsProvider/EventDetailsProvider";
import { useMapContext } from "../../context/MapContext/MapContext";
import { DateTime } from "luxon";
import { useSearchContext } from "../../context/SearchContext/SearchProvider";
import DropDownInput from "../DropDownInput/DropDownInput";
import { backendUrl } from "../../api/constants";
import axiosInstance from "../../api/axiosInstance";
import Logo from "../icons/Logo";
import PullToActionWrapper from "../PullToActionWrapper/PullToActionWrapper";
import { CiLocationArrow1 } from "react-icons/ci";
import { IoFilter } from "react-icons/io5";
import Icon from "../Icon/Icon";
import WJFIcon from "../../assets/icons/wjf-logo.png";
import { getKnownServiceImg } from "../../util/iconRegistry";
import { useAccountDetails } from "../../context/AccountDetailsContext/AccountDetailsProvider";
import AccountDetails from "../AccountDetails/AccountDetails";
import { MdCancel } from "react-icons/md";

//#region //* =========================SETUP=========================
const TagFilter = ({ disabled, className, children, active, onClick, id }) => {
  return (
    <div
      id={id}
      onClick={() => {
        if (disabled) return;
        onClick && onClick();
      }}
      className={`text-center duration-300 font-['Primary-Medium'] flex justify-start items-center py-[0.7rem] px-[1rem] rounded-[3rem] cursor-pointer border-[var(--inyc-input-border-color)] border-[1px] ${
        active
          ? "bg-[var(--inyc-secondary)] active !text-[--inyc-secondary-text-color]"
          : "bg-[var(--inyc-primary)]"
      } ${className} 
      ${disabled ? "opacity-50 pointer-events-none" : ""}
      `}
    >
      {children}
    </div>
  );
};

export const BottomSheetDateFilter = ({
  defaultDate = {
    start: DateTime.now().startOf("day").toJSDate(),
    end: DateTime.now().startOf("day").plus({ hours: 48 }).toJSDate(),
  },
  setFilterDate,
  filterDate,
  onCalendarIconClick,
  onMoreFiltersChange,
  className,
  tagFilter,
  style,
  setTagFilter,
}) => {
  const [moreFiltersOpen, setMoreFiltersOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const datePickerRef = useRef();

  useEffect(() => {
    if (onMoreFiltersChange) {
      onMoreFiltersChange(moreFiltersOpen);
    }
  }, [moreFiltersOpen]);

  const handleDateTagClick = (e) => {
    filterDate === e.target.id
      ? setFilterDate(null)
      : setFilterDate(e.target.id);
  };

  useEffect(() => {
    if (datePickerRef?.current?.calendarOpen) {
      setCalendarOpen(true);
    } else {
      setCalendarOpen(false);
    }
  }, [datePickerRef?.current?.calendarOpen]);

  let formattedDate = "today";

  if (filterDate?.start) {
    formattedDate = filterDate.start.toLocaleDateString();
    if (
      filterDate.end &&
      filterDate.end.toLocaleDateString() !==
        filterDate.start.toLocaleDateString()
    ) {
      formattedDate += " - " + filterDate.end.toLocaleDateString();
    } else if (
      filterDate.end?.toLocaleDateString() ===
      filterDate.start.toLocaleDateString()
    ) {
      if (
        filterDate.start?.toLocaleDateString() ===
        new Date().toLocaleDateString()
      ) {
        formattedDate = "today";
      } else if (
        filterDate.start?.toLocaleDateString() ===
        new Date(Date.now() + 24 * 60 * 60 * 1000).toLocaleDateString()
      ) {
        formattedDate = "tomorrow";
      } else {
        formattedDate = filterDate.start.toLocaleDateString();
      }
    }
  }

  console.log("filterDate ", filterDate?.start?.toLocaleDateString());
  console.log(
    "defaultDate ",
    DateTime.now().startOf("day").toJSDate().toLocaleDateString()
  );
  console.log(
    "defaultDate ",
    DateTime.now()
      .startOf("day")
      .plus({ hours: 48 })
      .toJSDate()
      .toLocaleDateString()
  );

  if (
    filterDate?.start?.toLocaleDateString() ===
      defaultDate.start.toLocaleDateString() &&
    filterDate?.end?.toLocaleDateString() ===
      defaultDate.end.toLocaleDateString()
  ) {
    formattedDate = "today";
  }

  const closeBtnShows =
    !calendarOpen &&
    !(
      filterDate?.start?.toLocaleDateString() ===
        defaultDate.start.toLocaleDateString() &&
      filterDate?.end?.toLocaleDateString() ===
        defaultDate.end.toLocaleDateString()
    ) &&
    filterDate?.start;

  return (
    <div
      style={style}
      className={`date-filter-container relative bottom-sheet-date-filter md:!translate-y-[-1rem] md:!left-0 md:!w-[30vw] md:!min-w-[100%] ${className}`}
    >
      <div
        className={`filter-option-flex h-fit date-filter duration-300`}
        id="date-picker"
      >
        <div
          onClick={() => setMoreFiltersOpen((prev) => !prev)}
          className={`cursor-pointer px-[0.7rem] py-[0.7rem] duration-100 rounded-[3rem] border-[1px] border-[var(--inyc-input-border-color)] ${
            moreFiltersOpen || tagFilter.length > 0
              ? "bg-[--inyc-secondary] text-[--inyc-secondary-text-color]"
              : ""
          }`}
        >
          <IoFilter size={16} />
        </div>
        <DateTag
          active={filterDate === "now"}
          id="now"
          onClick={handleDateTagClick}
        >
          now
        </DateTag>
        <DateTag
          id="soon"
          active={filterDate === "soon"}
          onClick={handleDateTagClick}
        >
          soon
        </DateTag>
        <DateTag
          id="later"
          active={filterDate === "later"}
          onClick={handleDateTagClick}
        >
          later
        </DateTag>
        <DateTag
          id="late night"
          active={filterDate === "late night"}
          onClick={handleDateTagClick}
        >
          late night
        </DateTag>
        <DatePickerFilter
          ref={datePickerRef}
          currentDate={filterDate}
          setCurrentDate={setFilterDate}
          onCalendarIconClick={onCalendarIconClick}
        />
      </div>
      <div className="w-full flex gap-[1rem] justify-center items-center text-center md:mt-[0rem]">
        <div className="w-fit px-[1rem] rounded-[1rem] bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]">
          {formattedDate}
        </div>
        <MdCancel
          style={{
            cursor: "pointer",
          }}
          className={`duration-300 ${
            closeBtnShows
              ? "opacity-100"
              : "absolute w-0 opacity-0 pointer-events-none"
          }`}
          color="var(--inyc-primary-text-color)"
          size={22}
          onClick={() => {
            setFilterDate(null);
          }}
        />
      </div>
      <div
        className={`w-full grid  overflow-hidden duration-300 ${
          moreFiltersOpen ? "grid-rows-[1fr] mb-[1rem]" : "grid-rows-[0fr]"
        } gap-[1rem]`}
      >
        <div className="min-h-0">
          <div className="text-center text-[--inyc-primary-text-color-light] mt-[1rem]">more filters coming soon!</div>
        </div>
      </div>
    </div>
  );
};

const ResetButton = ({ onClick, className }) => {
  return (
    <div className={`map-options-item ${className}`} onClick={onClick}>
      <AiOutlineUndo size={25} color="var(--inyc-secondary)" />
    </div>
  );
};

const newYorkCoords = {
  lat: 40.717426429835896,
  lng: -74.00518256903389,
};
//#endregion

//* =========================MAP=========================
const Map = () => {
  //#region variables
  const { userLocation, setUserLocation, globalConfig } = useData();
  const { setSearchQuery, setSearchLoading } = useSearchContext();
  const categories = globalConfig?.categories;
  const knownServices = globalConfig?.knownServices;
  const medium = useMediaQuery("md");
  const mapRef = useRef();
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [filterToggled, setFilterToggled] = useState(false);
  const filterButtonRef = useRef();

  const locationSearchRef = useRef();
  const locationSearchCompRef = useRef();
  const [locationSearchItems, setLocationSearchItems] = useState();
  const [locationSearchLoading, setLocationSearchLoading] = useState(0);
  const [locationSearchOpen, setLocationSearchOpen] = useState(false);
  const locationSearchTimer = useRef();
  const [locationResultsShowing, setLocationResultsShowing] = useState(false);

  const [smallIcon, setSmallIcon] = useState(false);

  useEffect(() => {
    if (!mapRef.current) return;

    const onZoomEnd = () => {
      const zoom = mapRef.current.getZoom();
      if (zoom > 13) {
        setSmallIcon(false);
        console.log("zoomed in");
      } else {
        setSmallIcon(true);
        console.log("zoomed out");
      }
    };

    mapRef.current.on("zoomend", onZoomEnd);

    return () => {
      mapRef.current.off("zoomend", onZoomEnd);
    };
  }, [mapRef.current]);

  useOutsideAlerter(filterButtonRef, () => {
    setFilterToggled(false);
  });
  const {
    // searchOpen: eventsOpen,
    setSearchOpen: setEventsOpen,
    setMainMapRef,
    bottomSheetRef,
    likedFilter,
    posterFilter,
    setLikedFilter,
    setPosterFilter,
    activeCategories,
    setActiveCategories,
    searchOpen,
    setSearchOpen,
    setSettingsOpen,
    mainMapRef,
  } = useUIContext();
  const {
    selectedEvent,
    selectedSubEvent,
    selectEvent,
    selectSubEvent,
    setSelectedEvent,
    setSelectedSubEvent,
    setDateFilter: setEventDetailsDateFilter,
    setQueryFilter: setEventDetailsQuery,
  } = useEventDetails();
  const { selectedAccount, setSelectedAccount } = useAccountDetails();
  const {
    setMapPage,
    totalResults,
    refreshEvents,
    mapLocations,
    dateFilter,
    tagFilter,
    setTagFilter,
    mapLoading,
    setMapLoading,
    setDateFilter,
    mapPageLoading,
    mapResults,
    defaultDateFilter,
  } = useMapContext();

  const eventRef = useRef();
  const subEventRef = useRef();
  //#endregion

  //#region //^ ANALYTICS: track filter type
  const { trackSessionEvent } = useAnalytics();
  const initialFilterType = useRef(true);
  const initialCategories = useRef(true);
  useEffect(() => {
    if (!initialFilterType.current) {
      trackSessionEvent({
        action: "filter changed",
        category: "filter",
        label: "filter_changed",
        value: {
          filterDate: dateFilter,
        },
        timestamp: Date.now(),
      });
    }
    initialFilterType.current = false;
  }, [dateFilter]);

  useEffect(() => {
    if (!initialCategories.current) {
      trackSessionEvent({
        action: "categories changed",
        category: "filter",
        label: "categories_changed",
        value: activeCategories,
        timestamp: Date.now(),
      });
    }
    initialCategories.current = false;
  }, [activeCategories]);
  //#endregion

  //#region effects
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo(userLocation);
    }
  }, [userLocation]);

  //add --inyc-page-top-space : 4rem to body
  useEffect(() => {
    document.body.style.setProperty("--inyc-page-top-space", `4.5rem`);

    return () => {
      // set it to 0rem
      document.body.style.setProperty("--inyc-page-top-space", "0px");
    };
  }, []);
  //#endregion

  //#region handlers

  const onLocationChange = async (e) => {
    //add to search queue
    // console.log("location chaged ,", e);
    setLocationSearchLoading(true);
    clearTimeout(locationSearchTimer.current);
    locationSearchTimer.current = setTimeout(async () => {
      const autocompleteUrl = `${backendUrl}/influencer/geolocation/autocomplete?${new URLSearchParams(
        {
          input: e?.target.value,
        }
      )}`;

      try {
        const results = await axiosInstance.get(autocompleteUrl);
        // console.log(results?.data);
        if (results.status === 200) {
          setLocationSearchItems(results.data);
        }

        // console.log(results);
      } catch (error) {
        console.log("Axios Error", error);
      } finally {
        setLocationSearchLoading(false);
      }
    }, 1000);
  };

  const selectLocationSuggestion = async (sug) => {
    try {
      setLocationSearchLoading(true);
      try {
        const { data } = await axiosInstance.get(
          `${backendUrl}/influencer/geolocation/place?${new URLSearchParams({
            place_id: sug?.place_id,
          })}`
        );

        const latitude = data.geometry?.location?.lat; // Latitude
        const longitude = data.geometry?.location?.lng;

        // Set the marker
        if (latitude && longitude) {
          // locationSearchCompRef?.current?.close()
          setUserLocation({ lat: latitude, lng: longitude });
          setLocationSearchOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLocationSearchLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onGetDirections = () => {
    // open link to google maps with directions from google maps current location to event location, first location must have the name 'current location'
    const url = `https://www.google.com/maps/dir/?api=1&origin=current+location&destination=${selectedEvent.location.coordinates[1]},${selectedEvent.location.coordinates[0]}&travelmode=walking`;
    window.open(url, "_blank");
  };

  const handleMapTagClick = (e) => {
    // handle all tag
    if (e.target.id === "all") {
      if (activeCategories.length === categories.length) {
        setActiveCategories([]);
      } else {
        setActiveCategories(categories.map((cat) => cat.id));
      }
      return;
    }

    // if activeCategories includes the tag id, remove it
    if (activeCategories.includes(e.target.id)) {
      setActiveCategories((prev) =>
        prev.filter((item) => item !== e.target.id)
      );
    } else {
      setActiveCategories((prev) => [...prev, e.target.id]);
    }
  };

  const onCalendarIconClick = () => {
    bottomSheetRef && !medium && bottomSheetRef.updateSheetHeight(100);
  };

  //#endregion

  // if filters are different from default then this should be true
  const filterChanged = !(
    dateFilter?.start?.toLocaleDateString() ===
      DateTime.now().startOf("day").toJSDate().toLocaleDateString() &&
    dateFilter?.end?.toLocaleDateString() ===
      DateTime.now()
        .startOf("day")
        .plus({ hours: 48 })
        .toJSDate()
        .toLocaleDateString() &&
    activeCategories.length === categories.length &&
    !likedFilter &&
    !posterFilter &&
    tagFilter.length === 0
  );

  // console.log("tagFilter ", tagFilter);

  const locationPromptOpen =
    distance(
      userLocation?.lat,
      userLocation?.lng,
      newYorkCoords.lat,
      newYorkCoords.lng
    ) > 150;

  useEffect(() => {
    console.log("mapLoading ", mapLoading);
  }, [mapLoading]);

  return (
    <div className="main-container flex">
      <div>
        {/* //* ======================= BOTTOM SHEET ======================= */}
        <BottomSheet
          onHeightChange={(height) => {
            if (height === 100) {
              setBottomSheetOpen(true);
            } else {
              setBottomSheetOpen(false);
            }
          }}
          useAsBottomSheetRef
          defaultHeight={40}
          className="md:!left-[--inyc-side-bar-width] !shadow-none"
          contentClassName="md:!mt-[1rem]"
        >
          <BottomSheetDateFilter
            defaultDate={defaultDateFilter}
            tagFilter={tagFilter}
            setTagFilter={setTagFilter}
            onCalendarIconClick={onCalendarIconClick}
            setFilterDate={setDateFilter}
            filterDate={dateFilter}
            showCalendar={false}
            style={{
              zIndex: 100,
            }}
          />
          <div
            style={{
              paddingBottom: "3rem",
              marginTop: "-2rem",
              backgroundColor: "var(--inyc-primary)",
            }}
          >
            {mapLoading ? (
              <div className="flex justify-center items-center w-full h-[100px]">
                <Loader />
              </div>
            ) : (
              mapLocations &&
              (mapLocations.length === 0 ? (
                <div className="map-events-none mt-[2rem]">
                  no nearby events
                </div>
              ) : (
                <>
                  <PullToActionWrapper
                    className={"pb-[5rem]"}
                    containerRef={bottomSheetRef}
                    onPull={async () => {
                      setMapLoading(true);
                      setSearchLoading(true);
                      refreshEvents();
                      // setMapLoading(false);
                      // setSearchLoading(false);
                    }}
                  >
                    <InfiniteScroller
                      getData={() => {
                        // increate map page
                        setMapPage((prev) => ({
                          page: prev.page + 1,
                          limit: prev.limit,
                        }));
                      }}
                      loading={mapPageLoading}
                      hasMore={mapLocations?.length < totalResults}
                      items={mapLocations}
                      renderer={(location, i) => (
                        <EventCard
                          key={i}
                          className="map-event-container"
                          latestEvent={location?._matchingEvent}
                          data={location}
                          onClick={() => {
                            // console.log("location ", location);
                            if (location._matchingEvent) {
                              selectEvent(location, location._matchingEvent);
                              // console.log("dateFilter ", dateFilter);
                              setEventDetailsDateFilter(dateFilter);
                            } else {
                              selectEvent(location);
                            }
                          }}
                          onVenueClick={() => {
                            //set sekected event but not sub event
                            setSelectedEvent(location);
                            //set the event details filter to be the same
                            setEventDetailsDateFilter(dateFilter);
                          }}
                          onEventClick={(ev) => {
                            // console.log("event click ", ev);
                            selectSubEvent(ev);
                          }}
                        />
                      )}
                    />
                  </PullToActionWrapper>
                </>
              ))
            )}
          </div>
          {/* </div> */}
        </BottomSheet>
        {userLocation && <SearchPage />}
        {/* //* ====================== EVENT DETAILS ====================== */}
        {medium && (
          <>
            <div
              ref={eventRef}
              className={`map-event-details-container duration-300 ${
                !selectedEvent ? "translate-x-[-100%]" : "translate-x-[0]"
              }`}
            >
              {selectedEvent && (
                <>
                  <div className="absolute text-white left-[5rem] top-[1.5rem] text-[1.3rem] font-['Primary-Medium']">
                    events
                  </div>
                  <EventDetails
                    className="!pt-[2rem]"
                    parentRef={eventRef}
                    useAsAnalytics
                    userLocation={userLocation}
                    Close={() => {
                      setSelectedEvent(null);
                    }}
                    refreshEvents={refreshEvents}
                    data={selectedEvent}
                    onGetDirections={onGetDirections}
                    onSelectEvent={(event) => {
                      selectSubEvent(event);
                      setEventDetailsDateFilter(dateFilter);
                    }}
                  />
                </>
              )}
            </div>
            <div
              ref={subEventRef}
              className={`map-sub-event-details-container duration-300 ${
                !selectedSubEvent ? "translate-x-[-100%]" : "translate-x-[0]"
              }`}
            >
              {selectedSubEvent && (
                <>
                  <div
                    className="absolute 
                  text-white left-[5rem] top-[1.5rem] text-[1.3rem] font-['Primary-Medium']"
                  >
                    event list
                  </div>
                  <EventDetails
                    className="!pt-[2rem]"
                    parentRef={subEventRef}
                    useAsAnalytics
                    userLocation={userLocation}
                    Close={() => {
                      setSelectedSubEvent(null);
                      if (selectedEvent?.events?.length <= 1) {
                        setSelectedEvent(null);
                      }
                    }}
                    refreshEvents={refreshEvents}
                    data={selectedSubEvent}
                    onGetDirections={onGetDirections}
                  />
                </>
              )}
            </div>
            <div
              className={`map-account-details-container duration-300 ${
                !selectedAccount ? "translate-x-[-100%]" : "translate-x-[0]"
              }`}
            >
              <AccountDetails />
            </div>
          </>
        )}
      </div>
      <div className="w-full md:w-[calc(100%-max(30vw,500px))] ml-auto">
        {/* //*=============================LOCATION PROMPTS============================= */}
        {locationPromptOpen && (
          <div
            style={{
              position: "absolute",
              width: "100vw",
              height:
                "calc(100vh - calc(var(--inyc-categories-bar-height) + calc(var(--inyc-bottom-space) + var(--inyc-bottom-navbar-height))))",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0,0,0,0.7)",
              zIndex: "2004",
              pointerEvents: "none",
              // paddingBottom: "7rem",
              pointerEvents: "all",
            }}
            className="location-prompt"
          >
            <div
              className=""
              style={{
                padding: "2rem 1rem",
                // transform: "translateY(-100%)",
                background: "var(--inyc-primary)",
                borderRadius: "1rem",
                width: "90%",
                maxWidth: "400px",
                pointerEvents: "all",
              }}
            >
              sorry, <span style={{ fontFamily: "Primary-Bold" }}>in—yc</span>{" "}
              isn’t available in this location <i>yet</i>
              <Button
                style={{
                  marginTop: "1rem",
                }}
                onClick={() => {
                  setUserLocation(newYorkCoords);
                  mapRef.current.flyTo(newYorkCoords);
                }}
              >
                take me to new york city
              </Button>
            </div>
          </div>
        )}
        {/* //* ====================== SIDEBAR_BUTTON ====================== */}
        <div
          className={`map-options-flex pointer-events-none w-[calc(100%-2rem)] 
            md:w-fit md:right-[1rem] md:!items-end
            md:flex-col  !justify-between duration-300 !z-[2004]
          ${
            searchOpen || bottomSheetOpen || locationPromptOpen
              ? "translate-y-[calc(calc(max(40vh,200px)+1rem)-calc(1rem+var(--inyc-bottom-navbar-height)))] sm:translate-y-0"
              : "translate-x-0"
          }
          `}
        >
          <div
            className={`map-options-item pointer-events-auto !rounded-[4rem] md:order-[3] ${
              locationSearchOpen
                ? `md:!w-[500px] !w-[300px] ${
                    locationResultsShowing
                      ? "!rounded-b-[2rem] !rounded-t-none "
                      : ""
                  }`
                : "!w-[3rem]"
            } duration-300 cursor-default flex items-center !justify-start relative`}
          >
            <div
              onClick={() => {
                setLocationSearchOpen((prev) => !prev);
                locationSearchCompRef?.current?.open();
              }}
              className="cursor-pointer max-w-[1.5rem] min-w-[1.5rem]"
            >
              <CiLocationArrow1
                color="var(--inyc-primary-text-color)"
                className="translate-x-[-5%]"
                size={20}
              />
              {/* <LocationSVG /> */}
            </div>
            <div className={`min-w-0 w-full ml-[1rem] cursor-default`}>
              <DropDownInput
                open={locationSearchOpen}
                dropDownClassName="!w-[calc(100%+2.2rem)] max-h-[calc(100svh-calc(40vh+calc(var(--inyc-bottom-space)+calc(var(--inyc-categories-bar-height)+7rem))))] mr-[2.5rem] p-[1rem] !rounded-t-[2rem]"
                dropDownDirection="top"
                style={{
                  width: "100%",
                  cursor: "default",
                  height: "3rem",
                  marginTop: 0,
                  padding: 0,
                  borderRadius: 0,
                  borderColor: "rgba(0,0,0,0)",
                  borderBottomColor: "rgba(0,0,0,0.1)",
                }}
                ItemComponent={({ item, key }) => {
                  const suggestion = item;
                  // console.log(suggestion);
                  if (!suggestion) return null;
                  let mainText, secondaryText, matchStart, matchEnd;

                  mainText = suggestion.structured_formatting.main_text;
                  secondaryText =
                    suggestion.structured_formatting.secondary_text;
                  matchStart =
                    suggestion.structured_formatting
                      ?.main_text_matched_substrings?.[0]?.offset || 0;
                  matchEnd =
                    matchStart +
                      suggestion.structured_formatting
                        ?.main_text_matched_substrings?.[0]?.length ||
                    mainText?.length ||
                    0;

                  // Construct suggestion display
                  const display = (
                    <>
                      {mainText.slice(0, matchStart)?.toLowerCase()}
                      <strong>
                        {mainText.slice(matchStart, matchEnd)?.toLowerCase()}
                      </strong>
                      {mainText.slice(matchEnd)?.toLowerCase()}
                      <br />
                      {secondaryText && (
                        <div className="text-gray-500 max-w-full overflow-hidden overflow-ellipsis">
                          {secondaryText?.toLowerCase()}
                        </div>
                      )}
                    </>
                  );

                  return (
                    <div
                      key={key}
                      onClick={() => {
                        selectLocationSuggestion(suggestion);
                      }}
                      className="cursor-pointer duration-300 hover:bg-[rgba(0,0,0,0.05)] relative text-[0.8rem] p-[1rem] rounded-lg"
                    >
                      {display}
                    </div>
                  );
                }}
                dropItems={locationSearchItems?.filter((l) => !l._id)}
                placeHolder="search for location"
                onChange={onLocationChange}
                Ref={locationSearchRef}
                ref={locationSearchCompRef}
                onFocusChange={(e, f) => {
                  // console.log("map", e, f);
                  if (locationSearchRef?.current?.value === "" && !e && !f) {
                    setLocationSearchOpen(false);
                  }
                  setLocationResultsShowing(e);
                }}
                dropLoading={locationSearchLoading}
              />
            </div>
          </div>
          <div className="flex pointer-events-auto gap-[1rem] md:flex-col">
            <ResetButton
              className={`duration-200 ${
                filterChanged
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 translate-y-[1rem] pointer-events-none"
              }`}
              onClick={async () => {
                setMapLoading(true);
                setSearchLoading(true);
                const coords = await getCurrentLocation({
                  setLocation: setUserLocation,
                  options: {
                    timeout: 10000,
                    maximumAge: 0,
                  },
                });
                //reload events
                if (coords && coords.latitude) {
                  const latlng = {
                    lat: coords.latitude,
                    lng: coords.longitude,
                  };
                  // console.log("COORDS ", coords);
                  refreshEvents();
                  mapRef.current.flyTo(latlng);
                } else {
                  refreshEvents();
                  mapRef.current.flyTo(userLocation);
                }
                //reset filters
                setDateFilter(null);
                setTagFilter([]);
                setActiveCategories(categories.map((cat) => cat.id));
                setLikedFilter(false);
                setSearchQuery("");
                setMapLoading(false);
                setSearchLoading(false);
              }}
            />
            <div
              className="map-options-item pointer-events-auto"
              onClick={() => setLikedFilter((prev) => !prev)}
            >
              {likedFilter ? (
                <AiFillHeart size={25} color="var(--inyc-secondary)" />
              ) : (
                <AiOutlineHeart size={25} color="var(--inyc-secondary)" />
              )}
            </div>
          </div>
        </div>

        {/* //* ====================== CATEGORIES ====================== */}
        <div className="flex justify-start  w-full max-w-[100vw] overflow-hidden min-h-[--inyc-categories-bar-height] max-h-[--inyc-categories-bar-height] fixed top-[0rem] left-0 z-[20004]">
          <div className="flex min-h-full justify-start md:justify-between items-center gap-[1rem] overflow-x-auto overflow-y-hidden px-[1rem] w-full ">
            <div
              onClick={() => {
                setSearchOpen(false);
                setSettingsOpen(false);
                if (bottomSheetRef) {
                  bottomSheetRef.updateSheetHeight(30);
                  bottomSheetRef.current.scrollTop = 0;
                }
                if (mainMapRef && userLocation) {
                  mainMapRef.flyTo(userLocation);
                }
              }}
              className="min-w-[100px] max-w-[150px] mr-[3rem]"
            >
              <Logo />
            </div>
            <div className="flex justify-start items-center gap-[1rem]">
              <Tag
                activeColor="var(--inyc-secondary)"
                className="bg-[var(--inyc-primary)] duration-300 cursor-pointer text-var(--inyc-secondary) font-['Primary-Medium'] px-[1rem] py-[0.7rem] rounded-[3rem] border-[0.5px] border-[var(--inyc-border-color)] flex justify-center items-center gap-[5px]"
                active={activeCategories.length === categories.length}
                id="all"
                onClick={handleMapTagClick}
              >
                all
              </Tag>
              {categories.map((category, i) => (
                <Tag
                  key={i}
                  activeColor={category.activeColor}
                  className={
                    "bg-[var(--inyc-primary)] duration-200 cursor-pointer text-var(--inyc-secondary) font-['Primary-Medium'] px-[1rem] py-[0.7rem] rounded-[3rem] border-[0.5px] border-[var(--inyc-border-color)] flex justify-center items-center gap-[5px]"
                  }
                  active={activeCategories.includes(category.id)}
                  id={category.id}
                  onClick={handleMapTagClick}
                >
                  {category.id}
                </Tag>
              ))}
            </div>
          </div>
        </div>
        {/* //* ====================== MAP ====================== */}
        <MapContainer
          whenReady={(map) => {
            mapRef.current = map.target;
            setMainMapRef(map.target);
          }}
          attributionControl={false}
          center={[userLocation.lat, userLocation.lng]}
          zoom={13}
          className="map-container"
          zoomControl={false}
          minZoom={10}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
        >
          <MapTiles />
          {userLocation && (
            <Marker
              zIndexOffset={-1}
              icon={createUserIcon()}
              position={[userLocation.lat, userLocation.lng]}
            />
          )}
          {mapResults &&
            mapResults.map((event, i) => (
              <>
                <MapMarker
                  key={i}
                  selected={
                    selectedEvent?._id === event._id ||
                    (mapResults &&
                      mapResults[0]._id === event._id &&
                      !selectedEvent &&
                      !selectedSubEvent)
                  }
                  icon={
                    // if the event has a tag from knownServices then use the icon
                    [
                      ...(event.tags || []),
                      ...(event._matchingEvent?.tags || []),
                    ]?.some(
                      (tag) =>
                        knownServices[tag?.toLowerCase()]?.icon &&
                        tagFilter?.includes(tag?.toLowerCase())
                    )
                      ? createImgIcon(
                          getKnownServiceImg(
                            knownServices[
                              [
                                ...(event.tags || []),
                                ...(event._matchingEvent?.tags || []),
                              ]
                                ?.find(
                                  (tag) =>
                                    knownServices[tag?.toLowerCase()]?.icon &&
                                    tagFilter?.includes(tag?.toLowerCase())
                                )
                                ?.toLowerCase()
                            ]?.icon
                          ),
                          event.events?.length
                        )
                      : selectedEvent?._id === event._id
                      ? createCategoryIcon(
                          event.category,
                          "darkred",
                          event.events?.length,
                          smallIcon
                        )
                      : createCategoryIcon(
                          event.category || event._matchingEvent?.category,
                          null,
                          event.events?.length,
                          smallIcon
                        )
                  }
                  position={[
                    event.location.coordinates[1],
                    event.location.coordinates[0],
                  ]}
                  title={event.name}
                  onClick={() => {
                    //set the event details filter to be the same
                    setEventDetailsDateFilter(dateFilter);
                    setEventDetailsQuery(null);
                    //if multiple events show venue instead
                    if (event.events?.length > 1) {
                      selectEvent(event);
                    } else {
                      selectEvent(
                        event,
                        event._matchingEvent
                          ? {
                              ...event._matchingEvent,
                              _mapMarker: true,
                            }
                          : null
                      );
                    }
                  }}
                />
              </>
            ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default Map;
