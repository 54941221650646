// Configuration service to manage backend URLs dynamically

// Define your environments
const environments = {
    production: {
      name: 'Production',
      url: 'https://inyc.us',
      color: '#dc3545'
    },
    dev: {
    name: 'Development',
    url: 'https://dev.inyc.us',
    color: '#ffc107'
    },
  local: {
    name: 'Local Development',
    url: 'http://localhost:8080',
    color: '#28a745'
  },
};

// Default to the environment from .env
const defaultEnvironment = import.meta.env.MODE || 'production';

class ConfigService {
  constructor() {
    // Check URL parameters first
    const urlParams = new URLSearchParams(window.location.search);
    const envParam = urlParams.get('env');
    
    // Use URL parameter if available, otherwise use default
    this.currentEnv = envParam && environments[envParam] 
      ? envParam 
      : defaultEnvironment;
      
    // Use sessionStorage for most temporary settings
    this.sessionStorage = window.sessionStorage;
    
    // Initialize session values if needed
    if (!this.sessionStorage.getItem('admin_environment')) {
      this.sessionStorage.setItem('admin_environment', this.currentEnv);
    }
    
    if (!this.sessionStorage.getItem('admin_backend_url')) {
      this.sessionStorage.setItem('admin_backend_url', this.getBackendUrl());
    }
    
    // Use localStorage specifically for logging preference to make it persistent
    if (localStorage.getItem('admin_logging_enabled') === null) {
      localStorage.setItem('admin_logging_enabled', 'false');
    }
  }

  // Get the current environment
  getCurrentEnvironment() {
    return this.sessionStorage.getItem('admin_environment') || this.currentEnv;
  }
  
  // Get all available environments
  getEnvironments() {
    return environments;
  }
  
  // Get the current backend URL
  getBackendUrl() {
    const customUrl = this.sessionStorage.getItem('admin_custom_backend_url');
    if (customUrl) {
      return customUrl;
    }
    
    const env = this.getCurrentEnvironment();
    return environments[env]?.url || import.meta.env.VITE_BACKEND_URL;
  }
  
  // Set a new environment
  setEnvironment(env) {
    if (environments[env]) {
      this.currentEnv = env;
      this.sessionStorage.setItem('admin_environment', env);
      this.sessionStorage.setItem('admin_backend_url', environments[env].url);
      
      // Clear custom URL when switching to a predefined environment
      this.sessionStorage.removeItem('admin_custom_backend_url');
      
      // Update URL parameter without reloading the page
      this.updateUrlParam('env', env);
      
      // Notify about the change
      this.notifyConfigChanged();
      
      // Force a page reload to apply changes
      window.location.reload();
      
      return true;
    }
    return false;
  }
  
  // Set a custom backend URL
  setCustomBackendUrl(url) {
    if (url && url.trim() !== '') {
      this.sessionStorage.setItem('admin_custom_backend_url', url);
      this.sessionStorage.setItem('admin_backend_url', url);
      this.currentEnv = 'custom';
      this.sessionStorage.setItem('admin_environment', 'custom');
      
      // Remove env parameter from URL
      this.updateUrlParam('env', null);
      
      // Notify about the change
      this.notifyConfigChanged();
      
      // Force a page reload to apply changes
      window.location.reload();
      
      return true;
    }
    return false;
  }
  
  // Update URL parameter without page reload
  updateUrlParam(key, value) {
    const url = new URL(window.location.href);
    if (value === null) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, value);
    }
    window.history.replaceState({}, '', url);
  }
  
  // Check if logging is enabled - use localStorage instead of sessionStorage
  isLoggingEnabled() {
    return localStorage.getItem('admin_logging_enabled') === 'true';
  }
  
  // Toggle logging on/off - use localStorage instead of sessionStorage
  toggleLogging() {
    const newValue = !this.isLoggingEnabled();
    localStorage.setItem('admin_logging_enabled', newValue ? 'true' : 'false');
    
    // Notify about the change
    this.notifyConfigChanged();
    
    return newValue;
  }
  
  // Get the color for the current environment (for UI indicators)
  getEnvironmentColor() {
    const env = this.getCurrentEnvironment();
    return environments[env]?.color || '#6c757d';
  }
  
  // Reset all settings to defaults
  resetToDefaults() {
    this.sessionStorage.removeItem('admin_environment');
    this.sessionStorage.removeItem('admin_backend_url');
    this.sessionStorage.removeItem('admin_custom_backend_url');
    localStorage.removeItem('admin_logging_enabled'); // Use localStorage here too
    
    // Reset URL parameters
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState({}, '', url);
    
    // Reset current environment
    this.currentEnv = defaultEnvironment;
  }

  // Notify other components about configuration changes
  notifyConfigChanged() {
    // Create and dispatch a custom event
    const event = new CustomEvent('admin_config_changed');
    window.dispatchEvent(event);
  }
}

export default new ConfigService();
