import React, { useEffect, useRef, useState } from "react";
import styles from "./BackButton.module.css";
import { IoIosArrowBack } from "react-icons/io";

const BackButton = ({ children, onClick }) => {
  // if user is not scrolled to the top, icon is white
  const [scrolled, setScrolled] = useState(false);
  const buttonRef = useRef(null);
  const scrollElRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position of the parent container
      const scrollPosition = scrollElRef.current.scrollTop;

      // If the scroll position is greater than 20px, set the 'scrolled' state to true, otherwise false
      setScrolled(scrollPosition > 2);
    };

    // get the parent container of the button
    scrollElRef.current = buttonRef.current?.parentNode;

    // Add the 'scroll' event listener to the parent container
    scrollElRef.current && scrollElRef.current.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      scrollElRef.current && scrollElRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [buttonRef]);

  return (
    <div ref={buttonRef} className={styles.BackButton}>
      <IoIosArrowBack onClick={onClick} className={`${styles.BackButtonIcon} ${scrolled ? styles.BackButtonIconScrolled : ""}`} size={55} />
      <div className={styles.BackButtonContent}>{children}</div>
    </div>
  );
};

export default BackButton;
