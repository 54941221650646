import React, { useEffect, useRef, useState } from "react";
import EditEvent from "../../../components/ui/EditEvent";
import axiosInstance from "../../../api/axiosInstance";
import { backendUrl } from "../../../api/constants";
import { Link, useNavigate } from "react-router-dom";
import { usePortalContext } from "../../../context/PortalContext/portalcontext";
import Button from "../../../components/Button/Button";
import { styles } from "../../../components/PortalStyles/portalStyles.style";
import Input from "../../../components/Input/Input";
import { useUser } from "../../../context/UserContext/UserProvider";
import { useLogger } from "../../../context/LogContext/LogProvider";
import { modal } from "../../../util/useModal";
import { enqueueSnackbar } from "notistack";
import { ErrorMessage } from "../../../components/Input/InputError";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BsPlusLg } from "react-icons/bs";

const NewEvent = ({ userLocation }) => {
  const { user } = useUser();
  const { editEvent, setEditEvent } = usePortalContext();
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState(null);
  const eventRef = useRef();
  const navigate = useNavigate();

  const { startLog } = useLogger();

  useEffect(() => {
    return () => {
      if (editEvent) {
        setEditEvent(null);
      }
    };
  }, []);

  const handleError = (err) => {
    return err;
  };

  const onSubmit = async () => {
    console.log("submitting event");
    const log = startLog({
      user: user,
      event: "submitting event",
    });
    log.log("Start submitting event");

    setLoading(true);
    try {
      log.log("Retrieving form data");
      const { form, error } = await eventRef.current?.getFormData();
      console.log("retrieve form data", form, error);
      if (error) {
        log.log("[Error] Form Data validation failed: ", error);
        setPostError(error);
        return;
      }

      const url = `${backendUrl}/influencer/${
        editEvent?._id ? "edit" : "post"
      }`;
      log.log("Posting form data: ", form, " to url :", url);
      const { data } = await axiosInstance.post(url, form, {
        "Content-Type": "multipart/form-data",
      });

      log.log("Received response: ", data);

      if (data.error) {
        log.log("[Error] Error in data: ", data);
        // if its a string then its a general error
        if (typeof data.error === "string") {
          setPostError(data.error);
          return;
        } else {
          // if its an object then its a field error
          for (const key in data.error) {
            if (data.error[key].hasOwnProperty("error")) {
              setPostError(data.error[key].error);
              return;
            }
          }
        }
      } else {
        log.log("[Success] Event submitted successfully");

        const recommend = eventRef.current?.recommend()?.checked;
        console.log(">>>>>>>> recommend", recommend);
        if (recommend) {
          log.log("Recommending event");
          const jsonObject = {};
          for (const [key, value] of form.entries()) {
            // Assuming 'event' field is a JSON string, parse it back to an object
            if (key === "event") {
              jsonObject[key] = JSON.parse(value);
            } else {
              jsonObject[key] = value;
            }
          }
          log.log("Posting recommendation: ", jsonObject?.event);
          const { data: rec } = await axiosInstance.post(
            `${backendUrl}/influencer/recommendations`,
            jsonObject?.event,
            {
              "Content-Type": "multipart/form-data",
            }
          );

          if (rec.error) {
            log.log("[Error] Error in recommendation: ", rec.error);
            console.log(rec.error);
          }
        }

        //post the events using _extraDates if it exists
        // _extraDates is an array of objects under the 'event' object in the form
        if (form.has("_extraDates")) {
          log.log("Posting extra dates");
          const extraDates = JSON.parse(form.get("_extraDates"));
          form.delete("_extraDates");
          for (const date of extraDates) {
            //TODO change date to date.date and rrule to date.rrule within the event field
            const event = JSON.parse(form.get("event"));
            event.date = date.date;
            event.rrule = date.rrule;
            form.set("event", JSON.stringify(event));

            // console log form as object
            const formObject = {};
            for (const [key, value] of form.entries()) {
              formObject[key] = value;
            }
            console.log("Posting extra date: ", formObject);
            const { data: extraDate } = await axiosInstance.post(
              `${backendUrl}/influencer/post`,
              form,
              {
                "Content-Type": "multipart/form-data",
              }
            );

            if (extraDate.error) {
              log.log("[Error] Error in extra date: ", extraDate.error);
              console.log(extraDate.error);
            }
          }
        }

        //show success message
        log.log("[Success] All operations completed successfully");
        navigate("/");
        if (editEvent?._id) {
          modal(() => (
            <div>
              your edits were submitted successfully!
              <br />
              <br />
              our team will review your event and in the meantime you can check
              your event status by hitting the 
              <div className="h-[1.5rem] translate-y-[-25%] leading-[2rem] w-[1.5rem] inline-flex p-[0.2rem] justify-center items-center rounded-[50%] mx-[0.5rem] bg-[--inyc-primary-light] text-[1.5rem] text-[var(--inyc-primary-text-color)]">
                <BsPlusLg size={15} />
              </div>
              symbol, then 
              <div className="h-[1.5rem] translate-y-[-25%] leading-[1rem] w-[1.5rem] inline-flex p-[0.2rem] justify-center items-center rounded-[50%] mx-[0.5rem] bg-[--inyc-primary-light] text-[1.5rem] text-[var(--inyc-primary-text-color)]">
                <HiOutlineDotsVertical size={15} />
              </div>
              and selecting the “posted events” tab
            </div>
          ));
          setEditEvent(null);
        } else {
          if (editEvent) {
            setEditEvent(null);
          }
          modal(() => (
            <div>
              event submission successful!
              <br />
              <br />
              you’ll receive an email with a status update after our team
              reviews your submission.
              <br />
              <br />
              alternatively hit the “+” symbol and select the “posted events
              tab” to see its status.
            </div>
          ));
        }
      }
    } catch (error) {
      log.log("[Error] Exception caught on the main function call: ", error);
      return { error: "something went wrong, please try again" };
    } finally {
      log.log("Setting loading to false and sending logs");
      setLoading(false);
      log.send();
    }
  };

  const saveAsTemplate = async () => {
    try {
      const log = startLog({
        user: user,
        event: "saving template",
      });
      const { form, error } = await eventRef.current?.getFormData();
      if (error) {
        log.log("[Error] Form Data validation failed: ", error);
        setPostError(error);
        return;
      }
      log.log("Saving template");
      const res = await modal(
        ({ proceed }) => {
          const [templateError, setTemplateError] = useState(null);
          const templateNameRef = useRef();
          const handleTemplateSubmit = async () => {
            if (templateNameRef.current?.value.trim() === "")
              return setTemplateError("please enter a template name");
            //if its less than 3 characters
            if (templateNameRef.current?.value.trim().length < 3)
              return setTemplateError(
                "template name must be at least 3 characters"
              );

            proceed(templateNameRef.current?.value.trim());
          };
          return (
            <div>
              <div className="text-[1.4rem] mb-[0.5rem]">please enter a template name</div>
              <div className="!text-[0.8rem]">
                to access your templates select the
                <div className="h-[1.5rem] translate-y-[25%] leading-[2rem] w-[1.5rem] inline-flex p-[0.2rem] justify-center items-center rounded-[50%] mx-[0.5rem] bg-[--inyc-primary-light] text-[1.5rem] text-[var(--inyc-primary-text-color)]">
                  <BsPlusLg size={15} />
                </div> sign and then
                <div className="h-[1.5rem] translate-y-[25%] leading-[1rem] w-[1.5rem] inline-flex p-[0.2rem] justify-center items-center rounded-[50%] mx-[0.5rem] bg-[--inyc-primary-light] text-[1.5rem] text-[var(--inyc-primary-text-color)]">
                  <HiOutlineDotsVertical size={15} />
                </div>
              </div>
              <Input
                placeHolder="template name"
                className="mb-[1rem]"
                Ref={templateNameRef}
              />
              <div className="flex justify-center items-center gap-[1rem] mb-[1rem]">
                <Button className="text-[1rem]" onClick={handleTemplateSubmit}>
                  submit
                </Button>
                <Button className="text-[1rem]" onClick={() => proceed(null)}>
                  cancel
                </Button>
              </div>
              {templateError && (
                <div className="text-[1.2rem] text-red-500">
                  {templateError}
                </div>
              )}
            </div>
          );
        },
        {
          hideCloseButton: true,
        }
      );

      log.log('Retrieved template name: "', res, '"');
      if (res) {
        form.append("templateName", res);
        //remove _id from form
        form.delete("_id");

        try {
          log.log("Saving template to backend: ", form);
          const { data: tmpdata } = await axiosInstance.post(
            `${backendUrl}/influencer/templates/save`,
            form
          );

          log.log("Received response from backend: ", tmpdata);
          if (tmpdata?.error) {
            log.log("[Error] Error in template: ", tmpdata.error);
            if (typeof tmpdata.error === "string") {
              setPostError(tmpdata.error);
              return;
            } else if (typeof tmpdata.error === "object") {
              for (const key in tmpdata.error) {
                if (tmpdata.error[key].hasOwnProperty("error")) {
                  setPostError(tmpdata.error[key].error);
                  return;
                }
              }

              return;
            }
          } else {
            log.log("[Success] Template saved successfully");
            setPostError(null);
            enqueueSnackbar(`template "${res}" saved successfully`, {
              variant: "success",
            });
          }
        } catch (error) {
          log.log("[Error] Exception caught while saving template: ", error);
          await log.display();
          setPostError("something went wrong, please try again later");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      return setPostError("something went wrong, please try again later");
    }
  };

  if (!user?.emailVerified) {
    return (
      <div className="p-[2rem]  justify-center px-[3rem] text-[1.5rem] text-center flex items-center pt-[4rem] bg-[var(--inyc-primary)] min-h-[calc(100vh-25rem)] font-bold h-[60px] w-full duration-300">
        <div>
          before you can post we need to verify your email.
          <br />
          <br />
          verify by clicking{" "}
          <Link
            className="inline-block font-['Primary-Medium'] ml-[5px]"
            to="/auth/verify-email"
          >
            here
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="pb-[5rem] bg-[var(--inyc-primary)]">
      <EditEvent
        allowExtraDates
        recommend
        Ref={eventRef}
        handleError={handleError}
        edit={editEvent}
        onSubmit={onSubmit}
        userLocation={userLocation}
      />
      {postError && (
        <div className="max-w-[90%] font-bold text-[1.2rem] mx-auto text-center mb-[1rem]">
          <ErrorMessage>{postError}</ErrorMessage>
        </div>
      )}
      <div className="text-center mt-[1rem] px-[1rem] mb-[1rem] font-bold text-[0.9rem] text-[var(--inyc-primary-text-color-light)]">
        templates save event information for easier future posting
      </div>
      <div className="max-w-[90%] flex-col gap-[1rem] md:max-w-[500px] mx-auto flex items-center justify-evenly text-center">
        <Button
          btnClassName="text-[1.2rem] hover:!text-white hover:!bg-black/30"
          className={"whitespace-nowrap"}
          onClick={saveAsTemplate}
          style={{
            width: "100%",
          }}
        >
          save template
        </Button>
        <Button
          className={"text-[1.2rem] flex-1 !m-0"}
          prompt
          message="Confirm"
          type="secondary"
          style={styles.PostButton}
          loading={loading}
          onClick={onSubmit}
        >
          {`${editEvent?._id ? "edit" : "submit"} event`}
        </Button>
      </div>
    </div>
  );
};

export default NewEvent;
