import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import Input from "../Input/Input";
import barMarker from "../../assets/map/bar-marker.png";
import { backendUrl } from "../../api/constants";
import DropDownInput from "../DropDownInput/DropDownInput";
import axiosInstance from "../../api/axiosInstance";
import { getVenues } from "../../api/events";
import { styles } from "../PortalStyles/portalStyles.style";
import ImageSlider from "../ImageSlider/ImageSlider";
import { useLocation } from "react-router-dom";
import { ErrorMessage } from "../Input/InputError";
import { DateTime } from "luxon";
import Notice from "../ui/notice";
import Tabs, { TabContent } from "./tabs";
import { RRule } from "rrule";
import Radio from "../Radio/Radio";
import Logo from "../../assets/imgs/inyc.svg";
import { FaChevronDown, FaPlus } from "react-icons/fa6";
import "./EditEvent.css";
import { useData } from "../../context/DataContext/DataProvider";
import SocialsHandler from "../SocialsInput/SocialsHandler";
import TagInput from "../TagInput/TagInput";
import { PortalTitle } from "../../pages/Portal";
import { IoCloseOutline, IoLocationSharp } from "react-icons/io5";
import AddressHandler from "../AddressHandler/AddressHandler";
import { ImHome } from "react-icons/im";
import { Popup } from "react-leaflet";

const frequencyMapping = {
  month: RRule.MONTHLY,
  week: RRule.WEEKLY,
  day: RRule.DAILY,
};

const dayToRRule = {
  sunday: RRule.SU,
  monday: RRule.MO,
  tuesday: RRule.TU,
  wednesday: RRule.WE,
  thursday: RRule.TH,
  friday: RRule.FR,
  saturday: RRule.SA,
};

const WeekDays = ({
  days,
  onChange = () => {
    console.log("changed");
  },
}) => {
  const availableDays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return availableDays.map((day, i) => (
    <div
      onClick={() => {
        if (days?.includes(day)) {
          onChange(days.filter((i) => i !== day));
        } else {
          onChange([...days, day]);
        }
      }}
      className={`${days?.includes(day)
        ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]"
        : "bg-[rgba(0,0,0,0.1)]"
        } duration-300 cursor-pointer w-[30px] h-[30px] flex items-center justify-center rounded-[50%]`}
    >
      {day[0]}
    </div>
  ));
};

const EditEvent = ({
  handleError = async () => true,
  Ref,
  userLocation,
  edit,
  recommend,
  noPortalTitle = false,
  allowExtraDates = false,
}) => {
  //#region variables
  const { globalConfig } = useData();
  const categories = globalConfig?.categories || [];
  // const [marker, setMarker] = useState(userLocation);
  const [marker, setMarker] = useState();
  const [category, setCategory] = useState("music");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(
    // get the current time in a format a "time" input can understand
    new Date().toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() + 60 * 60 * 1000)
  );
  const [endTime, setEndTime] = useState(
    // get the current time in a format a "time" input can understand
    new Date(new Date().getTime() + 60 * 60 * 1000).toLocaleTimeString(
      "en-GB",
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    )
  );
  const [postError, setPostError] = useState();
  const [timeErrors, setTimeErrors] = useState();
  const [venues, setVenues] = useState();
  const [ticketed, setTicketed] = useState(false);
  const [moreDatesNoticeOpen, setMoreDatesNoticeOpen] = useState(false);
  const [extraDates, setExtraDates] = useState([]);

  const [recurring, setRecurring] = useState(false);
  const [repeatEveryNumber, setRepeatEveryNumber] = useState(1);
  const [repeatEveryPeriod, setRepeatEveryPeriod] = useState("day");
  const [repeatOn, setRepeatOn] = useState([]);
  const [repeatEnds, setRepeatEnds] = useState("never");
  const [repeatEndsOnDate, setRepeatEndsOnDate] = useState(new Date());
  const [repeatEndsAfterNumber, setRepeatEndsAfterNumber] = useState(1);
  const [rruleString, setRruleString] = useState("");

  const mapRef = useRef();
  const venuSearchTimer = useRef();
  const nameRef = useRef();
  const addressHandlerRef = useRef();

  const descriptionRef = useRef();
  const imagesRef = useRef();
  const socialsRef = useRef();
  const tagsRef = useRef();
  const ageRestrictionRef = useRef();
  const ticketPriceRef = useRef();
  const ticketLinkRef = useRef();
  const payTheArtistRef = useRef();
  const location = useLocation();
  //#endregion

  useImperativeHandle(Ref, () => ({
    getFormData: async (obj) => {
      // console.log("getting form data");
      const form = await getValue(obj);
      // console.log("got form data ", form);
      return form;
    },
    recommend: () => addressHandlerRef.current?.recommend(),
    error: postError,
  }));

  //#region effects
  const fetchVenues = async (latLng) => {
    console.log('fetching venues');
    const veunesData = await getVenues(latLng);
    console.log('veunesData', veunesData);
    setVenues(veunesData);
  };
  useEffect(() => {
    console.log('userLocation', userLocation);
    if (userLocation) {
      fetchVenues(userLocation);
    } else if (edit?.location?.coordinates) {
      fetchVenues({
        lat: edit.location.coordinates[1],
        lng: edit.location.coordinates[0]
      });
    }
  }, [userLocation, edit?.location?.coordinates]);

  useEffect(() => {
    const handleUrlParams = async () => {
      const venue = new URLSearchParams(location.search).get("venue");
      if (venue) {
        setVenueFromId(venue);
      }
    };
    handleUrlParams();
    return () => {
      clearTimeout(venuSearchTimer.current);
    };
  }, []);

  useEffect(() => {
    if (edit) {
      //add images
      if (edit.images) {
        imagesRef.current.setValue(
          edit.images.map((img) => ({
            original: `${backendUrl}/assets/${img}`,
          }))
        );
      }
      if (edit.name) {
        nameRef.current.value = edit.name;
      }
      if (edit.category) {
        setCategory(edit.category);
      }

      // date is either under format "start - end" or just "start", convert it to {start,end} with valid dates
      if (edit.date) {
        const { start, end } = edit.date;

        // Convert to Luxon DateTime
        const startDateTime = DateTime.fromISO(start, {
          zone: edit.timezone[0],
        }).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {
          keepLocalTime: true,
        });
        const endDateTime = DateTime.fromISO(end, {
          zone: edit.timezone[0],
        }).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {
          keepLocalTime: true,
        });

        // Extract and set start date and time
        setStartDate(startDateTime.toJSDate());

        // // Extract and set end date and time
        // setEndDate(endDateTime.toJSDate());

        // Extract and set start time
        setStartTime(startDateTime.toFormat("HH:mm"));

        // Extract and set end time
        setEndTime(endDateTime.toFormat("HH:mm"));
      }
      if (edit.category) {
        setCategory(edit.category);
      }

      if (edit.rrule && edit.rrule.origOptions) {
        setRepeatEveryNumber(edit.rrule.origOptions.interval || 1);
        setRepeatEveryPeriod(
          Object.keys(frequencyMapping).find(
            (key) => frequencyMapping[key] === edit.rrule.origOptions.freq
          )
        );
        setRepeatOn(
          edit.rrule.origOptions.byweekday
            ?.map((day) => {
              return Object.keys(dayToRRule).find(
                (key) =>
                  dayToRRule[key].weekday === day ||
                  dayToRRule[key].weekday === day?.weekday
              );
            })
            .filter((i) => i !== undefined) || []
        );
        if (edit.rrule.origOptions.until) {
          setRepeatEnds("on");
          // const untilDate = formatRRuleDateToInput(edit.rrule.options.until, edit.timezone);
          const untilDate = formatInputToRRuleDate(
            edit.rrule.origOptions.until,
            edit.timezone
          ).toJSDate();
          // console.log("untilDate ", untilDate);
          setRepeatEndsOnDate(untilDate);
        } else if (edit.rrule.origOptions.count) {
          setRepeatEnds("after");
          setRepeatEndsAfterNumber(edit.rrule.origOptions.count);
        } else {
          setRepeatEnds("never");
        }

        setRecurring(true);
      }

      if (edit.venue) {
        setVenueFromId(edit.venue?._id || edit.venue);
      }
      // if (edit.address || edit.location ) {
      //   addressHandlerRef.current.setState(edit);
      // }
      if (edit.ticketed) {
        setTicketed(edit.ticketed);
        // ticketedRef.current.value = edit.ticketed;
      }
      if (edit.ticket_price) {
        ticketPriceRef.current.value = edit.ticket_price;
      }
      if (edit.ticket_link) {
        ticketLinkRef.current.value = edit.ticket_link;
      }
      if (edit.socials && edit.socials.length > 0) {
        socialsRef.current.setValue(edit.socials);
      }
      if (edit.website) {
        socialsRef.current.setValue([
          ...(edit.socials || []),
          { platform: "website", link: edit.website },
        ]);
      }

      if (edit.paytheartist_link) {
        payTheArtistRef.current.value = edit.paytheartist_link;
      }

      if (edit.age_restriction) {
        ageRestrictionRef.current.value = edit.age_restriction;
      }
      if (edit.description) {
        descriptionRef.current.value = edit.description;
      }
      if (edit.tags) {
        tagsRef.current.setValue(edit.tags);
      }
    }
  }, [edit]);
  //#endregion

  //#region new recurring code

  useEffect(() => {
    if (!startDate) return;
    if (recurring) {
      const rule = getRRule({
        repeatEveryNumber,
        repeatEveryPeriod,
        repeatOn,
        repeatEnds,
        repeatEndsOnDate,
        repeatEndsAfterNumber,
        startDate: startDate,
        startTime: startTime,
      });

      setRruleString(rule.text);
    }
  }, [
    recurring,
    repeatEveryNumber,
    repeatEveryPeriod,
    repeatOn,
    repeatEnds,
    repeatEndsOnDate,
    repeatEndsAfterNumber,
    startDate,
    startTime,
  ]);

  const formatInputToRRuleDate = (date, timezone) => {
    const datetime =
      date instanceof Date
        ? DateTime.fromJSDate(date, { zone: timezone[0] })
        : DateTime.fromISO(date, { zone: timezone[0] });
    const formatteddate = datetime
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {
        keepLocalTime: true,
      })
      .toUTC();

    return formatteddate;
  };

  const getRRuleDays = (days) => {
    // console.log("days", days);
    return days.map((day) => dayToRRule[day?.toLowerCase()]);
  };

  const getRRule = ({
    repeatEveryNumber,
    repeatEveryPeriod,
    repeatOn,
    repeatEnds,
    repeatEndsOnDate,
    repeatEndsAfterNumber,
    startDate,
    startTime,
  }) => {
    const startDateTime = new Date(
      `${startDate.toISOString().split("T")[0]} ${startTime}`
    );

    // console.log("startDateTime", startDateTime);

    let rruleOptions = {
      freq: frequencyMapping[repeatEveryPeriod],
      interval: repeatEveryNumber,
      dtstart: startDateTime,
      tzid: "utc",
    };

    if (repeatEveryPeriod === "week" && repeatOn.length > 0) {
      rruleOptions.byweekday = getRRuleDays(repeatOn || []);
    }
    switch (repeatEnds) {
      case "on":
        rruleOptions.until = repeatEndsOnDate;
        break;
      case "after":
        rruleOptions.count = parseInt(repeatEndsAfterNumber, 10);
        break;
      case "never":
        // No additional fields needed for 'never'.
        break;
    }

    // console.log(rruleOptions);
    const rule = new RRule(rruleOptions);
    return {
      text: rule?.toText()?.toLowerCase(),
      options: {
        origOptions: {
          ...rule.origOptions,
          byhour: null,
          byminute: null,
          bysecond: null,
          dtstart: formatDateForInput(startDateTime),
          until:
            repeatEnds === "on"
              ? formatDateForInput(repeatEndsOnDate)
              : undefined,
          tzid: "utc",
        },
      },
    };
  };
  //#endregion

  //#region handlers
  const setVenueFromId = async (id) => {
    try {
      const { data } = await axiosInstance.get(
        `${backendUrl}/venues/get/${id}`
      );
      if (data?.data && data?.data?._id) {
        handleVenuPick(data?.data);

        // setAddressFromVenue(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const venuesToMarkers = (list) => {
    if (!list || list.length === 0) {
      return [];
    }
    let venuMarkers = [];

    const currentVenue = addressHandlerRef?.current?.getVenueId();

    // Iterate over each venue
    list.forEach((venue) => {
      // Get the coordinates from the 'location' field
      const [lng, lat] = venue.location.coordinates;

      // Create a new object with the desired format and push it to the array
      if (venue._id !== currentVenue) {
        venuMarkers.push({
          lng,
          lat,
          icon: barMarker,
          venue: venue,
          props: {
            onVenueSelect: () => {
              console.log('onVenueSelect');
              handleVenuPick(venue);
            },
            onMapClick: () => {
              console.log('onMapClick');
              //set addresshandlers position to current venue latlng 
              addressHandlerRef.current.setState(prev => ({
                ...prev,
                location: {
                  coordinates: [lng, lat]
                }
              }));
            },
            Popup: (props) => {
              const { onClose, onMapClick, onVenueSelect } = props;
              return <Popup
                style={{
                  backgroundColor: "var(--inyc-primary-light)",
                }}
                className="bg-[--inyc-primary] w-full"
                onClose={() => onClose()}
                {...props}
              >
                <div
                  className="bg-[--inyc-primary] w-full"
                  style={{ padding: '0.5rem', minWidth: '15rem', borderRadius: '0.3rem' }}
                >
                  <div
                    className="mb-[0.5rem]"
                  >
                    <div className="text-[--inyc-primary-text-color-light] text-[0.8rem]">
                      venue
                    </div>
                    <div className="break-all mb-[0.2rem] text-[--inyc-primary-text-color] text-[1.2rem]">
                      {venue?.name || 'Venue'}
                    </div>
                    <div className="text-[0.7rem] mb-[0.5rem] font-['Primary-Regular'] text-[--inyc-primary-text-color-light]">
                      {venue?.address}
                    </div>
                  </div>
                  <div
                    className="flex items-center justify-start cursor-pointer px-[0.7rem] py-[0.5rem] rounded-[0.2rem] w-full hover:text-[--inyc-primary-text-color] transition-all duration-300 hover:bg-[--inyc-primary-light] text-[--inyc-primary-text-color] text-[1rem]"
                    style={{ width: '100%' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onVenueSelect();
                      onClose();
                    }}
                  >
                    <ImHome
                      className="mr-[0.5rem]"
                      size={11}
                    />
                    <div
                      className="text-[1rem] leading-[1.5rem] text-[--inyc-primary-text-color]"
                    >select venue</div>
                  </div>
                  <div
                    className="flex items-center justify-start cursor-pointer px-[0.7rem] py-[0.5rem] rounded-[0.2rem] w-full hover:text-[--inyc-primary-text-color] transition-all duration-300 hover:bg-[--inyc-primary-light] text-[--inyc-primary-text-color] text-[1rem]"
                    style={{ width: '100%' }}
                    onClick={(e) => {
                      //should be considered a normal map click
                      e.stopPropagation();
                      e.preventDefault();
                      onMapClick();
                      onClose();
                    }}
                  >
                    <IoLocationSharp
                      className="mr-[0.5rem]"
                      size={12}
                    />
                    <div
                      className="text-[1rem] leading-[1.5rem] text-[--inyc-primary-text-color]"
                    >add custom marker</div>
                  </div>
                </div>
              </Popup>
            }
          }
        })
      }
    })

    return venuMarkers;
  };

  const handleVenuPick = async (venue) => {
    addressHandlerRef.current.setState({
      address: venue.address,
      location: venue.location,
      venue: venue
    })
    if (mapRef.current) {
      mapRef.current.flyTo([
        venue.location.coordinates[1],
        venue.location.coordinates[0],
      ]);
    }
  };

  const getValue = async (obj) => {
    setTimeErrors(null);

    const startDateTime = new Date(
      `${startDate.toISOString().split("T")[0]} ${startTime}`
    );
    const endDateTime = new Date(
      `${startDate.toISOString().split("T")[0]} ${endTime}`
    );

    if (endDateTime <= startDateTime) {
      endDateTime.setDate(endDateTime.getDate() + 1);
    }

    const date = {
      start: formatDateForInput(startDateTime),
      end: formatDateForInput(endDateTime),
    };

    //if a recurring end date is specified then the recurring end date must be after the start date
    if (repeatEnds === "on" && repeatEndsOnDate < startDateTime) {
      const res = await handleError({
        error: "please specify a valid recurring end date",
        errors: {
          repeatEndsOnDate:
            "recurring end date cannot be before the start date",
        },
      });
      if (res?.error) {
        setTimeErrors(res.errors);
        return { error: res.error, errors: res.errors };
      }
    }
    const { venue, location, address } = addressHandlerRef?.current?.getState()
    console.log(">>>>>>>> venue", venue);
    console.log(">>>>>>>> location", location);
    console.log(">>>>>>>> address", address);

    //if no location coordinates and no venue is selected return error
    if (!location?.coordinates?.length && !venue) {
      const res = await handleError({
        error: "please specify a valid location",
        errors: {
          location: "please specify a valid location",
        },
      });
      if (res?.error) {
        return { error: res.error, errors: res.errors };
      }
    }

    //add images
    const images = imagesRef.current.getValue();
    const form = new FormData();
    if (images && images.length > 0) {
      for (let img of images) {
        form.append("images", img);
      }
    }

    // get the images that are not files
    const eimages = imagesRef.current.getURLs();

    //build the rrule object
    let rrule;

    if (recurring) {
      const rule = getRRule({
        repeatEveryNumber,
        repeatEveryPeriod,
        repeatOn,
        repeatEnds,
        repeatEndsOnDate,
        repeatEndsAfterNumber,
        startDate: startDate,
        startTime: startTime,
      });

      rrule = rule.options;
    }

    //if one of the objects in the social has empty platform or link return error
    const socials = socialsRef.current.getValue();

    console.log('got socials', socials)

    // return;
    const event = {
      _id: edit?._id,
      name: nameRef.current.value,
      category: category,
      images: eimages,
      date: date,
      rrule: rrule,
      description: descriptionRef.current.value,
      // location: marker?.venue ? marker?.venue._id : undefined,
      address: address,
      location: location,
      venue: venue?._id || undefined,
      socials: socials,
      age_restriction: ageRestrictionRef.current.value,
      ticketed: ticketed ? "on" : undefined,
      // if ticketed is true then ticket price is required
      ticket_price: ticketed ? ticketPriceRef.current?.value : undefined,
      ticket_link: ticketed ? ticketLinkRef.current?.value : undefined,
      paytheartist_link: payTheArtistRef.current?.value,
      tags: tagsRef.current?.getValue(),
      ...(obj || {}),
    };

    // console.log("event ", event);

    if (allowExtraDates) {
      //do safety checks on the extra dates
      if (extraDates) {
        for (let i = 0; i < extraDates.length; i++) {
          const date = extraDates[i];
          if (!date.startDate || !date.startTime || !date.endTime) {
            const res = await handleError({
              error: "please specify a valid time",
              errors: {
                [`extraDates[${i}].startDate`]: date.startDate
                  ? ""
                  : "please specify a valid start date",
                [`extraDates[${i}].startTime`]: date.startTime
                  ? ""
                  : "please specify a valid start time",
                [`extraDates[${i}].endTime`]: date.endTime
                  ? ""
                  : "please specify a valid end time",
              },
            });
            setTimeErrors(res.errors);
            if (res?.error) {
              return { error: res.error, errors: res.errors };
            }
          }

          //construct the date object
          const startDateTime = new Date(
            `${date.startDate.toISOString().split("T")[0]} ${date.startTime}`
          );
          const endDateTime = new Date(
            `${date.startDate.toISOString().split("T")[0]} ${date.endTime}`
          );

          if (startDateTime > endDateTime) {
            const res = await handleError({
              error: "please specify a valid time",
              errors: {
                [`extraDates[${i}].startTime`]:
                  "start date cannot be after end date",
              },
            });
            setTimeErrors(res.errors);
            if (res?.error) {
              return { error: res.error, errors: res.errors };
            }
          }
        }
      }

      const _extraDates = extraDates?.map((date) => {
        const startDateTime = new Date(
          `${date.startDate.toISOString().split("T")[0]} ${date.startTime}`
        );
        const endDateTime = new Date(
          `${date.startDate.toISOString().split("T")[0]} ${date.endTime}`
        );

        return {
          ...date,
          date: {
            start: formatDateForInput(startDateTime),
            end: formatDateForInput(endDateTime),
          },
        };
      });

      //add _extraDates to the form
      // console.log(_extraDates);
      form.append("_extraDates", JSON.stringify(_extraDates));
    }

    //remove the empty fields from the event object or fields that are ''

    for (let key in event) {
      if (event[key] === undefined || event[key] === "") {
        delete event[key];
      }
    }

    // console.log(event);

    form.append("event", JSON.stringify(event));

    return { form };
  };

  const handleExtraDatesChange = (dateObject, i) => {
    setExtraDates((prev) => {
      const newDates = [...prev];
      // construct rrule string
      const currentDateObject = {
        ...newDates[i],
        ...dateObject,
      };
      // console.log("currentDateObject ", currentDateObject);

      const rule = getRRule({
        repeatEveryNumber: currentDateObject.repeatEveryNumber,
        repeatEveryPeriod: currentDateObject.repeatEveryPeriod,
        repeatOn: currentDateObject.repeatOn,
        repeatEnds: currentDateObject.repeatEnds,
        repeatEndsOnDate: currentDateObject.repeatEndsOnDate,
        repeatEndsAfterNumber: currentDateObject.repeatEndsAfterNumber,
        startDate: currentDateObject.startDate,
        startTime: currentDateObject.startTime,
      });

      const rruleString = rule.text;

      newDates[i] = {
        ...currentDateObject,
        rruleString,
        rrule: rule.options,
      };
      return newDates;
    });
  };
  //#endregion

  function formatDateForInput(
    date,
    dateOnly = false,
    debug = false,
    noOffset = false
  ) {
    if (!date) return "";
    // debug && alert(`got date ${date}`);
    const local = new Date(date);
    // debug && alert(`local date before setting minutes ${local}`);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    // debug && alert(`local date ${local}`);

    const dt = DateTime.fromJSDate(date);
    if (!noOffset) {
      dt.toUTC({
        keepLocalTime: true,
      });
    }

    if (dateOnly) {
      // debug && alert(`event date dateOnly ${dt.toFormat("yyyy-MM-dd")}`);
      // Format as YYYY-MM-DD
      return dt.toFormat("yyyy-MM-dd");
      // return local.toJSON().slice(0, 10);
    }
    // debug && alert(`event date ${dt.toFormat("yyyy-MM-dd'T'HH:mm")}`);
    return local.toJSON().slice(0, 16);
  }

  return (
    <div
      style={{
        background: "var(--inyc-primary)",
        padding: "1rem",
        // boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
      }}
    >
      <div
        className="portal-content"
        style={{
          ...styles.SplitContainer,
          ...{ padding: "1rem" },
        }}
      >
        {noPortalTitle ? <></> : <PortalTitle>{edit ? "edit" : "create"} event</PortalTitle>}
        <div style={styles.EventAddOptions}>
          <div className="mb-[2rem] gap-[1rem] flex justify-center w-full items-center">
            <div
              onClick={() => setRecurring(false)}
              className={`shadow-[var(--inyc-button-shadow)] font-bold text-[1.2rem] px-[1.5rem] py-[0.7rem] duration-300 cursor-pointer text-center whitespace-nowrap flex-1 rounded-[2rem] ${!recurring
                ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]"
                : "border-[1px] border-[var(--inyc-input-border-color)]"
                }`}
            >
              one-time
            </div>
            <div
              onClick={() => setRecurring(true)}
              className={`shadow-[var(--inyc-button-shadow)] font-bold text-[1.2rem] px-[1.5rem] py-[0.7rem] duration-300 cursor-pointer text-center whitespace-nowrap flex-1 rounded-[2rem] ${recurring
                ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]"
                : "border-[1px] border-[var(--inyc-input-border-color)]"
                }`}
            >
              recurring
            </div>
          </div>
          <Input Ref={nameRef} label="title" placeHolder="input event name" />
          <DropDownInput
            disabled
            showDropDownArrow
            style={{
              zIndex: 202,
              marginBottom: "4rem",
            }}
            label="category"
            value={category}
            dropItems={categories?.map((cat) => ({
              label: cat.id,
              onClick: () => {
                setCategory(cat.id);
              },
            }))}
          />
          <div>
            <TagInput
              copy={<div className="text-[0.9rem] px-[1rem]">
                use tags to help users find your events
              </div>}
              label="tags"
              placeHolder="add tags (optional)"
              ref={tagsRef}
            />
          </div>
          <div className="flex items-start flex-col justify-start flex-wrap">
            <div className="flex justify-between gap-[1rem] flex-wrap ">
              <div className="mb-[1rem] max-sm:flex-1">
                <div className="">
                  <div className="text-[1.4rem] font-['Primary-Medium'] mb-[0.5rem] text-[--inyc-primary-text-color]">
                    date
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // paddingLeft: "1rem",
                      justifyContent: "flex-start",
                    }}
                  >
                    <input
                      type="date"
                      name="startDate"
                      // className="!min-w-[27rem]"
                      value={formatDateForInput(startDate, true)}
                      onChange={(e) => {
                        if (e.target.value === "") return;
                        setStartDate(DateTime.fromISO(e.target.value).toJSDate());
                      }}
                    />
                  </div>
                  {timeErrors?.startDate && (
                    <ErrorMessage>{timeErrors?.startDate}</ErrorMessage>
                  )}
                </div>
              </div>
              <div className="flex justify-between flex-1 gap-[1rem]">
                <div className="mb-[1rem] flex-1">
                  <div className="font-['Primary-Medium'] text-[1.4rem] mb-[0.5rem]">
                    start
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // paddingLeft: "1rem",
                      justifyContent: "flex-start",
                    }}
                  >
                    <input
                      type="time"
                      step={900}
                      name="startTime"
                      value={startTime}
                      onChange={(e) => {
                        if (e.target.value === "") return;
                        setStartTime(e.target.value);
                      }}
                    />
                  </div>
                  {timeErrors?.startTime && (
                    <ErrorMessage>{timeErrors?.startTime}</ErrorMessage>
                  )}
                </div>
                <div className="flex-1">
                  <div className="font-['Primary-Medium'] text-[1.4rem] mb-[0.5rem]">
                    end
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <input
                      type="time"
                      step={900}
                      name="endTime"
                      value={endTime}
                      onChange={(e) => {
                        if (e.target.value === "") return;
                        setEndTime(e.target.value);
                      }}
                    />
                  </div>
                  {timeErrors?.endTime && (
                    <ErrorMessage>{timeErrors?.endTime}</ErrorMessage>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            {timeErrors?.general && (
              <ErrorMessage>{timeErrors?.general}</ErrorMessage>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                alignItems: "flex-start",
                gap: "1rem",
                paddingLeft: "1rem",
                marginBottom: "1rem",
              }}
            >
              {recurring && (
                <div className="py-[1rem] text-[1.4rem] max-w-full">
                  <div className="mb-[1rem] gap-[3.2rem] md:gap-[2.2rem] w-full flex justify-start items-center">
                    <div className="whitespace-nowrap">repeats every </div>
                    <div>
                      <DropDownInput
                        showDropDownArrow
                        style={{
                          marginTop: "0",
                          zIndex: 202,
                          flex: "1",
                        }}
                        value={repeatEveryPeriod || "day"}
                        dropItems={[
                          {
                            label: "day",
                            onClick: () => setRepeatEveryPeriod("day"),
                          },
                          {
                            label: "week",
                            onClick: () => setRepeatEveryPeriod("week"),
                          },
                          {
                            label: "month",
                            onClick: () => setRepeatEveryPeriod("month"),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {repeatEveryPeriod === "week" && (
                    <div className="mb-[1rem] w-full">
                      <div className="mb-[0.5rem]">on these days:</div>
                      <div className="flex w-full justify-start items-center gap-[1rem]">
                        <WeekDays
                          days={repeatOn}
                          onChange={(days) => {
                            setRepeatOn(days);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="mb-[1rem] gap-[1rem] w-full flex">
                    <div className="mb-[0.5rem] pt-[1rem]">ends</div>
                    <div className="pl-[1rem] flex flex-col gap-[1rem]">
                      <Radio
                        style={{
                          field: {
                            margin: "0 10px",
                          },
                          label: {
                            display: "none",
                          },
                        }}
                        type="radio"
                        value={repeatEnds}
                        onChange={(e) => {
                          setRepeatEnds(e);
                        }}
                        options={[
                          {
                            label: (
                              <>
                                <div className="max-w-full flex justify-start items-center gap-[1rem]">
                                  <div className="min-w-[45px]">on</div>
                                  <input
                                    type="date"
                                    className="!min-w-0"
                                    name="repeatEndsOnDate"
                                    value={formatDateForInput(
                                      repeatEndsOnDate,
                                      true
                                    )}
                                    onChange={(e) => {
                                      // console.log(e.target.value);
                                      if (e.target.value === "") return;
                                      setRepeatEndsOnDate(
                                        DateTime.fromISO(e.target.value).toJSDate()
                                      );
                                    }}
                                  />
                                </div>
                              </>
                            ),
                            value: "on",
                          },
                          {
                            label: "never",
                            value: "never",
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="text-[var(--inyc-primary-text-color)]/80 text-[1.4rem] mb-[0.5rem] gap-[1rem] w-full flex justify-start items-center">
                    - {rruleString} from{" "}
                    {new Date(`1970-01-01T${startTime}`).toLocaleTimeString(
                      "en-US",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}{" "}
                    to{" "}
                    {new Date(`1970-01-01T${endTime}`).toLocaleTimeString(
                      "en-US",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
            {allowExtraDates && (
              <div className="px-[1rem] py-[1.5rem] rounded-[var(--inyc-input-border-radius)] border-t-[1px] border-[var(--inyc-input-border-color)] shadow-sm bg-[var(--inyc-primary-light)] border-[1px]">
                <div
                  className="px-[1rem] mb-[1rem] flex justify-between items-center gap-[1rem]"
                  onClick={() => {
                    setMoreDatesNoticeOpen((prev) => !prev);
                  }}
                >
                  different hours on different days of the week?
                  <FaChevronDown
                    size={10}
                    className={`${moreDatesNoticeOpen ? "rotate-[180deg]" : ""
                      }`}
                  />
                </div>
                <div
                  className={`grid duration-200 overflow-hidden ${moreDatesNoticeOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                    }`}
                >
                  <div className="min-h-0">
                    <Notice className="mb-[2rem]">
                      &#8226; hit <strong>add more dates</strong> below to add
                      another group of times.
                      <br />
                      <br />
                      &#8226; note, each time a group of dates/times is added,
                      that group will appear as a separate event under the{" "}
                      <strong>posted events</strong> tab above
                    </Notice>
                  </div>
                </div>
                {extraDates?.length > 0 &&
                  extraDates.map((date, i) => (
                    <div
                      key={i}
                      className={
                        i !== 0 &&
                        "mt-[1rem] pt-[2rem] border-t-[1px] border-t-[--inyc-input-border-color]/10"
                      }
                    >
                      <div className="relative mb-[2rem] flex items-start flex-col justify-start flex-wrap">
                        <div
                          className=" ml-auto p-[0.7rem] mb-[1rem] shadow-md duration-300 rounded-full bg-red-500 hover:bg-red-700 top-0 right-0 cursor-pointer"
                          onClick={() => {
                            setExtraDates((prev) => {
                              const newDates = [...prev];
                              newDates.splice(i, 1);
                              return newDates;
                            });
                          }}
                        >
                          <IoCloseOutline size={20} color="white" />
                        </div>
                        <div className="mb-[1.5rem] w-full gap-[1rem] flex justify-center items-center">
                          <div
                            onClick={() => {
                              handleExtraDatesChange(
                                {
                                  recurring: false,
                                },
                                i
                              );
                            }}
                            className={`shadow-[var(--inyc-button-shadow)] font-bold flex-1 text-center text-[1.2rem] px-[1.5rem] py-[0.7rem] duration-300 cursor-pointer whitespace-nowrap rounded-[2rem] ${!extraDates[i]?.recurring
                              ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]"
                              : "border-[1px] border-[var(--inyc-input-border-color)]"
                              }`}
                          >
                            one-time
                          </div>
                          <div
                            onClick={() => {
                              handleExtraDatesChange(
                                {
                                  recurring: true,
                                },
                                i
                              );
                            }}
                            className={`shadow-[var(--inyc-button-shadow)] flex-1 text-center font-bold px-[1.5rem] py-[0.7rem] duration-300 cursor-pointer whitespace-nowrap rounded-[2rem] ${extraDates[i]?.recurring
                              ? "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)]"
                              : "border-[1px] border-[var(--inyc-input-border-color)]"
                              }`}
                          >
                            recurring
                          </div>
                        </div>
                        <div className="flex justify-between gap-[1rem] flex-wrap">
                          <div className="mb-[1rem] flex-1">
                            <div className="">
                              <div className="font-['Primary-Medium'] text-[1.4rem] mb-[0.5rem]">
                                date
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  // paddingLeft: "1rem",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <input
                                  type="date"
                                  name="startDate"
                                  // className="!min-w-[27rem]"
                                  value={formatDateForInput(
                                    extraDates[i]?.startDate,
                                    true
                                  )}
                                  onChange={(e) => {
                                    if (e.target.value === "") return;
                                    handleExtraDatesChange(
                                      {
                                        startDate: DateTime.fromISO(e.target.value).toJSDate(),
                                      },
                                      i
                                    );
                                  }}
                                />
                              </div>
                              {timeErrors?.[`extraDates[${i}].startDate`] && (
                                <ErrorMessage>
                                  {timeErrors?.[`extraDates[${i}].startDate`]}
                                </ErrorMessage>
                              )}
                            </div>
                            <div className="flex gap-[1rem] flex-1">
                              <div className="flex-1">
                                <div className="font-['Primary-Medium'] text-[1.4rem] mb-[0.5rem]">
                                  start
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    // paddingLeft: "1rem",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <input
                                    type="time"
                                    step={900}
                                    name="startTime"
                                    value={extraDates[i]?.startTime}
                                    onChange={(e) => {
                                      if (e.target.value === "") return;
                                      handleExtraDatesChange(
                                        {
                                          startTime: e.target.value,
                                        },
                                        i
                                      );
                                    }}
                                  />
                                </div>
                                {timeErrors?.[`extraDates[${i}].startTime`] && (
                                  <ErrorMessage>
                                    {timeErrors?.[`extraDates[${i}].startTime`]}
                                  </ErrorMessage>
                                )}
                              </div>
                              <div className="flex-1">
                                <div className="font-['Primary-Medium'] text-[1.4rem] mb-[0.5rem]">
                                  end
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <input
                                    type="time"
                                    step={900}
                                    name="endTime"
                                    value={extraDates[i]?.endTime}
                                    onChange={(e) => {
                                      if (e.target.value === "") return;

                                      handleExtraDatesChange(
                                        {
                                          endTime: e.target.value,
                                        },
                                        i
                                      );
                                    }}
                                  />
                                </div>
                                {timeErrors?.[`extraDates[${i}].endTime`] && (
                                  <ErrorMessage>
                                    {timeErrors?.[`extraDates[${i}].endTime`]}
                                  </ErrorMessage>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                          alignItems: "flex-start",
                          gap: "1rem",
                          paddingLeft: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        {extraDates[i]?.recurring && (
                          <div className="py-[1rem] text-[1.4rem] max-w-full">
                            <div className="mb-[1rem] gap-[1.3rem] md:gap-[1rem] w-full flex justify-start items-center">
                              <div className="whitespace-nowrap">
                                repeats every{" "}
                              </div>
                              <div>
                                <DropDownInput
                                  showDropDownArrow
                                  style={{
                                    marginTop: "0",
                                    zIndex: 202,
                                    flex: "1",
                                  }}
                                  value={
                                    extraDates[i]?.repeatEveryPeriod || "day"
                                  }
                                  dropItems={[
                                    {
                                      label: "day",
                                      onClick: () => {
                                        handleExtraDatesChange(
                                          {
                                            repeatEveryPeriod: "day",
                                          },
                                          i
                                        );
                                      },
                                    },
                                    {
                                      label: "week",
                                      onClick: () => {
                                        handleExtraDatesChange(
                                          {
                                            repeatEveryPeriod: "week",
                                          },
                                          i
                                        );
                                      },
                                    },
                                    {
                                      label: "month",
                                      onClick: () => {
                                        handleExtraDatesChange(
                                          {
                                            repeatEveryPeriod: "month",
                                          },
                                          i
                                        );
                                      },
                                    },
                                  ]}
                                />
                              </div>
                            </div>

                            {extraDates[i]?.repeatEveryPeriod === "week" && (
                              <div className="mb-[1rem] w-full">
                                <div className="mb-[0.5rem]">
                                  on these days:
                                </div>
                                <div className="flex w-full justify-start items-center gap-[1rem]">
                                  <WeekDays
                                    days={extraDates[i]?.repeatOn}
                                    onChange={(days) => {
                                      handleExtraDatesChange(
                                        {
                                          repeatOn: days,
                                        },
                                        i
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="mb-[1rem] gap-[1rem] w-full flex">
                              <div className="mb-[0.5rem] pt-[1rem]">ends</div>
                              <div className="pl-[1rem] flex flex-col gap-[1rem]">
                                <Radio
                                  style={{
                                    field: {
                                      margin: "0 10px",
                                    },
                                    label: {
                                      display: "none",
                                    },
                                  }}
                                  type="radio"
                                  value={extraDates[i]?.repeatEnds}
                                  onChange={(e) => {
                                    handleExtraDatesChange(
                                      {
                                        repeatEnds: e,
                                      },
                                      i
                                    );
                                  }}
                                  options={[
                                    {
                                      label: (
                                        <>
                                          <div className="max-w-full flex justify-start items-center gap-[1rem]">
                                            <div className="">on</div>
                                            <input
                                              type="date"
                                              className="!min-w-0"
                                              name="repeatEndsOnDate"
                                              value={formatDateForInput(
                                                extraDates[i]?.repeatEndsOnDate,
                                                true
                                              )}
                                              onChange={(e) => {
                                                // console.log(e.target.value);
                                                if (e.target.value === "")
                                                  return;
                                                handleExtraDatesChange(
                                                  {
                                                    repeatEndsOnDate: DateTime.fromISO(e.target.value).toJSDate(),
                                                  },
                                                  i
                                                );
                                              }}
                                            />
                                          </div>
                                        </>
                                      ),
                                      value: "on",
                                    },
                                    {
                                      label: "never",
                                      value: "never",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="text-[var(--inyc-primary-text-color)]/80 text-[1.4rem] mb-[0.5rem] gap-[1rem] w-full flex justify-start items-center">
                              - {extraDates[i]?.rruleString} from{" "}
                              {new Date(
                                `1970-01-01T${extraDates[i]?.startTime}`
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}{" "}
                              to{" "}
                              {new Date(
                                `1970-01-01T${extraDates[i]?.endTime}`
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                <div
                  onClick={() => {
                    // console.log("add date");
                    setExtraDates((prev) => {
                      const defaultData = extraDates[extraDates.length - 1] || {
                        startDate: startDate,
                        startTime: startTime,
                        endTime: endTime,
                        endDate: endDate,
                        recurring: recurring,
                        repeatEveryPeriod: repeatEveryPeriod,
                        repeatEveryNumber: repeatEveryNumber,
                        repeatOn: repeatOn,
                        repeatEnds: repeatEnds,
                        repeatEndsOnDate: repeatEndsOnDate,
                        repeatEndsAfterNumber: repeatEndsAfterNumber,
                        rruleString: rruleString,
                      };
                      const newDate = defaultData;

                      return [...prev, newDate];
                    });
                    setTimeErrors(null);
                  }}
                  className="shadow-sm border-[1px] border-[var(--inyc-input-border-color)] gap-[0.5rem] hover:bg-[var(--inyc-primary)]/10 duration-300 cursor-pointer text-[1.2rem] bg-[var(--inyc-primary)]/5 px-[1.5rem] py-[0.7rem] justify-start items-center rounded-[2rem] flex w-fit"
                >
                  <div className="text-[var(--inyc-primary-text-color)]/50 p-[0.4rem] rounded-[0.2rem] ">
                    <FaPlus size={15} />
                  </div>
                  <div>add more dates</div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                color: "var(--inyc-primary-text-color)",
                fontSize: "1.4rem",
                marginBottom: "0.5rem",
              }}
              className="font-['Primary-Medium']"
            >
              image
            </div>
            <div
              style={{
                color: "var(--inyc-primary-text-color)",
                marginBottom: "2rem",
                marginLeft: "1rem",
              }}
            >
              upload up to 10 images of your event
            </div>
            <ImageSlider
              className={(hasImages) => `!mx-auto ${hasImages ? "!h-[350px]" : "!h-[160px]"} !w-full md:!h-[350px] md:!w-[350px]`}
              edit
              ref={imagesRef}
            />
          </div>
          <Input
            Ref={descriptionRef}
            label="description"
            placeHolder="“add an event description, keep it brief, and let users know what to expect. for example “doors are at 7pm, show starts at 8pm!”"
            multiLine
            style={{ minHeight: "160px"}}
          />
          <SocialsHandler ref={socialsRef} />
          <div
            classNAme=""
            style={{
              marginBottom: "2rem",
            }}
          >
            <AddressHandler
              ref={addressHandlerRef}
              showVenueCard
              recommendCheckbox={recommend}
              mapMarkers={venuesToMarkers(venues)}
              onChange={(e) => {
                if (e.location?.coordinates) {
                  fetchVenues({
                    lat: e.location.coordinates[1],
                    lng: e.location.coordinates[0]
                  });
                }
              }}
              initialValue={
                {
                  address: edit?.address,
                  location: edit?.location,
                  venue: edit?.venue
                }
              }
              renderSuggestion={(suggestion, i) => {
                const verified = !!suggestion._id;
                    const mainText = verified ? suggestion.name : suggestion.structured_formatting.main_text;
                    const secondaryText = verified ? suggestion.address : suggestion.description;

                return (
                  <div
                  // className="relative border-[1px] border-[var(--inyc-input-border-color)] bg-[var(--inyc-primary-light)] p-[1rem] whitespace-nowrap max-w-[250px] text-[0.8rem] shadow-md rounded-lg cursor-pointer"
                  >
                    {suggestion._id && (
                      <div className="px-[0.5rem] py-[0.1rem] flex justify-center items-center gap-[0.2rem] absolute top-[-1rem] right-[-0.5rem] bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)] rounded-lg">
                        <img
                          src={Logo}
                          alt="inyc verified"
                          className="w-[1.5rem] h-[1.5rem] object-contain invert-[var(--inyc-invert-white)]"
                        />
                        <div className="leading-[1rem] text-[var(--inyc-secondary-text-color)] font-['Primary-Regular'] text-[0.7rem]">
                          verified
                        </div>
                      </div>
                    )}
                    <div>
                      {mainText}
                      {secondaryText && (
                        <div className="text-gray-500 max-w-full overflow-hidden overflow-ellipsis">
                          {secondaryText}
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            }
              userLocation={userLocation}
            />
          </div>
          <Input
            style={{}}
            Ref={ageRestrictionRef}
            label="minimum age"
            placeHolder="(optional)"
            className={'mb-[2rem]'}
          />
          <div>
          <div className={"mb-[4rem] mt-[-1.5rem] "}>

            <div className="text-[1.4rem] font-['Primary-Medium']  mb-[1rem]">
              ticketing
            </div>
            <Tabs
              currentTab={ticketed ? "tickets required" : "no tickets"}
              setCurrentTab={(e) => {
                setTicketed(e === "tickets required");
              }}
              tabs={["no tickets", "tickets required"]}
            >
              <TabContent name="no tickets"></TabContent>
              <TabContent
                name="tickets required"
                className="pt-[1.5rem] flex flex-col gap-[1.5rem]"
              >
                <Input
                  Ref={ticketPriceRef}
                  label="ticket price"
                  formatValue={(value, prev) => {
                    // Regular expression to match a dollar sign followed by numbers and an optional decimal point with two digits
                    const allowed = /^\$[0-9]*(\.[0-9]{0,3})?$/;
                    // if value doesnt have a $ sign, add it
                    if (value && value[0] !== "$") {
                      value = `$${value}`;
                    }

                    // Check if the value matches the allowed pattern, and if not, revert to the previous value
                    if (value === "$" || allowed.test(value)) {
                      return value;
                    } else {
                      return prev;
                    }
                  }}
                  defaultValue={"$"}
                  style={{
                    display: ticketed ? "block" : "none",
                  }}
                />
                <Input
                  Ref={ticketLinkRef}
                  placeHolder="input ticket link"
                  label="ticket link"
                  style={{
                    display: ticketed ? "block" : "none",
                  }}
                />
              </TabContent>
            </Tabs>
           
          </div>
         </div>
          <Input
            style={{}}
            Ref={payTheArtistRef}
            label="payment"
            placeHolder="paste url here (optional)"
            copy={
              <div className="px-[1rem] text-[0.9rem] text-[var(--inyc-primary-text-color)]/60 mb-[1rem] mt-[-1rem]">
                allows users to pay you direclty via venmo, paypal, etc.
              </div>
            }
          />
      
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default EditEvent;
