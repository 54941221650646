import React, { useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import LocationSVG from "../icons/LocationSVG";
import { Marker, Popup } from "react-leaflet";
import { IoLocationSharp } from "react-icons/io5";
import { ImHome } from "react-icons/im";

export const MarkerPopupButton = ({ Icon, iconsize = 12,text, onClick }) => {
  return (
    <div
      className="flex items-center justify-start cursor-pointer px-[0.7rem] py-[0.5rem] rounded-[0.2rem] w-full hover:text-[--inyc-primary-text-color] transition-all duration-300 hover:bg-[--inyc-primary-light] text-[--inyc-primary-text-color] text-[1rem]"
      style={{ width: '100%' }}
      onClick={onClick}
    >
      <Icon
        className="mr-[0.5rem]"
        size={12}
      />
      <div
        className="text-[1rem] leading-[1.5rem] text-[--inyc-primary-text-color]"
      >
        {text}
      </div>
    </div>
  )
}

function MapMarker({ zIndexOffset, data, Popup, onClick, selected, selectedIcon, noHover = false, icon, position, onVenueSelect, onCustomMarkerAdd, ignoreNextMapClickRef }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <Marker
      zIndexOffset={zIndexOffset}
      icon={icon}
      position={position}
      eventHandlers={{
        click: (e) => {
          if (Popup) {
            setIsPopupOpen(true);
            ignoreNextMapClickRef.current = true;
          } else {
            onClick?.(e);
          }
        }
      }}
    >
      {isPopupOpen &&
        <Popup
          onClose={() => {
            // ignoreNextMapClickRef.current = true;
            setIsPopupOpen(false);
          }}
          onOpen={() => setIsPopupOpen(true)}
          eventHandlers={{
            click: (e) => {
              e?.stopPropagation();
              e?.preventDefault();
            }
          }}
          {...data?.props}
        />
      }
    </Marker>
  );
}

export default MapMarker;
