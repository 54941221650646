import React, { useEffect, useState } from "react";
import "./Switch.css";

const Switch = (props) => {
  const { value, onChange } = props;
  const isControlled = value !== undefined;
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    if (isControlled) {
      setChecked(value);
    }
  }, [value]);

  const handleChange = () => {
    if (isControlled) {
      onChange(!checked);
    } else {
      setChecked(!checked);
    }
  };
  return (
    <div className="">
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default Switch;
