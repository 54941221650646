import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUIContext } from "../UIContext/UIContext";
import { DateTime } from "luxon";
import { backendUrl } from "../../api/constants";
import axiosInstance from "../../api/axiosInstance";
import { convertEventDate } from "../../util/eventUtils";
import { enqueueSnackbar } from "notistack";
import { useData } from "../DataContext/DataProvider";
import isEqual from "lodash/isEqual";
import { defaultDateFilter } from "../../util/dataUtils";
import { useUser } from "../UserContext/UserProvider";
const mapContext = createContext();

export const MapProvider = ({ children }) => {
  const [mapLocations, setMapLocations] = useState([]);
  const [mapPage, setMapPage] = useState({
    page: 1,
    limit: 10,
  });
  const [totalResults, setTotalResults] = useState();
  const [mapResults, setMapResults] = useState([]);
  const { likedFilter, posterFilter, activeCategories } = useUIContext();
  const [mapLoading, setMapLoading] = useState(false);
  const [mapPageLoading, setMapPageLoading] = useState(false);
  const { selectedTimezone, userLocation } = useData();
  const { user } = useUser();
  const [dateFilter, setDateFilter] = useState();
  const [tagFilter, setTagFilter] = useState([]);
  const memoizedDateFilter = useMemo(
    () => defaultDateFilter(selectedTimezone),
    [selectedTimezone]
  );
  const previousFilter = useRef();

  const handleSpecialFilter = (locs) => {
    let filterFunction;
    const filter = dateFilter;
    switch (filter) {
      case "now":
        filterFunction = (ev, { nowZoned }) => {
          const date = ev.nextOccurence || ev.date;
          return date.start <= nowZoned && date.end >= nowZoned;
        };
        break;

      case "soon":
        filterFunction = (ev, { threeHoursFromNow, now }) => {
          const date = ev.nextOccurence || ev.date;
          return (
            (date.start < threeHoursFromNow && date.end > now) ||
            (date.end < threeHoursFromNow && date.end > now)
          );
        };
        break;

      case "later":
        filterFunction = (ev, { endOfToday, now }) => {
          const date = ev.nextOccurence || ev.date;
          return date.start <= endOfToday && date.end >= now;
        };
        break;

      case "late night":
        filterFunction = (ev, { tenPM, sixAM }) => {
          const date = ev.nextOccurence || ev.date;
          return date.end >= tenPM && date.start <= sixAM;
        };
        break;

      default:
        filterFunction = (e) => true;
        break;
    }

    // console.log("filterFunction ", filterFunction);
    const filtered = locs?.filter((e) => {
      const relativeDates = {
        now: DateTime.local(),
      };

      relativeDates.nowZoned = relativeDates.now.setZone(e.timezone[0]);

      relativeDates.threeHoursFromNow = relativeDates.now.plus({
        hours: 3,
      });

      relativeDates.endOfToday = relativeDates.nowZoned.endOf("day");
      relativeDates.tenPM = relativeDates.nowZoned.set({
        hour: 22,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      relativeDates.sixAM = relativeDates.nowZoned
        .plus({ days: 1 })
        .set({ hour: 6, minute: 0, second: 0, millisecond: 0 });

      if (e.type === "event") {
        return filterFunction(e, relativeDates);
      } else {
        e.events = e.events?.filter((o) => filterFunction(o, relativeDates));
        // if event._matchingEvent doesnt match the filter, then use the first event of the filtered list
        if (e._matchingEvent) {
          const matchingEvent = e.events.find(
            (ev) => ev._id === e._matchingEvent
          );
          if (matchingEvent) {
            e._matchingEvent = matchingEvent;
          } else {
            e._matchingEvent = e.events[0];
          }
        }

        return e.events?.length > 0;
      }
    });

    // console.log("filtered ", filtered);

    return filtered;
  };

  const fetchFilteredEvents = async (options) => {
    let opts = options;
    opts.setLoading && opts.setLoading(true);
    const specialDateFilter = ["now", "soon", "later", "late night"].includes(
      opts.dateFilter
    );
    // console.log("MapContext specialDateFilter ", specialDateFilter);
    if (specialDateFilter) {
      opts.specialDateFilter = true;
      const ddate = memoizedDateFilter;
      opts.from = ddate.start;
      opts.to = ddate.end;
    }

    opts.onSuccess = (results) => {
      const res = results?.data;
      // console.log("MapContext results ", res);
      let totalResults = results?.totalResults;
      if (res) {
        let presults;
        if (specialDateFilter) {
          presults = handleSpecialFilter(res);
          //resort results
          presults = presults?.sort((a, b) => {
            const aDate =
              a.nextOccurence?.start ||
              a.date?.start ||
              a._matchingEvent?.nextOccurence?.start ||
              a._matchingEvent?.date?.start;
            const bDate =
              b.nextOccurence?.start ||
              b.date?.start ||
              b._matchingEvent?.nextOccurence?.start ||
              b._matchingEvent?.date?.start;
            return aDate - bDate;
          });
          totalResults = presults.length;
          //minifedResults should also be updated
          results.minifiedResults = presults;
        } else {
          presults = res;
        }
        // console.log("MapContext presults ", presults);
        if (mapPage.page === 1) {
          setMapLocations(presults);
        } else {
          setMapLocations((prev) => [...prev, ...presults]);
        }
      }

      // console.log("MapContext totalResults after ", totalResults);
      setTotalResults(totalResults);
      // console.log("MapContext Minified Results : ", results?.minifiedResults);
      setMapResults(results?.minifiedResults);
      setMapLoading(false);
    };

    await performSearch(opts);
    setMapLoading(false);
  };

  const refreshEvents = () => {
    // console.log("called refresh events ", {
    //   categories: activeCategories,
    //   from: dateFilter?.start,
    //   to: dateFilter?.end,
    //   dateFilter,
    //   tagFilter,
    //   setLoading: setMapPageLoading,
    //   timezone: selectedTimezone,
    // });
    fetchFilteredEvents({
      categories: activeCategories,
      from: dateFilter?.start,
      to: dateFilter?.end,
      dateFilter,
      tagFilter,
      setLoading: setMapPageLoading,
      timezone: selectedTimezone,
    });
  };

  const performSearch = async (options) => {
    try {
      const ddate = memoizedDateFilter;

      // console.log("MapContext pre-filter dates ", options.from, options.to);
      if (!options.from) {
        options.from = ddate.start;
      }
      if (!options.to) {
        options.to = ddate.end;
      }
      const from = options.from;
      const to = options.to;
      let fromDate, toDate;
      const timezone = validateTimezone(options.timezone);
      // console.log("MapContext timezone ", timezone);
      const venue = options.venue;

      // safety check
      if ((!userLocation?.lat || !userLocation?.lng) && !venue) {
        options.setLoading && options.setLoading(false);
        return;
      }
      // console.log("performing search with : ", userLocation, venue);

      // Date filter has to be considered in the selected event time zone and then convert it to utc
      if (from) {
        fromDate = DateTime.fromJSDate(from)
          .setZone(timezone, {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO();
      }
      if (to) {
        toDate = DateTime.fromJSDate(to)
          .setZone(timezone, {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO();
      }
      // console.log("fromDate ", from, fromDate);
      // console.log("toDate ", to, toDate);
      options.setLoading && options.setLoading(true);
      let url = `${backendUrl}/events/search?includeMinifiedResults=true&`;
      url += `categories=${activeCategories.join(",")}&`;
      //add excludePastEvents query
      url += `excludePastEvents=true&`;
      // console.log("MapContext specialDateFilter ", options.specialDateFilter);
      if (options.specialDateFilter) {
        url += `page=1&limit=all&`;
      } else {
        url += `page=${mapPage.page || 1}&limit=${mapPage.limit || 10}&`;
      }
      if (userLocation?.lat && userLocation?.lng) {
        url += `lat=${userLocation.lat}&lng=${userLocation.lng}&`;
      }
      if (likedFilter) {
        url += `liked=${likedFilter}&`;
      }
      if (posterFilter) {
        url += `posterliked=${posterFilter}&`;
      }
      if (fromDate) {
        url += `from=${fromDate}&`;
      }
      if (toDate) {
        url += `to=${toDate}`;
      }
      if(tagFilter){
        // tagFilter is an array of tags
        url += `&tags=${tagFilter.join(",")}`;
      }

      // console.log("URL ", url);

      const { data } = await axiosInstance.get(url);
      options.setLoading && options.setLoading(false);
      if (!options.specialDateFilter) {
        setMapLoading(false);
      }
      if (data.error) {
        enqueueSnackbar(data.error, { variant: "error" });
        return;
      }
      // console.log("MapContext preformat search data :", data.data);
      const searchData = data.data;
      const totalResults = data.totalResults;
      const minifiedResults = data.minifiedResults;
      //TODO centralize this
      const formateddata = searchData?.map((item) => {
        //~ Convert Date to Local
        if (item.type === "event") {
          convertEventDate(item);
        } else if (item.type === "venue" && item.events) {
          item.events.forEach((event) => {
            convertEventDate(event);
          });
          if (item._matchingEvent) {
            convertEventDate(item._matchingEvent);
          }
        }

        return item;
      });

      // console.log("MapContext search data ", formateddata);

      options.onSuccess &&
        options.onSuccess({
          data: formateddata,
          totalResults: totalResults,
          minifiedResults: minifiedResults,
        });
    } catch (error) {
      console.log(error);
      options.setLoading && options.setLoading(false);
      setMapLoading(false);
    }
  };

  useEffect(() => {
    if (
      user &&
      userLocation?.lat &&
      userLocation?.lng &&
      selectedTimezone &&
      dateFilter
    ) {
      if (
        !isEqual(
          {
            dateFilter,
            tagFilter,
            userLocation,
            selectedTimezone,
            activeCategories,
            likedFilter,
            posterFilter,
            mapPage,
          },
          previousFilter.current
        )
      ) {
        // console.log("calling refresh events from effect ", {
        //   dateFilter,
        //   tagFilter,
        //   userLocation,
        //   selectedTimezone,
        //   activeCategories,
        //   likedFilter,
        //   posterFilter,
        //   mapPage,
        // });
        refreshEvents();
      }

      //set previous values
      previousFilter.current = {
        dateFilter,
        tagFilter,
        userLocation,
        selectedTimezone,
        activeCategories,
        likedFilter,
        posterFilter,
        mapPage,
      };
    }
  }, [
    user,
    dateFilter,
    tagFilter,
    userLocation?.lat,
    selectedTimezone,
    userLocation?.lng,
    activeCategories,
    likedFilter,
    posterFilter,
    mapPage,
  ]);

  useEffect(() => {
    setMapPage({
      page: 1,
      limit: 10,
    });
    if (!dateFilter) {
      setDateFilter(memoizedDateFilter);
    }
    setMapLoading(true);
  }, [
    dateFilter,
    tagFilter,
    userLocation?.lat,
    selectedTimezone,
    userLocation?.lng,
    activeCategories,
    likedFilter,
    posterFilter,
  ]);

  useEffect(() => {
    if (selectedTimezone) {
      console.log("MapContext selectedTimezone ", selectedTimezone);
      setDateFilter(memoizedDateFilter);
    }
  }, [selectedTimezone]);

  const value = {
    mapLocations,
    setMapLocations,
    dateFilter,
    setDateFilter,
    tagFilter,
    setTagFilter,
    mapLoading,
    setMapLoading,
    mapPageLoading,
    setMapPageLoading,
    refreshEvents,
    mapPage,
    setMapPage,
    totalResults,
    setTotalResults,
    mapResults,
    setMapResults,
    defaultDateFilter : memoizedDateFilter,
  };
  return <mapContext.Provider value={value}>{children}</mapContext.Provider>;
};

export const useMapContext = () => {
  const context = useContext(mapContext);
  if (!context) {
    throw new Error("useMap must be used within a MapProvider");
  }
  return context;
};

// Add timezone utilities
const validateTimezone = (timezone) => {
  try {
    DateTime.local().setZone(timezone);
    return timezone;
  } catch (e) {
    console.warn(`Invalid timezone ${timezone}, falling back to UTC`);
    return 'UTC';
  }
};
