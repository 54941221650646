import { enqueueSnackbar } from "notistack";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useData } from "../DataContext/DataProvider";
import { useUIContext } from "../UIContext/UIContext";
import axiosInstance from "../../api/axiosInstance";
import { backendUrl } from "../../api/constants";
import { convertEventDate } from "../../util/eventUtils";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import { useLocation } from "react-router-dom";
import { defaultDateFilter } from "../../util/dataUtils";
import { useEventDetails } from "../EventDetailsProvider/EventDetailsProvider";
import { getAccountById } from "../../api/events";
import { handleSpecialFilter } from "../../util/eventUtils";

const AccountDetailsContext = createContext();

export const AccountDetailsProvider = ({ children }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [queryFilter, setQueryFilter] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [tagFilter, setTagFilter] = useState([]);
  const [accountDetailsSearchLoading, setAccountDetailsSearchLoading] =
    useState(false);
  const { userLocation } = useData();
  const { selectedEvent } = useEventDetails();
  const { activeCategories } = useUIContext();
  const prevDateFilter = useRef();
  const prevQueryFilter = useRef();
  const location = useLocation();

  const fetchFilteredEvents = async (options) => {
    let opts = options;
    opts.setLoading && opts.setLoading(true);
    const specialDateFilter = ["now", "soon", "later", "late night"].includes(
      opts.dateFilter
    );
    // console.log("specialDateFilter ", specialDateFilter);
    if (specialDateFilter) {
      const ddate = defaultDateFilter(opts.timezone);
      opts.from = ddate.start;
      opts.to = ddate.end;
    } else if (!opts.from && !opts.to) {
      const ddate = defaultDateFilter(opts.timezone, {
        endPlus: { months: 6 },
      });
      opts.from = ddate.start;
      opts.to = ddate.end;
    }

    opts.onSuccess = (results) => {
      // console.log("results ", results);
      if (results) {
        if (specialDateFilter) {
          const presults = handleSpecialFilter(results, opts.dateFilter);
          // console.log("presults ", presults);
          setFilteredEvents(presults);
        } else {
          setFilteredEvents(results);
        }
      }
    };

    performSearch(opts);
  };

  const performSearch = useCallback(
    debounce(async (options) => {
      const query = options.search;
      const venue = options.venue;
      const from = options.from;
      const to = options.to;
      const account = options.account;
      // console.log("search query", query);
      let fromDate, toDate;
      const timezone = options.timezone || "UTC";
      // console.log("timezone ", timezone);
      const tags = options.tagFilter;
      // Date filter has to be considered in the selected event time zone and then convert it to utc
      if (from) {
        fromDate = DateTime.fromJSDate(from)
          .setZone(timezone, {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO();
      }
      if (to) {
        toDate = DateTime.fromJSDate(to)
          .setZone(timezone, {
            keepLocalTime: true,
          })
          .toUTC()
          .toISO();
      }

      options.setLoading && options.setLoading(true);
      let url = `${backendUrl}/events/search?`;
      url += `limit=all&`;
      url += `account=${account}&`;
      url += `categories=${activeCategories.join(",")}&`;
      if (query) {
        url += `search=${query}&`;
      }
      if (tags) {
        url += `tags=${tags.join(",")}&`;
      }
      if (venue) {
        url += `venue=${venue}&`;
      }
      if (fromDate) {
        url += `from=${fromDate}&`;
      }
      if (toDate) {
        url += `to=${toDate}`;
      }
      const { data } = await axiosInstance.get(url);
      options.setLoading && options.setLoading(false);
      if (data.error) {
        enqueueSnackbar(data.error, { variant: "error" });
        return;
      }
      // console.log("preformat search data :", data.data);
      const searchData = data.data || [];

      //TODO centralize this
      const formateddata =
        searchData?.map((item) => {
          //~ Convert Date to Local
          if (item.type === "event") {
            convertEventDate(item);
          } else if (item.type === "venue" && item.events) {
            item.events.forEach((event) => {
              convertEventDate(event);
            });
            if (item._matchingEvent) {
              convertEventDate(item._matchingEvent);
            }
          }

          return item;
        }) || [];

      // console.log("search data ", formateddata);

      options.onSuccess && options.onSuccess(formateddata);
    }, 500),
    [userLocation?.lat, userLocation?.lng, activeCategories, tagFilter]
  );

  // useEffect(() => {
  //     console.log("filteredEvents ", filteredEvents);
  // },[filteredEvents]);

  useEffect(() => {
    if (!selectedAccount?._id) {
      return;
    }

    if (selectedAccount) {
      fetchFilteredEvents({
        search: queryFilter,
        categories: activeCategories,
        // venue: selectedEvent?._id,
        from: dateFilter?.start,
        to: dateFilter?.end,
        dateFilter,
        tagFilter,
        account: selectedAccount._id,
        setLoading: setAccountDetailsSearchLoading,
        timezone: selectedEvent?.timezone[0],
      });
    }
    // }

    prevDateFilter.current = dateFilter;
    prevQueryFilter.current = queryFilter;
  }, [
    dateFilter,
    tagFilter,
    queryFilter,
    selectedAccount?._id,
    activeCategories,
  ]);

  // useEffect(() => {
  //   console.log("filteredEvents ", filteredEvents);
  // }, [filteredEvents]);

  //#regi
  const fetchAccount = async (accountId) => {
    try {
      const {data} = await axiosInstance.get(`${backendUrl}/user/${accountId}`);
      console.log(">>>>>>>>>>> fetched account data :", data);

      if (data.error) {
        enqueueSnackbar(data.error, {
          variant: "error",
        });
        return;
      }
      if(data?.account){
        setSelectedAccount(data?.account);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("failed to fetch account details", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!selectedAccount?._id) {
      return;
    }

    fetchAccount(selectedAccount._id);
  }, [selectedAccount?._id]);
  // on fetch event details

  const fetchAccountDetails = async (accountId) => {
    try {
      if (!accountId) {
        return;
      }
      const acc = await getAccountById(accountId);
      setSelectedAccount(acc);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("failed to fetch account details", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const handleLocationSearch = () => {
      const queryParams = new URLSearchParams(location.search);
      const accountId = queryParams.get("account");
      if (accountId) {
        fetchAccountDetails(accountId);
      }
    };

    window.addEventListener("popstate", handleLocationSearch);
    handleLocationSearch();

    return () => window.removeEventListener("popstate", handleLocationSearch);
    // }
  }, [location]);

  //TODO useEffect to fetch account details

  // useEffect(() => {
  //   console.log("selectedAccount ", selectedAccount);
  //   // fetch user info

  // }, [selectedAccount?._id]);
  //#endregion

  const selectAccount = (account) => {
    setSelectedAccount(account);
  };

  const value = {
    selectedAccount,
    setSelectedAccount,
    selectAccount,
    dateFilter,
    tagFilter,
    setTagFilter,
    setDateFilter,
    queryFilter,
    setQueryFilter,
    filteredEvents,
    setFilteredEvents,
    accountDetailsSearchLoading,
    setAccountDetailsSearchLoading,
  };
  return (
    <AccountDetailsContext.Provider value={value}>
      {children}
    </AccountDetailsContext.Provider>
  );
};

export const useAccountDetails = () => {
  const context = useContext(AccountDetailsContext);
  if (!context) {
    throw new Error(
      "useAccountDetails must be used within a AccountDetailsProvider"
    );
  }
  return context;
};
