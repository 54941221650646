import React from "react";
import logoLight from "../../../assets/imgs/logo-transparent-light.gif";
import { Link, useSearchParams } from "react-router-dom";
import ProfilePicture from "../../../components/icons/ProfilePicture";

const GoogleOAuthCallback = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const scopes = {
    "https://www.googleapis.com/auth/calendar": "permission to access your google calendar",
    "https://www.googleapis.com/auth/userinfo.email": "permission to read your email address",
    "https://www.googleapis.com/auth/userinfo.profile":
      "permission to read your profile information",
  };
  return (
    <div className="w-[90%] mx-auto max-w-[500px] px-[2rem] min-h-full flex flex-col justify-center items-center gap-[6rem]">
      <div className="px-[2rem] py-[1rem] top-0 left-0 w-full ">
        <img className="h-[200px] invert-[var(--inyc-invert-black)]" src={logoLight} alt="logo" />
      </div>
      <div className="text-center">
        <h2 className="mb-[2rem] text-[2rem] font-['Primary-Medium']">google calendar sync</h2>
        <div>
          {searchParams.get("status") === "success" ? (
            <div>
                <div className="mb-[1rem]">
                    <ProfilePicture 
                    style={{
                        margin: "0 auto 1rem auto",
                        width: "100px",
                        height: "100px",
                    }}
                    profilePicture={searchParams.get("profilepicture")} />
                    <h1 className="font-['Primary-Medium']">{searchParams.get("name")}</h1>
                </div>
              <div className="mb-[1rem] bg-green-100 text-green-700 p-[1rem] rounded-[5px] text-center">
                your google account has been connected successfully
              </div>
              <Link to="/dashboard?tab=calendar" className="text-[0.9rem] text-center">
                go back
              </Link>
            </div>
          ) : (
            searchParams.get("status") === "error" && (
              <div>
                <div className="mb-[1rem]">
                  {searchParams.get("message") === "missing_scopes" ? (
                    <div className="bg-red-100 text-red-700 p-[1rem] rounded-[5px]">
                      <div>missing permissions: </div>
                      <div className="pl-[1rem]">
                        {searchParams
                          .get("missingScopes")
                          ?.split(",")
                          .map((scope) => {
                            return <div>&bull; {scopes[scope]}</div>;
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="bg-red-100 text-red-700 p-[1rem] rounded-[5px]">
                      Failed to connect to google calendar
                    </div>
                  )}
                </div>
                <Link to="/dashboard?tab=business-tools" className="text-[0.9rem] text-center">
                  go back
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleOAuthCallback;
