import { socialOptions } from "./socials";

export const highlightMatches = (text, itemMatches, field, maxDis = 2) => {
  const match = itemMatches?.find((mt) => mt.key === field);
  if (!match || match.length === 0) return text;

  // Step 1: Sort indices by start positions and remove duplicates
  const sortedIndices = match.indices
    .slice()
    .sort((a, b) => a[0] - b[0] || a[1] - b[1]);

  // Step 2: Merge overlapping and nearby indices based on maxDis
  const mergedIndices = [];
  let currentStart = sortedIndices[0][0];
  let currentEnd = sortedIndices[0][1];

  for (let i = 1; i < sortedIndices.length; i++) {
    const [start, end] = sortedIndices[i];

    // If the current index range is close to the previous one (maxDis) or overlaps, merge them
    if (start <= currentEnd + maxDis) {
      currentEnd = Math.max(currentEnd, end);
    } else {
      // If it's too far, push the current merged range and start a new one
      mergedIndices.push([currentStart, currentEnd]);
      currentStart = start;
      currentEnd = end;
    }
  }

  // Push the last merged range
  mergedIndices.push([currentStart, currentEnd]);

  // Step 3: Find the longest merged range
  let longestRange = mergedIndices[0];
  for (const range of mergedIndices) {
    if (range[1] - range[0] > longestRange[1] - longestRange[0]) {
      longestRange = range;
    }
  }

  // Step 4: Highlight the longest range in the text
  const [start, end] = longestRange;

  // if longestRange is less than 3 dont do anything
  if (end - start < 2) {
    return text;
  }

  const highlightedText =
    text.slice(0, start) +
    `<span class="font-['Primary-Medium']">${text.slice(
      start,
      end + 1
    )}</span>` +
    text.slice(end + 1);

  return highlightedText;
};

export const getSocialOption = (link) => {
  try {
    const formattedLink =
      link.startsWith("http://") || link.startsWith("https://")
        ? link
        : `https://${link}`;
    const url = new URL(formattedLink);

    const matchedOption = socialOptions.find((option) =>
      option.patterns.some((pattern) => {
        // Strictly match the hostname or subdomain
        // console.log(url.hostname, pattern);
        return (
          url.hostname === pattern || // Exact match
          url.hostname.endsWith(`.${pattern}`) // Matches subdomains like "www.x.com"
        );
      })
    );

    if (matchedOption) {
      return matchedOption;
    }

    return socialOptions.find((option) => option.id === "website"); // Fallback
  } catch {
    return socialOptions.find((option) => option.id === "website"); // Fallback for invalid links
  }
};





