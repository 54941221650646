import React, { useCallback, useRef, useState } from "react";
import { useUIContext } from "../../context/UIContext/UIContext";
import { useSearchContext } from "../../context/SearchContext/SearchProvider";
import BackButton from "../Button/BackButton";
import Input from "../Input/Input";
import SearchSVG from "../icons/SearchSVG";
import InfiniteScroller from "../InfiniteScroller/InfiniteScroller";
import EventCard from "../EventCard/EventCard";
import Loader from "../icons/Loader";
import CheckBox from "../CheckBox/CheckBox";
import { useEventDetails } from "../../context/EventDetailsProvider/EventDetailsProvider";
import PullToActionWrapper from "../PullToActionWrapper/PullToActionWrapper";
import { FiSearch } from "react-icons/fi";

const SearchPage = () => {
  const { searchOpen, setSearchOpen } = useUIContext();
  const {
    processedSearchLocations,
    searchLocations,
    setSearchQuery,
    searchQuery,
    searchPageLoading,
    searchLoading,
    eventsOnly,
    setEventsOnly,
    setSearchPage,
    totalResults,
    refreshResults,
    autoSuggestion,
    setAutoSuggestion,
    handleSuggestionInteraction,
    isInteractingWithSuggestions
  } = useSearchContext();
  const { selectEvent, selectSubEvent, setFilteredEvents, setQueryFilter } =
    useEventDetails();
  const searchInputRef = useRef();
  const contentRef = useRef();
  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      if (!isInteractingWithSuggestions && autoSuggestion && autoSuggestion.length > 0) {
        setSearchQuery(autoSuggestion[0].fullName);
        setAutoSuggestion(null);
      }
    }
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setAutoSuggestion(null);
  };

  const onHintClick = () => {
    handleSuggestionInteraction(true);
    if (autoSuggestion && autoSuggestion.length > 0) {
      setSearchQuery(autoSuggestion[0].fullName);
      setAutoSuggestion(null);
    }
    handleSuggestionInteraction(false);
  };

  return (
    <div
      className={`map-events-container !pt-[0rem]
        ${!searchOpen ? "translate-x-[-100%]" : "translate-x-[0]"}`}
    >
      <div ref={contentRef} className="map-container-content mt-[0.5rem]">
        <BackButton
          onClick={() => {
            setSearchOpen(false);
          }}
        >
          search
        </BackButton>
        <div className="map-search-bar">
          {/* <div
            style={{
              textAlign: "center",
              fontFamily: "Primary-Light",
            }}
          >
            this searches events for keywords. search for an artist, band,
            location or business
          </div> */}
          <Input
            onKeyDown={handleKeyDown}
            placeHolder="search for events"
            Ref={searchInputRef}
            onChange={onSearchChange}
            value={searchQuery}
            hint={autoSuggestion?.[0]?.suggestion}
            icon={<FiSearch size={25} />}
            onHintClick={onHintClick}
          />
          {autoSuggestion && (
            <div
              className="flex flex-wrap items-center justify-start gap-[1rem]"
              onMouseEnter={() => handleSuggestionInteraction(true)}
              onMouseLeave={() => handleSuggestionInteraction(false)}
            >
              {autoSuggestion?.map(s => (
                <div
                  className="cursor-pointer duration-300 hover:text-[--inyc-primary-text-color] hover:bg-[--inyc-primary-ligher] text-[--inyc-primary-text-color-light] bg-[--inyc-primary-light] px-[0.5rem] py-[0.5rem] rounded-[1rem]"
                  onClick={() => {
                    setSearchQuery(s.fullName);
                    setAutoSuggestion(null);
                  }}
                >
                  {s.fullName.length > 15 ? s.fullName.slice(0, 15) + "..." : s.fullName}
                </div>
              ))}
            </div>
          )}
        </div>
        {searchLocations?.length > 0 && (
          <div className="w-fit pl-[1rem]">
            <CheckBox
              labelClassName={"!text-[1.2rem] !ml-[-0.3rem]"}
              checkboxClassName={"!min-w-[20px] !w-[20px] !h-[20px]"}
              value={eventsOnly}
              onChange={(e) => setEventsOnly(e)}
              label="only show events"
            />
          </div>
        )}
        {searchQuery.length > 0 && searchLoading ? (
          <div className="map-events-none !pt-[5rem]">
            <Loader />
          </div>
        ) : processedSearchLocations?.length === 0 ? (
          <div className="map-events-none">
            {searchQuery.length > 0 ? "no results found" : ""}
          </div>
        ) : (
          <>
            <div className="map-events-result-count">
              showing{" "}
              <span style={{ color: "var(--inyc-primary-text-color)" }}>
                {processedSearchLocations?.length}
              </span>{" "}
              out of{" "}
              <span style={{ color: "var(--inyc-primary-text-color)" }}>
                {totalResults}
              </span>{" "}
              results{" "}
              {searchQuery && searchQuery !== "" && (
                <span>
                  for "
                  <span style={{ color: "var(--inyc-primary-text-color)" }}>
                    {searchQuery}
                  </span>
                  "
                </span>
              )}
            </div>
            <PullToActionWrapper
              containerRef={contentRef}
              onPull={() => {
                setSearchPage({ page: 1, limit: 10 });
                refreshResults();
              }}
            >
              <InfiniteScroller
                hasMore={searchLocations?.length < totalResults}
                getData={() => {
                  // increate map page
                  setSearchPage((prev) => ({
                    page: prev.page + 1,
                    limit: prev.limit,
                  }));
                }}
                loading={searchPageLoading}
                items={processedSearchLocations}
                renderer={(event, i) => (
                  <EventCard
                    key={i}
                    hideEventCount
                    className="map-event-container"
                    latestEvent={event._matchingEvent}
                    data={event}
                    onClick={() => {
                      if (event.events?.length > 0 || event._venueMatches) {
                        setFilteredEvents(event?.events || []);
                      }
                      setQueryFilter("");
                      selectEvent(
                        event,
                        event._matchingEvent
                          ? {
                            ...event._matchingEvent,

                            _searchPageResult: true,
                          }
                          : null
                      );
                    }}
                  // onVenueClick={()=>{
                  //   if(!event?._venueMatches){
                  //     selectEvent(event, event._matchingEvent);
                  //   }
                  //   selectEvent(event);
                  //   setQueryFilter("");
                  // }}
                  />
                )}
              />
            </PullToActionWrapper>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
