import React, { useEffect, useState } from "react";
import "./EventCard.css";
import { backendUrl } from "../../api/constants";
import { AiFillHeart } from "react-icons/ai";
import { useUser } from "../../context/UserContext/UserProvider";
import { distance, formatDateTime } from "../../util/eventUtils";
import { highlightMatches } from "../../util/styleUtils";
import { useUIContext } from "../../context/UIContext/UIContext";
import { useData } from "../../context/DataContext/DataProvider";
import { loadIcon } from "../../util/iconRegistry";
import { useEventDetails } from "../../context/EventDetailsProvider/EventDetailsProvider";
import { useAccountDetails } from "../../context/AccountDetailsContext/AccountDetailsProvider";
import { FaEye } from "react-icons/fa6";

const CardTitle = ({ children }) => {
  return (
    <div
      className="text-[1.6rem] mb-[0.3rem] [&>*]:font-['Primary-Medium'] max-w-full leading-[1.9rem] flex justify-between"
      style={{
        color: "var(--inyc-primary-color)",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "2",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};

const EventCard = ({
  data,
  disabled,
  latestEvent,
  className = "default-event-container",
  onClick,
  onVenueClick,
  hideEventCount,
}) => {
  const [event, setEvent] = useState();
  const [imageExists, setImageExists] = useState(false);
  const { user } = useUser();
  const { likedEvents } = useUIContext();
  const { globalConfig, userLocation } = useData();
  const categories = globalConfig?.categories;
  const knownServices = globalConfig?.knownServices;
  const { selectAccount } = useAccountDetails();
  const { selectedEvent } = useEventDetails();

  useEffect(() => {
    setEvent(data);
  }, [data]);

  // declare latestEventDate which is latestEvent if it exists, otherwise it is the first of the event's events if it exists
  const latestEventData = event?._venueMatches
    ? event
    : latestEvent || event?.events?.[0] || event;
  const {
    formattedDate: latestEventformattedDate,
    formattedTime: latestEventformattedTime,
  } = formatDateTime(
    latestEventData?.nextOccurence || latestEventData?.date,
    event?.timezone || latestEventData?.timezone,
    {
      rrule: latestEventData?.rrule,
      recurring: latestEventData?.recurring,
      recurringEnd: latestEventData?.recurringEnd,
      frequency: latestEventData?.frequency,
    }
  );

  // console.log('timezone for event ', event?.name, ' is ', event?.timezone)

  const vmatches = event?.type === "venue" && event?._venueMatches;
  const eimages =
    event?.images?.length > 0
      ? event?.images
      : latestEventData?.images?.length > 0
      ? latestEventData?.images
      : [
          //default images go here
          "https://placehold.co/600x400/000000/FFF",
        ];

  // Perform a HEAD request to check if the image URL is valid
  useEffect(() => {
    const checkImage = async () => {
      if (eimages?.length > 0) {
        const imageurl = eimages[0]?.startsWith("http")
          ? eimages[0]
          : `${backendUrl}/assets/${eimages[0]}`;
        const response = await fetch(imageurl, { method: "HEAD" });
        if (response.status === 200) {
          setImageExists(true);
        } else {
          setImageExists(false);
        }
      }
    };
    checkImage();
  }, [eimages]);

  const likedLatestEvent =
    likedEvents?.find((e) => e._id === latestEventData?._id) || latestEventData;
  const likedEvent = likedEvents?.find((e) => e._id === event?._id) || event;

  const activeColor = categories?.find(
    (category) => category.id === latestEventData?.category
  )?.activeColor;

  const venueName =
    latestEventData?.venue === event?._id
      ? event?.name
      : event?.venueDetails?._id
      ? event?.venueDetails?.name
      : null;
  const location =
    event?.location ||
    selectedEvent?.location ||
    event?.venueDetails?.location ||
    latestEventData?.venueDetails?.location;

  // console.log('for event ',event?.name , ' location is ', location)

  //if eventdistance is not a valid number, set it to null
  let eventdistance =
    location?.coordinates &&
    distance(
      userLocation?.lat,
      userLocation?.lng,
      location?.coordinates[1],
      location?.coordinates[0]
    );

  if (isNaN(eventdistance)) {
    eventdistance = null;
  } else {
    eventdistance = eventdistance.toFixed(2);
  }

  const posterAccount = latestEventData?.account_id?._id
    ? latestEventData?.account_id
    : latestEventData?.accountDetails?._id
    ? latestEventData?.accountDetails
    : null;
  return (
    <div
      className={`relative md:max-w-[600px]
      ${disabled ? "opacity-[0.4]" : ""}`}
    >
      {event && (
        <div
          className={`event-container ${className} ${
            (!eimages || eimages.length === 0) && "no-image"
          }`}
          onClick={() => {
            if (event.type === "venue" && event.events?.length > 1) {
              onVenueClick ? onVenueClick() : onClick ? onClick() : null;
            } else {
              onClick && onClick();
            }
          }}
        >
          <div className="flex w-full mb-[0.5rem] justify-start overflow-hidden overflow-x-auto max-w-full items-stretch gap-[0.5rem]">
            {latestEventData?.type === "event" && (
              <div
                className="bg-[--inyc-primary-light] px-[0.5rem] py-[0.2rem] text-[0.8rem] rounded-[3px]"
                style={{
                  color: categories?.find(
                    (category) => category.id === latestEventData?.category
                  )?.activeColor,
                  // marginRight:
                  //   imageExists && event.images?.length > 0 ? "0" : "auto",
                  fontSize: "1.1rem",
                  fontFamily: "Primary-Medium",
                  // width: "100%",
                }}
                dangerouslySetInnerHTML={{
                  __html: latestEventData.category
                    ? highlightMatches(
                        latestEventData?.category?.toLowerCase(),
                        latestEventData?._matches,
                        "category"
                      )
                    : "venue",
                }}
              ></div>
            )}
            {latestEventData?.tags?.length > 0 &&
              [...new Set(latestEventData?.tags)]?.map((tag, i) => (
                <div
                  key={i}
                  className="font-['Primary-Medium'] px-[0.5rem] py-[0.2rem] text-[0.8rem] rounded-[3px] bg-[--inyc-primary-light] flex items-center gap-[0.5rem]"
                >
                  {knownServices[tag?.toLowerCase()]?.icon ? (
                    <div className="flex items-center justify-center gap-[0.5rem]">
                      <div className="w-[15px] h-[15px] ">
                        {loadIcon(knownServices[tag?.toLowerCase()]?.icon, {
                          size: 13,
                        })}
                      </div>
                      <div className="max-w-full font-['Primary-Medium']">
                        {knownServices[tag?.toLowerCase()]?.name}
                      </div>
                    </div>
                  ) : (
                    tag
                  )}
                </div>
              ))}

            {/* Add artist name tag */}
            {likedLatestEvent.likes?.includes(user?._id) && (
              <div className="flex items-center bg-[var(--inyc-primary-light)] px-[0.5rem] py-[0.2rem] text-[0.8rem] rounded-[3px]">
                <AiFillHeart
                  color={activeColor}
                  size={13}
                  className="translate-y-[-10%]"
                />
              </div>
            )}
            {posterAccount && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  selectAccount(posterAccount);
                }}
                className="ml-auto flex items-center justify-center font-['Primary-Regular'] whitespace-nowrap px-[0.7rem] py-[0.3rem] text-[0.9rem] rounded-[3px] bg-[--inyc-primary-light] text-[--inyc-secondary]"
              >
                <div className="text-[0.9rem]">
                  @{posterAccount?.businessName || posterAccount?.username}
                </div>
              </div>
            )}
          </div>

          <div className="w-full flex pb-[1rem] justify-start gap-[1rem] items-stretch">
            <div className="flex-1">
              <CardTitle>
                <div
                  dangerouslySetInnerHTML={{
                    __html: highlightMatches(
                      latestEventData?.name,
                      latestEventData?._matches,
                      "name"
                    ),
                  }}
                ></div>
              </CardTitle>
              {vmatches ? (
                <div
                  style={{
                    fontSize: "1rem",
                    marginBottom: "0.5rem",
                    marginTop: "10px",
                    color: "var(--inyc-secondary-medium)",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: highlightMatches(
                      latestEventData?.address?.toLowerCase(),
                      latestEventData?._matches,
                      "address"
                    ),
                  }}
                ></div>
              ) : (
                <div
                  className="mb-[0.5rem]"
                  style={{
                    color: "var(--inyc-secondary-medium)",
                  }}
                >
                  {event?.type === "venue" && !venueName && event?.name}

                  {/* {latestEventData?.address?.toLowerCase()} */}
                  {venueName ? (
                    <div
                      className="flex gap-[1rem] items-start"
                      style={{
                        color: activeColor,
                        fontFamily: "Primary-Medium",
                      }}
                    >
                      {venueName}{" "}
                      {!hideEventCount &&
                        event.type === "venue" &&
                        event.events?.length > 1 && (
                          <div
                            className={`px-[0.7rem] whitespace-nowrap py-[0.1rem] font-['Primary-Medium'] text-[0.8rem] rounded-[3rem] flex items-center justify-center leading-[1.4rem] text-white`}
                            style={{
                              backgroundColor: activeColor,
                            }}
                          >
                            {event.events?.length} event
                            {event.events?.length > 1 && "s"}
                          </div>
                        )}
                    </div>
                  ) : (
                    latestEventData?.address?.toLowerCase()
                  )}
                </div>
              )}
              <div className="flex w-full justify-between items-center">
                {eventdistance && (
                  <div className="text-[0.9rem]">
                    {eventdistance} miles away
                  </div>
                )}
                {latestEventformattedTime && (
                  <div
                    style={{
                      fontSize: "0.7rem",
                      color: "var(--inyc-secondary)",
                      backgroundColor: "var(--inyc-secondary-light)",
                      fontFamily: "Primary-Medium",
                      padding: "0.3rem 0.5rem 0.2rem 0.5rem",
                      borderRadius: "3px",
                      // marginBottom: "10px",
                      marginLeft: "auto",
                      width: "fit-content",
                    }}
                  >
                    {latestEventformattedTime}
                  </div>
                )}
              </div>
              <div className="text-[0.9rem]">
                {(latestEventData.nextOccurence || latestEventData.date) &&
                  latestEventformattedDate}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCard;
