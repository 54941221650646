import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import NewEvent from "../../pages/portal/new-event/NewEvent.jsx";
import PostedEvents from "../../pages/portal/posted-events/PostedEvents.jsx";
import { useUser } from "../UserContext/UserProvider.jsx";
import VenuesDashboard from "../../components/VenuesDashboard/VenuesDashboard.jsx";
import axiosInstance from "../../api/axiosInstance.js";
import { backendUrl } from "../../api/constants.js";
import Templates from "../../pages/portal/templates/Templates.jsx";
import BusinessTools from "../../pages/portal/calendar/Calendar.jsx";
import { getMyLocation as getLocationApi } from "../../api/events.js";
import { getCurrentLocation } from "../../util/dataUtils.jsx";
import { modal } from "../../util/useModal.jsx";
import { FaChevronDown } from "react-icons/fa";
import { useMediaQuery } from "../../util/useMediaQuery.js";

const PortalContext = createContext({});
const newyork = { lat: 40.7128, lng: -74.006 };
// export const allowedUsers = ["test","jeridgooding","jerid-inyc"];

export const PortalProvider = ({ children }) => {
  const [userLocation, setUserLocation] = useState({
    lat: newyork.lat,
    lng: newyork.lng,
  });
  const [locationLoading, setLocationLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(null);
  const { user } = useUser();
  const [currentTab, setCurrentTab] = useState();
  const [editEvent, setEditEvent] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
  let location = useLocation();
  const medium = useMediaQuery("md");

  useEffect(() => {
    if (searchParams.get("edit") && userLocation) {
      // console.log(searchParams.get("edit"));
      const fetchEditEvent = async () => {
        const fetchedEditEvent = await getLocationApi(
          searchParams.get("edit"),
          userLocation
        );
        // console.log(fetchedEditEvent);
        setEditEvent(fetchedEditEvent);
      };
      fetchEditEvent();
    }
  }, [searchParams, userLocation]);

  const handlePortalClick = (tab) => {
    setCurrentTab(tab);
  };

  const handleMoreOptionsClick = async () => {
    if (medium) return;
    const result = await modal(
      ({ proceed }) => {
        return (
          <div className="mb-[1rem] py-[1rem] border-[1px] rounded-[--inyc-input-border-radius] bg-[--inyc-primary-light] border-[--inyc-modal-border-color] min-w-[min(90vw,300px)]">
            {tabsList.map((tb) => (
              <div
                onClick={() => {
                  handlePortalClick(tb.index);
                  setMoreOptionsOpen(false);
                  proceed();
                }}
                className="w-full py-[1rem] px-[1.5rem] cursor-pointer duration-200 flex justify-between items-center gap-[1rem]"
              >
                <div className="font-['Primary-Medium'] text-[1.1rem]">
                  {tb.label}
                </div>
                <FaChevronDown
                  size={10}
                  color="var(--inyc-primary-text-color-light)"
                  className="rotate-[-90deg]"
                />
              </div>
            ))}
          </div>
        );
      },
      {
        containerClassName: "!items-end",
        raw: true,
        enableCloseOnBackdropClick: true,
        onClose: () => {
          setMoreOptionsOpen(false);
        },
      }
    );
  };

  useEffect(() => {
    const checkPermission = async () => {
      try {
        const { data } = await axiosInstance.get(
          `${backendUrl}/user/permissions?${tabsList
            .map((tb) => `&&permissions[]=${tb.permission}`)
            .join("")}`
        );
        // console.log(data);
        if (data.error) {
          console.log(data.error);
        } else {
          setHasPermission(data);

          //set current tab to the first tab that has permission
          const firstTab = tabsList.find((tb) => data[tb.permission] === true);
          if (firstTab) {
            setCurrentTab(firstTab.index);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkPermission();
  }, [user]);

  const tabsList = [
    {
      label: "new event",
      index: "new-event",
      id: "new_event",
      permission: "ACCESS_INFLUENCER_DASHBOARD",
      element: ({ key }) => <NewEvent key={key} userLocation={userLocation} />,
    },
    {
      label: "posted events",
      index: "posted-events",
      id: "posted_events",
      permission: "ACCESS_INFLUENCER_DASHBOARD",
      element: ({ key }) => <PostedEvents key={key} />,
    },
    {
      label: "templates",
      index: "templates",
      id: "templates",
      permission: "ACCESS_INFLUENCER_DASHBOARD",
      element: ({ key }) => <Templates key={key} />,
    },
    {
      label: "venues",
      index: "venues",
      id: "venues",
      permission: "ACCESS_BUSINESS_DASHBOARD",
      element: () => <VenuesDashboard userLocation={userLocation} />,
    },
    {
      label: "calendar",
      index: "calendar",
      id: "calendar",
      permission: "ACCESS_CALENDAR_DASHBOARD",
      element: () => <BusinessTools />,
    },
  ];

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    try {
      getCurrentLocation({
        setLocation: setUserLocation,
        default: {
          latitude: newyork.lat,
          longitude: newyork.lng,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLocationLoading(false);
    }
  };

  const value = {
    userLocation,
    setUserLocation,
    locationLoading,
    tabsList,
    hasPermission,
    moreOptionsOpen,
    setMoreOptionsOpen,
    setHasPermission,
    handlePortalClick,
    handleMoreOptionsClick,
    currentTab,
    setCurrentTab,
    editEvent,
    setEditEvent,
  };

  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
};

export const usePortalContext = () => {
  const context = useContext(PortalContext);
  if (context === undefined) {
    throw new Error("usePortalContext must be used within a PortalProvider");
  }
  return context;
};
