import React, { useEffect, useState, useRef } from "react";
import axios from "../../api/axiosInstance";
import "./EventDetails.css";
import Button from "../Button/Button";
import ImageSlider from "../ImageSlider/ImageSlider";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { enqueueSnackbar } from "notistack";
import { RiErrorWarningFill } from "react-icons/ri";
import { formatPosterAccount } from "../../util/dataUtils";
import { IoFilter } from "react-icons/io5";
import InfiniteScroller from "../InfiniteScroller/InfiniteScroller";
import MiniMap from "../MiniMap/MiniMap";
import EventCard from "../EventCard/EventCard";
import { useAnalytics } from "../../context/AnalyticsContext/AnalyticsProvider";
import { useData } from "../../context/DataContext/DataProvider";
import { useUser } from "../../context/UserContext/UserProvider";
import { TbShare3 } from "react-icons/tb";
import { QRCode } from "react-qrcode-logo";
import { Directory, Filesystem } from "@capacitor/filesystem";

import { FaInstagram } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { Instagram } from "capacitor-instagram-share";
import { LuCalendarPlus } from "react-icons/lu";
import { modal } from "../../util/useModal";
import { FACEBOOK_APP_ID, backendUrl } from "../../api/constants";
import html2canvas from "html2canvas";
import { TbPhotoShare } from "react-icons/tb";
import { IoCopyOutline } from "react-icons/io5";
import { MdQrCode2 } from "react-icons/md";
import { IoIosArrowBack, IoMdClose } from "react-icons/io";
import Tabs, { TabContent } from "../ui/tabs";
import { Share } from "@capacitor/share";
import { useLogger } from "../../context/LogContext/LogProvider";
import {
  defaultDate,
  distance,
  formatDateTime,
  isDefaultDateFilter,
} from "../../util/eventUtils";
import { useUIContext } from "../../context/UIContext/UIContext";
import { Clipboard } from "@capacitor/clipboard";
import { FaChevronDown, FaEye, FaRegEye } from "react-icons/fa6";
import { FaGoogle } from "react-icons/fa";
import { useOutsideAlerter } from "../../util/useOutsideAlerter";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { BottomSheetDateFilter } from "../Map/Map";
import { useEventDetails } from "../../context/EventDetailsProvider/EventDetailsProvider";
import Input from "../Input/Input";
import Loader from "../icons/Loader";
import LocationSVG from "../icons/LocationSVG";
import { FiSearch } from "react-icons/fi";
import { useAccountDetails } from "../../context/AccountDetailsContext/AccountDetailsProvider";
import { loadIcon } from "../../util/iconRegistry";
import { CapacitorCalendar } from "@ebarooni/capacitor-calendar";
import { Capacitor } from "@capacitor/core";
import SocialsView from "../SocialsInput/SocialsView";
import { TbTicket } from "react-icons/tb";
import { DateTime } from "luxon";

const ensureValidUrl = (url) => {
  if (!url) return url;
  if (/^(https?:)?\/\//i.test(url)) {
    return url;
  }
  return `https://${url}`;
};

const EventReportDropDown = ({ open, onReport, type = "event" }) => {
  return (
    <div className={open ? "open" : ""} id="event-report-dropdown-container">
      <div id={"event-report-dropdown"}>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RiErrorWarningFill style={{ marginRight: "0.5rem" }} />
          report
        </h2>
        <div id="event-report-dropdown-items">
          <div
            id="event-report-dropdown-item"
            onClick={() => onReport("not_a_real_event")}
          >
            not a real event
          </div>
          <div
            id="event-report-dropdown-item"
            onClick={() => onReport("incorrect_information")}
          >
            incorrect information
          </div>
          <div
            id="event-report-dropdown-item"
            onClick={() => onReport(`i_did_not_like_this_${type}`)}
          >
            i did not like this {type}
          </div>
        </div>
      </div>
    </div>
  );
};

const EventCalendarDropDown = ({ open, setOpen, eventId }) => {
  const { setUIData } = useUIContext();
  const { calendarAccounts, fetchCalendarAccounts } = useUser();
  const handleAddToCalendar = (account) => async (e) => {
    e.stopPropagation();
    try {
      const { data } = await axios.post(`/user/calendar/google/add-event`, {
        accountId: account._id,
        eventId: eventId,
      });
      if (data.error) {
        return enqueueSnackbar(data.error, { variant: "error" });
      }
      await fetchCalendarAccounts();
      setOpen(false);
      return enqueueSnackbar("event added to calendar", { variant: "success" });
    } catch (error) {
      console.error(error);
      return enqueueSnackbar("something went wrong", { variant: "error" });
    }
  };

  return (
    <div
      className={`rounded-[5px] bg-[var(--inyc-primary)] !justify-start flex-col shadow-[0px_5px_15px_rgba(0,0,0,0.2)] max-w-[70vw] absolute top-[40px] right-[50%] translate-x-[50%] min-w-[150px] overflow-hidden duration-200 ${
        open
          ? "pointer-events-all"
          : "opacity-0 translate-y-[-10px] pointer-events-none"
      }`}
      id="event-calendar-dropdown-container"
    >
      {calendarAccounts?.length > 0 ? (
        calendarAccounts.map((account) => (
          <div
            className="px-[1rem] py-[0.5rem] hover:bg-[rgba(0,0,0,0.1)] duration-200 flex items-start justify-start gap-[10px]"
            key={account._id}
            id="event-calendar-dropdown-item"
            onClick={handleAddToCalendar(account)}
          >
            {account.items?.find(
              (item) => item.eventId === eventId?.toString()
            ) && <FaCheck className="text-green-500" size={10} />}
            {account.service === "google" && <FaGoogle size={15} />}
            <div className="flex-col !items-start">
              <div className="leading-[1rem]">{account.meta.name}</div>
              <div className="text-black/60 text-[0.8rem]">
                {account.meta.email}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="p-[1rem] text-center text-[0.8rem] leading-[1rem] text-black/60">
          <div className="flex-col">
            you have no connected calendar accounts
            <br />
            <br />
            click{" "}
            <Link
              onClick={() => {
                setUIData((prev) => ({
                  ...prev,
                  selectedEvent: null,
                  selectedSubEvent: null,
                }));
              }}
              className="inline-block"
              to="dashboard?tab=calendar"
            >
              here
            </Link>{" "}
            to connect a calendar account
          </div>
        </div>
      )}
    </div>
  );
};

const EventDetailsReported = ({ onRedirect }) => {
  return (
    <div
      className="event-detail-container"
      style={{
        textAlign: "center",
        marginTop: "50px",
      }}
    >
      <div className="event-detail-title">!bummer</div>
      <div className="event-detail-description">keep exploring</div>
      <Button
        style={{
          margin: "auto",
          marginTop: "2rem",
          width: "fit-content",
        }}
        onClick={onRedirect}
      >
        Next
      </Button>
    </div>
  );
};

const EventDetails = ({
  data,
  parentRef,
  forceVenueInfoOpen,
  className,
  onGetDirections = (event) => {
    const url = `https://www.google.com/maps/dir/?api=1&origin=current+location&destination=${event.location.coordinates[1]},${event.location.coordinates[0]}&travelmode=walking`;
    window.open(url, "_blank");
  },
  onSelectEvent,
  Close,
  sheetLayout = false,
  useAsAnalytics,
}) => {
  const [reportOpen, setReportOpen] = useState(false);
  const [venueInfoOpen, setVenueInfoOpen] = useState(false);
  const [venueFilterOpen, setVenueFilterOpen] = useState(false);
  const [reported, setReported] = useState(false);
  const [event, setEvent] = useState();
  const [scrolled, setScrolled] = useState(false);
  const [occupancy, setOccupancy] = useState();
  const { user } = useUser();

  const {
    toggleFollowUser,
    shareEvent: updateShareEvent,
    userLocation,
    globalConfig,
  } = useData();
  const categories = globalConfig?.categories || [];
  const knownServices = globalConfig?.knownServices;
  const {
    dateFilter,
    setDateFilter,
    tagFilter,
    setTagFilter,
    queryFilter,
    setQueryFilter,
    filteredEvents,
    detailsSearchLoading,
    selectedEvent,
    selectedSubEvent,
    setSelectedSubEvent,
  } = useEventDetails();
  const { selectAccount } = useAccountDetails();
  const { trackSessionEvent } = useAnalytics();
  const { availableApps, likeEvent, likedEvents, activeCategories } =
    useUIContext();
  const { startLog } = useLogger();
  const venueInfoRef = useRef(null);
  const [calendarListOpen, setCalendarListOpen] = useState(false);
  const calendarAddRef = useRef();
  const reportRef = useRef();
  useOutsideAlerter(calendarAddRef, () => setCalendarListOpen(false));
  useOutsideAlerter(reportRef, () => setReportOpen(false));
  const [showFullDescription, setShowFullDescription] = useState(false);
  const characterLimit = 200;
  const POLL_INTERVAL = 30000; // 30 seconds

  useEffect(() => {
    let intervalId;

    // if (event && event.type === "venue") {
    //   setQueryFilter("");
    //   setTagFilter([]);
    //   setDateFilter();
    // }

    const fetchOccupancy = () => {
      if (event?.type === "venue" && event?.name) {
        axios
          .get(`${backendUrl}/user/occupancy/${event.name}`)
          .then((res) => {
            if (res.data.error) {
              // Handle errors (optional logging or silent fail)
            } else {
              // enqueueSnackbar(
              //     `Venue occupancy updated: ${JSON.stringify(res.data, null, 2)}`,
              //     { variant: "success" }
              // );
              setOccupancy(res.data.occupancy);
            }
          })
          .catch((err) => {
            console.error("Error fetching occupancy:", err);
          });
      }
    };

    // Initial fetch when component mounts
    fetchOccupancy();

    // Set up polling
    intervalId = setInterval(fetchOccupancy, POLL_INTERVAL);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [event]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (venueInfoRef.current) {
        if (venueInfoOpen || (event?.type && event?.type !== "venue"))
          venueInfoRef.current.classList.remove("overflow-hidden");
        else venueInfoRef.current.classList.add("overflow-hidden");
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [venueInfoOpen, event?.type]);

  useEffect(() => {
    setEvent(data);
    //if its a different event then reset venueInfoOpen
    if (event?._id !== data?._id) {
      setVenueInfoOpen(false);
      setVenueFilterOpen(false);
      setShowFullDescription(false);
    }

    // if the event has _venueMatches then set venueInfoOpen to true
    if (data?._venueMatches) setVenueInfoOpen(true);
  }, [data]);

  useEffect(() => {
    if (parentRef?.current) {
      const handleScroll = () => {
        if (parentRef.current.scrollTop > 10) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
      parentRef.current?.addEventListener("scroll", handleScroll);
      return () => {
        parentRef.current?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [parentRef]);

  const prevValue = useRef();
  //^ ANALYTICS: event opened
  useEffect(() => {
    if (event) {
      if (useAsAnalytics && prevValue.current?._id !== event._id) {
        trackSessionEvent({
          action: `${event.type} opened`,
          category: event.type,
          label: `${event.type}_opened`,
          [event.type]: event._id,
          value: {
            name: event.name,
            address: event.address,
          },
          timestamp: Date.now(),
        });
      }
      prevValue.current = event;
    }
  }, [event]);

  const toggleEventLike = () => {
    likeEvent(event);
    //^ ANALYTICS: send like event to analytics
    // if event is being liked then count towards analytics
    if (!event.likes?.includes(user?._id)) {
      trackSessionEvent({
        action: `${event.type} liked`,
        category: event.type,
        label: `${event.type}_liked`,
        [event.type]: event._id,
        value: {
          name: event.name,
          address: event.address,
        },
        timestamp: Date.now(),
      });
    }

    axios
      .get(`/${event.type}s/like/${event._id}`)
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
          likeEvent(event);
        }
      })
      .catch((err) => {
        console.log(err);
        likeEvent(event);
      });
  };

  const reportEvent = (report_reason) => {
    reportOpen && setReportOpen(false);

    axios
      .post(`/user/report`, {
        type: event.type,
        event: event.type === "event" ? event : null,
        venue: event.type === "venue" ? event : null,
        report_reason: report_reason,
      })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
        } else {
          enqueueSnackbar("reported! we'll look into this!", {
            variant: "success",
          });
          refreshEvents();
          if (report_reason.startsWith("i_did_not_like_this_"))
            setReported(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shareEvent = async () => {
    const isNative = Capacitor.isNativePlatform(); // detect if on mobile native
    const result = await modal(
      ({ proceed }) => {
        // const [load, setLoad] = useState(false);
        const [tab, setTab] = useState("image");
        const fullimageref = useRef(null);
        const imgref = useRef(null);
        const stickerRef = useRef(null);
        const qrcoderef = useRef(null);

        //#region handlers
        const getAssetFile = async (element, url) => {
          let data;
          if (url) {
            data = url;
          } else {
            const canvas = await html2canvas(element, {
              useCORS: true,
              backgroundColor: null,
            });
            const imageData = canvas.toDataURL("image/png");
            canvas.remove();
            data = imageData;
          }
          const filename = `${Date.now()}.png`;
          await Filesystem.writeFile({
            path: filename,
            data: data,
            directory: Directory.Cache,
          });
          const uriResult = await Filesystem.getUri({
            directory: Directory.Cache,
            path: filename,
          });
          return { url: uriResult.uri, data: data };
        };

        const updateShare = async () => {
          // updateShareEvent(event);
          //^ ANALYTICS: share event to analytics
          if (useAsAnalytics) {
            trackSessionEvent({
              action: `${event.type} shared`,
              category: event.type,
              label: `${event.type}_shared`,
              [event.type]: event._id,
              value: {
                name: event.name,
                address: event.address,
              },
              timestamp: Date.now(),
            });
          }
          axios
            .get(`/${event.type}s/share-count/${event._id}`)
            .then((res) => {
              if (res.data.error) {
                // updateShareEvent(event);
              }
            })
            .catch((err) => {
              // updateShareEvent(event);
            });
        };

        // Helper to convert a base64 data URL to a File object
        const dataURLtoFile = (dataurl, filename) => {
          const arr = dataurl.split(",");
          const mimeMatch = arr[0].match(/:(.*?);/);
          if (!mimeMatch) return null;
          const mime = mimeMatch[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, { type: mime });
        };

        const shareQrCode = async () => {
          setTab("qr");
          // getAssetFile returns both the file URI and the base64 data URL.
          const { url: qrUri, data: qrData } = await getAssetFile(
            qrcoderef.current
          );
          console.log("qrUri", qrUri);

          // Instead of using dataURLtoFile, convert the QR code base64 string to a Blob then a File.
          let qrFile;
          try {
            const response = await fetch(qrData);
            const blob = await response.blob();
            qrFile = new File([blob], "qr.png", { type: blob.type });
          } catch (err) {
            console.error("Error converting QR data to file:", err);
          }

          try {
            // If we can share a file (native platforms or via navigator.canShare) then share the File.
            if (
              qrFile &&
              ((navigator.canShare &&
                navigator.canShare({ files: [qrFile] })) ||
                Capacitor.isNativePlatform())
            ) {
              await Share.share({
                files: [qrFile],
                title: "Check out this event on in—yc",
              });
            } else {
              // Fallback: share the URL instead (mostly for desktop browsers).
              await Share.share({
                url: qrUri,
                title: "Check out this event on in—yc",
              });
            }
            updateShare();
          } catch (error) {
            console.error("Error sharing QR code:", error);
            // Fallback share in case of an error.
            await Share.share({
              url: qrUri,
              title: "Check out this event on in—yc",
            });
            updateShare();
          }
        };

        const shareImage = async () => {
          const log = startLog({
            user: user,
            event: "share image",
          });
          try {
            setTab("image");
            log.log("tab set to image");
            const { url: uri, data: base64img } = await getAssetFile(
              fullimageref.current
            );
            log.log("using capacitor share");
            await Share.share({
              files: [uri],
              url: `https://app.inyc.us/#/?location=${event._id}`,
              title: "Check out this event on in—yc",
            });

            log.log("[Success] shared");
          } catch (error) {
            log.log("[Error] ", error);
            console.error(error);
          } finally {
            updateShare();
            log.display();
          }
        };

        const copyLink = async () => {
          setTab("image");
          const shareContent = `https://app.inyc.us/#/?location=${event._id}`;

          navigator.clipboard
            .writeText(shareContent)
            .then(() => {
              enqueueSnackbar("link copied to clipboard.");
            })
            .catch((error) => {
              enqueueSnackbar("error copying content to clipboard.", {
                variant: "error",
              });
            });
          updateShare();
        };

        const shareLink = async () => {
          const shareContent = `https://app.inyc.us/#/?location=${event._id}`;
          if (navigator.share) {
            navigator.share({
              url: shareContent,
            });
          } else {
            await Share.share({
              url: shareContent,
            });
          }
          updateShare();
        };

        const shareInstagramStory = async () => {
          const log = startLog({
            user: user,
            event: "open instagram",
          });
          const result = await modal(({ proceed }) => {
            return (
              <div>
                <div className="text-center text-[var(--inyc-primary-text-color)] mb-[2rem]">
                  event link has automatically been copied to your clipboard.
                  paste the copied link to the <strong>"link"</strong> sticker
                  in instagram to bring your followers directly to the event on{" "}
                  <span className="font-['Primary-Bold']">in-yc</span>
                </div>
                <div className="flex items-center justify-center gap-[1.5rem]">
                  <Button onClick={() => proceed(true)}>share</Button>
                </div>
              </div>
            );
          });
          if (result) {
            try {
              try {
                const { url: stickerUri } = await getAssetFile(
                  stickerRef.current
                );
                const shareUrl = `https://app.inyc.us/#/?location=${event._id}`;
                await Clipboard.write({
                  string: shareUrl,
                });
                await Instagram.shareToInstagramStory({
                  appID: FACEBOOK_APP_ID,
                  stickerImage: stickerUri,
                  shareUrl: shareUrl,
                });
                log.log("opened instagram");
              } catch (err) {
                log.log("[Error] error opening instagram", JSON.stringify(err));
                log.log("[Error] ", err.message);
              }
            } catch (error) {
              log.log("[Error] ", error.message);
            } finally {
              log.display();
              updateShare();
            }
          }
        };

        //#endregion

        return (
          <div className="min-h-[100vh] flex flex-col justify-between items-center">
            <div className="flex w-full justify-center items-center flex-1">
              <Tabs
                staticHeight
                contentWrapperClass="items-center"
                className="w-full"
                tabs={["image", "qr"]}
                hideTabs
                currentTab={tab}
                setCurrentTab={setTab}
              >
                <TabContent name="image">
                  <div
                    ref={fullimageref}
                    className="relative overflow-hidden h-[590px] flex items-center justify-center w-[350px] mx-auto mb-[1rem] rounded-[1rem]"
                  >
                    <div
                      ref={imgref}
                      className={`absolute top-0 left-0 w-full h-full `}
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `linear-gradient(to bottom, #323232, #1A1A1A)`,
                      }}
                    ></div>
                    <div
                      ref={stickerRef}
                      className={`relative z-[1] text-start duration-300 p-[1rem] rounded-[0.5rem] min-w-[200px] max-w-[70%]`}
                      style={{
                        backgroundColor: activeColor,
                      }}
                    >
                      <div className="mb-[5px]">
                        {(event.images?.length > 0 ||
                          event.venueImages?.length > 0) && (
                          <div className="mb-[10px] rounded-[5px] overflow-hidden relative w-full h-[150px]">
                            <div
                              className="absolute top-0 left-0 w-full h-full"
                              style={{
                                backgroundImage: `url('${
                                  event.images?.length > 0
                                    ? `${backendUrl}/assets/${event.images[0]}`
                                    : event.venueImages?.length > 0
                                    ? `${backendUrl}/assets/${event.venueImages[0]}`
                                    : ""
                                }')`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          </div>
                        )}
                        {event.type === "event" && (
                          <div className="flex justify-between items-start">
                            {event.category && (
                              <div
                                className="text-[--inyc-details-light-color] w-fit break-words text-[1rem] px-[0.7rem] py-[0.3rem] font-['Primary-Medium'] mb-[1rem] rounded-[5px]"
                                style={{
                                  backgroundColor: secondaryColor,
                                  color: lightColor,
                                }}
                              >
                                {event.category?.toLowerCase()}
                              </div>
                            )}
                            {dateString && (
                              <div
                                className="text-[--inyc-details-light-color] w-fit break-words text-[0.8rem] px-[0.7rem] py-[0.2rem] font-['Primary-Medium'] mb-[1rem] rounded-[5px]"
                                style={{
                                  backgroundColor: secondaryColor,
                                  color: lightColor,
                                }}
                              >
                                {dateString}
                              </div>
                            )}
                          </div>
                        )}
                        <div className="text-white break-words text-[2rem] leading-[2.1rem] font-['Primary-Bold'] mb-[5px]">
                          {event.name?.toLowerCase()}
                        </div>
                        <div
                          style={{
                            color: lightColor,
                          }}
                          className="text-[1.1rem] font-['Primary-Regular']"
                        >
                          {event.type === "event" && venueName
                            ? venueName
                            : event.address?.toLowerCase()}
                        </div>
                        {event.type === "event" && (
                          <>
                            <div className="flex mb-[10px] justify-between items-center gap-[1rem] leading-[1rem]">
                              <div
                                style={{
                                  color: lightColor,
                                }}
                                className="font-['Primary-Regular'] text-[1rem]"
                              >
                                {rruleString}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex justify-end items-end mb-[5px]">
                        <div
                          style={{
                            backgroundColor: secondaryColor,
                            color: lightColor,
                          }}
                          className="text-[var(--inyc-secondary-text-color)] px-[0.5rem] font-bold w-fit rounded-[3px] text-[0.7rem] font-['Primary-Regular'] leading-[1rem] py-[0.3rem] whitespace-nowrap"
                        >
                          {formattedTime}
                        </div>
                      </div>
                      <div className="gap-[5px] flex items-center justify-start text-white">
                        <div className="text-[0.9rem] relative text-white font-['Primary-Regular']">
                          details on
                        </div>
                        <img
                          className="h-[18px]"
                          src={`${backendUrl}/icon_white_nobg.png`}
                        />
                      </div>
                    </div>
                  </div>
                </TabContent>
                <TabContent
                  name="qr"
                  className="flex items-center justify-center"
                >
                  <div
                    ref={qrcoderef}
                    className="rounded-[var(--inyc-input-border-radius)] overflow-hidden"
                  >
                    <QRCode
                      qrStyle="fluid"
                      eyeRadius={10}
                      value={`https://app.inyc.us/#/?location=${event._id}`}
                      size={200}
                    />
                  </div>
                </TabContent>
              </Tabs>
            </div>
            <div
              className={`relative duration-300 w-full md:w-fit md:min-w-[300px] pt-[2rem] pb-[4rem] px-[3rem] rounded-t-[2rem] border-[1px] border-[--inyc-input-border-color] bg-[var(--inyc-primary)] shadow-md`}
            >
              <div className="flex flex-wrap justify-center items-stretch  gap-[0.5rem] mb-[1rem]">
                {(availableApps?.native || navigator.share) && (
                  <Button
                    onClick={shareLink}
                    btnClassName="duration-300 hover:bg-black/5 flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                  >
                    <IoIosLink size={15} />
                    <div className="leading-[1rem] text-[0.8rem] ">
                      share link
                    </div>
                  </Button>
                )}
                {availableApps?.instagram && (
                  <Button
                    onClick={shareInstagramStory}
                    btnClassName="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] p-[5px] text-center"
                  >
                    <FaInstagram size={15} />
                    <div className="leading-[1rem] text-[0.8rem] btn-text">
                      post story
                    </div>
                  </Button>
                )}
                {availableApps?.native && isNative && (
                  <Button
                    onClick={shareImage}
                    btnClassName="relative duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] text-center"
                  >
                    <TbPhotoShare size={15} />
                    <div className="leading-[1rem] text-[0.8rem] btn-text">
                      share image
                    </div>
                  </Button>
                )}
                <Button
                  onClick={copyLink}
                  btnClassName="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] text-center"
                >
                  <IoCopyOutline size={15} />
                  <div className="btn-text leading-[1rem] text-[0.8rem]">
                    copy link
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setTab("qr");
                  }}
                  btnClassName="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] text-center"
                >
                  <MdQrCode2 size={15} />
                  <div className="leading-[1rem] text-[0.8rem] btn-text">
                    QR code
                  </div>
                </Button>
                {availableApps?.native && isNative && (
                  <Button
                    onClick={shareQrCode}
                    btnClassName="duration-300 hover:bg-black/5 [&:hover>.btn-text]:text-[--inyc-secondary-text-color] flex justify-start flex-col items-center gap-[5px] cursor-pointer w-[60px] rounded-[5px] text-center"
                  >
                    <TbShare3 size={15} />
                    <div className="leading-[1rem] text-[0.8rem] btn-text">
                      share QR code
                    </div>
                  </Button>
                )}
              </div>
              <div
                onClick={() => proceed(false)}
                className="absolute top-[0.5rem] right-[1rem] duration-300 hover:bg-black/5 flex justify-start flex-col items-center gap-[5px] cursor-pointer p-[0.5rem] rounded-[50%] text-center"
              >
                <IoMdClose size={20} />
              </div>
            </div>
          </div>
        );
      },
      {
        raw: true,
        fullScreen: true,
      }
    );
  };

  function extractWebsiteName(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\./;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  // if user has already reported this event , then show reported message
  if (reported) {
    return <EventDetailsReported onRedirect={Close} />;
  }

  // console.log('EventDetails ',event);
  const { formattedDate, formattedTime, rruleString, dateString } =
    formatDateTime(event?.nextOccurence || event?.date, event?.timezone, {
      rrule: event?.rrule,
      recurring: event?.recurring,
      recurringEnd: event?.recurringEnd,
      frequency: event?.frequency,
    });

  const toggleFollow = async () => {
    toggleFollowUser(event.account_id?._id);

    axios
      .get(`/user/follow/${event.account_id?._id}`)
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });

          toggleFollowUser(event.account_id?._id);
        }
      })
      .catch((err) => {
        console.log(err);

        toggleFollowUser(event.account_id?._id);
      });
  };

  const addToCalendar = async () => {
    const perm = await CapacitorCalendar.checkAllPermissions();
    if (perm.writeCalendar !== "granted" || perm.readCalendar !== "granted") {
      const req = await CapacitorCalendar.requestAllPermissions();
      // console.log("CapacitorCalendar.requestAllPermissions ");
      if (req.writeCalendar !== "granted" || req.readCalendar !== "granted") {
        // console.log("permission denied");
        enqueueSnackbar("permission denied", { variant: "error" });
        return;
      }
      // console.log(JSON.stringify(req, null, 2));
    }

    const dates = {
      startDate: new Date((event.nextOccurence || event.date)?.start).getTime(),
      endDate: new Date((event.nextOccurence || event.date)?.end).getTime(),
    };
    // console.log(JSON.stringify(dates, null, 2));
    // check if event already exists
    const cal = await CapacitorCalendar.listEventsInRange({
      startDate: new Date(dates.startDate).setDate(
        new Date(dates.startDate).getDate() - 1
      ),
      endDate: new Date(dates.endDate).setDate(
        new Date(dates.endDate).getDate() + 1
      ),
    });

    if (cal.result && cal.result?.length > 0) {
      for (let i = 0; i < cal.result.length; i++) {
        if (
          cal.result[i].title === event.name &&
          cal.result[i].startDate ===
            new Date((event.nextOccurence || event.date)?.start).getTime() &&
          cal.result[i].endDate ===
            new Date((event.nextOccurence || event.date)?.end).getTime()
        ) {
          enqueueSnackbar("event already exists in calendar", {
            variant: "error",
          });
          return;
        }
      }
    }

    // console.log("creating event");
    await CapacitorCalendar.createEvent({
      title: event.name,
      location: event.address,
      startDate: dates.startDate,
      endDate: dates.endDate,
      notes: event.description,
      url: `https://app.inyc.us/#/?location=${event._id}`,
    })
      .then((res) => {
        // console.log("Event added to calendar", JSON.stringify(res, null, 2));
        enqueueSnackbar("event added to calendar", {
          variant: "success",
          persist: false,
          content: (key) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#407F51",
                color: "white",
                fontFamily: "Primary-Regular",
                padding: "10px",
                fontSize: "1.1rem",
                borderRadius: "5px",
              }}
            >
              <LuCalendarPlus
                style={{
                  marginRight: "10px",
                  marginLeft: "0.6rem",
                  fontSize: "20px",
                }}
              />
              event added to calendar
            </div>
          ),
        });
      })
      .catch((error) => {
        console.error(
          "Error adding event to calendar",
          JSON.stringify(error, null, 2)
        );
        enqueueSnackbar("error adding event to calendar", { variant: "error" });
      });

    // console.log("finished creating event");
  };

  // console.log("EventDetails event : ", event);
  // Now compute filtersActive; if dateFilter equals the default, ignore it.
  const filtersActive =
    (queryFilter && queryFilter !== "") ||
    (!isDefaultDateFilter(dateFilter) && dateFilter) ||
    activeCategories.length < categories.length;

  const likedEvent = likedEvents?.find((e) => e._id === event?._id) || event;

  const styleCategory = categories.find((cat) => cat.id === event?.category);
  const activeColor = styleCategory?.activeColor;
  const secondaryColor = styleCategory?.secondaryColor;
  const lightColor = styleCategory?.lightColor;

  const venueName =
    selectedSubEvent?.venue && selectedSubEvent?.venue === selectedEvent?._id
      ? selectedEvent?.name
      : event?.venueDetails?._id
      ? event?.venueDetails?.name
      : null;

  console.log("EventDetais event ,", event);

  useEffect(() => {
    console.log("filtersActive changed:", filtersActive, {
      queryFilter,
      dateFilter,
      activeCategories: activeCategories.map((cat) => cat.id), // or you can log the whole array if that's more useful
      totalCategories: categories?.length,
    });
  }, [filtersActive, queryFilter, dateFilter, activeCategories, categories]);

  const eventItems = event &&
    event?.type === "venue" && [
      ...(filteredEvents?.map((e) => {
        e._filterEvent = true;
        return e;
      }) || []),
      ...(event?.events?.filter(
        (l) => !filteredEvents?.some((fl) => fl._id === l._id)
      ) || []),
    ];

  console.log("eventItems", eventItems);
  console.log("filtersActive", filtersActive);
  return (
    <>
      {event ? (
        <div
          className={`event-detail-container pb-[3rem] ${className}`}
          style={{
            backgroundColor: activeColor,
            "--inyc-details-secondary-color":
              secondaryColor || "var(--inyc-primary-text-color-light)",
            "--inyc-details-btn-bg":
              secondaryColor || "var(--inyc-primary-button)",
            "--inyc-details-border-color": "rgba(0,0,0,0)",
            "--inyc-details-light-color":
              lightColor || "var(--inyc-primary-text-color)",
            "--inyc-details-text-color": activeColor
              ? "white"
              : "var(--inyc-primary-text-color)",
            "--inyc-details-border-color": activeColor
              ? "rgba(0,0,0,0)"
              : "var(--inyc-input-border-color)",
          }}
        >
          {sheetLayout ? (
            <div className="sticky w-fit top-[0.5rem] z-[1000] left-[0rem] translate-x-[-1rem]">
              <div
                className={`arrow-events-container ${
                  scrolled ? "scrolled" : ""
                }`}
                style={{
                  transition: "0.3s",
                  margin: "1rem 1.5rem 0.5rem 1.5rem",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={Close}
              >
                <IoIosArrowBack
                  size={20}
                  className={"arrow-icon"}
                  // className={scrolled ? "text-[--inyc-secondary-text-color]" : "text-[--inyc-primary-text-color]"}
                />
              </div>
            </div>
          ) : (
            <div className="sticky w-fit top-[2rem] left-0 z-[1000] translate-y-[-1rem] translate-x-[1.5rem]">
              <div
                className={`arrow-events-container cursor-pointer p-[0.5rem] ${
                  scrolled
                    ? "scrolled"
                    : ""
                    ? "bg-[var(--inyc-secondary)] text-[--inyc-secondary-text-color] hover:bg-[var(--inyc-primary)] hover:text-[--inyc-primary-text-color]"
                    : "hover:bg-[var(--inyc-secondary)]"
                } `}
                style={{
                  transition: "0.3s",
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={Close}
              >
                <IoIosArrowBack className={"arrow-icon"} size={20} />
              </div>
            </div>
          )}
          <div className="px-[1.5rem]">
            <div
              className="max-w-full break-words flex items-center flex-wrap gap-[1rem] overflow-hidden"
              style={{
                fontFamily: "Primary-Bold",
                color: "var(--inyc-details-text-color)",
                lineHeight: "1.2",
                fontSize: "2.3rem",
                marginTop: "0.7rem",
                marginBottom: "0.5rem",
              }}
            >
              {event.name}
              {event.type === "venue" && occupancy && (
                <div className="w-fit font-['Primary-Medium'] text-[0.9rem] inline-block text-white bg-[--inyc-primary-lighter] px-[0.7rem] rounded-[3px] py-[0.2rem]">
                  {occupancy?.toLowerCase()}
                </div>
              )}
            </div>
            {/* tags */}
            <div className="flex items-end max-w-full flex-wrap justify-start gap-[1rem] mb-[1rem]">
              {event?.category && (
                <div
                  style={{
                    color: lightColor,
                  }}
                  className="
              font-['Primary-Medium']
              text-[1.4rem]
              rounded-[5px] bg-[--inyc-details-btn-bg] px-[0.5rem] py-[0.2rem] 
            "
                >
                  {event?.category}
                </div>
              )}
              {event?.tags?.length > 0 &&
                [...new Set(event?.tags)]?.map((tag, i) => (
                  <div
                    key={i}
                    className="font-['Primary-Medium'] text-[--inyc-details-text-color] px-[0.5rem] py-[0.4rem] text-[0.8rem] leading-[1rem] rounded-[5px] bg-[--inyc-details-btn-bg] flex items-center gap-[0.5rem]"
                  >
                    {knownServices[tag?.toLowerCase()]?.icon ? (
                      <div className="flex items-center justify-center gap-[0.5rem]">
                        <div className="w-[16px] h-[16px] ">
                          {loadIcon(knownServices[tag?.toLowerCase()]?.icon, {
                            size: 15,
                          })}
                        </div>
                        <div className="font-['Primary-Medium'] leading-[1rem] text-[--inyc-details-text-color]">
                          {knownServices[tag?.toLowerCase()]?.name}
                        </div>
                      </div>
                    ) : (
                      tag
                    )}
                  </div>
                ))}
            </div>
            {/* event address */}
            <div
              className={`event-detail-address`}
              style={{
                color: "var(--inyc-details-light-color)",
                marginBottom: "0.5rem",
              }}
            >
              {event.type === "event" && venueName && (
                <>
                  <span
                    className="cursor-pointer duration-300 
                    hover:!text-white
                    font-['Primary-Medium']"
                    onClick={() => setSelectedSubEvent(null)}
                    style={{
                      color: "white",
                    }}
                  >
                    <div className="flex items-center gap-[0.5rem]">
                      <div className="inline-block translate-y-[-5%] font-['Primary-Medium'] w-[1.2rem] h-[1.5rem]">
                        <LocationSVG
                          color={"white"}
                        />
                      </div>
                      <span className="font-['Primary-Medium'] text-white">
                        {venueName}
                      </span>
                    </div>
                  </span>
                </>
              )}
              {!venueName &&
                event.address
                  ?.toLowerCase()
                  .replace(venueName?.toLowerCase() + ",", "")}
            </div>
            {/* distance and time  */}
            <div className="mb-[1rem]">
              <div className="flex justify-between items-center gap-[5px]">
                <div className="text-[--inyc-details-text-color]">
                  {(selectedEvent?.location?.coordinates &&
                    distance(
                      userLocation?.lat,
                      userLocation?.lng,
                      selectedEvent?.location?.coordinates[1],
                      selectedEvent?.location?.coordinates[0]
                    )?.toFixed(2)) ||
                    0}{" "}
                  miles away
                </div>
                {formattedTime && (
                  <div
                    key={event?._id}
                    style={{
                      fontSize: "0.8rem",
                      color: "var(--inyc-details-text-color)",
                      backgroundColor: "var(--inyc-details-btn-bg)",
                      fontFamily: "Primary-Medium",
                      padding: "0.3rem 0.5rem 0.2rem 0.5rem",
                      borderRadius: "3px",
                      // marginBottom: "10px",
                      marginLeft: "auto",
                      width: "fit-content",
                    }}
                  >
                    {formattedTime}
                  </div>
                )}
              </div>
              {event.type === "event" && (
                <div className="text-[--inyc-details-light-color] mb-[5px] text-[1.1rem] font-['Primary-Regular']">
                  {formattedDate}
                </div>
              )}
            </div>
            {/* buttons */}
            <div className="flex mb-[0.5rem] gap-[1rem] justify-between">
              <div
                onClick={toggleEventLike}
                className="event-details-button flex-1"
              >
                <div
                  className="icon engage-icon"
                  style={{ cursor: "pointer", zIndex: 1001 }}
                >
                  <span className="tooltip">
                    {likedEvent.likes?.includes(user?._id) ? "liked!" : "like"}
                  </span>
                  <div>
                    <div className="flex-col">
                      {likedEvent.likes?.includes(user?._id) ? (
                        <AiFillHeart color={activeColor} size={20} />
                      ) : (
                        <AiOutlineHeart size={20} color="var(--icon-color)" />
                      )}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div
                className="event-details-button flex-1"
                onClick={() => onGetDirections(event)}
              >
                <div
                  className="
                  icon engage-icon h-[1.5rem]"
                >
                  <LocationSVG className={""} color="var(--icon-color)" />
                </div>
                {/* directions */}
              </div>
              {event.ticket_link && (
                <div
                  className="event-details-button flex-1"
                  onClick={() => {
                    window.open(ensureValidUrl(event.ticket_link), "_blank");
                  }}
                >
                  <div
                    className="icon engage-icon"
                    style={{ cursor: "pointer", zIndex: 1001 }}
                  >
                    {/* // share event */}
                    <span className="tooltip">tickets</span>
                    <div className="flex-col">
                      <TbTicket
                        className={"rotate-[-90deg]"}
                        color="var(--icon-color)"
                        size={20}
                      />
                    </div>
                  </div>
                  {/* tickets */}
                </div>
              )}
              <div onClick={shareEvent} className="event-details-button flex-1">
                <div
                  className="icon engage-icon"
                  style={{ cursor: "pointer", zIndex: 1001 }}
                >
                  {/* // share event */}
                  <span className="tooltip">share</span>
                  <div className="flex-col">
                    <TbShare3 size={20} color="var(--icon-color)" />
                  </div>
                </div>
              </div>
              {Capacitor.isNativePlatform() && event.type === "event" && (
                <div onClick={addToCalendar} className="event-details-button">
                  <div
                    className="icon engage-icon"
                    style={{ cursor: "pointer", zIndex: 1001 }}
                  >
                    {/* // share event */}
                    <span className="tooltip">add to calendar</span>
                    <div className="flex-col">
                      <LuCalendarPlus size={20} color="var(--icon-color)" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* Moved SocialsView here so it's always visible */}
            {event.socials && event.socials?.length > 0 && (
              <SocialsView socials={event.socials} />
            )}
            
            {event.type === "venue" && !forceVenueInfoOpen && (
              <div
                className="text-[1rem] font-['Primary-Medium'] flex gap-[0.5rem] items-center justify-end"
                onClick={() => setVenueInfoOpen(!venueInfoOpen)}
              >
                {venueInfoOpen || forceVenueInfoOpen ? "less" : "more"} venue
                info
                <FaChevronDown
                  size={10}
                  className={`${
                    venueInfoOpen || forceVenueInfoOpen ? "rotate-[180deg]" : ""
                  }`}
                />
              </div>
            )}
            <div
              className={` overflow-hidden duration-200
                  grid ${
                    event.type === "venue" &&
                    !venueInfoOpen &&
                    !forceVenueInfoOpen
                      ? "grid-rows-[0fr]"
                      : "grid-rows-[1fr]"
                  }
                `}
            >
              <div className="min-h-0">
                {/* description */}
                <div
                  className="event-detail-description mt-[1rem] text-[--inyc-details-text-color]"
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {showFullDescription ? (
                    <>
                      {event.description
                        ?.split("\n\n")
                        .filter((t) => t?.trim() !== "")
                        .join("\n")}
                      {"   "}
                      <span
                        style={{
                          color: "var(--inyc-details-secondary-color)",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          fontFamily: "Primary-Regular",
                        }}
                        onClick={() => setShowFullDescription(false)}
                      >
                        show less
                      </span>
                    </>
                  ) : (
                    <div className="text-[--inyc-details-text-color]">
                      {event.description
                        ?.split("\n\n")
                        .filter((t) => t?.trim() !== "")
                        .join("\n")
                        ?.slice(0, characterLimit)}
                      {event.description?.length > characterLimit ? (
                        <>
                          ...{"  "}
                          <span
                            style={{
                              color: "var(--inyc-details-secondary-color)",
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                              fontFamily: "Primary-Regular",
                            }}
                            onClick={() => setShowFullDescription(true)}
                          >
                            show more
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                {/* media */}
                {event.images?.length > 0 && (
                  <ImageSlider
                    className="overflow-hidden mb-[1rem] rounded-[5px] h-[200px] md:h-[min(calc(30vw-3.5rem), 400px)] mt-[0.5rem]"
                    images={event.images}
                  />
                )}
                {/* poster  */}
                {event.account_id && (
                  <div className="mb-[2rem] flex gap-[5px] leading-[1rem] font-['Primary-Regular'] text-[--inyc-details-text-color] text-[0.9rem] items-center">
                    <div className="text-[1.1rem] font-bold text-[--inyc-details-text-color]">
                      posted{" "}
                      {formatPosterAccount(
                        event.account_id,
                        "var(--inyc-details-light-color)",
                        () => {
                          // console.log("event account :", event.account_id);
                          selectAccount(event.account_id);
                          // onSelectAccount && onSelectAccount(event.account_id);
                        }
                      )}
                    </div>
                    {/* <div>@{event.account_id?.firstName + " " + event.account_id?.lastName}</div> */}
                    {user?.following?.includes(event.account_id?._id) ? (
                      <FaEye
                        onClick={toggleFollow}
                        color={"white"}
                        className="ml-[0.2rem] translate-y-[-5%] cursor-pointer duration-200 hover:scale-[1.2] hover:translate-y-[-2px]"
                        size={20}
                      />
                    ) : (
                      <FaRegEye
                        color={"var(--inyc-details-light-color)"}
                        onClick={toggleFollow}
                        className="ml-[0.2rem] translate-y-[-5%] cursor-pointer duration-200 hover:scale-[1.2] hover:translate-y-[-2px]"
                        size={20}
                      />
                    )}
                  </div>
                )}
                {/* //! rest */}
                {/* venue links */}
                {/* {event.type === "venue" && (
                  <>
                    <div>
                      {event.website && (
                        <a
                          target="_blank"
                          href={ensureValidUrl(event.website)}
                          className="event-detail-link"
                        >
                          website
                        </a>
                      )}
                      {event.media && // if media contains ' , ' then split it into an array and map over it
                        event.media.split(" , ").map((media, i) => (
                          <React.Fragment key={i}>
                            {media && (
                              <a
                                target="_blank"
                                href={ensureValidUrl(media)}
                                className="event-detail-link"
                              >
                                {extractWebsiteName(media)}
                              </a>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </>
                )} */}
                {/* event links */}
                {event.type === "event" && (
                  <div>
                    {event.ticket_link && (
                      <a
                        target="_blank"
                        href={ensureValidUrl(event.ticket_link)}
                        className="event-detail-link"
                      >
                        ticket link
                      </a>
                    )}
                    {event.paytheartist_link && (
                      <a
                        target="_blank"
                        href={ensureValidUrl(event.paytheartist_link)}
                        className="event-detail-link"
                      >
                        pay the artist
                      </a>
                    )}
                  </div>
                )}
                <div className="mb-[1.5rem]"></div>
                {/* venue emeneties */}
                {event.alcohol && (
                  <div className="event-detail-amenity">
                    -this {event.type} has{" "}
                    <span
                      style={{
                        fontFamily: "Primary-Regular",
                        color: "var(--inyc-details-secondary-color)",
                      }}
                    >
                      alcohol
                    </span>
                  </div>
                )}
                {event.food && (
                  <div className="event-detail-amenity">
                    -this {event.type} has{" "}
                    <span
                      style={{
                        fontFamily: "Primary-Regular",
                        color: "var(--inyc-details-secondary-color)",
                      }}
                    >
                      food
                    </span>
                  </div>
                )}
                {event.outdoor && (
                  <div className="event-detail-amenity">
                    -this {event.type} is{" "}
                    <span
                      style={{
                        fontFamily: "Primary-Regular",
                        color: "var(--inyc-details-secondary-color)",
                      }}
                    >
                      outdoors
                    </span>
                  </div>
                )}
                {event.age_restriction && (
                  <div className="event-detail-amenity">
                    -this {event.type} is{" "}
                    <span
                      style={{
                        fontFamily: "Primary-Regular",
                        color: "var(--inyc-details-secondary-color)",
                      }}
                    >
                      {event.age_restriction}+
                    </span>
                  </div>
                )}
                {event.ticketed &&
                  event.ticket_price &&
                  event.ticket_price !== "" && (
                    <div className="event-detail-amenity">
                      -this event is ticketed, cost :{" "}
                      <span
                        style={{
                          fontFamily: "Primary-Regular",
                          color: "var(--inyc-details-secondary-color)",
                        }}
                      >
                        {
                          // if ticket_price includes $ then show ticket_price else show $ticket_price
                          event.ticket_price.includes("$")
                            ? event.ticket_price
                            : "$" + event.ticket_price
                        }
                      </span>
                    </div>
                  )}
                {/* minimap */}
                {/* {event.location && ( */}
                <div className="rounded-[0.8rem] mt-[1.5rem] overflow-hidden mb-[1.5rem]">
                  <MiniMap
                    userLocation={userLocation}
                    event={event}
                    key={event._id}
                    style={{
                      height: "200px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* venu events */}
          <div className="mb-[5rem]">
            {event.type === "venue" && (
              <>
                <div className="flex items-center justify-between map-events-title mt-[0.5rem]">
                  events{" "}
                  <div onClick={() => setVenueFilterOpen(!venueFilterOpen)}>
                    {/* <HiOutlineDotsVertical size={20} /> */}

                    <IoFilter size={20} />
                  </div>
                </div>
                <div
                  className={`grid overflow-hidden duration-200 ${
                    venueFilterOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                  }`}
                >
                  <div className="min-h-0">
                    <Input
                      placeHolder="search events at this venue"
                      onChange={(e) => setQueryFilter(e.target.value)}
                      value={queryFilter}
                      icon={<FiSearch size={25} />}
                      className={
                        "!mt-[0.5rem] md:mb-[2rem] h-[3.5rem] max-w-[88%] mx-auto"
                      }
                    />
                    <BottomSheetDateFilter
                      filterDate={dateFilter}
                      setFilterDate={setDateFilter}
                      tagFilter={tagFilter}
                      setTagFilter={setTagFilter}
                      className={"!relative md:!min-w-full md:!w-full"}
                    />
                  </div>
                </div>
                {detailsSearchLoading && (
                  <div className="fixed left-[50%] translate-x-[-50%] p-[5rem] ">
                    <Loader />
                  </div>
                )}
                {event.events?.length > 0 || filteredEvents?.length > 0 ? (
                  <>
                    <InfiniteScroller
                      className={detailsSearchLoading ? "opacity-20" : ""}
                      items={eventItems}
                      renderer={(item, index) => {
                        return (
                          <EventCard
                            key={index}
                            disabled={filtersActive && !item._filterEvent}
                            data={{
                              ...item,
                              timezone: item.timezone || event.timezone,
                            }}
                            className={`map-event-container`}
                            onClick={() => {
                              onSelectEvent(item);
                            }}
                          />
                        );
                      }}
                    />
                  </>
                ) : (
                  <div className="map-events-none">no upcoming events</div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="event-detail-container">
          <Loader />
        </div>
      )}
    </>
  );
};

export default EventDetails;
