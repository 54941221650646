import React from "react";
import { BiSolidInfoCircle } from "react-icons/bi";
import { GoAlertFill } from "react-icons/go";

const variants = {
  error: {
    className: "bg-[var(--inyc-notice-bg-color)] text-[var(--inyc-primary-text-color)] border-[var(--inyc-input-border-color)]",
    Icon: <GoAlertFill size={20} className="min-w-[20px] min-h-[20px]" />,
  },
  default: {
    className: "bg-[var(--inyc-primary)] text-[var(--inyc-primary-text-color)] border-[rgba(0,0,0,0.03)]",
    Icon: <BiSolidInfoCircle size={20} 
    color="var(--inyc-primary-text-color)"
    className="min-w-[20px] min-h-[20px]" />,
  },
};

const Notice = (props) => {
  const { children, className, variant,noicon } = props;

  const { className: variantClassName, Icon } = variants[variant] || variants.default;
  return (
    <div
      className={`${variantClassName} w-fit rounded-[10px] border-[1px] text-[0.9rem]
      gap-[10px]
      flex-row 
      text-start 
      p-[1rem] 
      flex
      items-center 
      ${className}`}
    >
      {!noicon && Icon}
      <div className="font-['Primary-Regular'] text-[var(--inyc-primary-text-color)]">{children}</div>
    </div>
  );
};

export default Notice;
