import Icon from "../components/Icon/Icon";
import { FiMusic } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io5";
import { IoLogoTiktok } from "react-icons/io5";
import { FaSpotify } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { FaSoundcloud } from "react-icons/fa6";
import { FaBandcamp } from "react-icons/fa";
import { FaVimeo } from "react-icons/fa";
import wjfIcon from "../assets/icons/wjf-logo.png";
import websiteIcon from "../assets/icons/website.svg";

// dynamicIconRegistry.js
const iconRegistry = {
  FiMusic: {
    icon: FiMusic,
  },
  FaInstagram: {
    icon: FaInstagram,
  },
  IoLogoFacebook: {
    icon: IoLogoFacebook,
  },
  IoLogoTiktok: {
    icon: IoLogoTiktok,
  },
  FaSpotify: {
    icon: FaSpotify,
  },
  FaXTwitter: {
    icon: FaXTwitter,
  },
  FaApple: {
    icon: FaApple,
  },
  FaYoutube: {
    icon: FaYoutube,
  },
  FaSoundcloud: {
    icon: FaSoundcloud,
  },
  FaBandcamp: {
    icon: FaBandcamp,
  },
  FaVimeo: {
    icon: FaVimeo,
  },
  websiteIcon: {
    icon: (props) => <Icon src={websiteIcon} {...props} />,
    img: websiteIcon,
  },
  wjfIcon: {
    icon: (props) => (
      <Icon
        src={wjfIcon}
        {...props}
        className={`grayscale-[0.5] flex items-center justify-center ${props?.className}`}
      />
    ),
    img: wjfIcon,
  },
};

export const getKnownServiceImg = (iconName) => {
  if (!iconRegistry[iconName]) {
    console.warn(`Icon "${iconName}" not found in registry.`);
    return null;
  }
  return iconRegistry[iconName]?.img;
}

export const loadIcon = (iconName, props) => {
  if (!iconRegistry[iconName]) {
    console.warn(`Icon "${iconName}" not found in registry.`);
    return null;
  }
  const IconComponent = iconRegistry[iconName]?.icon;
  return <IconComponent {...props} />;
};
