import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import Notice from '../ui/notice';
import Loader from '../icons/Loader';
import Input from '../Input/Input';
import DashboardMap from '../DashboardMap/DashboardMap';
import { parseAddressFromObject, formatAddressObject, parseStoredAddress, parseGoogleAddress } from './addressUtils';
import eventMarker from "../../assets/map/event-marker.png";
import axiosInstance from "../../api/axiosInstance";
import { backendUrl } from "../../api/constants";
import Logo from "../../assets/imgs/inyc.svg";
import CheckBox from '../CheckBox/CheckBox';
import Tabs, { TabContent } from '../ui/tabs';
import VenueCard from '../VenueCard/VenueCard';
import { Popup } from 'react-leaflet';
import { MdClear, MdEdit } from "react-icons/md";
import { MarkerPopupButton } from '../Map/MapMarker';

/**
 * AddressHandler Component
 * 
 * A form component that handles address input and map location selection.
 * 
 * @component
 * @param {Object} props
 * @param {Object} props.value - Parent controlled value containing address string and location coordinates
 * @param {Function} props.onChange - Callback when address/location changes
 * @param {Array} props.mapMarkers - Additional markers to display on map
 * @param {Object} props.initialValue - Initial address/location value for uncontrolled mode
 * @param {React.Ref} ref - Forwarded ref that exposes component methods
 * @param {Function} props.renderSuggestion - Custom suggestion rendering function
 * @param {Function} props.onSuggestionSelected - Parent-specific selection handling callback
 * @param {Function} props.suggestionFilter - Optional filter function for suggestions
 * @param {boolean} props.recommendCheckbox - Optional prop to conditionally render recommend checkbox
* @param {boolean} props.showVenueCard - Optional prop to conditionally render the venue card if a verified venue is selected
*/
const AddressHandler = forwardRef((props, ref) => {

    const {
        value,
        onChange,
        userLocation,
        showVenueCard,
        mapMarkers = [],
        initialValue = { address: '', location: null },
        renderSuggestion,
        onSuggestionSelected,
        suggestionFilter,
        recommendCheckbox = false
    } = props
    // State
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [locationLoading, setLocationLoading] = useState(false);
    const [addressEmpty, setAddressEmpty] = useState(true);
    const [locationTab, setLocationTab] = useState("address");

    // Refs
    const isInternalUpdate = useRef(false);
    const mapRef = useRef();
    const locationSearchTimer = useRef();
    const recommendRef = useRef();

    // Hooks
    const isControlled = value !== undefined;

    // Initialize internal state
    const [internalState, setInternalState] = useState(() => ({
        address: parseAddressFromObject(initialValue),
        location: initialValue?.location || null,
        venue: initialValue?.venue || null
    }));


    console.log('value ', value)
    // Effects
    useEffect(() => {
        if (isControlled && !isInternalUpdate.current) {
            setInternalState({
                address: value?.addressFields || parseAddressFromObject(value),
                location: value?.location || null,
                venue: value?.venue || null
            });
        }
        isInternalUpdate.current = false;
    }, [isControlled, value?.address, value?.addressFields, value?.location, value?.venue]);


    useEffect(() => {
        if (!mapRef.current) return;
        if (userLocation?.lng && userLocation?.lat) {
            mapRef.current.panTo(userLocation);
        }

    }, [userLocation])

    useEffect(() => {
        if (!mapRef.current) return;

        console.log('location changed internally ', internalState?.location?.coordinates)

        if (internalState.location?.coordinates) {
            mapRef.current.panTo({
                lat: internalState.location.coordinates[1],
                lng: internalState.location.coordinates[0]
            });
        }
    }, [internalState.location?.coordinates]);

    useEffect(() => {
        const isEmpty = !Object.values(internalState.address).some(val => val?.trim() !== '') && !internalState.location;
        setAddressEmpty(isEmpty);
    }, [internalState.address]);

    // Expose methods via ref
    useImperativeHandle(ref, () => ({
        getState: () => ({
            address: formatAddressObject(internalState.address),
            location: internalState.location,
            venue: internalState.venue
        }),
        getVenueId: () => internalState.venue?._id,
        goToTab: (tab) => setLocationTab(tab),
        clearAddress,
        setState: (newState) => {
            //if newState is a function , use it with current state 
            if (typeof newState === 'function') {
                let newVal = newState(internalState);
                const { address, location, type, name, venue } = newVal;
                setInternalState({
                    address: parseStoredAddress(address, type === "venue" ? name : null),
                    location: location,
                    venue: venue,
                });
            } else {
                const { address, location, type, name, venue } = newState;
                setInternalState({
                    address: parseStoredAddress(address, type === "venue" ? name : null),
                    location: location,
                    venue: venue,
                });
            }
        },
        getAddressString: () => formatAddressObject(internalState.address),
        getLocation: () => internalState.location,
        recommend: () => recommendRef.current
    }));

    // Handlers
    const clearAddress = () => {
        if (isControlled) {
            onChange?.({
                address: '',
                location: null,
                addressFields: null,
                venue: null
            });
        } else {
            setInternalState({
                address: parseAddressFromObject(''),
                location: null,
                venue: null
            });
        }
        setLocationSuggestions([]);
    };

    const handleMapClick = (latlng) => {
        const newLoc = {
            type: "Point",
            coordinates: [latlng.lng, latlng.lat]
        }
        setInternalState(prev => {
            const newState = {
                ...prev,
                location: newLoc,
                venue: null
            };


            isInternalUpdate.current = true;
            onChange?.({
                address: formatAddressObject(prev.address),
                addressFields: prev.address,
                location: newLoc,
                venue: null
            });


            return newState;
        });
    };

    const handleFieldChange = (field, value) => {
        setInternalState(prev => {
            const newFields = { ...prev.address, [field]: value };
            const newState = {
                address: newFields,
                location: prev.location,
                venue: prev.venue
            };

            handleAddressChange();

            // Always call onChange regardless of control mode
            isInternalUpdate.current = true;
            onChange?.({
                address: formatAddressObject(newState.address),
                addressFields: newState.address,
                location: newState.location,
                venue: newState.venue
            });

            return newState;
        });
    };

    const handleAddressChange = async () => {
        setLocationLoading(true);
        const address = formatAddressObject(internalState.address);

        if (address.trim() === "") {
            setLocationSuggestions([]);
            clearTimeout(locationSearchTimer.current);
            setLocationLoading(false);
            return;
        }

        clearTimeout(locationSearchTimer.current);
        locationSearchTimer.current = setTimeout(async () => {
            try {
                let autocompleteUrl = `${backendUrl}/influencer/geolocation/autocomplete?${new URLSearchParams({ input: address })}`;
                if (userLocation) {
                    console.log('userLocation found', userLocation)
                    autocompleteUrl += `&latitude=${userLocation.lat}&longitude=${userLocation.lng}`
                } else {
                    console.log('no userLocation found defaulting to city NYC')
                    autocompleteUrl += `&cityCode=NYC`
                } 
                const results = await axiosInstance.get(autocompleteUrl);
                if (results?.data) {
                    setLocationSuggestions(results.data);
                }
            } catch (error) {
                console.error("Autocomplete error:", error);
            } finally {
                setLocationLoading(false);
            }
        }, 700);
    };

    const selectLocationSuggestion = async (suggestion) => {
        if (!suggestion) return;

        try {
            setLocationLoading(true);

            if (suggestion?._id) {
                // Handle existing venue from database
                const addressFields = parseStoredAddress(suggestion.address, suggestion.name);
                const newState = {
                    address: {
                        venueName: suggestion.name,
                        ...addressFields
                    },
                    location: suggestion.location,
                    venue: suggestion
                };

                setInternalState(newState);
                setLocationSuggestions([]);

                if (isControlled) {
                    onChange?.({
                        address: formatAddressObject(newState.address),
                        addressFields: newState.address,
                        location: newState.location,
                        venue: newState.venue
                    });
                }

                if (onSuggestionSelected) {
                    await onSuggestionSelected({
                        rawSuggestion: suggestion,
                        parsedAddress: newState.address,
                        coordinates: suggestion.location?.coordinates
                    });
                }

                if (suggestion.location?.coordinates) {
                    mapRef.current?.panTo({
                        lat: suggestion.location.coordinates[1],
                        lng: suggestion.location.coordinates[0]
                    });
                }
            } else {
                // Handle new location from Google Places
                const { data } = await axiosInstance.get(
                    `${backendUrl}/influencer/geolocation/place?${new URLSearchParams({
                        place_id: suggestion?.place_id,
                    })}`
                );

                if (!data) {
                    enqueueSnackbar('No place data received', { variant: 'error' });
                    return;
                }

                const googleAddress = parseGoogleAddress(data.address_components);
                const newState = {
                    address: {
                        venueName: data.name || '',
                        ...googleAddress
                    },
                    location: {
                        type: "Point",
                        coordinates: [data.geometry.location.lng, data.geometry.location.lat]
                    },
                    venue: null
                };

                setInternalState(newState);
                setLocationSuggestions([]);

                if (isControlled) {
                    onChange?.({
                        address: data.formatted_address,
                        addressFields: newState.address,
                        location: newState.location,
                        venue: null
                    });
                }

                mapRef.current?.panTo({
                    lat: data.geometry.location.lat,
                    lng: data.geometry.location.lng
                });
            }
        } catch (error) {
            console.error("Error selecting location:", error);
        } finally {
            setLocationLoading(false);
        }
    };

    // Render
    console.log('internalState ', internalState)
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <div className="flex justify-between items-center">
                <div className="text-[1.4rem] font-['Primary-Medium'] mb-[1rem]">
                    location
                </div>
                {/* <div className="font-['Primary-Medium']" style={{ color: "var(--inyc-primary-text-color)", fontSize: "1.4rem" }}>
                                address
                            </div> */}
                {!addressEmpty && (
                    <div className="rounded-[3rem] w-fit px-[1rem] py-[0.7rem] bg-[--inyc-primary-light] cursor-pointer" onClick={clearAddress}>
                        clear
                    </div>
                )}
            </div>
            <Tabs
                currentTab={locationTab}
                setCurrentTab={setLocationTab}
                tabs={["address", "map"]}
                stickyContent={<>
                    {(internalState.venue && showVenueCard) && (
                        <div className="scale-[0.8] w-full max-w-[450px] mx-auto flex justify-center items-center">
                            <VenueCard
                                hideOwnershipStatus
                                className={'!mb-0 min-w-full'}
                                venue={internalState.venue}
                                onUnselect={clearAddress}
                            />
                        </div>
                    )}
                </>}
            >
                <TabContent name="address">
                    <div className="" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        {!internalState?.location?.coordinates && <Notice>
                            you have not selected a location yet, please choose a location from the search results or use the map page to select a pin
                        </Notice>}
                        <div className="px-[1rem] mb-[1rem]">
                            <Input
                                value={internalState.address.venueName}
                                onChange={(e) => handleFieldChange('venueName', e.target.value)}
                                placeHolder="business / venue name"
                            />

                            {locationLoading ? (
                                <div className="w-full flex justify-center items-center p-[1rem]">
                                    <Loader />
                                </div>
                            ) : (
                                locationSuggestions.length > 0 && (
                                    <div className="mt-[1rem] max-w-full overflow-x-scroll p-[1rem] flex justify-start items-stretch gap-[1rem]">
                                        {locationSuggestions
                                            .filter(suggestion => suggestionFilter ? suggestionFilter(suggestion) : true)
                                            .map((suggestion, i) => renderSuggestion ? (
                                                <div
                                                    className="relative p-[1rem] whitespace-nowrap max-w-[250px] text-[0.8rem] shadow-md rounded-lg cursor-pointer"
                                                    key={i} onClick={() => selectLocationSuggestion(suggestion)}>
                                                    {renderSuggestion(suggestion, i)}
                                                </div>
                                            ) : (
                                                <div
                                                    key={i}
                                                    onClick={() => selectLocationSuggestion(suggestion)}
                                                    className="relative p-[1rem] whitespace-nowrap max-w-[250px] text-[0.8rem] shadow-md rounded-lg cursor-pointer"
                                                >
                                                    {suggestion._id && (
                                                        <div className="px-[0.5rem] py-[0.1rem] flex justify-center items-center gap-[0.2rem] absolute top-[-1rem] right-[-0.5rem] bg-black text-white rounded-lg">
                                                            <img src={Logo} alt="inyc verified" className="w-[1.5rem] h-[1.5rem] object-contain invert-[1]" />
                                                            <div className="leading-[1rem] font-['Primary-Regular'] text-[0.7rem]">verified</div>
                                                        </div>
                                                    )}
                                                    <div>
                                                        {suggestion._id ? suggestion.name : suggestion.structured_formatting.main_text}
                                                        {suggestion.address && (
                                                            <div className="text-gray-500 max-w-full overflow-hidden overflow-ellipsis">
                                                                {suggestion._id ? suggestion.address : suggestion.structured_formatting.secondary_text}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                            )}
                                    </div>
                                )
                            )}

                            <Input
                                value={internalState.address.streetAddress}
                                onChange={(e) => handleFieldChange('streetAddress', e.target.value)}
                                placeHolder="street address"
                            />
                            <Input
                                value={internalState.address.streetFloor}
                                onChange={(e) => handleFieldChange('streetFloor', e.target.value)}
                                placeHolder="street address (floor / apt / etc.)"
                            />

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                                <Input
                                    value={internalState.address.city}
                                    onChange={(e) => handleFieldChange('city', e.target.value)}
                                    className="flex-1"
                                    placeHolder="city"
                                />
                                <Input
                                    value={internalState.address.state}
                                    onChange={(e) => handleFieldChange('state', e.target.value)}
                                    className="flex-1"
                                    placeHolder="state"
                                />
                            </div>

                            <Input
                                value={internalState.address.zipCode}
                                onChange={(e) => handleFieldChange('zipCode', e.target.value)}
                                placeHolder="zip code"
                            />
                        </div>

                        {recommendCheckbox && (
                            <div className="px-[1rem]">
                                <CheckBox
                                    label={
                                        <div className="text-[1rem]">
                                            recommend this venue to{" "}
                                            <span className="font-['Primary-Bold']">in—yc</span>
                                        </div>
                                    }
                                    Ref={recommendRef}
                                />
                            </div>
                        )}
                    </div>
                </TabContent>

                <TabContent name="map">
                    <Notice className="mx-auto">
                        drop a pin on the <strong>map</strong> for events without a physical address.
                        <br />
                        for example a specific location in a park.
                    </Notice>
                    <div className="min-w-full mx-auto rounded-[var(--inyc-input-border-radius)] overflow-hidden w-[350px] md:w-[700px] h-[350px] md:h-[500px] flex md:min-w-[700px] justify-center p-[1rem] md:p-[2rem]">
                        <DashboardMap
                            className="md:!max-w-[700px] h-[350px] md:h-[700px] md:!mx-auto shadow-sm"
                            Ref={mapRef}
                            userLocation={userLocation}
                            style={{ borderRadius: "var(--inyc-input-border-radius)" }}
                            onSearchResultSelected={() => { }}
                            onMapClick={handleMapClick}
                            markers={[
                                internalState?.location?.coordinates ? {
                                    zIndexOffset: 1000,
                                    icon: eventMarker,
                                    lat: internalState.location?.coordinates[1],
                                    lng: internalState.location?.coordinates[0],
                                    props: {
                                        Popup: (props) => {
                                            const { onClose, onMapClick, onVenueSelect } = props;
                                            return <Popup
                                                style={{
                                                    backgroundColor: "var(--inyc-primary-light)",
                                                }}
                                                className="bg-[--inyc-primary] w-full"
                                                {...props}
                                            >
                                                <div className="bg-[--inyc-primary] w-full" style={{ padding: '0.5rem', minWidth: '15rem', borderRadius: '0.3rem' }}>
                                                    {internalState.venue && <>
                                                        <div className="text-[0.6rem] text-[--inyc-primary-text-color-light] font-['Primary-Medium']">
                                                            venue
                                                        </div>
                                                        <div className="text-[1rem] mb-[0.2rem] font-['Primary-Medium'] text-[--inyc-primary-text-color]">
                                                            {internalState.venue?.name}
                                                        </div>
                                                    </>}
                                                    <div className="text-[0.7rem] mb-[0.5rem] font-['Primary-Regular'] text-[--inyc-primary-text-color-light]">
                                                        {formatAddressObject(internalState?.address) || <div className="text-[0.8rem] ml-[0.5rem] font-['Primary-Regular'] text-[--inyc-primary-text-color-light]">no address</div>}
                                                    </div>
                                                    <MarkerPopupButton
                                                        text="edit address"
                                                        Icon={MdEdit}
                                                        iconsize={13}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            setLocationTab('address');
                                                            onClose();
                                                        }}
                                                    />
                                                    <MarkerPopupButton
                                                        Icon={MdClear}
                                                        text="clear"
                                                        iconsize={13}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            clearAddress();
                                                            onClose();
                                                        }}
                                                    />

                                                </div>
                                            </Popup>
                                        }
                                    }
                                } : null,
                                ...mapMarkers
                            ].filter(Boolean)}
                            searchBar
                        />
                    </div>
                </TabContent>
            </Tabs>
        </div>
    );
});

export default AddressHandler;