import React, { useEffect, useState } from "react";
import CheckSVG from "../icons/CheckSVG";
import "./Checkbox.css";
import { FaCheck } from "react-icons/fa";

const CheckBox = ({ Ref, 
  className,
  checkboxClassName,
  defaultChecked = false, onChange,label,labelClassName, value }) => {
  const isControlled = value !== undefined;
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (isControlled) {
      setChecked(value);
    }
  }
  , [value]);


  const handleChange = () => {
    setChecked((prev) => !prev);
    onChange && onChange(!checked);
  };

  return (
    <div className={`[&>.checked]:bg-[var(--inyc-secondary)] flex justify-center items-center gap-[1rem] ${className}`} onClick={handleChange}>
      <div
        className={`rounded-[5px] inline-block bg-transparent  min-w-[30px] w-[30px] h-[30px] ${checkboxClassName} ${value || checked ? "checked" : ""} cursor-pointer  border-[1px] border-[var(--inyc-input-border-color)]`}
      >
      <input className="checkbox" type="checkbox" ref={Ref} checked={checked} />
      {(value || checked) && 
      <div
        className="flex justify-center items-center w-full h-full"
      >
      <FaCheck

        size={15}
        color="var(--inyc-secondary-text-color)"
      />
      </div>
      }
      </div>
      {label && <div className={`flex items-center h-full whitespace-nowrap text-[1.4rem] ${labelClassName}`}>{label}</div>}
    </div>
  );
};

export default CheckBox;
