import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import { backendUrl } from "../../api/constants";
import Button from "../Button/Button";
import DropDownInput from "../DropDownInput/DropDownInput";
import VenueDropDownItem from "../DropDownInput/VenueDropDownItem";
import { useSnackbar } from "notistack";
import Notice from "../ui/notice";
import { useUser } from "../../context/UserContext/UserProvider";
import VenueCard from "../VenueCard/VenueCard";
import EditVenue from "../ui/EditVenue";

const MyVenuesPanel = ({
  userLocation,
  refreshVenues,
  refreshAll,
  venues,
  selectedVenue = {},
  setSelectedVenue,
  handleVenuPick,
}) => {
  const { user } = useUser();
  const nav = useNavigate();
  const [venueItems, setVenueItems] = useState();
  const [venueLoading, setVenueLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState();
  const venuSearchTimer = useRef();
  const editVenueRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedVenue?.marker) {
      refreshVenues(selectedVenue.marker);
    }
  }, [selectedVenue?.marker]);

  const onVenueChange = async (e) => {
    setVenueLoading(true);

    clearTimeout(venuSearchTimer.current);
    venuSearchTimer.current = setTimeout(async () => {
      try {
        const { data } = await axiosInstance.get(
          `${backendUrl}/venues/search?q=${e.target.value}`
        );
        if (data.error) {
          console.log(error);
        } else {
          setVenueItems(
            data.venues.map((item) => {
              return {
                label: item.name,
                onClick: () => {
                  handleVenuPick(item);
                },
                item: item,
              };
            })
          );
          setVenueLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };

  const handleInputChange = (e) => {
    setSelectedVenue((prev) => ({
      ...prev,
      venue: {
        ...prev.venue,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const postVenue = async () => {
    setLoading(true);

    const { form } = editVenueRef.current?.getFormData() || {
      form: new FormData(),
    };

    const mine = selectedVenue.venue?._id && selectedVenue.venue?.me;
    const endpoint = mine
      ? "update"
      : selectedVenue.venue?._id
        ? "claim"
        : "post";

    if (endpoint !== "post") {
      form.append(
        "venue_id",
        selectedVenue.venue?._id
          ? selectedVenue.venue._id.toString()
          : undefined
      );
    }

    try {
      const { data } = await axiosInstance.post(
        `${backendUrl}/business/${endpoint}`,
        form,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (data.error) {
        setPostError(null);
        for (const key in data.error) {
          if (data.error[key].hasOwnProperty("error")) {
            setPostError(data.error[key].error);
          }
        }
      } else {
        setPostError(null);
        enqueueSnackbar(
          `venue ${data.ticket?.resource?.name} posted successfully`,
          { variant: "success" }
        );
        refreshAll(selectedVenue?.marker);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // console.log("Selected Venue", selectedVenue);
  // console.log('user id', user?._id)

  return (
    <div
      style={{
        background: "var(--inyc-primary)",
        padding: "1rem",
        paddingTop: "2rem",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
      }}
    >
      <div
        className="md:!mx-auto max-w-[1000px]"
        style={{ marginRight: "20px", marginLeft: "20px" }}
      >
        <div
          className="font-['Primary-Medium'] mb-[1.5rem]"
          style={{
            fontSize: "1.5rem",
            textAlign: "center",
          }}
        >
          search for your venue:
        </div>
        <Notice className="mb-[1.5rem] mx-auto">
          if your venue already exists, hit <strong>claim venue</strong> below
          for easier event uploads
        </Notice>
        <div className="px-[1rem]">
          <DropDownInput
            ItemComponent={VenueDropDownItem}
            dropItems={venueItems}
            placeHolder="search already verified in—yc venues..."
            onChange={onVenueChange}
            dropLoading={venueLoading}
            style={{ marginBottom: "30px" }}
          />
          {selectedVenue.venue?._id && (
            <VenueCard
              onUnselect={() =>
                setSelectedVenue((prev) => ({ ...prev, venue: null }))
              }
              venue={selectedVenue.venue}
            />
          )}
          <div className="flex justify-center">
            {selectedVenue.venue?._id && (
              <>
                {selectedVenue.venue.me ? (
                  <div>
                    <Notice className="mb-[1rem]">
                      you are the owner of this venue
                    </Notice>
                  </div>
                ) : selectedVenue.venue.account_id ? (
                  <Notice className="mb-[1rem]">
                    this venue is already claimed
                  </Notice>
                ) : (
                  <div>
                    <Notice className="mb-[1rem]">
                      request to claim this venue as the representative of this
                      business, we'll reach out to verify
                      <br />
                      <br />
                      claiming a venue as the business representative allows you
                      to edit the venue info and makes for easier uploads
                    </Notice>
                    <Button
                      error={postError}
                      prompt
                      message="Confirm"
                      type="secondary"
                      loading={loading}
                      onClick={postVenue}
                      style={{
                        marginBottom: "20px",
                      }}
                      disabled={
                        selectedVenue.venue?.account_id &&
                        !selectedVenue.venue?.me
                      }
                    >
                      claim venue
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="w-full"
      >
        {
          (!selectedVenue.venue?._id || selectedVenue?.venue?.account_id === user?._id) && (
            <>
              <div
                className="font-['Primary-Medium'] mb-[1rem]"
                style={{
                  fontSize: "1.5rem",
                  textAlign: "center",
                }}
              >
                {selectedVenue.venue?._id
                  ? selectedVenue.venue?.me ||
                    selectedVenue.venue?.account_id === user?._id
                    ? "edit your venue:"
                    : "claim this venue:"
                  : "or upload a new venue"}
              </div>
              <EditVenue
                ref={editVenueRef}
                venues={venues}
                refreshVenues={refreshVenues}
                userLocation={userLocation}
                handleVenuPick={handleVenuPick}
                handleInputChange={handleInputChange}
                selectedVenue={selectedVenue}
                setSelectedVenue={setSelectedVenue}
              />
            </>
          )
        }
      </div>
      <div
        className={`${!selectedVenue?.venue?._id || selectedVenue?.venue?.account_id
          ? ""
          : "hidden"
          }`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
            marginBottom: "1rem",
            marginTop: "2rem",
          }}
        >
          <Button
            error={postError}
            prompt
            message="Confirm"
            className={"w-full"}
            type="secondary"
            loading={loading}
            onClick={postVenue}
            disabled={
              selectedVenue.venue?.account_id && !selectedVenue.venue?.me
            }
          >
            {selectedVenue.venue?.me
              ? "update venue"
              : selectedVenue.venue?.account_id
                ? "already claimed"
                : selectedVenue.venue?._id
                  ? "claim venue"
                  : "post venue"}
          </Button>
          {selectedVenue.venue?.me && (
            <Button
              className={"w-full"}
              onClick={() => {
                nav(
                  `/dashboard?tab=new-event&&venue=${selectedVenue.venue?._id}`
                );
              }}
            >
              post event
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyVenuesPanel;
