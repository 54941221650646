import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../context/UserContext/UserProvider';
import { enqueueSnackbar } from 'notistack';
import Button from '../Button/Button';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchUser } = useUser();
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const errorCode = params.get('error');
    
    if (errorCode) {
      // Handle specific error codes
      const errorMessages = {
        'access_denied': 'access denied. please try again.',
        'oauth_failed': 'authentication failed. please try again.',
        'oauth_processing_failed': 'there was a problem processing your login.',
        'account_exists': 'an account with this email already exists.',
        'default': 'an error occurred during authentication.',
        'user_exists': 'an account with this email already exists.'
      };
      
      const message = errorMessages[errorCode] || errorMessages.default;
      setError(message);
      enqueueSnackbar(message, { variant: 'error' });
      return;
    }
    
    if (token) {
      // Store token in both localStorage and sessionStorage
      localStorage.setItem('jwt_token', token);
      sessionStorage.setItem('jwt_token', token);
      
      // Update user context by fetching user data
      fetchUser()
        .then(() => {
        //   enqueueSnackbar('successfully signed in!', { variant: 'success' });
          
          // Check if there's a redirect URL stored in session
          const prevUrl = sessionStorage.getItem('redirect_url');
          if (prevUrl) {
            sessionStorage.removeItem('redirect_url');
            navigate(prevUrl);
          } else {
            // Default redirect to home page
            navigate('/');
          }
        })
        .catch(err => {
          console.error('Error fetching user data:', err);
          setError('failed to load your profile. Please try again.');
        //   enqueueSnackbar('Failed to load your profile', { variant: 'error' });
        });
    } else if (!errorCode) {
      // No token and no error code means something went wrong
      setError('Authentication response is missing. Please try again.');
      navigate('/auth/signin?error=missing_token');
    }
  }, [location, navigate, fetchUser]);
  
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      {error ? (
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <Button 
            onClick={() => navigate('/')}
          >
            return to main page
          </Button>
        </div>
      ) : (
        <p>Processing authentication, please wait...</p>
      )}
    </div>
  );
};

export default OAuthCallback;
