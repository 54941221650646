import React, { useRef, useState } from "react";
import { modal } from "./useModal";
import AvatarEditor from "react-avatar-editor";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const imageCroper = (props) => {
  const { image, proceed,borderRadius,border,width,height } = props;
  const imageCropRef = useRef();
  const [cropZoom, setCropZoom] = useState(1);

  const handleCropSave = () => {
    const canvas = imageCropRef.current.getImage().toDataURL();
    proceed({ base64: canvas });
  };

  return (
    <div>
      <AvatarEditor
        width={width || 200}
        height={height || 200}
        color={[0, 0, 0, 0.8]}
        ref={imageCropRef}
        image={image?.base64}
        border={border !== undefined ? props.border : 50}
        borderRadius={borderRadius !== undefined ?  props.borderRadius : 200}
        scale={1 + cropZoom / 100}
      />

      <div
        style={{
          height: 50,
          backgroundColor: "black",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 1rem",
          gap: "2rem",
        }}
      >
        <AiOutlineClose
          onClick={() => {
            proceed(false);
          }}
          size={25}
          style={{
            color: "white",
            cursor: "pointer",
          }}
        />

        <input
          type="range"
          style={{
            flex: 1,
          }}
          value={cropZoom}
          onChange={(e) => {
            setCropZoom(e.target.value);
          }}
          min={0}
          max={100}
        />

        <AiOutlineCheck
          onClick={handleCropSave}
          size={25}
          style={{
            color: "white",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

export const cropImageModal = async (props) => {
  return await modal(imageCroper, { props, raw: true });
};

export default imageCroper;
