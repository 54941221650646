export const parseAddressFromObject = (obj = {}) => {
    if (!obj?.address) {
        return { venueName: '', streetAddress: '', streetFloor: '', city: '', state: '', zipCode: '' };
    }

    const addressString = obj.address;
    const vname = obj.name || '';

    // First, clean up the address string by removing duplicate venue names
    let cleanedAddress = addressString;
    if (vname) {
        // Create a regex pattern to match repeated venue name at the beginning
        const venueNamePattern = new RegExp(`^(${vname},\\s*)+`, 'i');
        cleanedAddress = cleanedAddress.replace(venueNamePattern, `${vname}, `);
    }

    const components = cleanedAddress.split(',').map(part => part.trim());
    
    // Initialize fields
    let streetAddress = '';
    let streetFloor = '';
    let city = '';
    let state = '';
    let zipCode = '';

    // Skip the venue name if it's the first component
    let startIndex = 0;
    if (components.length > 0 && vname && components[0].toLowerCase() === vname.toLowerCase()) {
        startIndex = 1;
    }

    // Parse address components
    if (components.length > startIndex) {
        streetAddress = components[startIndex];
        
        if (components.length > startIndex + 1) {
            // Check if the next part looks like a floor/apt or the city
            if (components[startIndex + 1].toLowerCase().includes('floor') || 
                components[startIndex + 1].toLowerCase().includes('apt') || 
                components[startIndex + 1].toLowerCase().includes('#')) {
                streetFloor = components[startIndex + 1];
                startIndex++;
            }
            
            if (components.length > startIndex + 1) {
                city = components[startIndex + 1];
            }
            
            // Handle state and zip code
            if (components.length > startIndex + 2) {
                // Check if we have remaining components that might be part of a state name
                const remainingComponents = components.slice(startIndex + 2);
                
                // Common state patterns to look for
                const stateWithZipPattern = /^(.*?)\s+(\d{5}(-\d{4})?)$/;
                const knownTwoWordStates = ['new york', 'new jersey', 'new mexico', 'new hampshire', 
                                           'north carolina', 'south carolina', 'north dakota', 'south dakota', 
                                           'west virginia', 'rhode island', 'district of columbia'];
                
                // Try to identify state and zip from remaining components
                if (remainingComponents.length === 1) {
                    // Check if the last component has both state and zip
                    const match = remainingComponents[0].match(stateWithZipPattern);
                    if (match) {
                        state = match[1];
                        zipCode = match[2];
                    } else {
                        state = remainingComponents[0];
                    }
                } else if (remainingComponents.length >= 2) {
                    // Check for two-word states
                    const possibleState = remainingComponents.slice(0, 2).join(' ').toLowerCase();
                    if (knownTwoWordStates.includes(possibleState)) {
                        state = possibleState;
                        if (remainingComponents.length > 2) {
                            zipCode = remainingComponents[2];
                        }
                    } else {
                        state = remainingComponents[0];
                        zipCode = remainingComponents[1];
                    }
                }
            }
        }
    }

    return { venueName: vname, streetAddress, streetFloor, city, state, zipCode };
};

export const formatAddressObject = (fields) => {
    return [
        fields.venueName,
        fields.streetAddress,
        fields.streetFloor,
        fields.city,
        fields.state,
        fields.zipCode
    ].filter(Boolean).join(', ');
};

export const parseStoredAddress = (address = '', venueName = '') => {
    if (!address) {
        return { streetAddress: '', streetFloor: '', city: '', state: '', zipCode: '' };
    }

    // If address is already an object
    if (typeof address === 'object') {
        return address;
    }

    // Clean up the address string by removing duplicate venue names
    let cleanedAddress = address;
    if (venueName) {
        // Create a regex pattern to match repeated venue name at the beginning
        const venueNamePattern = new RegExp(`^(${venueName},\\s*)+`, 'i');
        cleanedAddress = cleanedAddress.replace(venueNamePattern, `${venueName}, `);
    }

    const components = cleanedAddress.split(',').map(part => part.trim());
    
    // Initialize fields
    let streetAddress = '';
    let streetFloor = '';
    let city = '';
    let state = '';
    let zipCode = '';

    // Skip the venue name if it's the first component
    let startIndex = 0;
    if (components.length > 0 && venueName && components[0].toLowerCase() === venueName.toLowerCase()) {
        startIndex = 1;
    }

    // Parse address components
    if (components.length > startIndex) {
        streetAddress = components[startIndex];
        
        if (components.length > startIndex + 1) {
            // Check if the next part looks like a floor/apt or the city
            if (components[startIndex + 1].toLowerCase().includes('floor') || 
                components[startIndex + 1].toLowerCase().includes('apt') || 
                components[startIndex + 1].toLowerCase().includes('#')) {
                streetFloor = components[startIndex + 1];
                startIndex++;
            }
            
            if (components.length > startIndex + 1) {
                city = components[startIndex + 1];
            }
            
            // Handle state and zip code
            if (components.length > startIndex + 2) {
                // Check if we have remaining components that might be part of a state name
                const remainingComponents = components.slice(startIndex + 2);
                
                // Common state patterns to look for
                const stateWithZipPattern = /^(.*?)\s+(\d{5}(-\d{4})?)$/;
                const knownTwoWordStates = ['new york', 'new jersey', 'new mexico', 'new hampshire', 
                                           'north carolina', 'south carolina', 'north dakota', 'south dakota', 
                                           'west virginia', 'rhode island', 'district of columbia'];
                
                // Try to identify state and zip from remaining components
                if (remainingComponents.length === 1) {
                    // Check if the last component has both state and zip
                    const match = remainingComponents[0].match(stateWithZipPattern);
                    if (match) {
                        state = match[1];
                        zipCode = match[2];
                    } else {
                        state = remainingComponents[0];
                    }
                } else if (remainingComponents.length >= 2) {
                    // Check for two-word states
                    const possibleState = remainingComponents.slice(0, 2).join(' ').toLowerCase();
                    if (knownTwoWordStates.includes(possibleState)) {
                        state = possibleState;
                        if (remainingComponents.length > 2) {
                            zipCode = remainingComponents[2];
                        }
                    } else {
                        state = remainingComponents[0];
                        zipCode = remainingComponents[1];
                    }
                }
            }
        }
    }

    return { streetAddress, streetFloor, city, state, zipCode };
};

export const parseGoogleAddress = (addressComponents) => {
    const components = addressComponents.reduce((acc, comp) => {
        comp.types.forEach(type => acc[type] = comp.long_name);
        return acc;
    }, {});

    const streetNumber = components.street_number || '';
    const route = components.route || '';
    const city = components.locality ||
        components.sublocality_level_1 ||
        components.administrative_area_level_2 ||
        '';
    const state = components.administrative_area_level_1 || '';
    const zipCode = components.postal_code || '';

    return {
        streetAddress: [streetNumber, route].filter(Boolean).join(' ').trim(),
        streetFloor: '',
        city,
        state,
        zipCode
    };
}; 