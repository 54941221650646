import React from "react";
import { IoMdClose } from "react-icons/io";

const styles = {
  default: "bg-[var(--inyc-primary)]",
  active: "bg-[var(--inyc-secondary)] text-[var(--inyc-secondary-text-color)] gap-[10px]",
};

const DateTag = (props) => {
  return (
    <div
      className={`text-center font-['Primary-Medium'] duration-100 text-[0.8rem] flex justify-start items-center py-[0.7rem] px-[1rem] rounded-[3rem] cursor-pointer 
          border-[var(--inyc-input-border-color)] border-[1px]
        ${
        props.active ? styles.active : styles.default
      }`}
      {...props}
    >
      {props.children}
      {/* <div className={`overflow-hidden duration-100 pointer-events-none ${props.active ? " opacity-[1] w-[15px]" : "opacity-0 w-0 "}`}>
        <IoMdClose className={`pointer-events-none`} />
      </div> */}
    </div>
  );
};

export default DateTag;
