import React, { useEffect, useRef, useState } from "react";
import "./BottomSheet.css";
import { useMediaQuery } from "../../util/useMediaQuery";
import { useUIContext } from "../../context/UIContext/UIContext";
import { IoMap } from "react-icons/io5";

const MobileDragHandle = ({ Ref, onDrag }) => {
  const handleDrag = (e) => {
    // stop propagation on browser and screen reader events
    e.stopPropagation();
    e.preventDefault();
    // use stopImmediatePropagation to stop propagation on custom events
    e.nativeEvent?.stopImmediatePropagation();

    onDrag(e);
  };

  useEffect(() => {
    if (Ref.current) {
      Ref.current.addEventListener("touchstart", handleDrag, {
        passive: false,
      });
      Ref.current.addEventListener("mousedown", handleDrag, { passive: false });
    }

    return () => {
      if (Ref.current) {
        Ref.current.removeEventListener("touchstart", handleDrag, {
          passive: false,
        });
        Ref.current.removeEventListener("mousedown", handleDrag, {
          passive: false,
        });
      }
    };
  }, [Ref]);

  return <div ref={Ref} className="mobile-drag-handle"></div>;
};

const BottomSheet = ({
  useAsBottomSheetRef = false,
  children,
  defaultHeight = 40,
  heightBreakpoint = 45,
  initialHeight,
  dismissText = <>
    <IoMap
      className="inline-block mr-[0.5rem]"
    />
    map
  </>,
  maxHeight = "calc(100% - 5rem)",
  zIndex,
  draggable = true,
  showDismiss = true,
  collapseApp = false,
  doubleCollapse = false,
  contentStyle,
  bottomSheetStyle,
  onHeightChange,
  className,
  contentClassName
}) => {
  const medium = useMediaQuery("md");
  const [height, setHeight] = useState(initialHeight || defaultHeight);
  const { setCollapsed, setDoubleCollapsed, setBottomSheetRef } =
    useUIContext();
  const isDragging = useRef(false);
  const startY = useRef(0);
  const startHeight = useRef(0);
  const bottomSheet = useRef(null);
  const bottomSheetContent = useRef(null);
  const dragButton = useRef(null);

  // expose updateSheetHeight function to parent component
  useEffect(() => {
    if (onHeightChange) {
      onHeightChange(height);
    }
  }, [height]);


  const updateSheetHeight = (height) => {
    setHeight(height); //updates the height of the sheet content
    // Toggles the fullscreen class to bottomSheet if the height is equal to 100
    bottomSheet.current?.classList.toggle("fullscreen", height === 100);
  };

  // Sets initial drag position, sheetContent height and add dragging class to the bottom sheet
  const dragStart = (e) => {
    isDragging.current = true;
    startY.current = e.pageY || e.touches?.[0].pageY;
    startHeight.current = height;
    bottomSheet.current.classList.add("dragging");
  };
  // Calculates the new height for the sheet content and call the updateSheetHeight function
  const dragging = (e) => {
    if (!isDragging.current) return;
    const delta = startY.current - (e.pageY || e.touches?.[0].pageY);
    const newHeight = startHeight.current + (delta / window.innerHeight) * 100;
    updateSheetHeight(newHeight);
  };
  // Determines whether to hide, set to fullscreen, or set to default
  // height based on the current height of the sheet content
  const dragStop = () => {
    // isDragging = false;
    isDragging.current = false;
    bottomSheet.current.classList.remove("dragging");
    const sheetHeight = parseInt(
      bottomSheet.current.style.height.replace("vh", "")
    );
    sheetHeight > heightBreakpoint
      ? updateSheetHeight(100)
      : updateSheetHeight(defaultHeight);
  };

  useEffect(() => {
    if (useAsBottomSheetRef && bottomSheetContent.current)
      setBottomSheetRef({
        current: bottomSheetContent.current,
        updateSheetHeight: updateSheetHeight,
      });
  }, [bottomSheetContent]);

  useEffect(() => {
    if (draggable) {
      document.addEventListener("mousemove", dragging);
      document.addEventListener("mouseup", dragStop);
      document.addEventListener("touchmove", dragging);
      document.addEventListener("touchend", dragStop);
    }

    if (collapseApp) {
      doubleCollapse ? setDoubleCollapsed(true) : setCollapsed(true);
    }

    updateSheetHeight(defaultHeight);

    return () => {
      if (draggable) {
        document.removeEventListener("mousemove", dragging);
        document.removeEventListener("mouseup", dragStop);
        document.removeEventListener("touchmove", dragging);
        document.removeEventListener("touchend", dragStop);
      }
    };
  }, []);

  return (
    <>
      <div
        className="bottom-sheet-background"
        style={{
          opacity: `${(height - defaultHeight) / 100}`,
          pointerEvents: "none",
          maxHeight: maxHeight,
          zIndex: zIndex || 2000,
        }}
      ></div>
      <div
        ref={bottomSheet}
        className={`bottom-sheet md:!h-full md:!w-fit md:!rounded-none ${
          useAsBottomSheetRef ? "md:!block" : ""
        } ${className || ""}`}
        style={{
          height: `${height}vh`,
          minHeight: draggable ? `max(${defaultHeight}vh, 200px)` : "",
          maxHeight: maxHeight,
          zIndex: zIndex || 2000,
          ...(collapseApp && { overflow: "scroll" }),
          ...bottomSheetStyle,
        }}
      >
        {draggable && !medium && (
          <MobileDragHandle Ref={dragButton} onDrag={dragStart} />
        )}
        <div
          className="md:w-[30vw] md:min-w-[500px] overscroll-none"
          ref={bottomSheetContent}
          style={{
            height: "100%",
            maxHeight: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className={`content ${contentClassName}`} style={contentStyle}>
            {children}
          </div>
        </div>
        {showDismiss && (
          <div
            className="dismiss"
            onClick={() => {
              updateSheetHeight(defaultHeight);
              bottomSheetContent.current.scrollTop = 0;
            }}
          >
            {dismissText}
          </div>
        )}
      </div>
    </>
  );
};

export default BottomSheet;
