import ImageSlider from "../ImageSlider/ImageSlider";
import Input from "../Input/Input";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { styles } from "../PortalStyles/portalStyles.style";
import TagInput from "../TagInput/TagInput";
import CheckBoxYesNo from "../CheckBox/CheckBoxYesNo";
import Logo from "../../assets/imgs/inyc.svg";
import { useUser } from "../../context/UserContext/UserProvider";
import SocialsHandler from "../SocialsInput/SocialsHandler";
import { useMediaQuery } from "../../util/useMediaQuery";
import AddressHandler from "../AddressHandler/AddressHandler";
import barMarker from "../../assets/map/bar-marker.png";
import { Popup } from "react-leaflet";
import { ImHome } from "react-icons/im";
import { IoLocationSharp } from "react-icons/io5";
import { MarkerPopupButton } from "../Map/MapMarker";
import { MdEdit, MdMyLocation } from "react-icons/md";


const EditVenue = forwardRef(({ userLocation, formatVenueMarkers,venues, refreshVenues, handleVenuPick, selectedVenue = {}, setSelectedVenue, handleInputChange = () => { } }, ref) => {
    const medium = useMediaQuery("md");
    const nameRef = useRef();
    const { user } = useUser();
    const [inputTags, setInputTags] = useState([]);

    //address
    const addressHandlerRef = useRef();
    const socialsRef = useRef();
    const neighborhoodRef = useRef();
    const boroughRef = useRef();
    const descriptionRef = useRef();
    const venueTypeRef = useRef();
    const imagesRef = useRef();
    const outdoorRef = useRef();


    useEffect(() => {
        refreshVenues(userLocation)
    }, [userLocation])

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            const images = imagesRef.current.getValue();
            const form = new FormData();

            if (images?.length > 0) {
                images.forEach(img => form.append("images", img));
            }

            const address = addressHandlerRef.current.getAddressString();
            const location = addressHandlerRef.current.getLocation();

            const venue = {
                name: nameRef.current.value,
                venueType: venueTypeRef.current.value,
                address: address,
                socials: socialsRef.current.getValue(),
                neighborhood: neighborhoodRef.current.value,
                borough: boroughRef.current.value,
                tags: inputTags,
                description: descriptionRef.current.value,
                location: location,
                alcohol: selectedVenue.venue?.alcohol,
                food: selectedVenue.venue?.food,
                outdoor: selectedVenue.venue?.outdoor,
                images: imagesRef.current.getURLs() || []
            };

            form.append("venue", JSON.stringify(venue));
            return { form };
        }
    }));

    console.log('selectedVenue ',selectedVenue)

    const venuesToMarkers = (list) => {
        if (!list || list.length === 0) {
            return [];
        }
        if(formatVenueMarkers){
            return formatVenueMarkers(list)
        }
        let venuMarkers = [];


        // Iterate over each venue
        list.forEach((venue) => {
            // Get the coordinates from the 'location' field
            const [lng, lat] = venue.location.coordinates;

            // Create a new object with the desired format and push it to the array
            if (venue._id !== selectedVenue.venue?._id) {
                const ownership = venue.account_id ? venue.account_id === user?._id ? "edit your venue" : "claimed venue" : "claim this venue"
                venuMarkers.push({
                    lng,
                    lat,
                    icon: barMarker,
                    props: {
                        onVenueSelect: () => {
                            console.log('onVenueSelect');
                            //change address 
                            // const addressFields = parseStoredAddress(venue.address, venue.name);
                            const newState = {
                                address: venue.address,
                                location: venue.location
                            };

                            setSelectedVenue((prev) => ({
                                ...prev,
                                venue: {
                                    ...prev.venue,
                                    ...newState
                                }
                            }));
                            // console.log('venue', venue)
                            handleVenuPick(venue);
                        },
                        onMapClick: () => {
                            console.log('onMapClick');
                            //set addresshandlers position to current venue latlng 
                            // addressHandlerRef.current.setState(prev => {
                            //     console.log('prev', prev)
                            //     return ({
                            //         ...prev,
                            //         location: venue.location
                            //     })
                            // });

                            setSelectedVenue((prev) => ({
                                ...prev,
                                venue: {
                                    ...prev.venue,
                                    location: venue.location
                                }
                            }));
                        },
                        onCopyAddress: () => {
                            console.log('onCopyAddress');
                            //set address on addresshandler
                            // addressHandlerRef.current.setState(prev => ({
                            //     ...prev,
                            //     address: venue.address
                            // }));

                            setSelectedVenue((prev) => ({
                                ...prev,
                                venue: {
                                    ...prev.venue,
                                    address: venue.address,
                                    addressFields: null,
                                }
                            }));    

                            addressHandlerRef.current.goToTab('address');

                            //go to address page

                        },
                        Popup: (props) => {
                            const { onClose, onMapClick, onCopyAddress, onVenueSelect } = props;
                            return <Popup
                                style={{
                                    backgroundColor: "var(--inyc-primary-light)",
                                }}
                                className="bg-[--inyc-primary] w-full"
                                onClose={() => onClose()}
                                {...props}
                            >
                                <div
                                    className="bg-[--inyc-primary] w-full"
                                    style={{ padding: '0.5rem', minWidth: '15rem', borderRadius: '0.3rem' }}
                                >
                                    <div
                                        className="mb-[0.5rem]"
                                    >
                                        <div className="text-[--inyc-primary-text-color-light] text-[0.8rem]">
                                            venue
                                        </div>
                                        <div className="break-all mb-[0.2rem] text-[--inyc-primary-text-color] text-[1.2rem]">
                                            {venue?.name || 'Venue'}
                                        </div>
                                        <div className="text-[0.7rem] mb-[0.5rem] font-['Primary-Regular'] text-[--inyc-primary-text-color-light]">
                                            {venue?.address}
                                        </div>
                                    </div>
                                    <div
                                        className="flex items-center justify-start cursor-pointer px-[0.7rem] py-[0.5rem] rounded-[0.2rem] w-full hover:text-[--inyc-primary-text-color] transition-all duration-300 hover:bg-[--inyc-primary-light] text-[--inyc-primary-text-color] text-[1rem]"
                                        style={{ width: '100%' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onVenueSelect();
                                            onClose();
                                        }}
                                    >

                                        {ownership === 'claim this venue' ? <ImHome
                                            className="mr-[0.5rem]"
                                            size={11}
                                        /> : ownership === 'edit your venue' ? <MdEdit
                                            className="mr-[0.5rem]"
                                            size={11}
                                        /> : <ImHome
                                            className="mr-[0.5rem]"
                                            size={11}
                                        />}
                                        <div
                                            className={`text-[1rem] leading-[1.5rem] text-[--inyc-primary-text-color] ${ownership === 'claimed venue' ? 'text-[--inyc-primary-text-color-light]' : ''}`}
                                        >{ownership}</div>
                                    </div>
                                    <MarkerPopupButton
                                        Icon={MdMyLocation}
                                        text="use address"
                                        iconsize={13}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onCopyAddress();
                                            onClose();
                                        }}
                                    />
                                    <div
                                        className="flex items-center justify-start cursor-pointer px-[0.7rem] py-[0.5rem] rounded-[0.2rem] w-full hover:text-[--inyc-primary-text-color] transition-all duration-300 hover:bg-[--inyc-primary-light] text-[--inyc-primary-text-color] text-[1rem]"
                                        style={{ width: '100%' }}
                                        onClick={(e) => {
                                            //should be considered a normal map click
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onMapClick();
                                            onClose();
                                        }}
                                    >
                                        <IoLocationSharp
                                            className="mr-[0.5rem]"
                                            size={12}
                                        />
                                        <div
                                            className="text-[1rem] leading-[1.5rem] text-[--inyc-primary-text-color]"
                                        >add custom marker</div>
                                    </div>
                                </div>
                            </Popup>
                        }
                    },
                    venue: venue,
                });
            }
        });
        return venuMarkers;
    };

    const handleAddTag = (tag) => {
        setInputTags((prev) => [...prev, tag]);
    };

    const handleTagDelete = (tag) => {
        setInputTags((prev) => prev.filter((t) => t !== tag));
    };

    return <div
        className="portal-content md:mx-auto max-w-[1000px]"
        style={{
            //   ...styles.SplitContainer,
            ...{ padding: "1rem 1rem 3rem 1rem", maxWidth: 1000 },
        }}
    >
        <div style={{
            padding: "0",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
        }}>
            <Input
                name="name"
                Ref={nameRef}
                onChange={handleInputChange}
                value={selectedVenue.venue?.name || ""}
                label="name"
                placeHolder="venue name..."
            />
            <Input
                name="venueType"
                Ref={venueTypeRef}
                onChange={handleInputChange}
                value={selectedVenue.venue?.venueType || ""}
                label="type"
                placeHolder="venue type..."
            />
            <div className="font-['Primary-Medium'] text-[1.4rem]">
                upload an image
            </div>
            <ImageSlider
                images={selectedVenue.venue?.images || []}
                className="!mx-auto !h-[250px] !w-[250px] md:!h-[400px] md:!w-[400px]"
                style={{
                    height: "250px",
                    width: "250px",
                    margin: !medium ? "auto" : 0,
                }}
                edit
                ref={imagesRef}
            />
            <AddressHandler
                ref={addressHandlerRef}
                mapMarkers={venuesToMarkers(venues)}
                onSuggestionSelected={(selectionData) => {
                    // console.log('selectionData', selectionData)
                    // Handle venue selection separately from address
                    handleVenuPick(selectionData.rawSuggestion, true);

                }}
                onChange={(e) => {
                    console.log('onChange', e)
                    setSelectedVenue((prev) => ({
                        ...prev,
                        venue: {
                            ...prev.venue,
                            ...e
                        },
                    }));
                }}
                value={selectedVenue.venue}
                initialValue={selectedVenue.venue}
                renderSuggestion={(suggestion) => {
                    const verified = !!suggestion._id;
                    const mainText = verified ? suggestion.name : suggestion.structured_formatting.main_text;
                    const secondaryText = verified ? suggestion.address : suggestion.description;

                    return (
                        <div
                        // key={i}
                        // onClick={onClick}
                        // className="relative p-[1rem] whitespace-nowrap max-w-[250px] text-[0.8rem] shadow-md rounded-lg cursor-pointer"
                        >
                            {verified && (
                                <div className="px-[0.5rem] py-[0.1rem] flex justify-center items-center gap-[0.2rem] absolute top-[-1rem] right-[-0.5rem] bg-black text-white rounded-lg">
                                    <img src={Logo} alt="inyc verified" className="w-[1.5rem] h-[1.5rem] object-contain invert-[1]" />
                                    <div className="leading-[1rem] font-['Primary-Regular'] text-[0.7rem] text-white">
                                        verified
                                    </div>
                                </div>
                            )}
                            <div>
                                <div className="font-['Primary-Medium']">
                                    {mainText.toLowerCase()}
                                </div>
                                {secondaryText && (
                                    <div className="text-gray-500 max-w-full overflow-hidden overflow-ellipsis mt-1">
                                        {secondaryText.toLowerCase()}
                                    </div>
                                )}
                                {verified && (
                                    <div className="mt-2 flex justify-between items-center">
                                        {suggestion.account_id ? (
                                            suggestion.account_id === user?._id ? (
                                                <div className="text-[--inyc-primary-text-color] text-xs">owned by you</div>
                                            ) : (
                                                <div className="text-[--inyc-primary-text-color] text-xs">claimed venue</div>
                                            )
                                        ) : (
                                            <div className="text-[--inyc-primary-text-color] font-['Primary-Medium'] text-xs">
                                                claim this venue
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }}
                userLocation={userLocation}
            />
            <Input
                name="neighborhood"
                Ref={neighborhoodRef}
                onChange={handleInputChange}
                value={selectedVenue.venue?.neighborhood || ""}
                label="neighborhood"
                placeHolder="neighborhood..."
            />
            <Input
                name="borough"
                Ref={boroughRef}
                onChange={handleInputChange}
                value={selectedVenue.venue?.borough || ""}
                label="borough"
                placeHolder="borough..."
            />
            <SocialsHandler
                ref={socialsRef}
                value={selectedVenue.venue?.socials}
                onChange={(e) => {
                    setSelectedVenue((prev) => {

                        // console.log('future selectedVenue ', {
                        //     ...prev,
                        //     venue: {
                        //         ...prev.venue,
                        //         socials: e,
                        //     },
                        // })
                        return ({
                            ...prev,
                            venue: {
                                ...prev.venue,
                                socials: e,
                            },
                        })

                    }

                    )
                }}
            />
            <Input
                name="description"
                Ref={descriptionRef}
                onChange={handleInputChange}
                value={selectedVenue.venue?.description || ""}
                label="description"
                placeHolder="venue description..."
                multiLine
                style={{ minHeight: "200px" }}
            />
            {/* // add alcohol and food */}
            <div style={styles.CheckBoxContainer}>
                <div
                    className="font-['Primary-Medium']"
                    style={styles.CheckBoxInputLabel}
                >
                    alcohol :
                </div>
                <CheckBoxYesNo
                    value={selectedVenue.venue?.alcohol}
                    onChange={(e) => {
                        setSelectedVenue((prev) => ({
                            ...prev,
                            venue: {
                                ...prev.venue,
                                alcohol: e,
                            },
                        }));
                    }}
                />
            </div>
            <div style={styles.CheckBoxContainer}>
                <div
                    className="font-['Primary-Medium']"
                    style={styles.CheckBoxInputLabel}
                >
                    food :
                </div>
                <CheckBoxYesNo
                    value={selectedVenue.venue?.food}
                    onChange={(e) => {
                        setSelectedVenue((prev) => ({
                            ...prev,
                            venue: {
                                ...prev.venue,
                                food: e,
                            },
                        }));
                    }}
                />
            </div>
            <div style={styles.CheckBoxContainer}>
                <div
                    className="font-['Primary-Medium']"
                    style={styles.CheckBoxInputLabel}
                >
                    outdoors :
                </div>
                <CheckBoxYesNo
                    Ref={outdoorRef}
                    value={selectedVenue.venue?.outdoor}
                    onChange={(e) => {
                        setSelectedVenue((prev) => ({
                            ...prev,
                            venue: {
                                ...prev.venue,
                                outdoor: e,
                            },
                        }));
                    }}
                />
            </div>
            <TagInput
                tags={inputTags}
                onTagAdd={handleAddTag}
                placeHolder="tags..."
                onTagDelete={handleTagDelete}
            />
        </div>
    </div>
})


export default EditVenue;