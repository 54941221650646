import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/imgs/logo-transparent-light.gif";
import { useUser } from "../../context/UserContext/UserProvider";
import { useData } from "../../context/DataContext/DataProvider";
import Loader from "../icons/Loader";

const UILoader = ({ style }) => {
  const { isLoading } = useUser();
  const [appLoading, setAppLoading] = useState();
  const { userLocationLoading } = useData();
  const loaded = useRef(false);

  useEffect(() => {
    if (loaded.current) return;
    setAppLoading(true);
  
    const runCheck = () => {
      console.log("checking", isLoading, userLocationLoading);
      if (!isLoading && !userLocationLoading) {
        setAppLoading(false);
        loaded.current = true;
        clearTimeout(maxTimeout);
      } else {
        timer = setTimeout(runCheck, 1000);
      }
    };
  
    let timer = setTimeout(runCheck, 1000);
    let maxTimeout = setTimeout(() => {
      setAppLoading(false);
      loaded.current = true;
      clearTimeout(timer);
    }, 8000);
  
    return () => {
      clearTimeout(timer);
      clearTimeout(maxTimeout);
    };
  }, [isLoading, userLocationLoading]);

  return (
    <div
      style={{
        width: "100vw",
        height: "calc(100vh - var(--inyc-bottom-space))",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 20000,
        background: "var(--inyc-loader-background)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        transition: "0.3s",
        alignItems: "center",
        opacity: appLoading === undefined ? 1 : appLoading ? 1 : 0,
        pointerEvents: appLoading === undefined ? 'all' : appLoading ? "all" : "none",
      }}
    >
      <img
        src={Logo}
        alt="logo"
        className="invert-[var(--inyc-invert-black)]"
        style={{
          width: "90%",
          maxWidth: "700px",
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
      <Loader />
      <div
        className="text-[1.1rem] bottom-[2vh] md:text-[1.3rem] max-w-[700px]"
        style={{
          position: "absolute",
          // color: "rgba(255,255,255,0.7)",
          color: "var(--inyc-primary-text-color)",
          padding: "2rem",
          textAlign: "center",
          fontFamily: "Primary-Medium",
        }}
      >
        easily discover the arts and artist-posted events 
       <br
        className="md:block hidden"
       />
        — no curation, no algorithm.
      </div>
    </div>
  );
};

export default UILoader;
