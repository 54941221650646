import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import DropDownInput from "../DropDownInput/DropDownInput";
import Input from "../Input/Input";
import { FaTrash } from "react-icons/fa6";
import websiteIcon from "../../assets/icons/website.svg";
import Icon from "../Icon/Icon";
import { ErrorMessage } from "../Input/InputError";
import { socialOptions } from "../../util/socials";
import { getSocialOption } from "../../util/styleUtils";
import { IoCloseOutline } from "react-icons/io5";
import { FaCirclePlus } from "react-icons/fa6";

export const SocialLinkOption = ({ label, iconClassName, Icon }) => {
  return (
    <div className="flex justify-start items-center">
      <div>
        <Icon
          className={`inline-block mr-[0.7rem] translate-y-[-10%] ${iconClassName}`}
          color="var(--inyc-primary-text-color-light)"
          size={18}
        />
      </div>
      <div className="text-[1.1rem] leading-[1rem]">{label}</div>
    </div>
  );
};

const options = socialOptions.map((opt) => {
  return {
    ...opt,
    label: <SocialLinkOption label={opt.id} Icon={opt.rawIcon} />,
  };
});

//add a custom option
options.push({
  id: "custom",
  label: (
    <SocialLinkOption
      label="custom"
      Icon={options?.find((opt) => opt.id === "website")?.rawIcon}
    />
  ),
});

// const SocialsInput = forwardRef(
//   (
//     {
//       linkError,
//       platformError,
//       value,
//       onChange,
//       z,
//       onDelete,
//       onAddSocial,
//       opts = [],
//     },
//     ref
//   ) => {
//     const [selectedSocial, setSelectedSocial] = useState(value?.platform || "");
//     // value?.platform !== undefined ? value.platform : options[0].id
//     const [socialLink, setSocialLink] = useState(value?.link || "");
//     const dropdownRef = useRef();
//     const linkRef = useRef();
//     const platformRef = useRef();

//     useImperativeHandle(ref, () => ({
//       getWrapperRef: () => dropdownRef.current.getWrapperRef(),
//       focusLinkInput: () => linkRef.current.focus(),
//       focusPlatformInput: () => platformRef.current.focus(),
//       linkInput: () => linkRef.current,
//       platformInput: () => platformRef.current,
//     }));

//     // useEffect(() => {
//     //   console.log(dropdownRef.current);
//     // }, [dropdownRef]);

//     useEffect(() => {
//       if (value) {
//         setSelectedSocial(value?.platform || "");
//         setSocialLink(value?.link || "");
//       }
//     }, [value]);
    

//     // useEffect(() => {
//     //   // alert(`value ${JSON.stringify(value,null,2)}`);
//     //   if (value) {
//     //     if (value.platform !== undefined) {
//     //       setSelectedSocial(value.platform);
//     //     }

//     //     setSocialLink(value.link || "");
//     //   }
//     // }, [value]);

//     useEffect(() => {
//       if (value?.platform !== selectedSocial || value?.link !== socialLink) {
//         onChange && onChange({ platform: selectedSocial, link: socialLink });
//       }
//     }, [selectedSocial, socialLink]);

//     const dropdownWrapperRef = dropdownRef?.current?.getWrapperRef();
//     const selectedOption = getSocialOption(socialLink);
//     const placeholder = selectedOption?.placeholder || "display name";
//     return (
//       <div className="relative [&:hover>.close-btn]:pointer-events-auto [&:hover>.close-btn]:opacity-[1]">
//         <div className="">
//           <Input
//             Ref={linkRef}
//             value={socialLink}
//             onChange={(e) => {
//               console.log('onchange ',e.target.value);
//               const selectedOp = getSocialOption(e.target.value);
//               if (selectedOp?.extract) {
//                 setSelectedSocial(selectedOp.extract(e.target.value));
//               }
//               setSocialLink(e.target.value);
//               // if extract is applicable then extract the username
//             }}
//             placeHolder="link"
//             icon={
//               getSocialOption(socialLink).icon || (
//                 <Icon
//                   className="!w-[25px] invert-[var(--inyc-lighten-white)]"
//                   src={websiteIcon}
//                 />
//               )
//             }
//             InlineComp={() => (
//               <div className="flex h-full gap-[1.3rem] items-center justify-end">
//                 <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
//                   <FaCirclePlus
//                     size={17}
//                     color="var(--inyc-primary-text-color-light)"
//                     className="cursor-pointer duration-300"
//                     onClick={onAddSocial}
//                   />
//                 </div>
//                 <div
//                   className="w-[1.6rem] h-[1.6rem] flex items-center justify-center"
//                   onClick={onDelete}
//                 >
//                   <IoCloseOutline
//                     size={18}
//                     color="var(--inyc-primary-text-color-light)"
//                     className="cursor-pointer duration-300"
//                   />
//                 </div>
//               </div>
//             )}
//           />
//           {linkError && <ErrorMessage>{linkError}</ErrorMessage>}
//         </div>
//         <div
//           className="mb-[-1rem] mt-[0.3rem] px-[1rem] text-[0.9rem]"
//         >
//           paste the url above
//         </div>
//         <Input
//           Ref={platformRef}
//           value={selectedSocial}
//           onChange={(e) => {
//             setSelectedSocial(e.target.value);
//             const url = selectedOption?.validateAndCompleteLink ? selectedOption?.validateAndCompleteLink(
//               socialLink,
//               e.target.value
//             ) : socialLink;
//             // console.log('url ' , url);
//             setSocialLink(url);
//           }}
//           placeHolder={placeholder}
//         />
//         {platformError && <ErrorMessage>{platformError}</ErrorMessage>}
//       </div>
//     );
//   }
// );

// export default SocialsInput;
const SocialsInput = forwardRef(
  (
    {
      linkError,
      platformError,
      value = { platform: "", link: "" },
      onChange,
      z,
      onDelete,
      onAddSocial,
      opts = [],
    },
    ref
  ) => {
    const dropdownRef = useRef();
    const linkRef = useRef();
    const platformRef = useRef();

    useImperativeHandle(ref, () => ({
      getWrapperRef: () => dropdownRef.current?.getWrapperRef?.(),
      focusLinkInput: () => linkRef.current?.focus(),
      focusPlatformInput: () => platformRef.current?.focus(),
      linkInput: () => linkRef.current,
      platformInput: () => platformRef.current,
    }));

    const handleLinkChange = (e) => {
      const newLink = e.target.value;
      const selectedOption = getSocialOption(newLink);
      const newPlatform = selectedOption?.extract?.(newLink) || value.platform;
      
      onChange({
        platform: newPlatform,
        link: newLink
      });
    };

    const handlePlatformChange = (e) => {
      const newPlatform = e.target.value;
      const selectedOption = getSocialOption(value.link);
      const newLink = selectedOption?.validateAndCompleteLink?.(
        value.link, 
        newPlatform
      ) || value.link;

      onChange({
        platform: newPlatform,
        link: newLink
      });
    };

    const selectedOption = getSocialOption(value.link);
    const placeholder = selectedOption?.placeholder || "display name";

    return (
      <div className="relative [&:hover>.close-btn]:pointer-events-auto [&:hover>.close-btn]:opacity-[1]">
        <div>
          <Input
            Ref={linkRef}
            value={value.link}
            onChange={handleLinkChange}
            placeHolder="link"
            icon={
              selectedOption?.icon || (
                <Icon
                  className="!w-[25px] invert-[var(--inyc-lighten-white)]"
                  src={websiteIcon}
                />
              )
            }
            InlineComp={() => (
              <div className="flex h-full gap-[1.3rem] items-center justify-end">
                <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
                  <FaCirclePlus
                    size={17}
                    color="var(--inyc-primary-text-color-light)"
                    className="cursor-pointer duration-300"
                    onClick={onAddSocial}
                  />
                </div>
                <div
                  className="w-[1.6rem] h-[1.6rem] flex items-center justify-center"
                  onClick={onDelete}
                >
                  <IoCloseOutline
                    size={18}
                    color="var(--inyc-primary-text-color-light)"
                    className="cursor-pointer duration-300"
                  />
                </div>
              </div>
            )}
          />
          {linkError && <ErrorMessage>{linkError}</ErrorMessage>}
        </div>
        <div className="mb-[-1rem] mt-[0.3rem] px-[1rem] text-[0.9rem]">
          paste the url above
        </div>
        <Input
          Ref={platformRef}
          value={value.platform}
          onChange={handlePlatformChange}
          placeHolder={placeholder}
        />
        {platformError && <ErrorMessage>{platformError}</ErrorMessage>}
      </div>
    );
  }
);

export default SocialsInput;