import L from "leaflet";
import wjfIcon from "../assets/icons/wjf-logo.png";

let initialised = false;
const icons = {
  selectedIcon: L.divIcon({
    html: `<div style="width:24px; height:24px; border-radius:50%;display:flex;justify-content:center;align-items:center;transform:translate(-25%,-25%)">
        <div
      style="position:relative;z-index:10;background-color:white; width:22px;height:22px;box-shadow:0px 0px 13px rgba(0,0,0,0.7); border-radius:50%; display:flex; justify-content:center; align-items:center;"
    >
    <div style="background-color:maroon; width:18px;height:18px;border-radius:50%;"></div>
    </div>
      </div>`,
      iconSize: [20, 20],
    iconAnchor:  [10, 10], // to center the icon
    className: "border-none bg-transparent",
  }),
  userIcon: L.divIcon({
    html: `
    <div
      class="pulsing-dot"
      style="position:relative;z-index:100;background-color:var(--inyc-secondary);display:flex;justify-items:center;align-items:center;width:12px;height:12px;box-shadow:0px 0px 13px rgba(0,0,0,0.7); border-radius:50%; display:flex; justify-content:center; align-items:center;"
    >
    </div>
    `,
    iconSize: [18, 18],
    iconAnchor: [9, 9], // to center the icon
    className: "border-none bg-transparent",
  }),
};

// for (const category of categories) {
//   icons[category.id] = L.divIcon({
//     html: `<div style="width:20px; height:20px; border-radius:50%;display:flex;justify-content:center;align-items:center;transform:translate(-25%,-25%)">
//       <div
//         style="background-color:${category.activeColor}; width:10px; height:10px; border-radius:50%;"
//       >
//       </div>
//     </div>`,
//     iconSize: [10, 10],
//     iconAnchor: [5, 5], // to center the icon
//     className: "border-none bg-transparent",
//   });
// }

export function createCategoryIcons(categories) {
  console.log("initialising icons with categories", categories);

  for (const category of categories) {
    for (let i = 1; i <= 6; i++) {
      const numberOfEvents = i;
      // console.log("setting icon : ", `${category.id}${numberOfEvents || ""}`);
      icons[`${category.id}${numberOfEvents || ""}`] = L.divIcon({
        html: `<div style="width:24px; height:24px; border-radius:50%;display:flex;justify-content:center;align-items:center;transform:translate(-25%,-25%)">
          <div
        style="position:relative;z-index:10;background-color:white; width:18px;height:18px;border-radius:50%; display:flex; justify-content:center; align-items:center;"
      >
        <div style="background-color:${
          category.activeColor
        };font-size:1rem;width:16px;height:16px;border-radius:50%;color:white">
          <div
          style="height:16px;width:16px;display:flex;align-items:center;justify-content:center;text-align:center;color:white;${
            numberOfEvents > 5
              ? "font-size:10px"
              : "font-size:14px"
          };margin:auto;font-family:Primary-Bold"
          >${
            numberOfEvents >= 6 ? "5+" : numberOfEvents > 1 ? numberOfEvents : ""
          }</div>
        </div>
      </div>
        </div>`,
        iconSize: [20, 20],
        iconAnchor: [7, 7], // to center the icon
        className: "border-none bg-transparent",
      });
    }

    // Add smaller version of the icon
    // console.log("setting small icon : ", `${category.id}small`);
    icons[`${category.id}small`] = L.divIcon({
      html: `<div style="width:8px;position:relative; height:8px; border-radius:50%;display:flex;justify-content:center;align-items:center;transform:translate(-25%,-25%);background-color:white">
      <div
      style="background-color:${category.activeColor}; width:6px;height:6px;border-radius:50%;"
    ></div>
      </div>`,
      iconSize: [8, 8],
      iconAnchor: [4, 4], // to center the icon
      className: "border-none bg-transparent",
    });
  }
  initialised = true;
}

export const createImgIcon = (img,numberOfEvents) => {
  return L.divIcon({
    html: `<div style="width:28px; height:28px; border-radius:50%;display:flex;justify-content:center;align-items:center;transform:translate(-25%,-25%)">
        <div
      style="position:relative;z-index:10;background-color:white; width:26px;height:26px;border-radius:50%; display:flex; justify-content:center; align-items:center;"
    >
          ${numberOfEvents > 1 ? `<div
        style="position:absolute;top:0;left:0;transform:translate(80%,-50%);background-color:black;color:white;z-index:10;line-height:1rem;font-family:Primary-Medium;${
            numberOfEvents > 5 ? "font-size:0.8rem" : "font-size:0.9rem"
        };padding:0.7rem; width:15px;height:15px;border-radius:50%; display:flex; justify-content:center; align-items:center;"
      >
      ${numberOfEvents > 5 ? "5+" : numberOfEvents > 1 ? numberOfEvents : ""}
      </div>` : '' } 
      <img src=${img} 
        style="width:22px;height:22px;opacity:0.9"
      />

    </div>
      </div>`,
    iconSize: [10, 10],
    iconAnchor: [5, 5], // to center the icon
    className: "border-none bg-transparent",
  });
};

export function createCategoryIcon(
  categoryId,
  overridecolor,
  numberOfEvents,
  small = false
) {
  if (!initialised) {
    const getIcon = () => {
      if (initialised) {
        return small
          ? icons[`${categoryId}small`] || icons["music1small"]
          : icons[
              `${categoryId}${numberOfEvents > 6 ? "6" : numberOfEvents || 1}`
            ] || icons["music1"];
      } else {
        setTimeout(getIcon, 1000);
      }
    };
    getIcon();
  }

  if (overridecolor) {
    return icons.selectedIcon;
  }

  return small
    ? icons[`${categoryId}small`] || icons["music1small"]
    : icons[
        `${categoryId}${numberOfEvents > 6 ? "6" : numberOfEvents || 1}`
      ] || icons["music1"];
}


export function createUserIcon() {
  return icons.userIcon;
}

export const createIconFromImage = (image, size, anchor) => {
  return L.icon({
    iconUrl: image,
    // shadowUrl: markerShadow,
    // shadowAnchor: [15, 40],
    iconSize: size || [15, 15],
    iconAnchor: anchor || [8.1, 9],
  });
};
