import React from "react";

const VenueDropDownItem = ({ item, onClick }) => {
  return (
    <div
      className="dropdown-item"
      style={styles.venuContainer}
      onClick={() => {
        onClick && onClick();
        item.onClick && item.onClick();
      }}
    >
      <div style={styles.venuTitle}>{item.item.name}</div>
      <div style={styles.venuLocation}>
        <div>{item.item.address}</div>
        <div>{item.item.neighborhood}</div>
      </div>
    </div>
  );
};

const styles = {
  venuContainer: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderBottom: "1px solid rgba(0,0,0,0.05)",
  },
  venuTitle: {
    fontSize: "1.2rem",
    marginTop: "10px",
    fontFamily: "Primary-Bold",
  },
  venuLocation: {
    marginTop: "10px",
    color: "rgba(0,0,0,0.5)",
    fontSize: "1.1rem",
  },
};

export default VenueDropDownItem;
