import React, { useEffect } from "react";
import { TileLayer, useMap } from "react-leaflet";
import { useUIContext } from "../../context/UIContext/UIContext";

const MapTiles = () => {
  const { darkMode } = useUIContext();
  const map = useMap(); // Get access to the map instance
  
  const lightTileUrl = `https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en`;
  const darkTileUrl =
    "https://tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=sU2JxqeHLIuGEEAWX5p8BoK7LeMCdLeKgvXC6LCKryFZ1bXah2HUukA7ffczvusA";

  // Use a unique key based on the current mode to force re-render
  const tileUrl = darkMode ? darkTileUrl : lightTileUrl;
  const tileKey = darkMode ? "dark" : "light";
  
  // When tiles fail to load, try to reload them
  useEffect(() => {
    const handleTileError = (e) => {
      console.log("Tile error, attempting reload");
      setTimeout(() => {
        e.tile.src = e.tile.src + "?reload=" + new Date().getTime();
      }, 1000);
    };
    
    map.on('tileerror', handleTileError);
    
    return () => {
      map.off('tileerror', handleTileError);
    };
  }, [map]);

  return (
    <TileLayer
      keepBuffer={8} // Increase buffer size to keep more tiles in memory
      updateWhenIdle={false} // Update tiles when panning
      updateWhenZooming={false} // Continue showing tiles during zoom
      key={tileKey} 
      url={tileUrl} 
      subdomains={darkMode ? [] : ["mt1", "mt2", "mt3"]}
      errorTileUrl="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" // Blank transparent image for error tiles
      className="tile-layer"
      maxZoom={18}
      minZoom={9}
    />
  );
};

export default MapTiles;
