import Loader from "../icons/Loader";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

const InputMessage = ({ messageObj, className }) => {
  return (
    <div className={"auth-input-message text-start " + className}>
      {messageObj &&
        (messageObj === "loading" ? (
          <div className="auth-input-loader"></div>
        ) : messageObj.error ? (
          <div
            className="auth-error"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              margin: "1rem",
              color: "rgb(223, 44, 44)",
            }}
          >
            <BsFillXCircleFill
              className="min-w-[20px]"
              style={{
                transform: "translateY(-2px)",
              }}
              size={20}
            />

            {messageObj.error}
          </div>
        ) : (
          messageObj.success && (
            <div className="auth-success"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              margin: "1rem",
              // color: "rgb(223, 44, 44)",
            }}
            >
              <BsFillCheckCircleFill
                className="min-w-[20px]"
                style={{
                  transform: "translateY(-2px)",
                }}
                size={20}
              />
              {messageObj.success}
            </div>
          )
        ))}
    </div>
  );
};

export default InputMessage;
